import React from 'react';
import Footer from '../../../Footer/Footer';
import TermsContent from './TermsContent';
import Headerinner from '../../../Header/Headerinner';

const TermsOffer = () => {
    return (
        <>
            <Headerinner/>
            <TermsContent/>
            <Footer/>
        </>
    );
};

export default TermsOffer;