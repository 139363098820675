import React, { useState } from "react";
import "react-phone-number-input/style.css";
import * as Yup from "yup";
import { Form, Col, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik } from "formik";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import url from "../../../../constants/url";
import SubmitLoader from "../../../common/SubmitLoader"

export default function ContactUsForm() {
    const [isShow, setIsShow] = useState(false);
    const [isLength, setIsLength] = useState(0);
    const [value, setValue] = useState();
    const [loader, setLoader] = useState(false);
    const [UIValues, setUIValues] = useState({
        name: "",
        email: "",
        query: "",
        contact: "",
        message: "",
        other_query_type: "",
    });
    const [validation, setValidation] = useState({
        name: "",
        contact: "",
        email: "",
        query: "",
        message: "",
        other_query_type: "",
    });
    const onSubmit = (values, { resetForm }) => {
        var formdata = new FormData();
        formdata.append("name", values.name);
        formdata.append("contact", values.contact);
        formdata.append("email", values.email);
        formdata.append("query", values.query);
        if (values.query === "other") {
            formdata.append("other_query_type", values.other_query_type);
        }
        formdata.append("message", values.message);

        var requestOptions = {
            method: "POST",
            body: formdata,
        };

        setLoader(true);
        fetch(`${url.apiUrl}web/contact-us`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setLoader(false);
                    toast.success("Submitted Successfully");
                    setValue();
                    resetForm();

                    setUIValues({
                        name: "",
                        contact: "",
                        email: "",
                        query: "",
                        message: "",
                        other_query_type: "",
                    });
                    setTimeout(() => {
                        document.getElementById("contact").reset();
                    }, 5000);
                } else {
                    resetForm();
                    setLoader(false);
                    toast.error("Something Want Wrong", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch((error) => console.log("error", error));
    };

    const VoucherSchema = Yup.object().shape({
        name: Yup.string().required("Required"),
        query: Yup.string().required("Required"),
        email: Yup.string().email("Please enter valid email ID").required("Required"),
        message: Yup.string().required("Required").max(500, "Please write less than 500 characters !"),
        other_query_type: Yup.string()
            .when("query", {
                is: (query) => query && query === "other",
                then: Yup.string().required("Required"),
            })
            .nullable(),
        contact: Yup.string().required("Required").min(13, "Please enter valid contact number").max(13, "Please enter valid contact number"),
    });
    return (
        <>
            <Formik
                initialValues={UIValues}
                validationSchema={VoucherSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {(props) => (
                    <form id="contact" onSubmit={props.handleSubmit}>
                        <Row>
                            <Col md={12} className="form-group">
                                <Form.Group>
                                    <Form.Label>
                                        Name <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        name="name"
                                        value={props.values.name}
                                        onChange={props.handleChange}
                                        placeholder="Please enter name"
                                    />
                                    {props.touched.name && props.errors.name ? (
                                        <div className="bg-error">{props.errors.name}</div>
                                    ) : (
                                        <p className="bg-error">{validation.name}</p>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={12} className="form-group">
                                <Form.Group>
                                    <Form.Label>
                                        Contact No. <span className="text-danger">*</span>
                                    </Form.Label>
                                    <PhoneInputWithCountrySelect
                                        name="contact"
                                        value={props.values.contact}
                                        onChange={(e) => {
                                            props.setFieldValue("contact", e);
                                        }}
                                        defaultCountry="IN"
                                        className="form-control"
                                        placeholder="Please enter contact no"
                                    />
                                    {props.touched.contact && props.errors.contact ? (
                                        <div className=" bg-error">{props.errors.contact}</div>
                                    ) : (
                                        <p className="bg-error">{validation.contact}</p>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={12} className="form-group">
                                <Form.Group>
                                    <Form.Label>
                                        Email ID <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="email"
                                        value={props.values.email}
                                        onChange={props.handleChange}
                                        placeholder="Please enter email id"
                                    />
                                    {props.touched.email && props.errors.email ? (
                                        <div className=" bg-error">{props.errors.email}</div>
                                    ) : (
                                        <p className="bg-error">{validation.email}</p>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={12} className="form-group">
                                <Form.Group>
                                    <Form.Label>
                                        Query <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name="query"
                                        onChange={(e) => {
                                            props.setFieldValue("query", e.target.value);
                                        }}
                                    >
                                        <option>Select query</option>
                                        <option value="course">Course</option>
                                        <option value="admission">Admission</option>
                                        <option value="institutes">Institutes</option>
                                        <option value="teachers">Teachers</option>
                                        <option value="payment">Payment</option>
                                        <option value="career_guidance">Career Guidance</option>
                                        <option value="education_loan">Education Loan</option>
                                        <option value="learning_management_system">Learning Management System</option>
                                        <option value="terms_and_conditions">Terms and Conditions</option>
                                        <option value="other">Other</option>
                                    </Form.Select>
                                    {props.touched.query && props.errors.query ? (
                                        <div className=" bg-error">{props.errors.query}</div>
                                    ) : (
                                        <p className="bg-error">{validation.query}</p>
                                    )}
                                </Form.Group>
                            </Col>
                            {props.values.query == "other" && (
                                <Col md={12} className="form-group">
                                    <Form.Group>
                                        <Form.Label>
                                            Other Query <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            name="other_query_type"
                                            value={props.values.other_query_type}
                                            onChange={props.handleChange}
                                            placeholder="Please enter other query"
                                        />
                                        {props.touched.other_query_type &&
                                            props.errors.other_query_type ? (
                                            <div className=" bg-error">
                                                {props.errors.other_query_type}
                                            </div>
                                        ) : (
                                            <p className="bg-error">
                                                {validation.other_query_type}
                                            </p>
                                        )}
                                    </Form.Group>
                                </Col>
                            )}
                            <Col md={12} className="form-group">
                                <Form.Group>
                                    <Form.Label>
                                        Message <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        className="form-control"
                                        name="message"
                                        value={props.values.message}
                                        placeholder="Leave your message here"
                                        onChange={props.handleChange}
                                    />
                                    {props.touched.message && props.errors.message ? (
                                        <div className=" bg-error">{props.errors.message}</div>
                                    ) : (
                                        <p className="bg-error">{validation.message}</p>
                                    )}
                                    <p className="text-end mb-0">
                                        {props.values.message?.length} / 500 Characters
                                    </p>
                                </Form.Group>
                            </Col>
                            <div className="col-md-12 text-end">
                                {loader ? (
                                    <SubmitLoader />
                                ) : (
                                    <button type="submit" className="btn btn-primary">
                                        Submit
                                    </button>
                                )}
                            </div>
                        </Row>
                    </form>
                )}
            </Formik>
            <ToastContainer />
        </>
    );
}
