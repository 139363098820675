import React, { useContext, useEffect } from "react";
import CardStatistics from "./CardStatistics";
import ReviewFeedback from "./ReviewFeedback";
import { Context } from '../../../../Context';
import PasswordPopup from '../../../LoginArea/PasswordPopup'

const DashboardTeacherProfile = () => {
    const { activities } = useContext(Context);
    const [active, setActive] = activities
    useEffect(() => {
        sessionStorage.setItem("url", window.location.pathname)
    }, [])
    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active" aria-current="page">
                        Dashboard
                    </li>
                </ol>
            </div>
            <CardStatistics />
            <ReviewFeedback />
            {active ? <PasswordPopup /> : ""}
        </>
    );
};

export default DashboardTeacherProfile;
