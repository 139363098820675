import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { getStudentAddEducation, updateStudentEducation } from "../../../../apis";
import url from "../../../../constants/url";
import Moment from "react-moment";
import { toast } from "react-toastify";
import moment from "moment";
import SubmitLoader from "../../../common/SubmitLoader";
import NoDataAvailable from "../../../common/NoDataAvailable";

const Education = (props) => {
    const [form, setForm] = useState(false);
    const [loader, setLoader] = useState(false);
    const date = moment().format("YYYY-MM-DD")
    const [openModal, setOpenModal] = useState(false);
    const [formValidation, setFormValidation] = useState([]);
    const [data, setData] = useState([]);
    const [isPresetnt, setIsPresetnt] = useState(0);
    const currentDate = moment().format("YYYY-MM-DD")
    const initialValue = {
        school_college: "",
        degree: "",
        field_of_study: "",
        is_present: 0,
        start_date: "",
        end_date: "",
    }
    const [addeudcation, setAddeudcation] = useState(initialValue);

    const closeModalHandle = () => {
        setOpenModal(false);
    };

    const handleChangeIsPresent = (event) => {
        let value = 0;
        if (event.target.checked) {
            value = 1;
            setIsPresetnt(1);
        } else {
            value = 0;
            setIsPresetnt(0);
        }
        setAddeudcation({
            ...addeudcation,
            ["is_present"]: value,
        });
    };

    const handleChangeEdu = (e, key) => {
        let value = e.target.value;
        e.preventDefault();
        setAddeudcation({ ...addeudcation, [key]: value });
    };

    const handleAddUPdateEdu = async () => {
        const errors = {};

        if (
            addeudcation.school_college === undefined ||
            addeudcation.school_college === ""
        ) {
            errors.school_college = "Required";
        }
        if (addeudcation.degree === undefined || addeudcation.degree === "") {
            errors.degree = "Required";
        }
        if (
            addeudcation.field_of_study === undefined ||
            addeudcation.field_of_study === ""
        ) {
            errors.field_of_study = "Required";
        }
        if (
            addeudcation.start_date === undefined ||
            addeudcation.start_date === ""
        ) {
            errors.start_date = "Required";
        }
        if (
            addeudcation.is_present === undefined ||
            addeudcation.is_present === 0
        ) {
            if (addeudcation.end_date === undefined || addeudcation.end_date === "") {
                errors.end_date = "Required";
            } else {
                const start_date = moment(addeudcation.start_date, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
                const end_date = moment(addeudcation.end_date, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
                if (start_date.isValid() && end_date.isAfter(start_date)) {
                    console.log();
                } else {
                    errors.end_date = "Please enter end date grater then start date";
                }
            }
        }

        if (Object.keys(errors).length === 0) {
            var res;
            if (addeudcation?.id) {
                addeudcation.education_id = addeudcation?.id
                res = await updateStudentEducation(JSON.stringify(addeudcation));
            } else {
                res = await getStudentAddEducation(JSON.stringify(addeudcation));
            }
            setOpenModal(false);
            if (res.status === "success") {
                setForm(true)
                closeModalHandle();
                getStudentEducation();
                setAddeudcation(initialValue);
                setFormValidation([])
                setForm(false)
                toast.success(res.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } else {
            setFormValidation(errors);
        }
    };

    const EditFormData = async (e, id) => {
        setForm(true);
        setFormValidation([])
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("AUTHTOKEN", auth_token);

        var raw = JSON.stringify({
            education_id: id,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
        };

        setLoader(true);
        fetch(`${url.apiUrl}student/get-education-by-id`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setLoader(false);
                    setAddeudcation(result.data);
                    setIsPresetnt(result?.data?.is_present);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const getStudentEducation = async () => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
        };

        fetch(`${url.apiUrl}student/get-all-educations`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setData(result.data);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const removeState = async (e) => {
        e.preventDefault();
        setAddeudcation(initialValue);
    };

    useEffect(() => {
        getStudentEducation();
    }, []);

    return (
        <>
            <div className="row ">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header justify-content-between">
                            <h4 className="card-title">Education Details</h4>
                            <div
                                className="d-flex align-items-center"
                                onClick={() => {
                                    setForm(true);
                                }}
                            >
                                <Link
                                    to="#"
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#addeducation"
                                    onClick={removeState}
                                >
                                    <i className="fa-solid fa-plus"></i>
                                </Link>
                            </div>
                            <div
                                className={
                                    form ? "modal fade show" : "modal fade"
                                }
                                id="addeducation"
                                aria-labelledby="myModalLabel33"
                                aria-hidden="true"
                                style={form ? { display: "block" } : { display: "none" }}
                            >
                                <div className="modal-dialog modal-dialog-centered modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title" id="myModalLabel33">
                                                Add Education Details
                                            </h4>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                id="education-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                                onClick={() => {
                                                    setForm(false);
                                                }}
                                            />
                                        </div>
                                        {form && (
                                            <form action="#">
                                                <div className="modal-body">
                                                    <div className="row">
                                                        <div className="col-md-6 form-group">
                                                            <label
                                                                htmlFor="school-name"
                                                            >
                                                                School / College
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                onChange={(e) =>
                                                                    handleChangeEdu(e, "school_college")
                                                                }
                                                                name="school_college"
                                                                autoComplete="off"
                                                                value={addeudcation.school_college}
                                                                id="school-name"
                                                                className="form-control"
                                                                placeholder="Enter title"
                                                            />
                                                            <p className="bg-error">
                                                                {formValidation.school_college}
                                                            </p>
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <label

                                                                htmlFor="degree-name"
                                                            >
                                                                Degree<span className="text-danger"> *</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                onChange={(e) => handleChangeEdu(e, "degree")}
                                                                name="degree"
                                                                autoComplete="off"
                                                                value={addeudcation.degree}
                                                                id="degree-name"
                                                                className="form-control"
                                                                placeholder="Ex. Bachelor's"
                                                            />
                                                            <p className="bg-error">
                                                                {formValidation.degree}
                                                            </p>
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <label

                                                                htmlFor="field-name"
                                                            >
                                                                Field of Study
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                onChange={(e) =>
                                                                    handleChangeEdu(e, "field_of_study")
                                                                }
                                                                name="field_of_study"
                                                                autoComplete="off"
                                                                value={addeudcation.field_of_study}
                                                                id="field-name"
                                                                className="form-control"
                                                                placeholder="Ex. Business"
                                                            /><p className="fs-12"><span className="text-danger">Note: </span> Eg. Computer Science is Field of Study for Engineering</p>

                                                            <p className="bg-error">
                                                                {formValidation.field_of_study}
                                                            </p>
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <label

                                                                htmlFor="start-date"
                                                            >
                                                                Start Date
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <input
                                                                type="date"
                                                                onChange={(e) => handleChangeEdu(e, "start_date")}
                                                                name="start_date"
                                                                value={addeudcation.start_date}
                                                                max={currentDate}
                                                                id="start-date"
                                                                placeholder="DD-MM-YYYY"
                                                                autoComplete="off"
                                                                className="form-control"
                                                            />
                                                            <p className="bg-error">
                                                                {formValidation.start_date}
                                                            </p>
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <div className="form-check form-check-inline mt-6 p-0">
                                                                <label className="custom-control form-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                        name="is_present"
                                                                        id="company-names"
                                                                        checked={addeudcation?.is_present}
                                                                        onChange={(e) =>
                                                                            handleChangeIsPresent(e)
                                                                        }
                                                                        value="1"
                                                                    />
                                                                    <span className="custom-control-label">
                                                                        I am currently study in this role
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {addeudcation.is_present === 0 && (
                                                            <div className="col-md-6 form-group">
                                                                <label

                                                                    htmlFor="end-date"
                                                                >
                                                                    End Date
                                                                    <span className="text-danger"> *</span>
                                                                </label>
                                                                <input
                                                                    type="date"
                                                                    onChange={(e) => handleChangeEdu(e, "end_date")}
                                                                    name="end_date"
                                                                    value={addeudcation.end_date}
                                                                    min={addeudcation.start_date}
                                                                    id="end-date"
                                                                    placeholder="DD-MM-YYYY"
                                                                    autoComplete="off"
                                                                    className="form-control"
                                                                />
                                                                <p className="bg-error">
                                                                    {formValidation.end_date}
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </form>
                                        )}
                                        <div className="modal-footer">
                                            {loader ? (
                                                <SubmitLoader />
                                            ) : (
                                                <>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary "
                                                        onClick={handleAddUPdateEdu}
                                                    >
                                                        Submit
                                                    </button>
                                                    <button
                                                        type="reset"
                                                        id="reset-btn"
                                                        className="btn btn-danger"
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                        onClick={() => {
                                                            setForm(false);
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {data?.length > 0 ? (
                            data?.map((item
                            ) => (
                                <div className="card-body" key={item?.id}>
                                    <ul className="d-flex mb-0">
                                        <li>
                                            <div className="activity1 bg-primary">
                                                <i className="fe fe-shopping-bag"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="font-weight-semibold">
                                                {item?.degree} - ({item?.field_of_study})
                                            </div>
                                            <p className="text-muted mb-0">
                                                {item?.school_college}
                                            </p>
                                        </li>
                                        <div className="text-muted ms-auto">
                                            <Moment format="DD-MM-YYYY">
                                                {item?.start_date}
                                            </Moment>
                                            {" - "}
                                            {item?.is_present === 1 ? (
                                                "Present"
                                            ) : (
                                                <Moment format="DD-MM-YYYY">
                                                    {item?.end_date}
                                                </Moment>
                                            )}
                                            <Link
                                                to="#"
                                                className="btn btn-primary ms-3"
                                                title="Edit"
                                                data-bs-toggle="modal"
                                                data-bs-target={`#editexperience${item?.id}`}
                                                onClick={(e) => EditFormData(e, item?.id)}
                                            >
                                                <i className="fa fa-edit"></i>
                                            </Link>
                                        </div>
                                    </ul>
                                </div>
                            ))
                        ) : (
                            <NoDataAvailable />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Education;
