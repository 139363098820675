import instance from "./config/axios";
import routes from "./constants/routes";
import webroutes from "./constants/web_routes";
import url from "./constants/url";

export const getTeacherGetProfile = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",

        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.teacher_get_profile}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};
export const getTeacherEducationsList = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",

        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.teacher_education}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};
export const getTeacherExperienceList = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",

        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.teacher_experience}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};
export const getTeacherAchievementList = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",

        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.teacher_achievement}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};
export const getInstituteRegister = (params) => {
    let headers = {
        "Content-Type": "multipart/form-data",
        Accept: "*",
    };
    return instance
        .post(`${url.apiUrl}${webroutes.institute_register}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};
export const getTeacherRegister = (params) => {
    let headers = {
        "Content-Type": "multipart/form-data",
        Accept: "*",
    };
    return instance
        .post(`${url.apiUrl}${routes.teacher_register}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};
export const getStudentRegister = (params) => {
    let headers = {
        "Content-Type": "multipart/form-data",
        Accept: "*",
    };
    return instance
        .post(`${url.apiUrl}${routes.student_register}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};

export const getTeacherGetAbout = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.teacher_edit_profile}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};

export const getTeacherAddEducation = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.teacher_add_education}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};
export const getTeacherEditEducation = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.teacher_edit_education_by_id}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};
export const getTeacherGetEducationById = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.teacher_get_education_by_id}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};

export const getTeacherAddExperience = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.teacher_add_experience}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};
export const getTeacherEditExperience = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.teacher_edit_experience_by_id}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};
export const getTeacherGetExperienceById = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.teacher_get_experience_by_id}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};

export const getAeacherAddAchievement = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.teacher_add_achievement}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};
export const getTeacherEditAchievementById = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.teacher_edit_achievement_by_id}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};
export const getTeacherGetAchievementById = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.teacher_get_achievement_by_id}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};

export const getLogin = (params) => {
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
    };
    return instance
        .post(`${url.apiUrl}${routes.login}`, params, { headers: headers })
        .then((res) => {
            return res;
        });
};

export const getInstitutGetProfile = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.institute_get_profile}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};

export const getInstituteTeacherNewRegister = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "multipart/form-data",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.institute_teacher_new_register}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};

export const getAllSubjects = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.institute_get_subjects}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};
export const getInstituteBranchGetAllBranches = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.institute_branch_get_all_branches}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};
export const getTeacherBranchGetAllBranches = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .get(`${url.apiUrl}${routes.teacher_branch_get_all_branches}`, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};
export const getGetAllTeachers = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.institute_teacher_teachers}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};
export const getGetAllBoard = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.get_board}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};
export const getGetAllUniversity = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.get_university}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};
export const getGetAllMediums = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.get_mediums}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};

export const getAllCategorys = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.gat_category}`, params, { headers: headers })
        .then((res) => {
            return res;
        });
};

export const getInstituteCourseAddOnlineCourse = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "multipart/form-data",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.institute_course_add_online_course}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};

export const getInstituteCourseGetAlOnlineCourses = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "multipart/form-data",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(
            `${url.apiUrl}${routes.institute_course_get_all_online_courses}`,
            params,
            { headers: headers }
        )
        .then((res) => {
            return res;
        });
};

export const getStudentGetProfile = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.student_get_profile}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};
export const getStudentAddEducation = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.student_add_education}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};
export const updateStudentEducation = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.student_update_education}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};

export const getStudentAddExperience = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.student_add_experience}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};

export const getStudentAddAchievement = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.student_add_achievement}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};
export const getStudentUpdateAchievement = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.student_update_achievement}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};
export const getStudentAchievement = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.student_achievement}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};
export const getStudentGetAbout = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.student_edit_profile}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};

export const getStudentGetOnlineCourse = (params) => {
    let token = localStorage.getItem("common-user-info");
    let data = JSON.parse(token);
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
        AUTHTOKEN: data && data.auth_token != null && data.auth_token,
    };
    return instance
        .post(`${url.apiUrl}${routes.student_online_course}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};
