import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import utils from "../../../../utils/utils";
import NoDataAvailable from "../../../common/NoDataAvailable";

const RelatedCourse = (props) => {
    let now = new Date().setHours(0, 0, 0, 0);
    const history = useHistory();
    const [relatedCourse, setRelatedCourse] = useState({});
    useEffect(() => {
        if (props.relatedCourse && props.relatedCourse)
            setRelatedCourse(props.relatedCourse);
    }, [props.relatedCourse]);
    let rating = [1, 2, 3, 4, 5];
    return (
        <>
            <section className="sptb bg-white">
                <div className="container">
                    <div className="section-title text-center">
                        <h2>Related Course</h2>
                    </div>
                    <div className="row match-height">
                        {relatedCourse?.length > 0 ? (
                            relatedCourse.map((item, index) => (
                                <div className="col-md-4" key={index} >
                                    <Link to={`/video-course-detail/${item?.id}`}>
                                        <div className="card">
                                            <div className="card-body ">
                                                <div className="item-card7-img">
                                                    <img className="cover-image" src={item?.teaser_thumb_image} alt={item?.title} />
                                                    {(
                                                        item?.price == "paid" && (
                                                            item?.is_discount == 1 && (
                                                                (item?.is_limited_offer == 1 && (new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                (item?.is_limited_offer == 0 && new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                        )
                                                    ) && (
                                                            <Link to="#" className="bg-danger top left">
                                                                {item?.discount_price}% off
                                                            </Link>
                                                        )}
                                                    <Link to="#" className="bg-danger right top">
                                                        Video Course
                                                    </Link>
                                                </div>
                                                <div className="item-card2-desc">
                                                    <div className="d-inline-flex mb-2">
                                                        <div className="br-widget">
                                                            {rating.map((el, i) => (
                                                                <i
                                                                    data-rating-value={el}
                                                                    data-rating-text={el}
                                                                    className={
                                                                        el <= item?.average_review
                                                                            ? "br-selected"
                                                                            : ""
                                                                    }
                                                                    key={i}
                                                                />
                                                            ))}
                                                        </div>
                                                        <span>({item?.review_count} Reviews)</span>
                                                    </div>
                                                    <h4 className="mb-2 leading-tight2">
                                                        {item?.title}
                                                    </h4>
                                                    <p className="leading-tight3 education-mandal">
                                                        {item?.short_discription}
                                                    </p>
                                                    <div className="pt-2 pb-2 border-bottom d-flex justify-content-between">
                                                        <div className="d-flex justify-content-start">
                                                            <i className="fa fa-shop me-2" />
                                                            <span className="text-muted text-data-over-cut">
                                                                {item?.institute.name}
                                                            </span>
                                                        </div>
                                                        <div className="float-end">
                                                            <i className="fa fa-signal me-2" />
                                                            <span className="text-muted text-capitalize">
                                                                {item?.course_level}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="pt-2 pb-2 border-bottom">
                                                        <i className="fa fa-file me-2" />
                                                        <span className="text-muted">
                                                            {item?.lectures_count} Videos
                                                        </span>
                                                        <div className="float-end">
                                                            <i className="fa fa-clock-o me-1" />
                                                            <span className="text-muted">
                                                                {item?.total_duretion ? utils.toHHMMSS(item?.total_duretion) : 0} Hours
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="pt-2">
                                                        <i className="fa fa-microphone me-2" />
                                                        <span className="text-muted">
                                                            {item?.audio_language}
                                                        </span>
                                                        <div className="float-end">
                                                            {item?.price === "paid" && (
                                                                <h4 className="mb-0"> ₹ {Math.round(item?.payable_price)} {(
                                                                    item?.price == "paid" && (
                                                                        item?.is_discount == 1 && (
                                                                            (item?.is_limited_offer == 1 && (new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                            (item?.is_limited_offer == 0 && new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                                    )
                                                                ) && (<del className="fs-14 text-muted"> ₹ {Math.round(item?.regular_price)} </del>
                                                                    )}
                                                                </h4>
                                                            )}
                                                            {item?.price === "free" && (
                                                                <h4 className="mb-0">Free</h4>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))
                        ) : (
                            <NoDataAvailable />
                        )}
                    </div>
                </div>
            </section >
        </>
    );
};

export default RelatedCourse;