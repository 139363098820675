import React, { useEffect, useState } from "react";
import Headerinner from "../../Header/Headerinner";
import Footer from "../../Footer/Footer";
import AboutEdu from "./AboutEdu";
import AboutExist from "./AboutExist";
import WhyChooseEdu from "./WhyChooseEdu";
import Institutes from "./Institutes";
import Teachers from "./Teachers";
import Students from "./Students";
import RegsiterHere from "../../Homepage/RegsiterHere";
import TotalSection from "../../Homepage/TotalSection";
import Founder from "./Founder";
import ContactNow from "./ContactNow";
import { getTotal, } from "../../../../apis_web";

const About = () => {
    const [totalData, setTotalData] = useState();
    const getAllTotalData = async () => {
        let res = await getTotal("", 0);
        if (res.status === "success") {
            setTotalData(res.data);
        }
    };
    useEffect(() => {
        getAllTotalData();
    }, []);
    return (
        <>
            <Headerinner />
            <AboutEdu />
            <AboutExist />
            <WhyChooseEdu />
            <Institutes />
            <Teachers />
            <Students />
            <RegsiterHere />
            <TotalSection data={totalData && totalData} />
            <Founder />
            <ContactNow />
            <Footer />
        </>
    );
};


export default About;
