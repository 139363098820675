import React from "react";
const VideoPopup = ({ data, active, setActive }) => {

    return (
        <div
            className={active ? "modal fade show" : "modal fade"}
            id="view-details"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={active ? { display: "block" } : { display: "none" }}
        >
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="myModalLabel33">Preview Video</h4>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => setActive(false)}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <video controls unmuted loop autoPlay controlsList="nodownload">
                            <source
                                src={data?.teaser_media && data?.teaser_media}
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default VideoPopup;
