import React, { useEffect, useState } from "react";
import { Switch, Routes, Route } from "react-router-dom";
import Loader from "../../common/loader";
import Footer from "../../common/Footer";
import SidebarNav from "../Component/SidebarNav";

import Branch from "../Dashboard/Branch/Branch";
import AddBranch from "../Dashboard/Branch/AddBranch";
import EditBranch from "../Dashboard/Branch/EditBranch";
import BranchProfile from "../Dashboard/Branch/BranchProfile/BranchProfile";

import Teacher from "../Dashboard/Teacher/Teacher";
import AddTeacher from "../Dashboard/Teacher/AddTeacher/AddTeacher";
import TeacherEdit from "../Dashboard/Teacher/TeacherEdit";
import Profile from "../Dashboard/Teacher/Profile/Profile";

import Courses from "../Dashboard/Courses/Courses";
import CourseDetails from "../Dashboard/Courses/CourseDetails/CourseDetails";
import AddCourse from "../Dashboard/Courses/AddCourse/AddCourse";
import CreateCourseVideo from "../Dashboard/Courses/AddCourse/CreateCourseVideo";
import UpdatedCourse from "../Dashboard/Courses/UpdatedCourse/UpdatedCourse";
import CourseApprove from "../Dashboard/ApproveCourse";

import Class from "../Dashboard/Classes/Class/Class";
import ClassAdd from "../Dashboard/Classes/Class/ClassAdd";
import ClassEdit from "../Dashboard/Classes/Class/ClassEdit";
import Subject from "../Dashboard/Classes/Subject/Subject";
import SubjectAdd from "../Dashboard/Classes/Subject/SubjectAdd";
import Slot from "../Dashboard/Classes/Slot/Slot";
import SlotView from "../Dashboard/Classes/Slot/SlotView/SlotView";
import SlotAdd from "../Dashboard/Classes/Slot/SlotAdd";
import SlotEdit from "../Dashboard/Classes/Slot/SlotEdit";
import ClassDetails from "../Dashboard/Classes/ClassDetails/ClassDetails";

import DashboardView from "../Dashboard/DashboardView/DashboardView";
import Payment from "../Dashboard/Payment/Payment";
import Revenue from "../Dashboard/Revenue/Revenue";

import InstituteProfile from "../Dashboard/InstituteProfile/InstituteProfile";

import Student from "../Dashboard/Student/Student";
import PurchaseHistory from "../Dashboard/Student/PurchaseHistory/PurchaseHistory";
import PurchaseItems from "../Dashboard/Student/PurchaseHistory/PurchaseItems";
import CourseClassStudent from "../Dashboard/Student/CourseClassStudent/CourseClassStudent";
import StudentList from "../Dashboard/Student/StudentList";
import StudentProfile from "../Dashboard/Student/Profile/StudentProfile";

import ChangePassword from "../Dashboard/Setting/ChangePassword/ChangePassword";
import SubjectView from "../Dashboard/Classes/Subject/SubjectView";
import RevisedClass from "../Dashboard/Classes/Class/RevisedClass";

import Careers from "../Dashboard/Careers/Careers";
import CareerAdd from "../Dashboard/Careers/CareerAdd";
import CareerDetails from "../Dashboard/Careers/CareerDetails";
import Applicants from "../Dashboard/Careers/Applicants";
import RevisedCareer from "../Dashboard/Careers/RevisedCareer";

import Attendance from "../Dashboard/Attendance/Attendance";
import AttendanceViewSubject from "../Dashboard/Attendance/AttendanceViewSubject";
import AttendanceViewTimeSlot from "../Dashboard/Attendance/AttendanceViewTimeSlot";
import AttendanceEdit from "../Dashboard/Attendance/AttendanceEdit";

import Alumni from "../Dashboard/Alumni/Alumni";
import AddAlumni from "../Dashboard/Alumni/AddAlumni";
import AlumniDetails from "../Dashboard/Alumni/AlumniDetails";

import Notifications from "../Dashboard/Notifications/Notifications"

const Routess = () => {
    const [isLoader, setisLoader] = useState(true);
    const data_local = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(data_local);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    if (local_storage === null || local_storage === undefined) {
        window.location.replace("/");
    }

    useEffect(() => {
        setisLoader(false);
    }, []);
    return (
        <>
            {isLoader ? (
                <Loader />
            ) : (
                <>
                    <div className={`page ` + (!isSidebarOpen ? "app sidenav-toggled" : '')}>
                        <div className="page-main h-100">
                            <SidebarNav isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
                            <div className="app-content">
                                <div className="side-app">
                                    <Switch>
                                        <Route exact path="/institute-dashboard" component={DashboardView} />

                                        <Route path="/institute-dashboard/branch" component={Branch} />
                                        <Route path="/institute-dashboard/change-password" component={ChangePassword} />
                                        <Route path="/institute-dashboard/branch-edit/:id" component={EditBranch} />
                                        <Route path="/institute-dashboard/branch-add" component={AddBranch} />
                                        <Route path="/institute-dashboard/branch-detail/:id" component={BranchProfile} />

                                        <Route path="/institute-dashboard/teacher" component={Teacher} />
                                        <Route path="/institute-dashboard/teacher-edit/:id" component={TeacherEdit} />
                                        <Route path="/institute-dashboard/teacher-add" component={AddTeacher} />
                                        <Route exact path="/institute-dashboard/teacher-detail/:id" component={Profile} />

                                        <Route path="/institute-dashboard/institute-profile" component={InstituteProfile} />

                                        <Route path="/institute-dashboard/video-course" component={Courses} />
                                        <Route path="/institute-dashboard/video-course-detail/:is_main/:id" component={CourseDetails} />
                                        <Route path="/institute-dashboard/revised-video-course" component={UpdatedCourse} />
                                        <Route path="/institute-dashboard/video-course-add" component={AddCourse} />
                                        <Route path="/institute-dashboard/video-course-edit/:is_main_course/:id" component={AddCourse} />
                                        <Route path="/institute-dashboard/updated-course-edit/:is_main_course/:id" component={AddCourse} />
                                        <Route path="/institute-dashboard/add-video-course/:id" component={CreateCourseVideo} />
                                        <Route path="/institute-dashboard/video-course-approval" component={CourseApprove} />

                                        <Route path="/institute-dashboard/class" component={Class} />
                                        <Route path="/institute-dashboard/revised-class" component={RevisedClass} />
                                        <Route path="/institute-dashboard/class-detail/:is_main/:id" component={ClassDetails} />
                                        <Route path="/institute-dashboard/class-add" component={ClassAdd} />
                                        <Route path="/institute-dashboard/class-edit/:is_main/:id" component={ClassEdit} />
                                        <Route path="/institute-dashboard/subject" component={Subject} />
                                        <Route path="/institute-dashboard/subject-add" component={SubjectAdd} />
                                        <Route path="/institute-dashboard/subject-view/:class_id" component={SubjectView} />
                                        <Route path="/institute-dashboard/slot" component={Slot} />
                                        <Route path="/institute-dashboard/slot-view/:class_id" component={SlotView} />
                                        <Route path="/institute-dashboard/slot-add" component={SlotAdd} />
                                        <Route path="/institute-dashboard/slot-edit/:slot_id" component={SlotEdit} />

                                        <Route path="/institute-dashboard/student" component={Student} />
                                        <Route path="/institute-dashboard/student-purchase-history/:student_id" component={PurchaseHistory} />
                                        <Route path="/institute-dashboard/student-course-class/:type/:id" component={CourseClassStudent} />
                                        <Route path="/institute-dashboard/student-purchase-items/:payment_id/:student_id" component={PurchaseItems} />
                                        <Route path="/institute-dashboard/student-profile/:student_id" component={StudentProfile} />
                                        <Route path="/institute-dashboard/student-list" component={StudentList} />

                                        <Route path="/institute-dashboard/payment" component={Payment} />
                                        <Route path="/institute-dashboard/revenue" component={Revenue} />

                                        <Route path="/institute-dashboard/careers" component={Careers} />
                                        <Route path="/institute-dashboard/revised-careers" component={RevisedCareer} />
                                        <Route path="/institute-dashboard/career-add" component={CareerAdd} />
                                        <Route path="/institute-dashboard/career-detail/:is_main_career/:id" component={CareerDetails} />
                                        <Route path="/institute-dashboard/career-edit/:is_main_career/:id" component={CareerAdd} />
                                        <Route path="/institute-dashboard/applicants-list/:id" component={Applicants} />

                                        <Route path="/institute-dashboard/attendance" component={Attendance} />
                                        <Route path="/institute-dashboard/attendance-add/:slot_id" component={AttendanceEdit} />
                                        <Route path="/institute-dashboard/attendance-edit/:slot_id" component={AttendanceEdit} />
                                        <Route path="/institute-dashboard/attendance-view-time-slot/:class_id/:subject" component={AttendanceViewTimeSlot} />
                                        <Route path="/institute-dashboard/attendance-view-subject/:class_id" component={AttendanceViewSubject} />

                                        <Route path="/institute-dashboard/alumni" component={Alumni} />
                                        <Route path="/institute-dashboard/add-alumni" component={AddAlumni} />
                                        <Route path="/institute-dashboard/alumni-detail" component={AlumniDetails} />

                                        <Route path="/institute-dashboard/notifications" component={Notifications} />

                                        {/* <Route path="/institute-dashboard/meeting-detail/:id" component={MeetingHistoryDetails} /> */}
                                    </Switch>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </>
            )}
        </>
    );
};

export default Routess;
