import React from "react";
import { Link } from "react-router-dom";
import { calculateExperience } from "../../../../utils/utils";
import NoDataAvailable from "../../../common/NoDataAvailable";
let rating = [1, 2, 3, 4, 5];

export default function Teacher({ teacherList, searchs, setSearch, teacherCount, type }) {
    const loadMorehandle = () => {
        setSearch({ ...searchs, perpage: searchs.perpage + 9 });
    };
    return (
        <>
            {(type == 'teacher' && teacherList?.length == 0) && (
                <NoDataAvailable/>
            )}
            {teacherList?.length > 0 && (
                <div className="col-md-12 ">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Teacher's List</h4>
                        </div>
                        <div className="card-body pb-0">
                            <div className="row">
                                {teacherList ? (
                                    teacherList.map((item, i) => (
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12" key={i}>
                                            <Link to={`/teacher-detail/${item?.teacher_id}`}>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="text-center">
                                                            <div className="item-card7-img">
                                                                <img src={item?.image} className="cover-image teacher-student-image" alt={item?.name} />
                                                            </div>
                                                            <h4 className="mt-4 mb-2 leading-tight">
                                                                {item?.name}
                                                            </h4>
                                                            <h6 className="team-section badge">
                                                                <span className="font-weight-semibold me-1">
                                                                    {item?.total_experiance != 0 ? calculateExperience(item?.total_experiance) : "---"}
                                                                </span>  Experience
                                                            </h6>
                                                            <div className="d-flex justify-content-center">
                                                                <div className="br-widget">
                                                                    {rating.map((el, i) => (
                                                                        <i
                                                                            data-rating-value={el}
                                                                            data-rating-text={el}
                                                                            className={
                                                                                el <= item?.average_review
                                                                                    ? "br-selected"
                                                                                    : ""
                                                                            }
                                                                            key={i}
                                                                        />
                                                                    ))}
                                                                </div>
                                                                <span>({item?.review_count} Reviews)</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))
                                ) : (
                                    <NoDataAvailable/>
                                )}
                            </div>
                        </div>
                        {teacherList?.length < teacherCount && (
                            <div className="card-footer" onClick={loadMorehandle}>
                                <Link to="#" className="w-100">
                                    Load More Teacher's
                                </Link>
                            </div>
                        )}
                    </div>
                </div>)}</>

    );
}
