import React, { useEffect, useState } from "react";
import {
    getTeacherAddExperience,
    getTeacherEditExperience,
    getTeacherExperienceList,
    getTeacherGetExperienceById,
} from "../../../../apis";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Popup from "../../../../common/Popup";
import Moment from "react-moment";
import { Editor } from "@tinymce/tinymce-react";
import NoDataAvailable from "../../../common/NoDataAvailable";

const Experience = ({ }) => {
    const current = new Date();
    let month = `${current.getMonth() + 1}`;
    if (month.length < 2) {
        month = "0" + month;
    }
    let cu_date = `${current.getFullYear()}-${month}-${current.getDate()}`;

    const [addExperenceModal, setaddExperenceModal] = useState(false);
    const [data, setData] = useState([]);
    const initialValues = {
        title: "",
        employement_type: "",
        company_name: "",
        location: "",
        start_date: "",
        end_date: "",
        is_present: 0,
        description: "",
    };
    const [addexperience, setAddexperience] = useState(initialValues);
    const initialValidation = {
        title: "",
        employement_type: "",
        company_name: "",
        location: "",
        start_date: "",
        end_date: "",
        description: "",
    };
    const [experienceValidation, setExperienceValidation] = useState(initialValidation);


    const handleChangeIsPresent = (event) => {
        let value = 0;
        if (event.target.checked) {
            value = 1;
        } else {
            value = 0;
        }
        setAddexperience({
            ...addexperience, is_present: value,
        });
    };

    const getTeacherExperience = async () => {
        let res = await getTeacherExperienceList();
        if (res.status === "success") {
            setData(res.data);
        }
    };

    const handleChangeExp = (e, key) => {
        e.preventDefault();
        let value = e.target.value;

        setAddexperience({
            ...addexperience, [key]: value,
        });
    };

    const handleEditExp = async (id) => {
        handleAddExpModal(true)
        let reqData = {
            experience_id: id,
        };
        let res = await getTeacherGetExperienceById(reqData);
        setAddexperience(res.data);
    };

    const handleAddExpModal = (val) => {
        setAddexperience(initialValues)
        setExperienceValidation(initialValidation)
        setaddExperenceModal(val)
    };

    const handleAddExp = async () => {
        const errors = {};
        if (addexperience.title === undefined || addexperience.title === "") {
            errors.title = "Required";
        }
        if (addexperience.employement_type === undefined || addexperience.employement_type === "") {
            errors.employement_type = "Required";
        }
        if (addexperience.location === undefined || addexperience.location === "") {
            errors.location = "Required";
        }
        if (addexperience.company_name === undefined || addexperience.company_name === "") {
            errors.company_name = "Required";
        }
        if (addexperience.description === undefined || addexperience.description === "") {
            errors.description = "Required";
        } else if (addexperience.description.length > 500) {
            errors.description = "Please write less than 500 characters"
        }
        if (addexperience.start_date === undefined || addexperience.start_date === "") {
            errors.start_date = "Required";
        }
        if (addexperience.is_present === undefined || addexperience.is_present === 0) {
            if (addexperience.end_date === undefined || addexperience.end_date === "") {
                errors.end_date = "Required";
            } else {
                if (addexperience.start_date < addexperience.end_date) {
                } else {
                    errors.end_date = "Please enter end date grater then start date";
                }
            }
        }

        setExperienceValidation(errors);

        var raw = {
            title: addexperience.title,
            employement_type: addexperience.employement_type,
            location: addexperience.location,
            company_name: addexperience.company_name,
            start_date: addexperience.start_date,
            is_present: addexperience.is_present,
            end_date: addexperience.end_date,
            description: addexperience.description,
        };

        if (Object.keys(errors).length === 0) {
            if (addexperience.id) {
                raw.experience_id = addexperience.id;
                raw.status = "active";
                var result = await getTeacherEditExperience(raw);
            } else {
                var result = await getTeacherAddExperience(raw);
            }
            if (result.status === "success") {
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setAddexperience(initialValues)
                setExperienceValidation(initialValidation)
                getTeacherExperience();
                handleAddExpModal(false);
            } else {
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }

        } else {
            setExperienceValidation(errors);
        }
    };

    const ModalExperence = () => {
        return (
            <>
                <form action="#">
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="title-name">
                                Title <span className="text-danger"> *</span>
                            </label>
                            <input
                                type="text"
                                onChange={(e) => handleChangeExp(e, "title")}
                                name="title"
                                value={addexperience.title}
                                id="title-name"
                                className="form-control"
                                placeholder="Enter title"
                            />
                            <p className="bg-error">{experienceValidation.title}</p>
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="employee-type">
                                Employment Type<span className="text-danger"> *</span>
                            </label>
                            <select
                                className="form-select select2"
                                onChange={(e) => handleChangeExp(e, "employement_type")}
                                name="employement_type"
                                value={addexperience.employement_type}
                                id="employee-type"
                                tabIndex={0}
                                aria-hidden="false"
                            >
                                <option disabled="">Select Employee Type</option>
                                <option value="full_time">Full Time</option>
                                <option value="part_time">Part Time</option>
                                <option value="internship">Internship</option>
                                <option value="contract">Contract</option>
                                <option value="freelancer">Freelancer</option>
                                <option value="seasonal">Seasonal</option>
                                <option value="temporary">Temporary</option>
                            </select>
                            <p className="bg-error">
                                {experienceValidation.employement_type}
                            </p>
                        </div>

                        <div className="col-md-6 form-group">
                            <label htmlFor="company-name">
                                Company Name<span className="text-danger"> *</span>
                            </label>
                            <input
                                type="text"
                                onChange={(e) => handleChangeExp(e, "company_name")}
                                name="company_name"
                                value={addexperience.company_name}
                                id="company-name"
                                className="form-control"
                                placeholder="Enter company name"
                            />
                            <p className="bg-error">
                                {experienceValidation.company_name}
                            </p>
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="location-name">
                                Location<span className="text-danger"> *</span>
                            </label>
                            <input
                                type="text"
                                onChange={(e) => handleChangeExp(e, "location")}
                                name="location"
                                value={addexperience.location}
                                id="location"
                                className="form-control"
                                placeholder="Enter company location"
                            />
                            <p className="bg-error">{experienceValidation.location}</p>
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="start-date">
                                Start Date<span className="text-danger"> *</span>
                            </label>
                            <input
                                type="date"
                                onChange={(e) => handleChangeExp(e, "start_date")}
                                name="start_date"
                                defaultValue={addexperience.start_date}
                                id="start-date"
                                max={cu_date}
                                className="form-control"
                            />
                            <p className="bg-error">{experienceValidation.start_date}</p>
                        </div>
                        <div className="col-md-6 form-group">
                            <label className="custom-control form-checkbox mt-6">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    defaultValue="0"
                                    onChange={(e) => handleChangeIsPresent(e)}
                                    name="is_present"
                                    checked={addexperience.is_present}
                                    id="company-names"
                                />
                                <span className="custom-control-label ">
                                    I am currently working this company.
                                </span>
                            </label>
                            <p className="bg-error">{experienceValidation.is_present}</p>
                        </div>
                        {addexperience.is_present === 0 && (
                            <div className="col-md-6 form-group">
                                <label htmlFor="end-date">
                                    End Date
                                </label>
                                <input
                                    type="date"
                                    onChange={(e) => handleChangeExp(e, "end_date")}
                                    name="end_date"
                                    value={addexperience.end_date}
                                    min={addexperience.start_date}
                                    id="end-date"
                                    placeholder="DD-MM-YYYY"
                                    autoComplete="off"
                                    className="form-control"
                                />
                                <p className="bg-error">{experienceValidation.end_date}</p>
                            </div>
                        )}
                        <div className="col-md-12 form-group">
                            <label htmlFor="last-name">
                                Description<span className="text-danger"> *</span>
                            </label>
                            <Editor
                                textareaName='description'
                                placeholder='Enter description'
                                apiKey='9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d'
                                init={{
                                    plugins: [
                                        "advlist",
                                        "autolink",
                                        "lists",
                                        "link",
                                        "image",
                                        "charmap",
                                        "preview",
                                        "anchor",
                                        "searchreplace",
                                        "visualblocks",
                                        "code",
                                        "fullscreen",
                                        "insertdatetime",
                                        "media",
                                        "table",
                                        "help",
                                        "wordcount",
                                        "spellchecker",
                                    ],

                                    toolbar:
                                        " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help |spellchecker ",
                                }}
                                value={addexperience.description}
                                onEditorChange={(e) =>
                                    setAddexperience({
                                        ...addexperience, description: e,
                                    })
                                }
                            />
                            <p className="bg-error">{experienceValidation.description}</p>
                            <p style={{ position: "absolute", right: "15px" }}>
                                {addexperience.description.length} / 500 Characters
                            </p>
                        </div>
                    </div>
                </form>

                <div className="col-md-12 form-group text-end mt-5">
                    <button
                        type="button "
                        onClick={handleAddExp}
                        className="btn btn-primary me-2"
                    >
                        Submit
                    </button>
                    <button
                        type="reset"
                        className="btn btn-danger "
                        onClick={() => handleAddExpModal(false)}
                    >
                        Cancel
                    </button>
                </div>

                <ToastContainer />
            </>
        );
    };

    useEffect(() => {
        getTeacherExperience();
    }, []);

    return (
        <>
            <div className="row ">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header justify-content-between">
                            <h4 className="card-title">Experience Details</h4>
                            <div className="d-flex align-items-center">
                                <Link
                                    to="#"
                                    className="btn btn-primary"
                                    onClick={() => handleAddExpModal(true)}
                                    title="Add"
                                >
                                    <i className="fa fa-plus"></i>
                                </Link>
                            </div>
                        </div>
                        {data?.length > 0 ? (
                            data?.map((item) => (
                                <div className="card-body">
                                    <ul className="d-flex mb-0">
                                        <li>
                                            <div className="activity1 bg-primary">
                                                <i className="fe fe-shopping-bag"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="font-weight-semibold text-capitalize">
                                                {item?.title} - ({item?.employement_type === "full_time" ? "Full Time" : (item?.employement_type === "part_time" ? "Part Time" : item?.employement_type)})
                                            </div>
                                            <p className="text-muted mb-0">
                                                {item?.company_name}, {item?.location}
                                            </p>
                                        </li>
                                        <div className="text-muted ms-auto">
                                            <Moment format="DD-MM-YYYY">
                                                {item?.start_date}
                                            </Moment>
                                            {" - "}
                                            {item?.is_present === 1 ? (
                                                "Present"
                                            ) : (
                                                <Moment format="DD-MM-YYYY">
                                                    {item?.end_date}
                                                </Moment>
                                            )}
                                            <Link
                                                to="#"
                                                onClick={() => handleEditExp(item?.id)}
                                                className="btn btn-primary ms-3"
                                            >
                                                <i className="fa-solid fa-pen-to-square"></i>
                                            </Link>
                                        </div>
                                    </ul>
                                    <p className="text-muted mb-0 margin-left education-mandal" dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                                </div>
                            ))
                        ) : (
                            <NoDataAvailable/>
                        )}
                    </div>
                </div>
            </div>
            <Popup
                title="Add Experience Details"
                show={addExperenceModal}
                handleClose={handleAddExpModal}
                body={ModalExperence}
            />
        </>
    );
};

export default Experience;
