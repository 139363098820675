import React, { useState } from 'react'
import Model from 'react-modal'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as  Yup from 'yup';

const EnquiryForm = () => {
    const [visible, setVisible] = useState(true);
    // popus window 
    Model.setAppElement('#root');
    // const [visible, setVisible] = useState(false);
    const MemberValues = {
        name: '',
        email: '',
        phoneno: '',
    };
    const validateYupSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required')
            .min(2, 'Name must be at least 2 characters long'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        phoneno: Yup.string()
            .matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits')
            .required('Phone number is required'),

    });
    function mySubmit(value) {
        console.log(value);
    }
    return (
        <>
            <div>
                <Model isOpen={visible} className={`${visible ? "" : "d-none"}`}>

                    <div className="row form-row" >
                        <div className="col-lg-6 side-img border">
                            <img src="assets/images/EnquiryForm.avif" className="pt-5" style={{marginTop: "4rem"}} alt="" />
                        </div>
                        <div className="col-lg-6  pe-0 mt-lg-4">
                            <h2 className="mt-5 text-center" style={{fontWeight:"bold"}}>REGISTER</h2>
                            <p className="mb-4 text-center" style={{fontWeight:"bold"}}>Fill Your Contact Details Now</p>
                            <Formik
                                initialValues={MemberValues}
                                onSubmit={mySubmit}
                                validationSchema={validateYupSchema}>

                                <Form className="forms mt-5 pt-5 p-2 border rounded">
                                    <div className='row'>
                                        <div className="col-md-12 mb-5 pb-2">
                                            <label htmlFor="name" className="form-label">Full Name *</label>
                                            <Field
                                                type="text"
                                                className="form-control form-control-lg"
                                                id="name"
                                                name="name"
                                                placeholder="Enter your first name"

                                            />

                                            <ErrorMessage name="name" component="div" className="text-danger" />

                                        </div>
                                        <div className="col-md-12 mb-5 pb-2">
                                            <label htmlFor="phoneno" className="form-label">Phone Number*</label>
                                            <Field
                                                type="tel"
                                                className="form-control form-control-lg"
                                                id="phoneno"
                                                name="phoneno"
                                                placeholder="Enter phone number"

                                            />

                                            <ErrorMessage name="phoneno" component="div" className="text-danger" />
                                        </div>

                                        <div className="col-md-12 mb-5 pb-2">
                                            <label htmlFor="email" className="form-label">Email address *</label>
                                            <Field
                                                type="email"
                                                className="form-control form-control-lg"
                                                id="email"

                                                name="email"
                                                placeholder="Enter email address"

                                            />

                                            <ErrorMessage name="email" component="div" className="text-danger" />
                                        </div>
                                    </div>
                                    <div className="mt-3 text-center thirty max mb-5">
                                        <button type="submit" className="btn btn-primary me-3 px-4">Submit</button>
                                        <button type="button" onClick={() => setVisible(false)} aria-haspopup="true" aria-expanded="false" className=" px-4 btn btn-outline-danger">Close</button>

                                    </div>
                                </Form>
                            </Formik>
                        </div>
                    </div>


                </Model>
            </div>
        </>
    )
}

export default EnquiryForm
