import React, { useState } from "react";
import LearningManagementForm from "./LearningManagementForm";

const EducationLoan = () => {
    const [active, setActive] = useState(false);
    return (
        <>
            <section className="sptb">
                <div className="container">
                    <div className="section-title">
                        <h2>Education Loan</h2>
                        <div className="ms-auto">
                            <button
                                className="btn btn-primary"
                                onClick={() => setActive(true)}
                            >
                                View More <i className="fe fe-arrow-right" />
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <p>

                                Education Mandal offers a third-party education loan to assist
                                students in financing their education. This product is designed
                                to provide students with the financial support they need to
                                pursue their academic goals and overcome financial obstacles.
                            </p>
                            <div className="row match-height ">
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-body text-center">
                                            <div className="feature">
                                                <div className="fa-stack fea-icon bg-primary mb-3">
                                                    <i className="fa fa-indian-rupee-sign text-white" />
                                                </div>
                                                <h3>Loan Amount</h3>
                                                <p>
                                                    Students can borrow the amount they need to cover
                                                    the cost of their education, including tuition,
                                                    fees, books, and living expenses.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-body text-center">
                                            <div className="feature">
                                                <div className="fa-stack fea-icon bg-success mb-3">
                                                    <i className="fa fa-credit-card text-white" />
                                                </div>
                                                <h3>
                                                    Repayment Terms
                                                </h3>
                                                <p>
                                                    Repayment terms are flexible, allowing students to
                                                    repay the loan over a period of time that works best
                                                    for them.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-body text-center">
                                            <div className="feature">
                                                <div className="fa-stack fea-icon bg-secondary mb-3">
                                                    <i className="fa fa-percent text-white" />
                                                </div>
                                                <h3>
                                                    Low Interest Rates
                                                </h3>
                                                <p>
                                                    It offers competitive interest rates, making it easier
                                                    for students to manage the cost of their education.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center match-height">
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-body text-center">
                                            <div className="feature">
                                                <div className="fa-stack fea-icon bg-primary mb-3">
                                                    <i className="fa fa-file text-white"></i>
                                                </div>
                                                <h3>
                                                    Easy Application Process
                                                </h3>
                                                <p>
                                                    The loan application process is straightforward and
                                                    designed to be user-friendly, making it easy for
                                                    students to apply for the loan.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-body text-center">
                                            <div className="feature">
                                                <div className="fa-stack  fea-icon bg-success mb-3">
                                                    <i className="fa fa-phone-volume text-white" />
                                                </div>
                                                <h3>
                                                    Personalized Customer Support
                                                </h3>
                                                <p>
                                                    The loan product comes with personalized customer
                                                    support, ensuring that students have the support they
                                                    need throughout the loan process
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {active && (
                <LearningManagementForm
                    type="education_loan"
                    heading="Education Loan"
                    active={active}
                    setActive={setActive}
                />
            )}

        </>
    );
};

export default EducationLoan;
