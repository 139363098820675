import React, { useState } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import url from "../../../../../constants/url";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import SubmitLoader from "../../../../common/SubmitLoader";
import { replaceWord } from "../../../../../utils/utils";

export default function SubjectList({ timeSchedule, filters, loading, getClassSchedule }) {
    const [subjectForm, setSubjectForm] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [values, setValues] = useState({});
    const [teachers, setTeachers] = useState([]);
    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage.auth_token;

    var myHeaders = new Headers();
    myHeaders.append("AUTHTOKEN", auth_token);
    myHeaders.append("Content-Type", "application/json");

    const getTeachers = async (branch_id) => {

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
                branch_ids: branch_id,
            }),
        };

        fetch(url.apiUrl + "institute-teacher/get-teachers", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setTeachers(
                        result.data.map((item) => ({ label: item.name, value: item.id }))
                    );
                } else {
                    setTeachers([]);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const getSubjectDetail = (id) => {
        subjectHandler(true);
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        fetch(`${url.apiUrl}institute/class-subject-detail/${id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setValues(result.data);
                    getTeachers(result.data.branch_id)
                }
            })
            .catch((error) => console.log("error", error));
    }
    const subjectHandler = (value) => {
        setSubjectForm(value);

    };

    const ValidateSchema = Yup.object().shape({
        teachers: Yup.array().min(1, "Required"),
        subject: Yup.string().required("Required"),
    });

    const FormSubmit = async (values) => {
        try {
            setIsSubmit(true)
            var data = {
                subject_id: values.id,
                subject: values.subject,
                teacher_ids: values.teachers.map(b => b.value).join(','),
            }
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify(data),
            };
            fetch(url.apiUrl + "institute/update-class-subject", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === "success") {
                        subjectHandler(false);
                        setIsSubmit(false);
                        toast.success(result.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        getClassSchedule()
                    } else {
                        toast.error(result.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                })
                .catch((error) => console.log("error", error));
        } catch (err) {
            throw err;
        }
    }

    const deleteSubject = (id) => {
        try {

            if (window.confirm("Are you sure you want to delete this record?")) {
                var requestOptions = {
                    method: "DELETE",
                    headers: myHeaders,
                };

                fetch(`${url.apiUrl}institute/delete-class-subject/${id}`, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.status === "success") {
                            toast.success(result.message, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                            getClassSchedule()
                        } else {
                            toast.error(result.message, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }
                    })
                    .catch((error) => console.log("error", error));
            }
        } catch (err) {
            throw err;
        }
    }
    return (
        <>
            <ToastContainer />
            <div className="card-header d-flex justify-content-between">
                <h4 className="card-title">View Class Subject Teacher List</h4>
                <Link className="btn btn-primary" to="/institute-dashboard/subject-Add" >
                    <i className="fa fa-plus"></i> Add Subject
                </Link>
            </div>
            <div className="card-body">
                <table className="table table-bordered table-hover dataTable table-responsive-xl">
                    <thead>
                        <tr>
                            <th className="text-center">Sr. No.</th>
                            <th className="text-center">Subject</th>
                            <th>Teachers Details</th>
                            <th className="text-center">Create Time</th>
                            <th className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td className="text-center" colSpan={5}>Loading..</td>
                            </tr>
                        ) : (
                            !timeSchedule.length ? (
                                <tr>
                                    <td className="text-center" colSpan={5}>No Data Available !</td>
                                </tr>
                            ) : (timeSchedule.map((time_slot, index) => (
                                <tr key={index}>
                                    <th className="text-center">{filters.offset + index + 1}</th>
                                    <td className="text-center">{time_slot?.subject}</td>
                                    <td>
                                        {time_slot.teachers.length != 0 && time_slot?.teachers.map((teacher, index) => (
                                            <div className="media mb-2" key={index}>
                                                <img
                                                    src={teacher?.image}
                                                    alt={time_slot?.teacher?.name}
                                                    className="me-3 rounded-circle"
                                                />
                                                <div className="media-body">
                                                    <Link className="font-weight-semibold"
                                                        to={`/institute-dashboard/teacher-detail/${teacher?.id}`}
                                                    > {teacher?.name}
                                                    </Link>
                                                    <p className="mb-0"> {teacher?.email} </p>
                                                </div>
                                            </div>
                                        ))}

                                    </td>
                                    <td className="text-center"><Moment format="DD-MM-YYYY hh:mm A">{time_slot?.created_at}</Moment></td>
                                    <td className="text-center text-nowrap">
                                        <Link className="btn btn-primary btn-sm me-2" to="#" onClick={() => { getSubjectDetail(time_slot.id) }}>
                                            <i className="fa fa-pen-to-square fs-16"></i>
                                        </Link>
                                        <button className='btn btn-danger btn-sm'
                                            type="button"
                                            onClick={() => { deleteSubject(time_slot.id) }}
                                        >
                                            <i className='fa fa-trash fs-16'></i>
                                        </button>
                                    </td>
                                </tr>
                            ))
                            )
                        )}
                    </tbody>
                </table>
            </div>
            {subjectForm && (
                <div className={"modal fade show"}
                    id='about'
                    tabIndex={-1}
                    aria-labelledby='InstituteAbout'
                    aria-hidden='true'
                    style={{ display: "block" }}
                >
                    <div className='modal-dialog modal-dialog-centered modal-lg'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h4 className='modal-title' id='InstituteAbout'>
                                    Edit Class Subject Teacher List
                                </h4>
                                <button
                                    type='button'
                                    className='btn-close'
                                    id='about-close'
                                    data-bs-dismiss='modal'
                                    aria-label='Close'
                                    onClick={() => subjectHandler(false)}
                                />
                            </div>
                            <div className='modal-header'>
                                <Formik
                                    initialValues={values}
                                    onSubmit={FormSubmit}
                                    enableReinitialize={true}
                                    validationSchema={ValidateSchema}
                                >
                                    {(props) => {
                                        return (
                                            <form noValidate='novalidate' onSubmit={props.handleSubmit} >
                                                <div className='row'>
                                                    <div className="col-md-6 form-group">
                                                        <label>
                                                            Branch <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter other branch"
                                                            onChange={props.handleChange}
                                                            name={`branch_id`}
                                                            value={props.values?.class_course?.institute_branch?.name}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="col-md-6 form-group">
                                                        <label>
                                                            Class Type <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Class Type"
                                                            onChange={props.handleChange}
                                                            name={`class_type`}
                                                            value={replaceWord(props.values?.class_course?.class_type)}
                                                            disabled
                                                        />
                                                    </div>
                                                    {props.values?.class_course?.class_type === "tuition" && (
                                                        <div className="col-md-6 form-group">
                                                            <label>
                                                                Tuition Type <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Tuition Type"
                                                                onChange={props.handleChange}
                                                                name={`tuition_type`}
                                                                value={replaceWord(props.values?.class_course?.tuition_type)}
                                                                disabled
                                                            />
                                                        </div>
                                                    )}
                                                    {(props.values?.class_course?.class_type === "school" || props.values?.class_course?.tuition_type === "school") && (
                                                        <>
                                                            <div className="col-md-6 form-group">
                                                                <label>
                                                                    Board <span className="text-danger">*</span>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter Board"
                                                                    onChange={props.handleChange}
                                                                    name={`board`}
                                                                    value={props.values?.class_course?.board}
                                                                    disabled
                                                                />
                                                            </div>
                                                            <div className="col-md-6 form-group">
                                                                <label>
                                                                    Section <span className="text-danger">*</span>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter Section"
                                                                    onChange={props.handleChange}
                                                                    name={`section`}
                                                                    value={props.values?.class_course?.section}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                    {(props.values?.class_course?.class_type === "university" || props.values?.class_course?.tuition_type === "university") && (
                                                        <>
                                                            <div className="col-md-6 form-group">
                                                                <label>University <span className="text-danger"> *</span></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter University"
                                                                    onChange={props.handleChange}
                                                                    name={`university`}
                                                                    value={props.values?.class_course?.universities.name}
                                                                    disabled
                                                                />

                                                            </div>
                                                            <div className="col-md-6 form-group">
                                                                <label>Semester <span className="text-danger">*</span></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter semester"
                                                                    onChange={props.handleChange}
                                                                    name={`semester`}
                                                                    value={props.values?.class_course?.semester}
                                                                    disabled
                                                                />

                                                            </div>
                                                        </>
                                                    )}
                                                    <div className="col-md-6 form-group">
                                                        <label>
                                                            Class Name <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter class name"
                                                            onChange={props.handleChange}
                                                            name={`class_name`}
                                                            value={props.values?.class_course?.class_name}
                                                            disabled
                                                        />

                                                    </div>
                                                    <div className="col-md-6 form-group">
                                                        <label htmlFor="school-name">Teacher<span className="text-danger"> *</span></label>
                                                        <Select
                                                            options={teachers}
                                                            onChange={(value) =>
                                                                props.setFieldValue("teachers", value)
                                                            }
                                                            value={props.values.teachers}
                                                            isMulti
                                                        />
                                                        {props.errors.teachers &&
                                                            props.touched.teachers && (
                                                                <p className='text-danger'>
                                                                    {props.errors.teachers}
                                                                </p>
                                                            )}
                                                    </div>
                                                    <div className="col-md-6 form-group">
                                                        <label htmlFor="school-name">Subject Name<span className="text-danger"> *</span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter subject name"
                                                            onChange={props.handleChange}
                                                            name={`subject`}
                                                            value={props.values?.subject}
                                                        />
                                                        {props.errors.subject &&
                                                            props.touched.subject && (
                                                                <p className='text-danger'>
                                                                    {props.errors.subject}
                                                                </p>
                                                            )}
                                                    </div>
                                                    <div className="col-md-12 text-end">
                                                        {isSubmit ? (
                                                            <SubmitLoader />
                                                        ) : (
                                                            <>
                                                                <button type="submit" className="btn btn-primary me-2">Submit</button>
                                                                <button className="btn btn-danger" onClick={() => subjectHandler(false)}>Cancel</button>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}