import { Link } from "@mui/material";
import React from "react";

const BannerSection = () => {
    return (
        <>
            <section className="absolute-banner-section ServiceBanner">
                <div className="container">
                    <div className="card mb-0 overflow-hidden">
                        <div className="card-body">
                            <div className="row align-items-center">
                                <div className="col-lg-3 col-md-6 col-sm-6 mb-4 mb-sm-4 mb-lg-0">
                                    <div className="d-flex">
                                        <div>
                                            <span data-aos="fade-right" className="bg-primary icon-service text-white">
                                                <i className="fa fa-people-roof fs-30" />
                                            </span>
                                        </div>
                                        <h4 data-aos="flip-up" className="font-weight-semibold mb-0 ms-4 mt-4">
                                            <Link>Educational
                                                <br /> Aggregator</Link>
                                        </h4>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 mb-4 mb-sm-4 mb-lg-0">
                                    <div className="d-flex">
                                        <div>
                                            <span data-aos="fade-right" className="bg-secondary icon-service text-white">
                                                <i className="fa fa-person-chalkboard fs-30" />
                                            </span>
                                        </div>
                                        <h4 data-aos="flip-up" className="font-weight-semibold ms-4 mt-4">
                                            <Link>Learning Management
                                                <br /> System</Link>
                                        </h4>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 mb-4 mb-sm-4 mb-lg-0">
                                    <div className="d-flex">
                                        <div>
                                            <span data-aos="fade-right" className="bg-success icon-service text-white">
                                                <i className="fa fa-handshake-angle fs-30" />
                                            </span>
                                        </div>
                                        <h4 data-aos="flip-up" className="font-weight-semibold ms-4 mt-4">
                                            <Link> Career
                                                <br /> Guidance</Link>
                                        </h4>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 mb-4 mb-sm-4 mb-lg-0">
                                    <div className="d-flex">
                                        <div>
                                            <span data-aos="fade-right" className="bg-info icon-service text-white">
                                                <i className="fa fa-indian-rupee-sign fs-30" />
                                            </span>
                                        </div>
                                        <h4 data-aos="flip-up" className="font-weight-semibold ms-4 mt-4">
                                            <Link>Education
                                                <br /> Loan</Link>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default BannerSection;
