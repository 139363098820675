import React, { useState, useEffect } from "react";
import { useHistory, Link, useParams } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Moment from "react-moment";
import Popup from "../../../../common/Popup";
import VideoPopup from "../../../../common/VideoPopup"
import url from "../../../../../constants/url";
import utils from "../../../../../utils/utils";
import ReviewForm from "./ReviewForm";
import { calculateExperience } from "../../../../../utils/utils";
import NoDataAvailable from "../../../../common/NoDataAvailable";
let rating = [1, 2, 3, 4, 5];

const OrderDetails = () => {
    let now = new Date().setHours(0, 0, 0, 0);
    const history = useHistory();
    const [activeCOursePreview, setActiveCOursePreview] = useState(false);
    const [openPopup, setOpenPopup] = useState({});
    const [openPreviewPopup, setOpenPreviewPopup] = useState({});
    const [active, setActive] = useState({});
    const [data, setData] = useState({});
    const [intodata, setIntodata] = useState();
    const param = useParams();
    const getAllCourse = async () => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        fetch(
            `${url.apiUrl}student/online-course-detail/${param.id}`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setData(result.data);
                }
            })
            .catch((error) => console.log("error", error));
    };

    useEffect(() => {
        getAllCourse();
    }, []);
    const activeHandler = (id) => {
        setActive((prev) => ({ [id]: !prev[id] }));
    };

    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/student-dashboard">Dashboard</Link>
                    </li>

                    <li className="breadcrumb-item">
                        <Link to="/student-dashboard/OrderCourse">Video Courses</Link>
                    </li>
                    <li className="breadcrumb-item active">Video Course Detail</li>
                </ol>
            </div>

            <div className="row">
                <div className="col-md-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="product-slider">
                                <ul className="list-unstyled video-list-thumbs">
                                    <li className="mb-0">
                                        <div className="item-card7-img">
                                            <img src={data?.teaser_thumb_image} alt={data?.title} className="cover-image" />
                                            {(
                                                data?.price == "paid" && (
                                                    data?.is_discount == 1 && (
                                                        (data?.is_limited_offer == 1 && (new Date(data?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(data?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                        (data?.is_limited_offer == 0 && new Date(data?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                )
                                            ) && (
                                                    <Link to="#" className="bg-danger top left">
                                                        {data?.discount_price}% off
                                                    </Link>
                                                )}
                                            <Link to="#" className="bg-danger right top">
                                                Video Course
                                            </Link>
                                            {data?.is_professional === 0 && (
                                                <Link to="#" className="bg-danger right bottom">
                                                    {data?.board}
                                                </Link>
                                            )}
                                            <span className="fa fa-play-circle text-white class-icon" onClick={() => { setActiveCOursePreview(true); }}></span>
                                        </div>
                                    </li>
                                    {activeCOursePreview ? (
                                        <VideoPopup
                                            className="videoIntor"
                                            active={activeCOursePreview}
                                            setActive={setActiveCOursePreview}
                                            data={data}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </ul>
                            </div>
                            <div className="mt-4 mb-4 text-center">
                                <div>
                                    {data?.price === "paid" && (
                                        <span className="font-weight-semibold h2 mb-0">
                                            ₹ {Math.round(data?.payable_price)} {(
                                                data?.price == "paid" && (
                                                    data?.is_discount == 1 && (
                                                        (data?.is_limited_offer == 1 && (new Date(data?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(data?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                        (data?.is_limited_offer == 0 && new Date(data?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                )
                                            ) &&
                                                (<del className="fs-14 text-muted">₹ {Math.round(data?.regular_price)} </del>
                                                )}
                                        </span>
                                    )}
                                    {data?.price === "free" && (
                                        <span className="font-weight-semibold h2 mb-0">
                                            Free
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div>
                                <Link
                                    className="btn btn-block btn-primary mb-3 mb-xl-0"
                                    to={`/student-dashboard/course-study/${data.id}`}
                                >
                                    <span>Start Learning</span>
                                </Link>
                            </div>
                        </div>
                        <div className="card-header">
                            <h4 className="card-title">Video Course Info :</h4>
                        </div>
                        <table className="table table-hover dataTable">
                            <tbody>
                                <tr>
                                    <th>
                                        Institute Name
                                    </th>
                                    <td className="w-0 text-center">:</td>
                                    <td className="text-capitalize text-end">
                                        <Link className="text-primary" to={`/institute-detail/${data?.institute?.institute_id}`}>
                                            {data?.institute?.name}
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Branch Name
                                    </th>
                                    <td className="w-0 text-center">
                                        :
                                    </td>
                                    <td className="text-capitalize text-end">
                                        <Link className="text-primary" to={`/branch-detail/${data?.institute_branch?.branch_id}`}>
                                            {data?.institute_branch?.name}
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Course Level
                                    </th>
                                    <td className="w-0 text-center">
                                        :
                                    </td>
                                    <td className="text-capitalize text-end">
                                        {data?.course_level}
                                    </td>
                                </tr>
                                {data?.is_professional === 0 && (
                                    <>
                                        <tr>
                                            <td className="font-weight-semibold">
                                                Board
                                            </td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end text-capitalize">
                                                {data?.board}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-semibold">
                                                Standard
                                            </td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end text-capitalize">
                                                {data?.standard}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-semibold">
                                                Medium
                                            </td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end text-capitalize">
                                                {data?.medium}
                                            </td>
                                        </tr>
                                    </>
                                )}
                                <tr>
                                    <th>
                                        Audio Language
                                    </th>
                                    <td className="w-0 text-center">
                                        :
                                    </td>
                                    <td className="text-end">
                                        {data?.audio_language}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Videos
                                    </th>
                                    <td className="w-0 text-center">:</td>
                                    <td className="text-end">
                                        {data.lectures_count} Videos
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Duration
                                    </th>
                                    <td className="w-0 text-center">:</td>
                                    <td className="text-end">
                                        {data?.total_duretion ? utils.toHHMMSS(data?.total_duretion) : 0} Hours
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Last Update
                                    </th>
                                    <td className="w-0 text-center">:</td>
                                    <td className="text-end">
                                        <Moment format="DD-MM-YYYY hh:mm A">
                                            {data?.last_update}
                                        </Moment>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Course Name</h4>
                        </div>
                        <div className="card-body">
                            {data?.title}
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Short Description</h4>
                        </div>
                        <div className="card-body">
                            <p>{data?.short_discription}</p>
                        </div>
                        <div className="card-body">
                            <div className="row ">
                                <div className="col-xl-4 col-lg-4 col-md-4 ">
                                    <div className="card mb-0 border-0 shadow-none">
                                        <div className="d-flex pb-0">
                                            <img src={data?.teacher?.image} className="border-radius-50 d-md-flex avatar-lg me-3" alt={data?.teacher?.name} />
                                            <div>
                                                <span className="icons fs-16 font-weight-semibold ">Teacher</span>
                                                <Link to={`/teacher-detail/${data?.teacher?.teacher_id}`} className="icons h4 font-weight-semibold ">
                                                    <span className=" d-block">{data?.teacher?.name}</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-4">
                                    <div className="card mb-0 border-0 shadow-none">
                                        <div className="pb-0">
                                            <div>
                                                <span className="icons fs-16 font-weight-semibold ">Category</span>
                                                <span className="d-block h4 font-weight-semibold">{data?.category?.title}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-4">
                                    <div className="card mb-0 border-0 shadow-none">
                                        <span className="icons font-weight-semibold fs-16 ">Reviews</span>
                                        <span className="d-inline-flex">
                                            <div className="br-widget">
                                                {rating.map((el, i) => (
                                                    <i
                                                        data-rating-value={el}
                                                        data-rating-text={el}
                                                        className={
                                                            el <= data?.average_review
                                                                ? "br-selected"
                                                                : ""
                                                        }
                                                        key={i}
                                                    />
                                                ))}
                                            </div>
                                            <span>({data?.review_count} Reviews)</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <Tabs defaultActiveKey="overview" id="uncontrolled-tab-example">
                            <Tab eventKey="overview" title="overview">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Course Description</h4>
                                    </div>
                                    <div className="card-body education-mandal"
                                        dangerouslySetInnerHTML={{ __html: data?.course_discription }}
                                    >
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">What Will I Learn in this course?</h4>
                                    </div>
                                    <div className="card-body education-mandal"
                                        dangerouslySetInnerHTML={{
                                            __html: data?.student_learn_in_cutse,
                                        }}
                                    >
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Requirements</h4>
                                    </div>
                                    <div className="card-body education-mandal"
                                        dangerouslySetInnerHTML={{ __html: data?.requirements }}
                                    >
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="curriculum" title="curriculum">
                                <div className="accordion" id="accordionExample">
                                    {data?.section?.length > 0 ? (
                                        data?.section.map((data, i) => (
                                            <div className="accordion-item mb-3" key={i}>
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button
                                                        className={
                                                            active[i]
                                                                ? "accordion-button"
                                                                : "accordion-button collapsed"
                                                        }
                                                        type="button"
                                                        onClick={() => activeHandler(i)}
                                                    >
                                                        Chapter {i + 1} : {data?.title}
                                                    </button>
                                                </h2>
                                                {active[i] && (
                                                    <div
                                                        id="collapseOne"
                                                        className={
                                                            active[i]
                                                                ? "accordion-collapse collapse show"
                                                                : "accordion-collapse collapse"
                                                        }
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="accordion-body">
                                                            <div className="align-items-center">
                                                                {data?.lectures.length > 0 ? (
                                                                    data?.lectures.map((data, i) => (
                                                                        <div
                                                                            className="d-flex align-items-center justify-content-between cursor-pointer mb-4"
                                                                            key={i}
                                                                        >
                                                                            <div className="d-flex align-items-center">
                                                                                <i className="fa-solid fa-video"></i>
                                                                                <div className="ms-3">{data?.title}</div>
                                                                            </div>

                                                                            <div className="d-flex align-items-center">

                                                                                {data?.is_preview == 1 && (
                                                                                    <Link
                                                                                        className="text-danger d-flex me-5 align-items-center"
                                                                                        to="#"
                                                                                        onClick={() => {
                                                                                            setOpenPreviewPopup({ [data.id]: true });
                                                                                        }}
                                                                                    >
                                                                                        <i className="fa-solid fa-play me-2"></i> preview
                                                                                    </Link>
                                                                                )}
                                                                                <Link
                                                                                    className="text-danger d-flex me-5"
                                                                                    to="#"
                                                                                    onClick={() => {
                                                                                        setOpenPopup({ [data.id]: true });
                                                                                    }}
                                                                                >
                                                                                    <i className="fa-solid fa-play"></i>
                                                                                </Link>

                                                                                <div className="badge btn-primary width-55px">
                                                                                    {utils.toHHMMSS(
                                                                                        data?.video_duration
                                                                                    )}
                                                                                </div>

                                                                                {openPreviewPopup[data.id] && (
                                                                                    <Popup
                                                                                        className="videoIntor"
                                                                                        title={intodata?.title}
                                                                                        data={data?.preview_video}
                                                                                        openPopup={openPreviewPopup}
                                                                                        setOpenPopup={setOpenPreviewPopup}
                                                                                        id={data.id}
                                                                                    />
                                                                                )}
                                                                                {openPopup[data.id] && (
                                                                                    <Popup
                                                                                        className="videoIntor"
                                                                                        title={intodata?.title}
                                                                                        data={data?.preview_video}
                                                                                        openPopup={openPopup}
                                                                                        setOpenPopup={setOpenPopup}
                                                                                        id={data.id}
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    <NoDataAvailable />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ))
                                    ) : (
                                        <NoDataAvailable />
                                    )}
                                </div>
                            </Tab>
                            <Tab eventKey="teacher" title="Teacher">
                                <div className="row">
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                        <Link to={`/teacher-detail/${data?.teacher?.teacher_id}`}>
                                            <div className="card">
                                                <div className="card-body text-center" >
                                                    <div className="item-card7-img">
                                                        <img
                                                            src={data?.teacher?.image}
                                                            className="cover-image teacher-student-image"
                                                            alt={data?.teacher?.name}
                                                        />
                                                    </div>
                                                    <h4 className="mt-4 mb-2 leading-tight">
                                                        {data?.teacher?.name}
                                                    </h4>
                                                    <h6 className="team-section badge">
                                                        {data?.teacher?.total_experiance != 0 ? calculateExperience(data?.teacher?.total_experiance) : "---"} Experience
                                                    </h6>
                                                    <div className="d-flex justify-content-center">
                                                        <div className="br-widget">
                                                            {rating.map((el, i) => (
                                                                <i
                                                                    data-rating-value={el}
                                                                    data-rating-text={el}
                                                                    className={
                                                                        el <= data?.average_review
                                                                            ? "br-selected"
                                                                            : ""
                                                                    }
                                                                    key={i}
                                                                />
                                                            ))}
                                                        </div>
                                                        <span>({data?.review_count} Reviews)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="reviews" title="reviews">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Review</h4>
                                    </div>
                                    <div className="card-body">
                                        <ReviewForm data={data} />
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrderDetails;
