import React, { useEffect, useState } from "react";
import BannerSection from "./BannerSection";
import Header from "../Header/Header";
import LatestBlogNews from "./LatestBlogNews";
import LatestCourses from "./LatestCourses";
import RegsiterHere from "./RegsiterHere";
import Institute from "./Institute";
import TotalSection from "./TotalSection";
import BestTeachers from "./BestTeachers";
import Footer from "../Footer/Footer";
import url from "../../../constants/url";
import ComingUpClasses from "./ComingUpClasses";
import Partner from "./Partners"
import {
    getBestTeacher,
    getLatestReview,
    getClass,
    getTotal,
    getLatestBlog,
    getOnlineAllCourse,
} from "../../../apis_web";
import EnquiryForm from "../../../EnquiryForm";
import WhyChooseUs from "./WhyChooseUs";
import InstituteBenefits from "./InstituteBenefits";
import ProductServices from "./ProductServices";
import HomePrice from "./HomePrice";
import RegisterYourself from "./RegisterYourself";
import HomeAboutUs from "./HomeAboutUs";
import Testimonial from "./Testimonial";
import FAQ from "./FAQ";
import HomeContact from './HomeContact'

const HomePage = () => {
    const [courseData, setCourseData] = useState();
    const [isLoader, setIsLoader] = useState(true);
    const [showEnquiryForm, setShowEnquiryForm] = useState(false); // State to control visibility
    const [isFormVisible, setIsFormVisible] = useState(false); // State for animation

    const getAllOnlineCourseData = async () => {
        let res = await getOnlineAllCourse(10, 0);
        if (res.status === "success") {
            setCourseData(res.data);
        }
    };

    useEffect(() => {
        getAllOnlineCourseData();
        setTimeout(() => {
            setIsLoader(false);
        }, 5000);
    }, []);

    const [teacherData, setTeacherData] = useState();
    const getAllBestTeacherData = async () => {
        let res = await getBestTeacher("", 0);
        if (res.status === "success") {
            setTeacherData(res.data);
        }
    };

    useEffect(() => {
        getAllBestTeacherData();
    }, []);

    const [reviewData, setReviewData] = useState();
    const getAllReviewData = async () => {
        let res = await getLatestReview("", 0);
        if (res.status === "success") {
            setReviewData(res.course_review);
        }
    };

    useEffect(() => {
        getAllReviewData();
    }, []);

    const [classData, setClassData] = useState();
    const getAllClassData = async () => {
        let res = await getClass(10, 0);
        if (res.status === "success") {
            setClassData(res.data);
        }
    };

    useEffect(() => {
        getAllClassData();
    }, []);

    const [totalData, setTotalData] = useState();
    const getAllTotalData = async () => {
        let res = await getTotal("", 0);
        if (res.status === "success") {
            setTotalData(res.data);
        }
    };

    useEffect(() => {
        getAllTotalData();
    }, []);

    const [blogData, setBlogData] = useState();
    const getAllBlogData = async () => {
        let res = await getLatestBlog("", 0);
        if (res.status === "success") {
            setBlogData(res.data);
        }
    };

    useEffect(() => {
        getAllBlogData();
    }, []);


    useEffect(() => {
        const formShown = sessionStorage.getItem('enquiryFormShown');
    
        if (formShown !== 'true') {
            const timer = setTimeout(() => {
                setShowEnquiryForm(true);
                setIsFormVisible(true);
            }, 2000);
    
            sessionStorage.setItem('enquiryFormShown', 'true');
    
            return () => clearTimeout(timer);
        }
    }, []);

    return (
        <>
            <Header />
            {showEnquiryForm && (
                <div className={`enquiry-form ${isFormVisible ? "visible" : ""}`}>
                    <EnquiryForm />
                </div>
            )}
            <BannerSection />
            <WhyChooseUs/>
            <Partner />
            {/* <InstituteBenefits/> */}
            <ProductServices/>
            <LatestCourses data={courseData} />
            {/* <ComingUpClasses data={classData} /> */}
            {/* <Institute /> */}
            {/* <BestTeachers data={teacherData} /> */}
            <TotalSection data={totalData} />
            <HomePrice/>
            <HomeAboutUs/>
            <RegsiterHere />
            <RegisterYourself/>
            <Testimonial/>
            <LatestBlogNews data={blogData} />
            <FAQ/>
            <HomeContact/>
            <Footer />
        </>
    );
};

export default HomePage;
