import React, { useEffect, useState } from "react";
import {
    getTeacherAddEducation,
    getTeacherEducationsList,
    getTeacherGetEducationById,
    getTeacherEditEducation,
} from "../../../../apis";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import moment from "moment";
import NoDataAvailable from "../../../common/NoDataAvailable";

const Education = () => {
    const [educationForm, setEducationForm] = useState(false);
    const current = moment().format("YYYY-MM-DD");
    const [data, setData] = useState([])
    const initialValues = {
        school_college: "",
        degree: "",
        field_of_study: "",
        start_date: "",
        end_date: "",
        is_present: 0,
    };
    const [addeudcation, setAddeudcation] = useState(initialValues);
    const initialValidation = {
        school_college: "",
        degree: "",
        field_of_study: "",
        start_date: "",
        end_date: "",
    };
    const [educationValidation, setEducationValidation] = useState(initialValidation);

    const handleChangeEdu = (e, key) => {
        e.preventDefault();
        let value = e.target.value;
        setAddeudcation({
            ...addeudcation,
            [key]: value,
        });
    };
    const handleChangeIsPresent = (event) => {
        let value = 0;
        if (event.target.checked) {
            value = 1;
        } else {
            value = 0;
        }
        setAddeudcation({ ...addeudcation, "is_present": value });
    };

    const getTeacherEducations = async () => {
        let res = await getTeacherEducationsList();
        if (res.status === "success") {
            setData(res.data);
        }
    };

    const handleEditEdu = async (id) => {
        // setIsModelOpen(1)
        setEducationForm(true)
        let reqData = {
            education_id: id,
        };
        let res = await getTeacherGetEducationById(reqData);
        setAddeudcation(res.data);
    };

    const handleEducation = async (val) => {
        setEducationForm(val);
        setEducationValidation(initialValidation);
        setAddeudcation(initialValues);

    };

    const handleAddUPdateEdu = async (e) => {
        e.preventDefault();
        const errors = {};
        if (
            addeudcation.school_college === undefined ||
            addeudcation.school_college === ""
        ) {
            errors.school_college = "Required";
        }
        if (addeudcation.degree === undefined || addeudcation.degree === "") {
            errors.degree = "Required";
        }
        if (
            addeudcation.field_of_study === undefined ||
            addeudcation.field_of_study === ""
        ) {
            errors.field_of_study = "Required";
        }
        if (
            addeudcation.start_date === undefined ||
            addeudcation.start_date === ""
        ) {
            errors.start_date = "Required";
        }
        if (addeudcation.is_present === undefined || addeudcation.is_present === 0) {
            if (addeudcation.end_date === undefined || addeudcation.end_date === "") {
                errors.end_date = "Required";
            } else {
                if (addeudcation.start_date < addeudcation.end_date) {
                } else {
                    errors.end_date = "Please enter end date grater then start date";
                }
            }
        }

        var raw = JSON.stringify({
            school_college: addeudcation.school_college,
            degree: addeudcation.degree,
            field_of_study: addeudcation.field_of_study,
            start_date: addeudcation.start_date,
            end_date: addeudcation.end_date,
            is_present: addeudcation.is_present,
        });

        if (Object.keys(errors).length === 0) {
            if (addeudcation.id) {
                let reqData = addeudcation;
                reqData["education_id"] = addeudcation.id;
                var res = await getTeacherEditEducation(reqData);
            } else {
                var res = await getTeacherAddEducation(raw);
            }
            if (res.status === "success") {
                toast.success(res.message);
                document.getElementById("education-close").click();
                document.getElementById("education-close-reset").click();
                getTeacherEducations();
                setAddeudcation(initialValues);
                setEducationValidation(initialValidation);
            } else {
                toast.error(res.message);
                setEducationValidation(res.data);
            }
        } else {
            setEducationValidation(errors);
        }
    };

    useEffect(() => {
        getTeacherEducations();
    }, []);
    return (
        <>
            <div className="row ">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header justify-content-between">
                            <h4 className="card-title">Education Details</h4>
                            <div
                                className="d-flex align-items-center"
                                onClick={() => {
                                    handleEducation(true);
                                }}
                            >
                                <Link
                                    to="#"
                                    className="btn btn-primary"
                                    title="Add"
                                    data-bs-toggle="modal"
                                    data-bs-target="#addeducation"
                                >
                                    <i className="fa-solid fa-plus"></i>
                                </Link>
                            </div>
                            <div
                                className={
                                    educationForm
                                        ? "modal fade show"
                                        : "modal fade"
                                }
                                id="addeducation"
                                tabIndex={-1}
                                aria-labelledby="myModalLabel33"
                                aria-hidden="true"
                                style={
                                    educationForm ? { display: "block" } : { display: "none" }
                                }
                            >
                                <div className="modal-dialog modal-dialog-centered modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title" id="myModalLabel33">
                                                Add Education Details
                                            </h4>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                id="education-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                                onClick={() => handleEducation(false)}
                                            />
                                        </div>
                                        {educationForm && (
                                            <form action="#">
                                                <div className="modal-body">
                                                    <div className="row">
                                                        <div className="col-md-6 form-group">
                                                            <label

                                                                htmlFor="school-name"
                                                            >
                                                                School / College
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                onChange={(e) =>
                                                                    handleChangeEdu(e, "school_college")
                                                                }
                                                                name="school_college"
                                                                value={addeudcation.school_college}
                                                                id="school-name"
                                                                autoComplete="off"
                                                                className="form-control"
                                                                placeholder="Enter title"
                                                            />
                                                            <p className="bg-error">
                                                                {educationValidation.school_college}
                                                            </p>
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <label htmlFor="degree-name" >
                                                                Degree<span className="text-danger"> *</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                onChange={(e) => handleChangeEdu(e, "degree")}
                                                                name="degree"
                                                                value={addeudcation.degree}
                                                                id="degree-name"
                                                                autoComplete="off"
                                                                className="form-control"
                                                                placeholder="Ex. Bachelor's"
                                                            />
                                                            <p className="bg-error">
                                                                {educationValidation.degree}
                                                            </p>
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <label

                                                                htmlFor="field-name"
                                                            >
                                                                Field of Study
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                onChange={(e) =>
                                                                    handleChangeEdu(e, "field_of_study")
                                                                }
                                                                name="field_of_study"
                                                                value={addeudcation.field_of_study}
                                                                id="field-name"
                                                                autoComplete="off"
                                                                className="form-control"
                                                                placeholder="Ex. Business"
                                                            /><p className="fs-12 mb-0"><span className="text-danger">Note: </span> Eg. Computer Science is Field of Study for Engineering</p>

                                                            <p className="bg-error">
                                                                {educationValidation.field_of_study}
                                                            </p>
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <label

                                                                htmlFor="start-date"
                                                            >
                                                                Start Date
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <input
                                                                type="date"
                                                                onChange={(e) => handleChangeEdu(e, "start_date")}
                                                                name="start_date"
                                                                value={addeudcation.start_date}
                                                                max={current}
                                                                id="start-date"
                                                                placeholder="DD-MM-YYYY"
                                                                autoComplete="off"
                                                                className="form-control"
                                                            />

                                                            <p className="bg-error">
                                                                {educationValidation.start_date}
                                                            </p>
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <div className="form-check form-check-inline mt-6  p-0">
                                                                <label className="custom-control form-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                        name="is_present"
                                                                        id="company-names"
                                                                        checked={addeudcation?.is_present}
                                                                        onChange={(e) =>
                                                                            handleChangeIsPresent(e)
                                                                        }
                                                                        value="checkbox"
                                                                    />
                                                                    <span className="custom-control-label">
                                                                        I am currently study in this role
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {addeudcation.is_present === 0 && (
                                                            <div className="col-md-6 form-group">
                                                                <label

                                                                    htmlFor="end-date"
                                                                >
                                                                    End Date
                                                                    <span className="text-danger"> *</span>
                                                                </label>
                                                                <input
                                                                    type="date"
                                                                    onChange={(e) => handleChangeEdu(e, "end_date")}
                                                                    name="end_date"
                                                                    value={addeudcation.end_date}
                                                                    min={addeudcation.start_date}
                                                                    id="end-date"
                                                                    placeholder="DD-MM-YYYY"
                                                                    autoComplete="off"
                                                                    className="form-control"
                                                                />
                                                                <p className="bg-error">
                                                                    {educationValidation.end_date}
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </form>
                                        )}

                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn btn-primary "
                                                onClick={handleAddUPdateEdu}
                                            >
                                                Submit
                                            </button>
                                            <button
                                                type="reset"
                                                className="btn btn-danger "
                                                id="education-close-reset"
                                                data-bs-dismiss="modal"
                                                onClick={() => handleEducation(false)}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {data?.length > 0 ? (
                            data.map((item, i) => (
                                <div className="card-body" key={i}>
                                    <ul className="d-flex mb-0">
                                        <li>
                                            <div className="activity1 bg-primary">
                                                <i className="fe fe-shopping-bag"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="font-weight-semibold">
                                                {item?.degree} - ( {item?.field_of_study} )
                                            </div>
                                            <p className="text-muted mb-0">
                                                {item?.school_college}
                                            </p>
                                        </li>
                                        <div className="text-muted ms-auto">
                                            <Moment format="DD-MM-YYYY">
                                                {item?.start_date}
                                            </Moment>
                                            {" - "}
                                            {item?.is_present === 1 ? (
                                                "Present"
                                            ) : (
                                                <Moment format="DD-MM-YYYY">
                                                    {item?.end_date}
                                                </Moment>
                                            )}
                                            <Link
                                                to="#"
                                                onClick={() => handleEditEdu(item?.id)}
                                                className="btn btn-primary ms-3"
                                                title="Edit"
                                            >
                                                <i className="fa-solid fa-pen-to-square"></i>
                                            </Link>
                                        </div>
                                    </ul>
                                </div>
                            ))
                        ) : (
                            <NoDataAvailable/>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Education;
