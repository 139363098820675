import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import url from "../../../../../constants/url";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import UpdateProfile from "./UpdateProfile";
import Moment from "react-moment";
let rating = [1, 2, 3, 4, 5];

const InstituteProfile = () => {
    const [item, setItem] = useState({});
    const [value, setValue] = useState();
    const [profileForm, setProfileForm] = useState(false);
    const [selectedImage, setSelectedImage] = useState();
    const getProfileData = async () => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
        };

        fetch(`${url.apiUrl}institute/get-profile`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    sessionStorage.setItem("header_image", result?.data?.image);
                    setValue(result.data?.contact);
                    setItem(result?.data);
                }
            })
            .catch((error) => console.log("error", error));
    };

    useEffect(() => {
        getProfileData();
    }, []);

    return (
        <>
            <section className='app-user-view'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='card'>
                            <div className='card-header justify-content-between'>
                                <h4 className='card-title'>Institute Profile</h4>

                                <div
                                    className='d-flex align-items-center'
                                    onClick={() => {
                                        setProfileForm(true);
                                    }}
                                >
                                    <Link
                                        to='#'
                                        className='btn btn-primary'
                                        data-bs-toggle='modal'
                                        data-bs-target='#profile'
                                    >
                                        <i className='fa fa-edit'></i>
                                    </Link>
                                </div>
                                <div
                                    className={
                                        profileForm ? "modal fade show" : "modal fade"
                                    }
                                    id='profile'
                                    tabIndex={-1}
                                    aria-labelledby='myModalLabel33'
                                    aria-hidden='true'
                                    style={
                                        profileForm ? { display: "block" } : { display: "none" }
                                    }
                                >
                                    <div className='modal-dialog modal-dialog-centered modal-lg'>
                                        <div className='modal-content'>
                                            <div className='modal-header'>
                                                <h4 className='modal-title' id='myModalLabel33'>
                                                    Institute Profile Update
                                                </h4>
                                                <button
                                                    type='button'
                                                    className='btn-close'
                                                    onClick={() => setProfileForm(false)}
                                                />
                                            </div>
                                            {profileForm && (
                                                <UpdateProfile
                                                    getProfileData={getProfileData}
                                                    setProfileForm={setProfileForm}
                                                    formValues={item}
                                                    setFormValues={setItem}
                                                    setSelectedImage={setSelectedImage}
                                                    selectedImage={selectedImage}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-xl-2 col-lg-6 col-md-6 col-12'>
                                        <div className="item-card7-img">
                                            <img
                                                className='cover-image'
                                                src={item?.image}
                                                alt={item?.name}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-xl-4 col-lg-6 col-md-6 col-12 mt-5 mt-xl-0'>
                                        <h4>{item?.name}</h4>
                                        <p className='card-text mb-2'>{item?.email}</p>
                                        <p className='card-text mb-2'>{item?.contact}</p>
                                        <p className='card-text'><i className='fa fa-location-dot me-1'></i> {item?.address}, {item?.cities?.name}, {item?.states?.name} - {item?.pincode}</p>
                                    </div>
                                    <div className='col-xl-6 col-lg-12 mt-5 mt-xl-0'>
                                        <table className='user-details table mb-0'>
                                            <tbody>
                                                <tr>
                                                    <td className="py-1 px-0 font-weight-semibold w-50">
                                                        Username / Referral Code
                                                    </td>
                                                    <td className='py-1 px-0 text-uppercase'>
                                                        {item?.user_name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="py-1 px-0 font-weight-semibold w-50">
                                                        Year of Incorporation
                                                    </td>
                                                    <td className='py-1 px-0'>
                                                        {item?.year_of_incap ? item?.year_of_incap : "---"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="py-1 px-0 font-weight-semibold w-50">
                                                        Rating
                                                    </td>
                                                    <td className='py-1 px-0'>
                                                        <div className='d-inline-flex'>
                                                            <div className='br-widget'>
                                                                {rating.map((el, i) => (
                                                                    <i
                                                                        data-rating-value={el}
                                                                        data-rating-text={el}
                                                                        className={
                                                                            el <= item?.average_review
                                                                                ? "br-selected"
                                                                                : ""
                                                                        }
                                                                        key={i}
                                                                    />
                                                                ))}
                                                            </div>
                                                            <span>
                                                                ({item?.review_count} Reviews)
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="py-1 px-0 font-weight-semibold w-50">
                                                        Last Update
                                                    </td>
                                                    <td className='py-1 px-0'>
                                                        <Moment format='DD-MM-YYYY hh:mm:A'>{item?.updated_at}</Moment>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
            <ToastContainer />
        </>
    );
};

export default React.memo(InstituteProfile);
