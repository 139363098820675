import { Link } from 'react-router-dom';
import React, { useState, useRef, useEffect } from 'react';

const HomeAboutUs = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <>
            <section className="transform-section-five">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-xl-5 mb-4">
                            <div className="joing-count-five">
                                <img
                                    src="https://img.freepik.com/free-vector/man-working-home-with-your-laptop-prevent-virus-infection_1150-34988.jpg?t=st=1732088953~exp=1732092553~hmac=60646911d3830424efd4e9ecbe31726cbbb4d2af0feff392cc2d91af6914ff46&w=740"
                                    alt="Img"
                                />
                            </div>
                        </div>
                        <div className="col-lg-12 col-xl-7 mb-4" data-aos="fade-left">
                            <div className="transform-access-content">
                                <div className="header-five-title">
                                    <h2>Education Mandal : Lead, Identify And Learn</h2>
                                </div>
                                <div className="career-five-content">
                                    <p className="mb-0">
                                        Education Mandal connects institutions, teachers, and students, fostering a thriving learning ecosystem. It simplifies access to top institutions, skilled educators, and quality courses while promoting inclusive and accessible education.
                                    </p>
                                </div>
                                <Accordion openIndex={openIndex} toggleAccordion={toggleAccordion} />
                                <Link to="/about-us" className="btn btn-primary mt-5">
                                    View More Information
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

const Accordion = ({ openIndex, toggleAccordion }) => {
    const accordionData = [
        {
            title: "Why Choose Education Mandal?",
            content: [
                "One-stop-shop",
                "Institutions Database",
                "Access to top-notch Teachers",
                "Institution and Teacher Ratings",
                "Student Community",
            ],
        },
        {
            title: "Benefits for Institutes",
            content: [
                "Increased Visibility",
                "Branch Management",
                "Teacher Management",
                "Student Acquisition",
                "Data Insights",
                "Cost-effective Solution",
            ],
        },
        {
            title: "Benefits for Teachers",
            content: [
                "Increased Visibility",
                "Career Growth",
                "Networking Opportunities",
                "Reduced Administrative Work",
                "Student Feedback",
            ],
        },
        {
            title: "Benefits for Students",
            content: [
                "Comprehensive Database",
                "User-friendly Interface",
                "Cost-effective",
                "Career Guidance",
                "Education Loan",
            ],
        },
    ];

    return (
        <div className="accordion" id="educationalAggregatorAccordion">
            {accordionData.map((item, index) => (
                <AccordionItem
                    key={index}
                    index={index}
                    isOpen={openIndex === index}
                    toggleAccordion={toggleAccordion}
                    title={item.title}
                    content={item.content}
                />
            ))}
        </div>
    );
};

const AccordionItem = ({ index, isOpen, toggleAccordion, title, content }) => {
    const contentRef = useRef(null);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        if (isOpen) {
            setHeight(contentRef.current.scrollHeight);
        } else {
            setHeight(0);
        }
    }, [isOpen]);

    return (
        <div className="accordion-item">
            <h2 className="accordion-header" id={`heading${index}`}>
                <button
                    className={`accordion-button ${isOpen ? "" : "collapsed"}`}
                    type="button"
                    onClick={() => toggleAccordion(index)}
                    aria-expanded={isOpen}
                    aria-controls={`collapse${index}`}
                >
                    {title}
                </button>
            </h2>
            <div
                id={`collapse${index}`}
                className="accordion-collapse"
                style={{
                    height: `${height}px`,
                    overflow: "hidden",
                    transition: "height 0.3s ease",
                }}
                aria-labelledby={`heading${index}`}
            >
                <div className="accordion-body" ref={contentRef}>
                    <ul>
                        {content.map((item, idx) => (
                            <li key={idx}>
                                <h5>{item}</h5>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default HomeAboutUs;
