import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import utils from "../../../utils/utils";
import NoDataAvailable from "../../common/NoDataAvailable";
let rating = [1, 2, 3, 4, 5];

const LatestCourses = (props) => {
    let now = new Date().setHours(0, 0, 0, 0);

    const history = useHistory();
    const [data, setCourseData] = useState(props.data);
    useEffect(() => {
        if (props.data && props.data) setCourseData(props.data);
    }, [props.data]);
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 2,
        autoplay: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
        ],
    };
    
    return (
        <>
            {data == 0 ? '' : <section className="sptb bg-white">
                <div className="container">
                    <div className="row">
                        <div className="section-title col-md-12">
                            <h2>Best-Selling Video Courses</h2>
                            <div className="ms-auto">
                                <Link to="/video-course" className="btn btn-primary">
                                    View More <i className="fe fe-arrow-right" />
                                </Link>
                            </div>
                        </div>
                        <div className="match-height">
                            {data?.length >= 3 ? (
                                <Slider {...settings}>
                                    {data?.map((item, i) => (
                                        <div className="col-md-12" key={i} >
                                            <Link to={`/video-course-detail/${item?.id}`}>
                                                <div className="card">
                                                    <div className="card-body ">
                                                        <div className="item-card7-img">
                                                            <img className="cover-image" src={item?.teaser_thumb_image} alt={item?.title} />
                                                            {(
                                                                item?.price == "paid" && (
                                                                    item?.is_discount == 1 && (
                                                                        (item?.is_limited_offer == 1 && (new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                        (item?.is_limited_offer == 0 && new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                                )
                                                            ) && (
                                                                    <Link to="#" className="bg-danger top left">
                                                                        {item?.discount_price}% off
                                                                    </Link>
                                                                )}
                                                            <Link to="#" className="bg-danger right top">
                                                                Video Course
                                                            </Link>
                                                            {item?.is_professional === 0 && (
                                                                <Link to="#" className="bg-danger right bottom">
                                                                    {item?.board}
                                                                </Link>
                                                            )}
                                                        </div>
                                                        <div className="item-card2-desc">
                                                            <div className="d-inline-flex mb-2">
                                                                <div className="br-widget">
                                                                    {rating.map((el, i) => (
                                                                        <i
                                                                            data-rating-value={el}
                                                                            data-rating-text={el}
                                                                            className={
                                                                                el <= item?.average_review
                                                                                    ? "br-selected"
                                                                                    : ""
                                                                            }
                                                                            key={i}
                                                                        />
                                                                    ))}
                                                                </div>
                                                                <span>({item?.review_count} Reviews)</span>
                                                            </div>
                                                            <h4 className="mb-2 leading-tight2" >
                                                                {item?.title}
                                                            </h4>
                                                            <p className="leading-tight3 education-mandal">
                                                                {item?.short_discription}
                                                            </p>

                                                            <div className="pt-2 pb-2 border-bottom d-flex justify-content-between">
                                                                <div className="d-flex justify-content-start">
                                                                    <i className="fa fa-shop me-2" />
                                                                    <span className="text-muted text-data-over-cut">
                                                                        {item?.institute?.name}
                                                                    </span>
                                                                </div>
                                                                <div className="float-end">
                                                                    <i className="fa fa-signal me-2" />
                                                                    <span className="text-muted text-capitalize">
                                                                        {item?.course_level}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="pt-2 pb-2 border-bottom">
                                                                <i className="fa fa-video me-2" />
                                                                <span className="text-muted">
                                                                    {item?.lectures_count} Videos
                                                                </span>
                                                                <div className="float-end">
                                                                    <i className="fa fa-clock-o me-2" />
                                                                    <span className="text-muted">
                                                                        {item?.total_duretion ? utils.toHHMMSS(item?.total_duretion) : 0} Hours
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="pt-2">
                                                                <i className="fa fa-microphone me-2" />
                                                                <span className="text-muted">
                                                                    {item?.audio_language}
                                                                </span>
                                                                <div className="float-end">
                                                                    {item?.price === "paid" && (
                                                                        <h4 className="mb-0"> ₹ {Math.round(item?.payable_price)} {(
                                                                            item?.price == "paid" && (
                                                                                item?.is_discount == 1 && (
                                                                                    (item?.is_limited_offer == 1 && (new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                                    (item?.is_limited_offer == 0 && new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                                            )
                                                                        ) && (<del className="fs-14 text-muted">₹ {Math.round(item?.regular_price)} </del>
                                                                            )}
                                                                        </h4>
                                                                    )}
                                                                    {item?.price === "free" && (
                                                                        <h4 className="mb-0">Free</h4>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </Slider>
                            ) : (
                                <div className="row">
                                    {data?.length > 0 ? (
                                        data.map((item, i) => (
                                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12" key={i}>
                                                <Link to={`/video-course-detail/${item?.id}`}>
                                                    <div className="card">
                                                        <div className="card-body ">
                                                            <div className="item-card7-img">
                                                                <img
                                                                    src={item?.teaser_thumb_image}
                                                                    alt={item?.title}
                                                                    className="cover-image"
                                                                />
                                                                {(
                                                                    item?.price == "paid" && (
                                                                        item?.is_discount == 1 && (
                                                                            (item?.is_limited_offer == 1 && (new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                            (item?.is_limited_offer == 0 && new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                                    )
                                                                ) && (
                                                                        <Link to="#" className="bg-danger top left">
                                                                            {item?.discount_price}% Off
                                                                        </Link>
                                                                    )}
                                                                <Link to="#" className="bg-danger right top ">
                                                                    Video Course
                                                                </Link>
                                                                {item?.is_professional === 0 && (
                                                                    <Link to="#" className="bg-danger right bottom">
                                                                        {item?.board}
                                                                    </Link>
                                                                )}
                                                            </div>
                                                            <div className="item-card2-desc">
                                                                <div className="d-inline-flex mb-2">
                                                                    <div className="br-widget">
                                                                        {rating.map((el, i) => (
                                                                            <i
                                                                                data-rating-value={el}
                                                                                data-rating-text={el}
                                                                                className={
                                                                                    el <= item?.average_review
                                                                                        ? "br-selected"
                                                                                        : ""
                                                                                }
                                                                                key={i}
                                                                            />
                                                                        ))}
                                                                    </div>
                                                                    <span>({item?.review_count} Reviews)</span>
                                                                </div>
                                                                <h4 className="mb-2 leading-tight2">
                                                                    {item?.title}
                                                                </h4>
                                                                <p className="leading-tight3 education-mandal">
                                                                    {item?.short_discription}
                                                                </p>
                                                                <div className="pt-2 pb-2 border-bottom d-flex justify-content-between">
                                                                    <div className="d-flex justify-content-start">
                                                                        <i className="fa fa-shop me-2" />
                                                                        <span className="text-muted text-data-over-cut">
                                                                            {item?.institute.name}
                                                                        </span>
                                                                    </div>
                                                                    <div className="float-end">
                                                                        <i className="fa fa-signal me-2" />
                                                                        <span className="text-muted text-capitalize">
                                                                            {item?.course_level}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="pt-2 pb-2 border-bottom">
                                                                    <i className="fa fa-video me-2" />
                                                                    <span className="text-muted">
                                                                        {item?.lectures_count} Videos
                                                                    </span>
                                                                    <div className="float-end">
                                                                        <i className="fa fa-clock-o me-2" />
                                                                        <span className="text-muted">
                                                                            {item?.total_duretion ? utils.toHHMMSS(item?.total_duretion) : 0} Hours
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="pt-2">
                                                                    <i className="fa fa-microphone me-2 font-weight-semibold" />
                                                                    <span className="text-muted">
                                                                        {item?.audio_language}
                                                                    </span>
                                                                    <div className="float-end">
                                                                        {item?.price === "paid" && (
                                                                            <h4 className="mb-0"> ₹ {Math.round(item?.payable_price)}  {(
                                                                                item?.price == "paid" && (
                                                                                    item?.is_discount == 1 && (
                                                                                        (item?.is_limited_offer == 1 && (new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                                        (item?.is_limited_offer == 0 && new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                                                )
                                                                            ) && (<del className="fs-14 text-muted">₹ {Math.round(item?.regular_price)} </del>
                                                                                )}
                                                                            </h4>
                                                                        )}
                                                                        {item?.price === "free" && (
                                                                            <h4 className="mb-0">Free</h4>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))

                                    ) : (
                                        <NoDataAvailable />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>}
        </>
    );
};

export default LatestCourses;
