import React, { useState, useEffect } from "react";
import { getTeacherGetAbout } from "../../../../apis";
import { toast } from "react-toastify";
import { Formik } from "formik";
import { aboutSchema } from "./TeacherAbout.helper";
import { Editor } from "@tinymce/tinymce-react";

const AboutProfile = ({ setForm, data, getTeacherGetProfiles }) => {

    const [initialState, setInitialState] = useState({
        about: "",
    });
    useEffect(() => {
        setInitialState({ about: data });
    }, []);

    async function onSubmit(value) {
        try {
            let reddata = {
                about: value.about,
            };
            let result = await getTeacherGetAbout(reddata);
            setForm(false);
            if (result.status === "success") {
                getTeacherGetProfiles();
                setForm(false)
                toast.success("About the Teacher Edited Successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else {
                toast.error("Something went wrong", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } catch (error) {
            console.log(error?.data?.message || error?.message || "Error");
        }
    }

    return (
        <>
            <Formik
                initialValues={initialState}
                onSubmit={onSubmit}
                validationSchema={aboutSchema}
                enableReinitialize={true}
            >
                {(props) => {
                    return (
                        <form onSubmit={props?.handleSubmit}>
                            <div className="modal-body">
                                <Editor
                                    textareaName='about'
                                    placeholder='Enter about'
                                    apiKey='9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d'
                                    init={{
                                        plugins: [
                                            "advlist",
                                            "autolink",
                                            "lists",
                                            "link",
                                            "image",
                                            "charmap",
                                            "preview",
                                            "anchor",
                                            "searchreplace",
                                            "visualblocks",
                                            "code",
                                            "fullscreen",
                                            "insertdatetime",
                                            "media",
                                            "table",
                                            "help",
                                            "wordcount",
                                            "spellchecker",
                                        ],

                                        toolbar:
                                            " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help |spellchecker ",
                                    }}
                                    value={props?.values?.about}
                                    onEditorChange={(e) =>
                                        props.handleChange({
                                            target: {
                                                name: `about`,
                                                value: e,
                                            },
                                        })
                                    }
                                />
                                {props?.errors.about ? (
                                    <p className="text-danger">
                                        {props?.errors?.about}
                                    </p>
                                ) : null}
                                <p className='text-end'>
                                    {props?.values?.about?.length} / 500 Characters
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                >
                                    Submit
                                </button>
                                <button
                                    type="reset"
                                    className="btn btn-danger "
                                    id="about-close-reset"
                                    data-bs-dismiss="modal"
                                    onClick={() => {
                                        props?.handleReset();
                                        setForm(false)
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </>
    )
}
export default AboutProfile;

