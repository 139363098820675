import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import {
    getStudentAddAchievement,
    getStudentUpdateAchievement,
    getStudentAchievement,
} from "../../../../apis";
import url from "../../../../constants/url";
import Moment from "react-moment";
import { toast } from "react-toastify";
import moment from "moment";
import SubmitLoader from "../../../common/SubmitLoader";
import { Editor } from "@tinymce/tinymce-react";
import NoDataAvailable from "../../../common/NoDataAvailable";

const Achievement = (props) => {
    const [form, setForm] = useState(false);
    const [loader, setLoader] = useState(false);
    const [data, setdata] = useState(props);

    const [formValidation, setFormValidation] = useState([]);
    const [isLegnth, setIsLegnth] = useState(0);


    const [addachievement, setAddachievement] = useState([]);

    const handleChangeAch = (e, key) => {
        e.preventDefault();
        let value = e.target.value;
        setAddachievement({
            ...addachievement,
            [key]: value,
        });
    };

    const handleAddUPdateAch = async (e) => {
        e.preventDefault();
        const errors = {};
        if (addachievement.title === undefined || addachievement.title === "") {
            errors.title = "Required";
        }
        if (addachievement.date === undefined || addachievement.date === "") {
            errors.date = "Required";
        }
        if (addachievement.description === undefined || addachievement.description === "") {
            errors.description = "Required";
        } else if (addachievement.description.length > 500) {
            errors.description = "Please write less than 500 characters"
        }
        setFormValidation(errors);

        if (Object.keys(errors).length === 0) {

            var res;
            if (addachievement.id) {
                addachievement.achievement_id = addachievement.id
                res = await getStudentUpdateAchievement(addachievement);
            } else {
                res = await getStudentAddAchievement(addachievement);
            }
            if (res.status === "success") {
                setLoader(false);
                removeState(false)
                getStudentAchivementGetProfiles();
                toast.success(res.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else {
                setLoader(false);
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } else {
            setFormValidation(errors);
        }
    };

    const EditDataFunction = async (e, id) => {
        removeState(true)
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("AUTHTOKEN", auth_token);

        var raw = JSON.stringify({
            achievement_id: id,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
        };
        setLoader(true);
        fetch(`${url.apiUrl}student/get-achievement-by-id`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setLoader(false);
                    setAddachievement(result.data);
                    setIsLegnth(result?.data?.description?.length);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const getStudentAchivementGetProfiles = async () => {
        let res = await getStudentAchievement();
        if (res.status === "success") {
            setdata(res.data);
        }
    };

    useEffect(() => {
        getStudentAchivementGetProfiles();
    }, []);

    const removeState = async (value) => {
        setForm(value);
        setAddachievement([]);
        setFormValidation([])
    };
    return (
        <>
            <div className="row ">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header justify-content-between">
                            <h4 className="card-title">Achievement Details</h4>
                            <div
                                className="d-flex align-items-center"
                                onClick={() => {
                                    removeState(true);
                                }}
                            >
                                <Link
                                    to="#"
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#addachivement"
                                >
                                    <i className="fa-solid fa-plus"></i>
                                </Link>
                            </div>
                            <div
                                className={
                                    form ? "modal fade show" : "modal fade"
                                }
                                id="addachivement"
                                tabIndex={-1}
                                aria-labelledby="myModalLabel33"
                                aria-hidden="true"
                                style={form ? { display: "block" } : { display: "none" }}
                            >
                                <div className="modal-dialog modal-dialog-centered modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title" id="myModalLabel33">
                                                Add Achievement Details
                                            </h4>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                id="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                                onClick={() => {
                                                    removeState(false);
                                                }}
                                            />
                                        </div>
                                        {form && (
                                            <form action="#">
                                                <div className="modal-body">
                                                    <div className="row">
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="title">
                                                                Title <span className="text-danger"> *</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                onChange={(e) => handleChangeAch(e, "title")}
                                                                onBlur={(e) => handleChangeAch(e, "title")}
                                                                name="title"
                                                                defaultValue={addachievement.title}
                                                                autoComplete="off"
                                                                id="title"
                                                                className="form-control"
                                                                placeholder="Enter title"
                                                            />
                                                            <p className="bg-error">
                                                                {formValidation.title}
                                                            </p>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="date">
                                                                Date <span className="text-danger"> *</span>
                                                            </label>
                                                            <input
                                                                type="date"
                                                                onChange={(e) => handleChangeAch(e, "date")}
                                                                name="date"
                                                                value={addachievement.date}
                                                                max={moment().format("YYYY-MM-DD")}
                                                                id="date"
                                                                placeholder="DD-MM-YYYY"
                                                                autoComplete="off"
                                                                className="form-control"
                                                            />
                                                            <p className="bg-error">
                                                                {formValidation.date}
                                                            </p>
                                                        </div>
                                                        <div className="form-group col-md-12">
                                                            <label

                                                                htmlFor="description"
                                                            >
                                                                Description
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Editor
                                                                textareaName='description'
                                                                placeholder='Enter description'
                                                                apiKey='9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d'
                                                                init={{
                                                                    plugins: [
                                                                        "advlist",
                                                                        "autolink",
                                                                        "lists",
                                                                        "link",
                                                                        "image",
                                                                        "charmap",
                                                                        "preview",
                                                                        "anchor",
                                                                        "searchreplace",
                                                                        "visualblocks",
                                                                        "code",
                                                                        "fullscreen",
                                                                        "insertdatetime",
                                                                        "media",
                                                                        "table",
                                                                        "help",
                                                                        "wordcount",
                                                                        "spellchecker",
                                                                    ],

                                                                    toolbar:
                                                                        " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help |spellchecker ",
                                                                }}
                                                                value={addachievement.description}
                                                                onEditorChange={(e) =>
                                                                    setAddachievement({ ...addachievement, description: e, })
                                                                }
                                                            />
                                                            <p className="bg-error">
                                                                {formValidation.description}
                                                            </p>
                                                            <p
                                                                style={{ position: "absolute", right: "15px" }}
                                                            >
                                                                {addachievement?.description?.length} / 500 Characters
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        )}

                                        <div className="modal-footer">
                                            {loader ? (
                                                <SubmitLoader />
                                            ) : (
                                                <>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={handleAddUPdateAch}
                                                    >
                                                        Submit
                                                    </button>
                                                    <button
                                                        type="reset"
                                                        id="reset-btn"
                                                        className="btn btn-danger"
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                        onClick={() => {
                                                            removeState(false);
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {data?.length > 0 ? (
                            data?.map((item, key
                            ) => (
                                <div className="card-body" key={key}>
                                    <ul className="d-flex mb-0">
                                        <li>
                                            <div className="activity1 bg-primary">
                                                <i className="fe fe-shopping-bag"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="font-weight-semibold">
                                                {item?.title}
                                            </div>
                                        </li>
                                        <div className="text-muted ms-auto">
                                            <Moment format="DD-MM-YYYY">{item?.date}</Moment>
                                            <Link
                                                to="#"
                                                className="btn btn-primary ms-3"
                                                title="Edit"
                                                data-bs-toggle="modal"
                                                data-bs-target={`#editachivement_${key}`}
                                                onClick={(e) => EditDataFunction(e, item?.id)}
                                            >
                                                <i className="fa fa-edit"></i>
                                            </Link>
                                        </div>
                                    </ul>
                                    <p className="text-muted mb-0 margin-left education-mandal" dangerouslySetInnerHTML={{ __html: item?.description }}>
                                    </p>
                                </div>
                            ))
                        ) : (
                            <NoDataAvailable />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Achievement;
