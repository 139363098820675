import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Subscribe from "../Homepage/Subscribe";

const Footer = () => {
    const [date, setDate] = useState();
    const getYear = () => setDate(new Date().getFullYear());
    useEffect(() => {
        getYear();
    }, [])
    const upArrowHandler = () => {
        window.scroll(0, 0);
    };

    const [isActive, setIsActive] = useState(false);
    const progressPathRef = useRef(null);

    useEffect(() => {
        const progressPath = progressPathRef.current;
        const pathLength = progressPath.getTotalLength();

        progressPath.style.transition = progressPath.style.WebkitTransition = "none";
        progressPath.style.strokeDasharray = `${pathLength} ${pathLength}`;
        progressPath.style.strokeDashoffset = pathLength;

        const updateProgress = () => {
            const scroll = window.scrollY;
            const height = document.documentElement.scrollHeight - window.innerHeight;
            const progress = pathLength - (scroll * pathLength) / height;
            progressPath.style.strokeDashoffset = progress;
        };

        updateProgress();
        window.addEventListener("scroll", updateProgress);

        const handleScroll = () => {
            setIsActive(window.scrollY > 50);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", updateProgress);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const scrollToTop = (e) => {
        e.preventDefault();
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    return (
        <>
            <footer className="bg-white pt-5 mt-5">
                <div className="footer-main pb-0">
                    <div className="container px-sm-0 px-5">
                        <div className="row">
                            <div className="pl-sm-5 col-md-12 col-lg-5 p-0 pe-lg-6 col-mm-1">
                                <img src="/assets/images/EduM_Black.png" alt="Footer Logo" />
                                <ul className="list-unstyled mb-0 mt-4 pe-5">
                                    <li>
                                        D 401, Bachraj Landmark, Near club one, Global City, Virar, Maharashtra - 401303
                                    </li>
                                    <li>
                                        <a href="mailto:info@educationmandal.com">info@educationmandal.com</a>
                                    </li>
                                </ul>
                                <ul className="list-unstyled mt-4 mb-lg-0 mb-5 pr-sm-5">
                                    <li className="list-inline-item">
                                        <a href="https://www.facebook.com/profile.php?id=100090317913078&mibextid=LQQJ4d" className="social-icons" target="_blank" rel="noreferrer">
                                            <i className="fe fe-facebook" />
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://wa.me/+919730063010" className="social-icons" target="_blank" rel="noreferrer">
                                            <i className="fa-brands fa-whatsapp"></i>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://www.linkedin.com/company/education-mandal/" className="social-icons" target="_blank" rel="noreferrer">
                                            <i className="fe fe-linkedin" />
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://instagram.com/education_mandal?igshid=YmMyMTA2M2Y=" className="social-icons" target="_blank" rel="noreferrer">
                                            <i className="fe fe-instagram" />
                                        </a>
                                    </li>
                                    <div className="pr-sm-5"><Subscribe /></div>
                                </ul>

                            </div>
                            <div className="pl-sm-5 col-lg-7 col-md-12 pl-lg-5 pt-md-4 pt-lg-0">
                                <div className="row">
                                    <div className="col-md-4 col-sm-6 col-6 p-0 col-mm-1">
                                        <h6 className="text-dark" style={{ fontWeight: "600" }}>
                                            Useful Links
                                        </h6>
                                        <ul className="list-unstyled mb-0">
                                            <li>
                                                <Link to="/">
                                                    <i className="fe fe-chevron-right" /> Home
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/about-us">
                                                    <i className="fe fe-chevron-right" /> About us
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/explore">
                                                    <i className="fe fe-chevron-right" /> Explore
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/services">
                                                    <i className="fe fe-chevron-right" /> Services
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/blog">
                                                    <i className="fe fe-chevron-right" /> Blogs
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-6 p-0 col-mm-1">
                                        <h6 className="text-dark" style={{ fontWeight: "600" }}>Support</h6>
                                        <ul className="list-unstyled mb-0">
                                            <li>
                                                <Link to="/privacy-policy">
                                                    <i className="fe fe-chevron-right" /> Privacy Policy
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/terms-offer">
                                                    <i className="fe fe-chevron-right" /> Terms & Conditions
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/disclaimer">
                                                    <i className="fe fe-chevron-right" /> Disclaimer
                                                </Link>
                                            </li>
                                        </ul>

                                    </div>
                                    <div className="col-md-4 col-sm-6 col-6 p-0 pt-md-0 pt-3 col-mm-1">
                                        <h6 className="text-dark" style={{ fontWeight: "600" }}>
                                            Useful Links
                                        </h6>
                                        <ul className="list-unstyled mb-0">
                                            <li>
                                                <Link to="/contact-us">
                                                    <i className="fe fe-chevron-right" /> Contact us
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/work-with-us">
                                                    <i className="fe fe-chevron-right" /> Work with us
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/careers-list">
                                                    <i className="fe fe-chevron-right" /> Carrers
                                                </Link>
                                            </li>
                                            {/* <li>
                                        <Link to="/pricing">
                                            <i className="fe fe-chevron-right" /> Pricing
                                        </Link>
                                    </li> */}
                                            {/* <li>
                                        <Link to="/alumni-list">
                                            <i className="fe fe-chevron-right" /> Alumni
                                        </Link>
                                    </li> */}
                                        </ul>
                                        {/* <div className="d-md-block d-none position-absolute" style={{ width: "205%" }}><Subscribe /></div> */}
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="footer-main">
                        <div className="container">
                            <div className="row align-items-center mt-5" style={{ "--bs-gutter-x": "0rem" }}>
                                <hr />
                                <div className="col-md-6">
                                    <ul className="payments mb-0">
                                        <li>
                                            <Link to="#" className="payments-icon text-white-50">
                                                <i className="fa fa-credit-card-alt" aria-hidden="true" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#" className="payments-icon text-white-50">
                                                <i className="fa-brands fa-cc-mastercard"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#" className="payments-icon text-white-50">
                                                <i className="fa-brands fa-cc-paypal"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6 " style={{ fontWeight: "600" }}>
                                    Copyright © {date}
                                    <a href="#" className="m-1 text-primary">
                                        Education Mandal.
                                    </a>
                                    Designed By
                                    <a
                                        href="https://mstsglobal.com/"
                                        className="m-1 text-primary"
                                        target="_blank" rel="noreferrer"
                                    >
                                        Mindstorm Techsolutions
                                    </a>
                                    All rights reserved.
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

            </footer >
            <div
                className={`progress-wrap ${isActive ? "active-progress" : ""}`}
                onClick={scrollToTop}
            >
                <svg
                    className="progress-circle svg-content"
                    width="100%"
                    height="100%"
                    viewBox="-1 -1 102 102"
                >
                    <path
                        ref={progressPathRef}
                        d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                    />
                </svg>
            </div>
            <a href="https://wa.me/919730063010" style={{ curser: "pointer" }} id="WhatsappIcon" onClick={upArrowHandler}>
                <img src="./assets/images/whatsapp.gif" alt="" />
            </a>
        </>
    );
};

export default Footer;
