import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import url from "../../../../constants/url";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import MapboxAutocomplete from "react-mapbox-autocomplete";
import SubmitLoader from "../../../common/SubmitLoader"


const AddBranch = () => {
    var localdata = localStorage.getItem("common-user-info");
    var jsondata = JSON.parse(localdata);
    var token_authorization = jsondata?.auth_token;

    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const [name, setName] = useState("");
    const [contact, setContact] = useState("");
    const [email, setEmail] = useState("");
    const [stateName, setStateName] = useState({});
    const [cityName, setCityName] = useState({});
    const [state, setState] = useState([]);
    const [selectedState, setSelectedState] = useState("");
    const [city, setCity] = useState([]);
    const [contact_person_name, setContact_person_name] = useState("");
    const [address, setAddress] = useState("");
    const [pin, setPin] = useState("");
    const [location, setLocation] = useState("");
    const [year_of_startup, setYear_of_startup] = useState("");
    const [image, setImage] = useState({});
    const [imageBase64, setImageBase64] = useState("");
    const [about, setAbout] = useState("");
    const [value, setValue] = useState();
    const [longitutde, setLongitute] = useState("");
    const [latitude, setLatitude] = useState("");
    const [isError, setIsError] = useState(false);
    const [isLegnth, setIsLegnth] = useState(0);
    const current = new Date();
    let current_month = current.getMonth() + 1;
    if (current_month.length > 1) {
        current_month = current_month;
    } else {
        current_month = "0" + current_month;
    }
    const date = `${current.getFullYear()}-${current_month}-${current.getDate()}`;
    const [experienceValidation, setExperienceValidation] = useState({
        name: "",
        address: "",
        location: "",
        state: "",
        city: "",
        contact: "",
        email: "",
        contact_person_name: "",
        year_of_startup: "",
        image: "",
        about: "",
    });
    const mapAccess = {
        mapboxApiAccessToken:
            "pk.eyJ1Ijoic2FuY2hpdHNoYXJtYSIsImEiOiJjbGZocmRoMm0yMTJmM3FwZ21nZ2NqdG91In0.Ngsso-JXR08GApV2N1rmUw",
    };

    const handleFileInputChange = async (e) => {
        let { file } = e.target.files[0];

        file = e.target.files[0];

        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function (e) {
            var image = new Image();
            image.src = e.target.result;
            image.onload = function () {
                var height = this.height;
                var width = this.width;
                if (height > 200 || width > 368) {
                    setImage({})
                    setImageBase64("");
                    alert(
                        "Image size: width = 368px and height = 200px"
                    );
                    setExperienceValidation({ ...experienceValidation, 'image': 'Image size: width = 368px and height = 200px' })
                } else if (height < 200 || width < 368) {
                    setImage({})
                    setImageBase64("");
                    alert(
                        "Image size: width = 368px and height = 200px"
                    );
                    setExperienceValidation({ ...experienceValidation, 'image': 'Image size: width = 368px and height = 200px' })
                } else {
                    getBase64(file)
                        .then((result) => {
                            file["base64"] = "true";
                            setImageBase64(result);
                            setImage(file);
                        })
                        .catch((err) => { });
                }

            };
        };
    };
    const getBase64 = (file) => {
        return new Promise((resolve) => {
            let fileInfo;
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    function _suggestionSelect(result, lat, long, text) {
        setLocation(result);
        setLatitude(lat);
        setLongitute(long);
    }

    const onValueChange = (e) => {
        let splitD = e.split("-");
        let value = `${splitD[2]}-${splitD[1]}-${splitD[0]}`;
        setYear_of_startup(value);
    };

    const handleChange = (e) => {
        e.preventDefault();
        let value = e.target.value;
        if (e.target.name === "about") {
            if (value.length > 500) {
                setIsError(true);
            } else {
                setIsError(false);
            }
            setIsLegnth(value.length);
        }
        setAbout(value);
    };
    const addBranches = (e) => {
        e.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", token_authorization);

        var formdata = new FormData();
        formdata.append("name", name);
        formdata.append("address", address);
        formdata.append("location", location);
        formdata.append("latitude", latitude);
        formdata.append("longitude", longitutde);
        formdata.append("state", stateName.value);
        formdata.append("city", cityName.value);
        formdata.append("pincode", pin);
        formdata.append("contact", value);
        formdata.append("email", email);
        formdata.append("contact_person_name", contact_person_name);
        formdata.append("year_of_startup", year_of_startup);
        formdata.append("image", image);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
        };

        const errors = {};
        const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        if (name === undefined || name === "") {
            errors.name = "Required";
        }
        if (address === undefined || address === "") {
            errors.address = "Required";
        }
        if (location === undefined || location === "") {
            errors.location = "Required";
        }
        if (stateName.value === undefined || stateName.value === '') {
            errors.state = 'Required'
        }
        if (cityName.value === undefined || cityName.value === '') {
            errors.city = 'Required'
        }
        if (value === undefined || value === "") {
            errors.contact = "Required";
        } else {
            if (value.length !== 13) {
                errors.contact = "Please enter valid contact";
            }
        }
        if (email === undefined || email === "") {
            errors.email = "Required";
        } else if (!email.match(isValidEmail)) {
            errors.email = "Invalid email";
        }
        if (contact_person_name === undefined || contact_person_name === "") {
            errors.contact_person_name = "Required";
        }
        if (image && image.base64 === undefined || image.base64.length == 0) {
            errors.image = "Required";
        }
        if (year_of_startup === undefined || year_of_startup === "") {
            errors.year_of_startup = "Required";
        } else {
            if (year_of_startup.length > 4 || year_of_startup.length < 4) {
                errors.year_of_startup =
                    "Please enter valid 4 digits Year of Incorporation";
            }
        }
        if (pin === undefined || pin == "") {
            errors.pin = "Required";
        } else {
            if (pin.length > 6 || pin.length < 6) {
                errors.pin = "Please enter valid 6 digits pincode";
            }
        }
        setExperienceValidation(errors);
        if (Object.keys(errors).length == 0) {
            setLoader(true);
            fetch(`${url.apiUrl}institute-branch/add-branch`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === "success") {
                        setLoader(false);
                        toast.success("Branch Added Successfully", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setTimeout(() => {
                            history.push("/institute-dashboard/branch");
                        }, 5000);
                    } else {
                        setLoader(false);
                        setExperienceValidation(result?.data);
                    }
                })
                .catch((error) => console.log("error", error));
        }
    };

    useEffect(() => {
        StateListApi();
    }, []);

    const StateListApi = async (e) => {
        var requestOptions = {
            method: "GET",
        };

        fetch(`${url.apiUrl}state/101`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    const arr = result.data.map((v) => {
                        return { value: v.id, label: v.name };
                    });
                    setState(arr);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const CityListApi = async (id) => {
        var requestOptions = {
            method: "GET",
        };

        fetch(`${url.apiUrl}city/${id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    const arr = result.data.map((v) => {
                        return { value: v.id, label: v.name };
                    });
                    setCity(arr);

                }
            })
            .catch((error) => console.log("error", error));
    };

    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard/branch">Branch's List</Link>
                    </li>
                    <li className="breadcrumb-item active">Add Branch</li>
                </ol>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Add Branch </h4>
                        </div>
                        <div className="card-body">
                            <form onSubmit={addBranches} encType="multipart/form-data">
                                <div className="row">
                                    <input
                                        type="hidden"
                                        name="_token"
                                        value="<?php echo csrf_token() ?>"
                                    />
                                    <div className="col-lg-4 col-md-6 form-group">
                                        <label>
                                            Branch Name <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            aria-describedby="name"
                                            onChange={(e) => setName(e.target.value)}
                                            name="name"
                                            placeholder="Enter branch name"
                                        />
                                        <p className="bg-error">{experienceValidation?.name}</p>
                                    </div>

                                    <div className="col-lg-4 col-md-6 form-group">
                                        <label>
                                            Address <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="classwise"
                                            onChange={(e) => setAddress(e.target.value)}
                                            name="address"
                                            placeholder="Enter address"
                                        />
                                        <p className="bg-error">
                                            {experienceValidation?.address}
                                        </p>
                                    </div>
                                    <div className="col-lg-4 col-md-6 form-group">
                                        <label>
                                            Location <span className="text-danger">*</span>
                                        </label>
                                        <MapboxAutocomplete
                                            publicKey={mapAccess.mapboxApiAccessToken}
                                            inputclassName="form-control "
                                            onSuggestionSelect={_suggestionSelect}
                                            country="IN"
                                            query={location}
                                            resetSearch={false}
                                            placeholder="Search Location..."
                                        />
                                        <p className="bg-error">
                                            {experienceValidation?.location}
                                        </p>
                                    </div>
                                    <div className="col-lg-4 col-md-6 form-group">
                                        <label>
                                            State <span className="text-danger">*</span>
                                        </label>
                                        <Select
                                            name="state"
                                            onChange={(e) => {
                                                setStateName(e);
                                                CityListApi(e.value);
                                            }}
                                            options={state}
                                        />
                                        <p className="bg-error">
                                            {experienceValidation?.state}
                                        </p>
                                    </div>
                                    <div className="col-lg-4 col-md-6 form-group">
                                        <label>
                                            City <span className="text-danger">*</span>
                                        </label>
                                        <Select
                                            onChange={(e) => {
                                                setCityName(e);
                                            }}
                                            options={city}
                                        />
                                        <p className="bg-error">
                                            {experienceValidation?.city}
                                        </p>
                                    </div>
                                    <div className="col-lg-4 col-md-6 form-group">
                                        <label>
                                            Pincode <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="pin"
                                            id="pinecode"
                                            className="form-control"
                                            onChange={(e) => {
                                                setPin(e.target.value);
                                            }}
                                            placeholder="Enter pincode"
                                        />
                                        <p className="bg-error">{experienceValidation?.pin}</p>
                                    </div>
                                    <div className="col-lg-4 col-md-6 form-group">
                                        <label>
                                            Contact Person Name <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="classwise"
                                            onChange={(e) => setContact_person_name(e.target.value)}
                                            name="contact_person_name"
                                            placeholder="Enter contact person name"
                                        />
                                        <p className="bg-error">
                                            {experienceValidation?.contact_person_name}
                                        </p>
                                    </div>
                                    <div className="col-lg-4 col-md-6 form-group">
                                        <label>
                                            Contact No. <span className="text-danger">*</span>
                                        </label>
                                        <PhoneInputWithCountrySelect
                                            name="contact"
                                            value={value}
                                            min={10}
                                            max={10}
                                            onChange={setValue}
                                            defaultCountry="IN"
                                            className="form-control"
                                            placeholder="Enter contact no"
                                        />
                                        <p className="bg-error">
                                            {experienceValidation?.contact}
                                        </p>
                                    </div>
                                    <div className="col-lg-4 col-md-6 form-group">
                                        <label>
                                            Email ID <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="classwise"
                                            onChange={(e) => setEmail(e.target.value)}
                                            name="email"
                                            placeholder="Enter email id"
                                        />
                                        <p className="bg-error">{experienceValidation?.email}</p>
                                    </div>
                                    <div className="col-lg-4 col-md-6 form-group">
                                        <label>
                                            Year of Incorporation <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="classwise"
                                            placeholder="Enter year of incorporation"
                                            onChange={(e) => setYear_of_startup(e.target.value)}
                                            max={date}
                                            name="year_of_startup"
                                        />
                                        <p className="bg-error">
                                            {experienceValidation?.year_of_startup}
                                        </p>
                                    </div>
                                    <div className="col-lg-4 col-md-6 form-group">
                                        <label>
                                            Profile Picture <span className="text-danger"> *</span>
                                        </label>
                                        <input
                                            type='file'
                                            className='form-control'
                                            id='image'
                                            name='image'
                                            onChange={(e) => handleFileInputChange(e)}
                                        />
                                        <p className="bg-error"><span className="text-danger">Note: </span> {experienceValidation?.image ? experienceValidation?.image : "Image size: width = 368px and height = 200px"}</p>
                                    </div>
                                    {imageBase64 && (

                                        <div className="col-md-2 mt-5">
                                            <img
                                                className='img-thumbnail'
                                                src={imageBase64}
                                                height='200px'
                                                width='368px'
                                                alt='Branch Image'
                                            />
                                        </div>
                                    )}
                                    <div className="col-md-12 text-end mt-5">
                                        {loader ? (
                                            <SubmitLoader />
                                        ) : (
                                            <>
                                                <button type="submit" className="btn btn-primary me-2">
                                                    Submit
                                                </button>
                                                <Link
                                                    to="/institute-dashboard/branch"
                                                    className="btn btn-danger "
                                                >
                                                    Cancel
                                                </Link>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
};

export default AddBranch;
