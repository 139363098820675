import React from 'react';
import { Link } from 'react-router-dom';
import TeacherAddform from './TeacherAddForm';

const AddTeacher = () => {
    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/institute-dashboard">Dashboard</Link></li>
                    <li className="breadcrumb-item"><Link to="/institute-dashboard/teacher">Teacher's List</Link></li>
                    <li className="breadcrumb-item active">Add Teacher</li>
                </ol>
            </div>
            <TeacherAddform />
        </>
    );
};

export default AddTeacher;