import React, { useState } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import url from "../../constants/url";
import Swal from "sweetalert2";
import SubmitLoader from "../common/SubmitLoader"

function ForgotPassword() {
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState({
        type: "",
        email: "",
    });
    const options = [
        {
            label: "Institute",
            value: "institute",
        },
        {
            label: "Student",
            value: "student",
        },
        {
            label: "Teacher",
            value: "teacher",
        },
    ];

    const ValidateSchema = Yup.object().shape({
        type: Yup.object().required("Required"),
        email: Yup.string().required("Required")

    });

    const onSubmit = async (values, { resetForm }) => {
        const config = {
            headers: {
                content: "multipart/form-data",
            },
        };
        const formData = new FormData();

        formData.append("email", values.email);
        formData.append("type", values.type.value);
        formData.append("redirect_url", window.location.origin + '/reset-password');

        setLoading(true);
        try {
            const response = await axios.post(
                url.apiUrl + `forget-password`,
                formData,
                config
            );
            setLoading(false);
            Swal.fire({
                title: 'Success',
                text: response.data?.message,
                icon: response.data?.status,
            });
            resetForm();
        } catch (err) {
            throw err;
        }
    };
    return (
        <>
            <ToastContainer />
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-xl-12 p-0 background-image-login">
                        <div className="login-card">
                            <div className="theme-form login-form">
                                <div className="logo-section">
                                    <Link to="/">
                                        <img src="/assets/images/em.png" />
                                    </Link>
                                </div>
                                <hr></hr>
                                <div className="mb-6">
                                    <h5 className="fs-25">Forgot Password</h5>
                                </div>
                                <div className="customer-page">
                                    <div className="wrapper box-shadow-0">
                                        <Formik
                                            initialValues={value}
                                            onSubmit={onSubmit}
                                            validationSchema={ValidateSchema}
                                            enableReinitialize={true}

                                        >
                                            {(props) => (
                                                <Form
                                                    className="row"
                                                    action="#"
                                                    onSubmit={props.handleSubmit}>
                                                    <div className="col-lg-12 form-group">
                                                        <label>
                                                            User Type <span className="text-danger">*</span>
                                                        </label>
                                                        <Select
                                                            options={options}
                                                            name="type"
                                                            className="select_box form-control form-select"
                                                            onChange={(e) => {
                                                                props.setFieldValue("type", e);
                                                            }}
                                                            value={props.values.type}

                                                        >
                                                        </Select>
                                                        {props.touched.type &&
                                                            props.errors.type ? (
                                                            <div className="bg-error">
                                                                {props.errors.type}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-lg-12 form-group">
                                                        <label htmlFor="login-email">
                                                            Email <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            name="email"
                                                            onChange={props.handleChange}
                                                            type="email"
                                                            value={props.values.email}
                                                            placeholder="Please enter email id"
                                                        />
                                                        {props.touched.email && props.errors.email ? (
                                                            <div className="bg-error">
                                                                {props.errors.email}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-lg-12 form-group text-end">
                                                        {loading ? (
                                                            <SubmitLoader />
                                                        ) : (
                                                            <button
                                                                className="btn btn-primary mt-2 w-100"
                                                                type="submit"
                                                            >Submit
                                                            </button>
                                                        )}
                                                    </div>
                                                    <p className="mb-0 text-end">
                                                        Already have an account?
                                                        <Link to={"/login"} className="text-primary ms-1">
                                                            Sign In
                                                        </Link>
                                                    </p>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ForgotPassword;
