import React, { useState } from "react";
import LearningManagementForm from "./LearningManagementForm";

const CareerGuidance = () => {
    const [active, setActive] = useState(false);
    return (
        <>
            <section className="sptb bg-white">
                <div className="container">
                    <div className="section-title">
                        <h2>Career Guidance</h2>
                        <div className="ms-auto">
                            <button
                                className="btn btn-primary"
                                onClick={() => setActive(true)}
                            >
                                View More <i className="fe fe-arrow-right" />
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <p>
                                We understand that choosing a career can be a challenging
                                decision, and that's why we offer career guidance services to
                                our users. Our experts will help you understand your skills and
                                interests, and provide you with tailored recommendations for
                                careers that are best suited for you. Whether you're just
                                starting out or looking for a change, our career guidance
                                services can help you make informed decisions about your future.
                            </p>
                            <div className="row match-height ">
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-body text-center">
                                            <div className="feature">
                                                <div className="fa-stack fea-icon bg-primary mb-3">
                                                    <i className="fa fa-list-check text-white" />
                                                </div>
                                                <h3>Assessment</h3>
                                                <p>
                                                    Career guidance typically begins with an assessment
                                                    of an individual's interests, skills, values,
                                                    personality, and other factors that impact career
                                                    choice.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-body text-center">
                                            <div className="feature">
                                                <div className="fa-stack fea-icon bg-success mb-3">
                                                    <i className="fa-solid fa-line-chart text-white" />
                                                </div>
                                                <h3>
                                                    Decision-making Support
                                                </h3>
                                                <p>
                                                    It helps individuals make informed decisions about
                                                    their careers by providing guidance on job search
                                                    strategies, resume building, and interview
                                                    preparation.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-body text-center">
                                            <div className="feature">
                                                <div className="fa-stack fea-icon bg-secondary mb-3">
                                                    <i className="fa fa-fill-drip text-white"></i>
                                                </div>
                                                <h3>
                                                    Skill Development
                                                </h3>
                                                <p>
                                                    It can help individuals identify their strengths and
                                                    weaknesses and develop the skills needed to succeed in
                                                    their chosen careers.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center match-height">
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-body text-center">
                                            <div className="feature">
                                                <div className="fa-stack fea-icon bg-primary mb-3">
                                                    <i className="fa fa-handshake text-white" />
                                                </div>
                                                <h3>
                                                    Networking Opportunities
                                                </h3>
                                                <p>
                                                    It can connect individuals with alumni, industry
                                                    professionals, and other resources that can provide
                                                    valuable support.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-body text-center">
                                            <div className="feature">
                                                <div className="fa-stack fea-icon bg-success mb-3">
                                                    <i className="fa fa-line-chart text-white" />
                                                </div>
                                                <h3>
                                                    Insight into job market trends
                                                </h3>
                                                <p>
                                                    Current and future job market trends, such as emerging
                                                    industries and in-demand skills, which can help them
                                                    make better decisions about their career paths.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {active && (
                <LearningManagementForm
                    type="career_guidance"
                    heading="Career Guidance"
                    active={active}
                    setActive={setActive}
                />
            )}

        </>
    );
};

export default CareerGuidance;
