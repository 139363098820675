import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import url from "../../../../../../constants/url";
import ReactPaginate from "react-paginate";
import SlotList from "./SlotList";

const SlotView = () => {
    const { class_id } = useParams();
    const [filters, setFilters] = useState({
        limit: 10,
        offset: 0,
        class_id: class_id,
        status: '',
        teacher_id: '',
        subject: '',
        start_date: '',
        end_date: '',
    })
    const [loading, setLoading] = useState(false)
    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage.auth_token;
    const [timeSchedule, setTimeSchedule] = useState([]);
    const [count, setCount] = useState();
    const [teachers, setTeachers] = useState([]);
    const [subjects, setSubjects] = useState([]);

    var myHeaders = new Headers();
    myHeaders.append("AUTHTOKEN", auth_token);
    myHeaders.append("Content-Type", "application/json");

    const getClassSchedule = async (activity) => {
        setLoading(true)
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(activity)
        };

        fetch(url.apiUrl + "institute/class-slot-schedule", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setTimeSchedule(result.data);
                    setCount(result.count);
                }
            })
            .catch((error) => console.log("error", error))
            .finally(() => {
                setLoading(false)
            })
    }


    const getTeacherAndSubjectData = async () => {
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
                class_id: class_id,
                required_fields: 'subject,teacher',
            })
        };

        fetch(url.apiUrl + "institute/get-class-data", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setTeachers(result.data.teachers);
                    setSubjects(result.data.subjects.map((value) => {
                        return { label: value, value: value }
                    }));
                }
            })
            .catch((error) => console.log("error", error))
            .finally(() => {
                setLoading(false)
            })
    }
    const handlePageClick = async (lectures) => {
        let currentPage = lectures.selected * filters.limit;
        setFilters({ ...filters, offset: currentPage })
        var activity = {
            ...filters,
            limit: filters.limit,
            offset: currentPage,
            class_id: class_id,
        }
        await getClassSchedule(activity);
    };
    const handlePagination = async (value) => {
        setFilters({ ...filters, offset: 0, limit: value })
        var activity = {
            ...filters,
            limit: value,
            offset: 0,
        }
        await getClassSchedule(activity);
    };

    const handleSearch = async () => {
        var activity = {
            ...filters,
            limit: filters.limit,
            offset: filters.offset,
            class_id: class_id,
            status: filters.status,
            teacher_id: filters.teacher_id,
            subject: filters.subject,
            start_date: filters.start_date,
            end_date: filters.end_date,
        }
        await getClassSchedule(activity);
    };


    useEffect(() => {
        getClassSchedule({
            limit: filters.limit,
            offset: filters.offset,
            class_id: class_id,
        })
        getTeacherAndSubjectData()
    }, [])



    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">View Classroom Subject Time Slot Teacher List</li>
                </ol>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <SlotList class_id={class_id} filters={filters} setFilters={setFilters} timeSchedule={timeSchedule} loading={loading} teachers={teachers} subjects={subjects} handleSearch={handleSearch} />
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-2">
                                    <select className="form-select" onChange={(e) => {
                                        handlePagination(e.target.value)
                                    }}>
                                        <option value="10">10</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value={count}>all</option>
                                    </select>
                                </div>
                                <div className="col-md-10">
                                    {timeSchedule.length > 0 && (
                                        <ReactPaginate
                                            previousLabel={"Previous"}
                                            nextLabel={"Next"}
                                            breakLabel={"..."}
                                            pageCount={Math.ceil(count / filters.limit)}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SlotView;
