import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import Select from "react-select";
import url from "../../../../constants/url";
import ReactPaginate from "react-paginate";
import ApproveCareerPopup from "./ApproveCareerPopup";
import { toast, ToastContainer } from "react-toastify";
import { replaceWord } from "../../../../utils/utils";

export default function CareerList({ is_main_course }) {
    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage.auth_token;
    const [LiveBranch, setLiveBranch] = useState([]);
    const [values, setValues] = useState({});
    const [filters, setFilters] = useState({
        limit: 10,
        offset: 0,
        branch: '',
        class_type: '',
        status: '',
        query: '',
        list_type: 'lecture',
    })
    var activity = {
        is_main_course: is_main_course,
        list_type: filters.list_type,
        limit: filters.limit,
        offset: filters.offset,
        branch_id: filters.branch.value || undefined,
        class_type: filters.class_type || undefined,
        status: filters.status || undefined,
        query: filters.query || undefined
    };
    const [careers, setCareers] = useState([])
    const [loading, setLoading] = useState(false)
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState();
    const [lectureId, setLectureId] = useState(0);
    const [aboutForm, setAboutForm] = useState(false);
    var myHeaders = new Headers();
    myHeaders.append("AUTHTOKEN", auth_token);
    myHeaders.append("Content-Type", "application/json");

    const getchBranch = async () => {
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
        };

        fetch(url.apiUrl + "institute-branch/get-branches", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    const arr =
                        result.data &&
                        result.data.map((v) => {
                            return { value: v.id, label: v.text }
                        });
                    setLiveBranch(arr);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const getLectures = async (activity) => {
        setLoading(true)
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(activity)
        };

        fetch(url.apiUrl + "institute/career-list", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setCareers(result.data);
                    setCount(result.count);
                }
            })
            .catch((error) => console.log("error", error))
            .finally(() => {
                setLoading(false)
            })
    };

    const handleStatus = (id, status) => {
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({ career_id: id, status: (status === "active") ? "inactive" : "active" })
        };

        fetch(url.apiUrl + "institute/career-status", requestOptions)
            .then((result) => result.json())
            .then((result) => {
                if (result.status === "success") {
                    toast.success(result.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    getLectures(activity)
                } else {
                    toast.error(result.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch((error) => console.log("error", error))
            .finally(() => {
                setLoading(false)
            })
    }


    const aboutHandler = (id) => {
        setLectureId(id);
        setAboutForm(true);
    };

    const handlePageClick = async (careers) => {
        let currentPage = careers.selected * filters.limit;
        setOffset(currentPage);
        setFilters({
            ...filters,
            limit: filters.limit,
            offset: currentPage,
        })
        await getLectures({
            is_main_course: is_main_course,
            list_type: filters.list_type,
            limit: filters.limit,
            offset: currentPage,
            branch_id: filters.branch.value || undefined,
            class_type: filters.class_type || undefined,
            status: filters.status || undefined,
            query: filters.query || undefined
        });
    };

    useEffect(() => {
        getchBranch();
    }, []);

    useEffect(() => {
        getLectures({
            is_main_course: is_main_course,
            list_type: filters.list_type,
            limit: filters.limit,
            offset: filters.offset,
            branch_id: filters.branch.value || undefined,
            class_type: filters.class_type || undefined,
            status: filters.status || undefined,
            query: filters.query || undefined
        })
    }, [])

    const searchQuery = (e) => {
        getLectures({
            is_main_course: is_main_course,
            list_type: filters.list_type,
            limit: filters.limit,
            offset: filters.offset,
            branch_id: filters.branch.value || undefined,
            class_type: filters.class_type || undefined,
            status: filters.status || undefined,
            query: e.target.value || undefined
        });
    }

    const onSubmit = async (values) => {
        getLectures({
            is_main_course: is_main_course,
            list_type: filters.list_type,
            limit: filters.limit,
            offset: filters.offset,
            branch_id: values.branch.value || undefined,
            class_type: values.class_type || undefined,
            status: values.status || undefined,
            query: filters.query || undefined
        });
    };

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Careers Filter</h4>
                        </div>
                        <div className="card-body">
                            <Formik
                                initialValues={values}
                                onSubmit={onSubmit}
                                enableReinitialize={true}
                            >
                                {(props) => (
                                    <form onSubmit={props.handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-3 form-group">
                                                <label>Branch</label>
                                                <Select
                                                    onChange={(value) => {
                                                        props.setFieldValue("branch", value)
                                                        setFilters({ ...filters, branch: value })
                                                    }
                                                    }
                                                    options={LiveBranch}
                                                    value={props.values?.branch}
                                                    name="branch"
                                                />
                                            </div>
                                            <div className="col-md-3 form-group">
                                                <label>Class Type </label>
                                                <select
                                                    className="form-select"
                                                    value={props.values.class_type}
                                                    onChange={(e) => {
                                                        props.setFieldValue(
                                                            "class_type",
                                                            e.target.value
                                                        );
                                                        setFilters({ ...filters, class_type: e.target.value })
                                                    }}
                                                >
                                                    <option value="">Select class type</option>
                                                    <option value="school">School (Till 12th Standard)</option>
                                                    <option value="university">University</option>
                                                    <option value="preparation">Entrance Exam / Professional Courses</option>
                                                    <option value="tuition">Tuition</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-3 form-group">
                                                <label>Career Status</label>
                                                <select className="form-select"
                                                    name="status" onChange={e => {
                                                        props.setFieldValue(
                                                            "status",
                                                            e.target.value
                                                        );
                                                        setFilters({ ...filters, status: e.target.value })
                                                    }}>
                                                    <option>Select status</option>
                                                    <option value="active">Active</option>
                                                    <option value="inactive">Inactive</option>
                                                    <option value="pending">Pending</option>
                                                    <option value="submitted">Submitted</option>
                                                    <option value="decline">Decline</option>
                                                </select>
                                            </div>
                                            <div className="open-button col-md-3">
                                                <button type="submit" className="btn btn-primary"><i className="fe fe-search"></i> Search</button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Careers List</h4>
                        </div>
                        <div className="card-body">
                            <div
                                id="basic-1_wrapper"
                                className="dataTables_wrapper no-footer"
                            >
                                <div id="basic-1_filter" className="dataTables_filter">
                                    <label>
                                        Search:
                                        <input
                                            type="search"
                                            placeholder="Search..."
                                            onChange={(e) => searchQuery(e)}
                                        />
                                    </label>
                                </div>
                                <table className="table table-bordered table-hover dataTable table-responsive-xl">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Sr. No.</th>
                                            <th>Job Details</th>
                                            <th>Branch Details</th>
                                            <th className="text-center text-nowrap">Class Type</th>
                                            <th className="text-center text-nowrap">Experience</th>
                                            <th className="text-center text-nowrap">Salary</th>
                                            <th className="text-center text-nowrap">Job Type</th>
                                            <th className="text-center">Applicants</th>
                                            <th className="text-center">Status</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading ? (
                                            <tr>
                                                <td className="text-center" colSpan={10}>Loading..</td>
                                            </tr>
                                        ) : (careers.length === 0 ? (
                                            <tr>
                                                <td className="text-center" colSpan={10}>No Data Available !</td>
                                            </tr>
                                        )
                                            : (
                                                careers.map((item, index) => (
                                                    <tr key={item.id}>
                                                        <th className="text-center text-nowrap">{index + offset + 1}</th>
                                                        <td>
                                                            <div className="media">
                                                                <img src={item?.thumb_image} alt={item?.title} className="me-3 rounded-circle" />
                                                                <div className="media-body">
                                                                    <Link to={`/institute-dashboard/career-detail/${is_main_course}/${item?.id}`}
                                                                        className="font-weight-semibold"
                                                                    >
                                                                        {item?.title}
                                                                    </Link>
                                                                    <p className="text-muted mb-0 text-capitalize">
                                                                        {item?.employee_type}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="media">
                                                                <img src={item?.institute_branch?.image} alt={item?.institute_branch?.name} className="me-3 rounded-circle" />
                                                                <div className="media-body">
                                                                    <Link to={"/institute-dashboard/branch-detail/" + item?.branch_id}
                                                                        className="font-weight-semibold"
                                                                    >
                                                                        {item?.institute_branch?.name}
                                                                    </Link>
                                                                    <p className="text-muted mb-0">
                                                                        {item?.institute_branch?.email}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="text-center">
                                                            {replaceWord(item?.class_type)}
                                                        </td>
                                                        <td className="text-center">
                                                            {item?.experience}
                                                        </td>

                                                        {item?.is_disclosed_salary === 1 ? (
                                                            <td className="text-center">
                                                                ₹ {item?.salary}
                                                            </td>
                                                        ) : (
                                                            <td className="text-center">
                                                                Not Disclosed
                                                            </td>
                                                        )}
                                                        <td className="text-center text-capitalize">
                                                            {item?.job_type}
                                                        </td>
                                                        <td className="text-center">
                                                            <Link
                                                                className="btn btn-primary btn-sm"
                                                                to={`/institute-dashboard/applicants-list/${(is_main_course === 1) ? item.id : item.career_id}`}
                                                            >
                                                                {item?.applications_count}
                                                            </Link>
                                                        </td>
                                                        <td className="text-center text-capitalize align-items-center">
                                                            <div className="d-flex">
                                                                {(item?.status === "active" || item?.status === 'inactive') && (
                                                                    <label className="custom-control form-checkbox mb-0">
                                                                        <input type="checkbox"
                                                                            className="custom-control-input"
                                                                            name="checkbox"
                                                                            value="checkbox"
                                                                            checked={item?.status === "active"}
                                                                            onChange={() => {
                                                                                handleStatus(item.id, item?.status)
                                                                            }}
                                                                        /> <span className="custom-control-label"></span>
                                                                    </label>
                                                                )}
                                                                {(item?.status === "decline" || item?.status === 'pending') &&
                                                                    item?.is_submit == 1
                                                                    ? "Submitted"
                                                                    : item?.status} {item?.status === "decline" && `(${item?.reason?.reason})`}
                                                            </div>
                                                        </td>
                                                        <td className="text-center text-nowrap">
                                                            {item?.status === "pending" &&
                                                                item?.is_submit == 1 ? (
                                                                <Link
                                                                    disabled
                                                                    className="btn btn-outline-grey btn-sm me-2"
                                                                    onClick={() => {
                                                                        alert(
                                                                            "You can't edit this job, since your job is pending for approval from our administrator. Thank you for your patience."
                                                                        );
                                                                    }}
                                                                >
                                                                    <i className="fa fa-pen-to-square fs-16"></i>
                                                                </Link>
                                                            ) : (
                                                                (item?.status === "decline" || item?.status === 'pending') &&
                                                                    item?.is_submit == 1 ? (
                                                                    <Link
                                                                        disabled
                                                                        className="btn btn-primary btn-sm me-2"
                                                                        onClick={() => {
                                                                            alert(
                                                                                "You can't update the course until it gets approved !"
                                                                            );
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-pen-to-square fs-16"></i>
                                                                    </Link>
                                                                ) : (
                                                                    <Link title="Edit Career" className="btn btn-primary btn-sm me-2" to={`/institute-dashboard/career-edit/${is_main_course}/${item?.id}`}>
                                                                        <i className="fa fa-pen-to-square fs-16"></i>
                                                                    </Link>
                                                                )
                                                            )}
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary btn-sm"
                                                                onClick={() => aboutHandler(item.id)} title="Change Status"
                                                            >
                                                                <i className="fa fa-eye fs-16"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            )
                                        )}
                                    </tbody>
                                </table>
                                {careers.length > 0 && (
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        pageCount={Math.ceil(count / filters.limit)}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ApproveCareerPopup getLectures={getLectures} activity={activity} setAboutForm={setAboutForm} aboutForm={aboutForm} lectureId={lectureId} setLectureId={setLectureId} isMain={is_main_course} />
        </>
    )
}