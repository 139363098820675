import React, { useState, useEffect } from "react";
import url from "../../../../constants/url";
let rating = [1, 2, 3, 4, 5];

const TotalCards = () => {
    const [data, setData] = useState([]);
    const [review, setReview] = useState([]);

    const CardDetails = async () => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        fetch(`${url.apiUrl}institute/dashboard`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setData(result?.data);
                    setReview(result?.review);
                }
            })
            .catch((error) => console.log("error", error));
    };

    useEffect(() => {
        CardDetails();
    }, []);
    return (
        <>
            <div className="row match-height">
                <div className="col-xl-4 col-lg-6 col-md-12">
                    <div className="card">
                        <div className="card-body text-center">
                            <div className="item-card7-img">
                                <img src={data?.image} className="cover-image" alt={data?.name} />
                            </div>
                            <div className="mt-3">
                                <h5 className=" mb-3 fs-16">
                                    {data?.name}
                                </h5>
                                <h6 className="text-muted mb-3">
                                    {data?.email}
                                </h6>
                                <h6 className="text-muted mb-3">
                                    {data?.contact}
                                </h6>
                                <h6 className="text-muted mb-3">
                                    <i className="fa-solid fa-location-dot me-2"></i>
                                    {data?.address}, {data?.cities?.name}, {data?.states?.name} - {data?.pincode}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-8 col-lg-6 col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Institute Details</h4>
                        </div>
                        <div className="card-body">
                            <ul className="list-unstyled me-0">
                                <li className="d-flex justify-content-between">
                                    <div className="font-weight-semibold">Rating :</div>
                                    <div className="d-inline-flex mb-2">
                                        <div className="br-widget">
                                            {rating.map((el, i) => (
                                                <i
                                                    data-rating-value={el}
                                                    data-rating-text={el}
                                                    className={
                                                        el <= data?.average_review
                                                            ? "br-selected"
                                                            : ""
                                                    }
                                                    key={i}
                                                />
                                            ))}
                                        </div>
                                        <span>({data?.review_count} Reviews)</span>
                                    </div>
                                </li>
                                <li className="d-flex justify-content-between">
                                    <div className="font-weight-semibold">Year of Incorporation :</div>
                                    <div className="detail-amt mb-2">
                                        {data?.year_of_incap ? data?.year_of_incap : "---"}
                                    </div>
                                </li>
                                <li className="d-flex justify-content-between">
                                    <div className="me-3 font-weight-semibold">About </div>
                                    <div className="education-mandal" dangerouslySetInnerHTML={{ __html: data?.about }}></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* <div className="col-xl-4 col-lg-6 col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Institute Location </h4>
                        </div>
                        <div className="card-body">
                        <iframe width='100%' height='100%' src="https://api.mapbox.com/styles/v1/feki256363/clu9evlru00ks01p631j32hmj.html?title=true&access_token=pk.eyJ1IjoiZmVraTI1NjM2MyIsImEiOiJjbGZreHI5Z3owY21pNDNzMHhuendtcHRoIn0.2JNpUvxAE5cupW5VYFlc-w&zoomwheel=false#17.13/28.425336/77.068494" title="Ezdat" ></iframe>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
};

export default TotalCards;
