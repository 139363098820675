import React from 'react'

const WhyChooseUs = () => {
    return (
        <>
            <div className="why-choose-us-area mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <div
                                className="Choose-section-title text-animation col-12"
                                style={{ perspective: "400px",paddingBottom: "52px" }}
                            >
                                <div>
                                    <h1>Why Choose Us</h1>
                                    <h2>Education Mandal Provide</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {[
                            {
                                id: "01",
                                title: "One-stop-shop",
                                description:
                                    "With Education Mandal, you don't have to spend hours searching for educational resources. Everything you need is in one place.",
                            },
                            {
                                id: "02",
                                title: "Institutions Database",
                                description:
                                    "A comprehensive database of educational institutions with detailed information on programs, courses, syllabus, fees, and more.",
                            },
                            {
                                id: "03",
                                title: "Access to top-notch Teachers",
                                description:
                                    "Our platform provides students with access to top-notch teachers who are experts in their respective fields.",
                            },
                            {
                                id: "04",
                                title: "Institution and Teacher Ratings",
                                description:
                                    "Students can rate institutions and teachers, making it easier for other students to find quality resources.",
                            },
                            {
                                id: "05",
                                title: "Student Community",
                                description:
                                    "A platform for students to connect, share information, and support each other as they pursue their education.",
                            },
                        ].map((item, index) => (
                            <div
                                key={index}
                                className={`col-lg-6 col-md-6 ${index % 2 === 0 ? "" : "margin-t"
                                    }`} data-aos="zoom-in-up"
                            >
                                <div className="single-choose-us-box" >
                                    <div className="choose-us-title">
                                        <div className="choose-us-numbar">
                                            <h4>{item.id}</h4>
                                        </div>
                                        <div className="choose-us-text">
                                            <h4>{item.title}</h4>
                                        </div>
                                    </div>
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="choose-us-shape">
                        <img src="./assets/images/chooseUs/choose-us-shape.png" style={{height:"250px"}} alt="" />
                    </div>
                    <div className="choose-us-shape-2">
                        <img src="https://wp.ditsolution.net/toptech-preview/assets/images/section-images/choose-us-shap-2.png" alt="" />
                    </div>
                </div>
            </div>

        </>
    )
}

export default WhyChooseUs
