import React, { useEffect, useState, useRef } from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import MapboxAutocomplete from "react-mapbox-autocomplete";
import url from "../../../constants/url";
import { useHistory } from "react-router-dom";
import axios from "axios";

const Header = () => {
    const ref = useRef();
    const [item, totalItem] = useState(0);
    const [error, setError] = useState("");
    const [searchValues, setSearchValues] = useState({ query: "" });
    const [teacherSuggestions, setTeacherSuggestions] = useState([]);
    const [courseSuggestions, setCourseSuggestions] = useState([]);
    const [instituteSuggestions, setInstituteSuggestions] = useState([]); // New state
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [isSuggestionsVisible, setSuggestionsVisible] = useState(false);
    const [openList, setOpenList] = useState(false);
    const [openList1, setOpenList1] = useState(false);
    const [UserProfile, setUserProfile] = useState(false);
    const [UserProfileType, setUserProfileType] = useState("");
    const history = useHistory();
    const inputRef = useRef(null);




    useEffect(() => {
        fetchTeacherSuggestions();
        fetchCourseSuggestions();
        fetchInstituteSuggestions();
    }, []);

    useEffect(() => {
        GetUserDetail();
        GetCartData();
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);

    const fetchTeacherSuggestions = async () => {
        try {
            const response = await fetch(
                "https://educationmandal.com/em-admin/api/get-teacher/133/0"
            );
            const data = await response.json();
            if (data.status === "success") {
                const teachers = data.data.map((item) => ({
                    title: item.name,
                    type: "Teacher",
                }));
                setTeacherSuggestions(teachers);
            }
        } catch (error) {
            console.error("Error fetching teacher suggestions:", error);
        }
    };

    const fetchCourseSuggestions = async () => {
        try {
            const response = await fetch(
                "https://educationmandal.com/em-admin/api/get-class-course/550/0"
            );
            const data = await response.json();
            if (data.status === "success") {
                const courses = data.data.map((item) => ({
                    title: item.title,
                    type: "Course",
                }));
                setCourseSuggestions(courses);
            }
        } catch (error) {
            console.error("Error fetching course suggestions:", error);
        }
    };

    const fetchInstituteSuggestions = async () => { // New function
        try {
            const response = await fetch(
                "https://educationmandal.com/em-admin/api/institute-list/1000/0"
            );
            const data = await response.json();
            if (data.status === "success") {
                const institutes = data.data.map((item) => ({
                    title: item.name,
                    type: "Institute",
                }));
                setInstituteSuggestions(institutes);
            }
        } catch (error) {
            console.error("Error fetching institute suggestions:", error);
        }
    };

    const GetCartData = async () => {
        const token = JSON.parse(
            localStorage.getItem("common-user-info")
        )?.auth_token;

        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", token);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        fetch(`${url.apiUrl}get-cart`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    totalItem(result.data.length);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const GetUserDetail = async () => {
        var data = localStorage.getItem("common-user-info");
        if (data !== null) {
            const userData = JSON.parse(data);
            if (userData.id !== 0 && userData.id !== null) {
                setUserProfile(true);
                if (userData.type === "institute") {
                    setUserProfileType("/institute-dashboard");
                } else if (userData.type === "teacher") {
                    setUserProfileType("/teacher-dashboard");
                } else {
                    setUserProfileType("/student-dashboard");
                }
            }
        } else {
            setUserProfile(false);
        }
    };

    const isSticky = (e) => {
        const header = document.querySelector(".sticky-wrapperr");
        const scrollTop = window.scrollY;
        scrollTop >= 250
            ? header?.classList.add("is-sticky")
            : header?.classList.remove("is-sticky");
    };

    const [isActive, setActive] = useState("false");
    const ToggleClass = () => {
        setActive(!isActive);
    };


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!ref?.current?.contains(event.target)) {
                var elems = document.getElementsByClassName("react-mapbox-ac-menu");
                for (var i = 0; i < elems.length; i += 1) {
                    elems[i].style.display = "none";
                }
            }
        };
        document.addEventListener("click", handleClickOutside, true);
    }, [ref]);



    const handleSuggestionClick = (suggestion) => {
        setSearchValues({ query: suggestion.title });
        setSuggestionsVisible(false);
        performSearch(suggestion.title);
    };

    const performSearch = (query) => {
        if (!query) {
            setError("Required");
        } else {
            const searchQuery = query.split(" ").join("-");
            history.push(`/search?query=${searchQuery}`);
        }
    };

    const handleSearchInput = (e) => {
        const query = e.target.value;
        setSearchValues({ query });

        if (query.length > 0) {
            const combinedSuggestions = [
                ...teacherSuggestions,
                ...courseSuggestions,
                ...instituteSuggestions,
            ];
            const filtered = combinedSuggestions.filter((item) =>
                item.title.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredSuggestions(filtered);
            setSuggestionsVisible(true);
        } else {
            setSuggestionsVisible(false);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            performSearch(searchValues.query);
        }
    };




    const reff = useRef(null);
    const [searchInput, setSearchInput] = useState("");
    const [locationdropdownshow, setlocationdropdownshow] = useState(false)
    const [locationSuggestions, setLocationSuggestions] = useState([]);
    const [isLocationSuggestionsVisible, setIsLocationSuggestionsVisible] = useState(false);
    const locationInputRef = useRef(null);


    const handleLocationInputChange = async (e) => {
        const query = e.target.value;
        setSearchInput(query);

        if (query.length > 2) {
            try {
                const apiKey = "a1f19c4176d84a1eab7304777a936e85"; // Your OpenCage API key
                const response = await axios.get(
                    `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(query)}&key=${apiKey}&limit=5`
                );
                const suggestions = response.data.results.map(result => ({
                    name: result.formatted,
                    lat: result.geometry.lat,
                    lng: result.geometry.lng
                }));
                setLocationSuggestions(suggestions);
                setIsLocationSuggestionsVisible(true);
            } catch (error) {
                console.error("Error fetching location suggestions:", error);
            }
        } else {
            setIsLocationSuggestionsVisible(false);
        }
    };

    const handleLocationSelect = (location) => {
        setSearchInput(location.name);
        sessionStorage.setItem("text", location.name);
        sessionStorage.setItem("longitude", location.lng);
        sessionStorage.setItem("latitude", location.lat);
        setIsLocationSuggestionsVisible(false);
    };

    // Function to handle location detection
    const detectLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(async (position) => {
                const { latitude, longitude } = position.coords;
                const apiKey = "a1f19c4176d84a1eab7304777a936e85"; // Your OpenCage API key
                try {
                    const response = await axios.get(
                        `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}`
                    );
                    const location = response?.data?.results[0]?.formatted;
                    setSearchInput(location || "Location not found");
                    sessionStorage.setItem("text", location);
                    sessionStorage.setItem("longitude", longitude);
                    sessionStorage.setItem("latitude", latitude);
                } catch (error) {
                    console.error("Error fetching location:", error);
                }
            }, (error) => {
                console.error("Geolocation error:", error);
            });
        } else {
            console.error("Geolocation not supported by this browser.");
        }
    };

    return (
        <>
            <div className="header-main bg-primary shadow-sm">
                {/* <div className="top-bar">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="top-bar-start">
                                    <ul className="socials">
                                        <li>
                                            <a className="social-icon" target="_blank" href="https://www.facebook.com/profile.php?id=100090317913078&mibextid=LQQJ4d">
                                                <i className="fe fe-facebook" />
                                            </a>
                                        </li>
                                        <li>
                                            <a className="social-icon" target="_blank" href="https://wa.me/+919730063010">
                                                <i className="fa-brands fa-whatsapp"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="social-icon" target="_blank" href="https://www.linkedin.com/company/education-mandal/">
                                                <i className="fe fe-linkedin" />
                                            </a>
                                        </li>
                                        <li>
                                            <a className="social-icon" target="_blank" href="https://instagram.com/education_mandal?igshid=YmMyMTA2M2Y=">
                                                <i className="fe fe-instagram" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="top-bar-end">
                                    <ul className="custom">
                                        <li>
                                            <Link to="/cart" >
                                                <i className="fe fe-shopping-cart me-1" />
                                                {item > 0 && <span className="badge badge-danger">{item}</span>}
                                            </Link>
                                        </li>
                                        {UserProfile === false &&
                                            <li className="dropdown">
                                                <Link to="/registration"

                                                    onClick={DashboardHandler1}
                                                    title="Registration"
                                                >
                                                    <i
                                                        className="fe fe-user me-1"
                                                        style={{ paddingRight: 3.5 }}
                                                    />
                                                    <span>
                                                        Registration

                                                    </span>
                                                </Link>
                                            </li>
                                        }
                                        {UserProfile === false &&
                                            <li>
                                                <Link to="/login" target="_blank" rel="noreferrer" title="Login">
                                                    <i className="fe fe-log-in me-1" /> <span>Login</span>
                                                </Link>
                                            </li>
                                        }
                                        {UserProfile === true &&
                                            <li className="dropdown">
                                                <>
                                                    <Link
                                                        to="#"
                                                        className={
                                                            openList ? " show" : ""
                                                        }
                                                        aria-expanded={openList ? "true" : "false"}
                                                        onClick={DashboardHandler}
                                                    >
                                                        <i className="fe fe-home me-1" />
                                                        <span>
                                                            My Dashboard
                                                            <i className="fe fe-chevron-down ms-1" />
                                                        </span>
                                                    </Link>
                                                    {openList ? (
                                                        <div
                                                            className={
                                                                openList
                                                                    ? "dropdown-menu dropdown-menu-end dropdown-menu-arrow show"
                                                                    : "dropdown-menu dropdown-menu-end dropdown-menu-arrow"
                                                            }
                                                        >
                                                            <Link
                                                                to={`${UserProfileType}`}
                                                                className="dropdown-item"
                                                            >
                                                                <i className="dropdown-icon fa-solid fa-user " />
                                                                My Profile
                                                            </Link>
                                                            <Link
                                                                to="#"
                                                                className="dropdown-item border-top"
                                                                onClick={handleLogout}
                                                            > <i className="dropdown-icon fa-solid fa-power-off"></i>  Log Out
                                                            </Link>
                                                        </div>
                                                    ) : null}
                                                </>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* for Header Space */}
                <div id="sticky-wrapperr" className="sticky-wrapperr bg-white">
                    <div className="sticky">
                        <div className="horizontal-header clearfix">
                            <div className="container">
                                <Link
                                    to="#"
                                    className="animated-arrow"
                                    onClick={ToggleClass}
                                >
                                    <span />
                                </Link>
                                <div
                                    className={
                                        isActive
                                            ? "dropdown-menu dropdown-menu-end dropdown-menu-arrow "
                                            : "dropdown-menu dropdown-menu-end dropdown-menu-arrow education-menu-open"
                                    }
                                >
                                    <div className="d-flex mt-auto menu-logo-education align-items-center">
                                        <span className="smllogo">
                                            <img
                                                src="/assets/images/EduM_White_New.png"
                                                alt="img"
                                            />
                                        </span>
                                        <Link
                                            to="#"
                                            className="animated-arrow1"
                                            onClick={ToggleClass}
                                        >
                                            <i className="fe fe-x" aria-hidden="true"></i>
                                        </Link>
                                    </div>
                                    <ul className="horizontalMenu-list">
                                        <li aria-haspopup="true">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li aria-haspopup="true">
                                            <Link to="/about-us">About us </Link>
                                        </li>
                                        <li aria-haspopup="true">
                                            <Link to="/explore">Explore</Link>
                                        </li>
                                        <li aria-haspopup="true">
                                            <Link to="/services">Services</Link>
                                        </li>
                                        <li aria-haspopup="true">
                                            <Link to="/blog">Blog</Link>
                                        </li>
                                        <li aria-haspopup="true">
                                            <Link to="/contact-us">Contact us</Link>
                                        </li>
                                        <li aria-haspopup="true">
                                            <Link to="/work-with-us">Work with US</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <Link to="/">
                                    <span className="smllogo">
                                        <img src="/assets/images/EduM_Black.png" alt="img" />
                                    </span>
                                </Link>
                                <a href="tel:+91 97300 63010" className="callus-btn" >
                                    <i className="fe fe-phone" aria-hidden="true" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <Navbar />
                </div>
            </div>
            <div
                className="cover-image bg-background-1 bg-background-dark "
                data-bs-image-src="assets/images/banners/slide-3.webp"
                style={{
                    height: "462px"
                }}
            >
                <video
                    autoPlay
                    muted
                    loop
                    playsInline
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        zIndex: -1,
                    }}
                >
                    <source src="https://videos.pexels.com/video-files/3977640/3977640-hd_1920_1080_25fps.mp4" type="video/mp4" />
                    Aapka browser video support nahi karta.
                </video>

                <section className="sptb-2 header-banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-10 col-lg-12 col-md-12 d-block mx-auto">
                                <h1 className="font-weight-semibold fs-50 text-white text-center  animate__animated animate__fadeInDown ">
                                    Education Mandal
                                </h1>
                                <p className="text-white text-center mb-7 bannerDes" >One Stop Solution For All Of Your Educational Needs</p>
                                <div className="search-background bg-transparent">
                                    <div className="row align-item-center">
                                        <div className="col-xl-8 col-lg-8 col-md-8 d-block mx-auto">
                                            <div className="row g-0">
                                                <div className="form-group col-lg-3 col-md-12 select2-lg mb-2" ref={reff}>
                                                    <div className="position-relative">
                                                        <i className="fas fa-map-marker-alt location-icon search-icon absolute left-3 top-1/2 transform -translate-y-1/2 z-10"></i>
                                                        <input
                                                            type="text"
                                                            ref={locationInputRef}
                                                            value={searchInput}
                                                            onChange={handleLocationInputChange}
                                                            onFocus={() => setIsLocationSuggestionsVisible(true)}
                                                            placeholder="Search Location..."
                                                            className="form-control pl-5 locationsearch"
                                                            style={{ borderRadius: '5px 0 0 5px' }}
                                                        />
                                                    </div>

                                                    {isLocationSuggestionsVisible && (
                                                        <ul
                                                            className="suggestions-list list-group mb-3 absolute w-full z-20 bg-white"
                                                            style={{
                                                                maxHeight: '90px',
                                                                overflowY: 'auto',
                                                                // border: '2px solid #ccc',
                                                                borderRadius: '5px',
                                                            }}
                                                        >
                                                            {searchInput.length === 0 ? (
                                                                <li
                                                                    className="suggestion-item list-group-item bg-white p-3 text-primary text-center"
                                                                    style={{ border: '2px solid #ddd', fontWeight: '700', cursor: 'pointer' }}
                                                                    onClick={detectLocation}
                                                                >
                                                                    <i className="fas fa-crosshairs me-0"></i> Detect Current Location
                                                                </li>
                                                            ) : (
                                                                locationSuggestions.map((location, index) => (
                                                                    <li
                                                                        key={index}
                                                                        className="suggestion-item list-group-item bg-white p-3"
                                                                        style={{ border: '2px solid #ddd', cursor: 'pointer' }}
                                                                        onClick={() => handleLocationSelect(location)}
                                                                    >
                                                                        {location.name || "Location Not Found"}
                                                                    </li>
                                                                ))
                                                            )}
                                                        </ul>
                                                    )}
                                                </div>

                                                <div className="form-group col-lg-9 col-md-12 position-relative">
                                                    <div className="input-with-icon">
                                                        <i className="fe fe-search search-icon absolute left-3 top-1/2 transform -translate-y-1/2 z-10"></i>
                                                        <input
                                                            type="text"
                                                            className="form-control input-xl pl-10 homeSearch"
                                                            placeholder="Search Courses, Institutes, Teachers, etc."
                                                            data-min-length="1"
                                                            list="teacherSuggestions"
                                                            name="courses"
                                                            ref={inputRef}
                                                            value={searchValues.query}
                                                            onChange={handleSearchInput}
                                                            onFocus={() => setSuggestionsVisible(true)}
                                                            onKeyDown={handleKeyDown}
                                                            style={{ borderRadius: '0px 5px 5px 0px', paddingLeft: "40px" }}
                                                        />
                                                    </div>
                                                    {isSuggestionsVisible && filteredSuggestions.length > 0 && (
                                                        <ul className="suggestions-list list-group mb-3 absolute w-full z-20 bg-white"
                                                            style={{
                                                                maxHeight: '200px',
                                                                overflowY: 'auto',
                                                                border: '2px solid #ccc',
                                                                borderRadius: '5px'
                                                            }}
                                                        >
                                                            {filteredSuggestions.map((item, index) => (
                                                                <li
                                                                    key={index}
                                                                    className="suggestion-item list-group-item bg-white p-3"
                                                                    style={{ border: '2px solid #ddd', fontWeight: "700", cursor: "pointer" }}
                                                                    onClick={() => handleSuggestionClick(item)}
                                                                >
                                                                    {item?.title} <span className="suggestion-type">({item.type})</span>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                    {error && <p className="bg-error ml-5">{error}</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    );
};

export default Header;
