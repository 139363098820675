import React from 'react'

const HomeContact = () => {
    return (
        <>
            <section>
                <div className='container'>
                    <div className='row'>
                        <div className="col-md-12 col-lg-6">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="fs-25 text-center mt-4">
                                        Let us help you !!!
                                    </h5>
                                    <p className="mb-6 text-center">
                                        At Education Mandal, we are dedicated to provide the
                                        best possible educational experience for all our users.
                                        If you have any questions, feedback, or suggestions,
                                        please don't hesitate to get in touch with us.
                                    </p>
                                    <div className="contacts row match-height">
                                        <div className="col-lg-6 col-md-12">
                                            <div className="card">
                                                <div className="support-service bg-primary">
                                                    <i className="fa fa-phone" />
                                                    <h5>
                                                        <a href="tel:+919730063010">+91 9730063010</a>
                                                    </h5>
                                                    <p>Call us</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <div className="card">
                                                <div className="support-service bg-primary">
                                                    <i className="fa-solid fa-envelope"></i>
                                                    <h5>
                                                        <a href="mailto:info@educationmandal.com">
                                                            info@educationmandal.com
                                                        </a>
                                                    </h5>
                                                    <p>Write to us</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <div className="card">
                                                <div className="support-service bg-primary">
                                                    <i className="fa fa-map-marker" />
                                                    <h5>
                                                        D 401, Bachraj Landmark, Near club one, Global
                                                        City, Virar, Maharashtra - 401303
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <div className="card">
                                                <div className="support-service bg-primary">
                                                    <i className="fa-solid fa-clock"></i>
                                                    <h5>Mon - Sat (10 AM - 06 PM)</h5>
                                                    <p>Working Hours!</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6">
                            <div className="card">
                                <div className="card-body">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3761.625643784146!2d72.80483657498797!3d19.471700681816042!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7a9dcb67d3bbf%3A0x5b1297ca2f06e085!2sBachraj%20Landmark!5e0!3m2!1sen!2sin!4v1732267979487!5m2!1sen!2sin"
                                        className="responsive-iframe"
                                        allowFullScreen=""
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeContact
