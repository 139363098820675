import React from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import CareerList from "./CareerList";

const Careers = () => {

    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Careers</li>
                </ol>
                <Link
                    className="btn btn-primary"
                    to="/institute-dashboard/career-add"
                >
                    <i className="fa fa-plus"></i> Add Career
                </Link>
            </div>

            <CareerList is_main_course={1} />

            <ToastContainer />
        </>
    );
};
export default Careers;