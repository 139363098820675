import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ComingUpClasses = () => {

    var settings = {
        dots: false,       
        infinite: true,    
        speed: 4000,      
        slidesToShow: 4,   
        slidesToScroll: 1, 
        autoplay: true,    
        autoplaySpeed: 0,  
        cssEase: "linear", 
        arrows: false,   
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
        ],
    };



    return (
        <>
            <section className="sptb">
                <div className="container">
                    <div className="section-title">
                        <h2>Our Business Partners</h2>
                        <div className="ms-auto">
                            <Link className="btn btn-primary" to="/classroom-course">
                                View More <i className="fe fe-arrow-right" />
                            </Link>
                        </div>
                    </div>
                    <div className="match-height">
                        <Slider {...settings}>
                            <div className="col-md-12" >
                                <Link>
                                    <div className="">
                                        <div className="">
                                            <div className=" position-relative">
                                                <div className="overflow-hidden PartnerSlide">
                                                    <img
                                                        src="https://www.zarla.com/images/nike-logo-2400x2400-20220504.png?crop=1:1,smart&width=150&dpr=2"
                                                        onError={(e) => { e.target.src = "/assets/images/default.webp"; }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-12" >
                                <Link>
                                    <div className="">
                                        <div className="">
                                            <div className=" position-relative">
                                                <div className="overflow-hidden PartnerSlide">
                                                    <img
                                                        src="https://www.zarla.com/images/disney-logo-2400x2400-20220513-2.png?crop=1:1,smart&width=150&dpr=2"
                                                        onError={(e) => { e.target.src = "/assets/images/default.webp"; }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-12" >
                                <Link>
                                    <div className="">
                                        <div className="">
                                            <div className=" position-relative">
                                                <div className="overflow-hidden PartnerSlide">
                                                    <img
                                                        src="https://upload.wikimedia.org/wikipedia/en/thumb/3/37/Jumpman_logo.svg/1200px-Jumpman_logo.svg.png"
                                                        onError={(e) => { e.target.src = "/assets/images/default.webp"; }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-12" >
                                <Link>
                                    <div className="">
                                        <div className="">
                                            <div className=" position-relative">
                                                <div className="overflow-hidden PartnerSlide">
                                                    <img
                                                        src="https://www.svgrepo.com/show/303123/bmw-logo.svg"
                                                        onError={(e) => { e.target.src = "/assets/images/default.webp"; }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-12" >
                                <Link>
                                    <div className="">
                                        <div className="">
                                            <div className=" position-relative">
                                                <div className="overflow-hidden PartnerSlide">
                                                    <img
                                                        src="https://brandlogos.net/wp-content/uploads/2014/12/starbucks_coffee_company-logo_brandlogos.net_9jqys.png"
                                                        onError={(e) => { e.target.src = "/assets/images/default.webp"; }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-12" >
                                <Link>
                                    <div className="">
                                        <div className="">
                                            <div className=" position-relative">
                                                <div className="overflow-hidden PartnerSlide">
                                                    <img
                                                        src="https://www.zarla.com/images/nike-logo-2400x2400-20220504.png?crop=1:1,smart&width=150&dpr=2"
                                                        onError={(e) => { e.target.src = "/assets/images/default.webp"; }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            

                        </Slider>
                    </div>
                </div >
            </section >
        </>
    );
};

export default ComingUpClasses;
