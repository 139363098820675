import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PaymentFilter from "./PaymentFilter";
import PaymentTable from "./PaymentTable";
import url from "../../../../constants/url";
import axios from "axios";

const Payment = () => {
    const [courseType, setCourseType] = useState("");
    const [branch, setBranch] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [teacherId, setTeacherId] = useState("");
    const [offset, setOffset] = useState(0);
    const [search, setSearch] = useState({
        start: 0,
        perPage: 10,
        searchTxt: "",
        searchField: "",
    });
    const [count, setCount] = useState();
    const [courseData, setCourseData] = useState([]);

    useEffect(() => {
        getAllCourseData();
    }, [search]);
    const apiCall = async (activity) => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        const config = {
            headers: {
                content: "application/json",
                AUTHTOKEN: auth_token,
            },
        };
        try {
            const response = await axios.post(
                `${url.apiUrl}institute/course-sale`,
                activity,
                config
            );
            if (response) {
                setCourseData(response.data?.data);
                setCount(response.data?.count / search.perPage);
            }
        } catch (err) {
            throw err;
        }
    };
    const getAllCourseData = async () => {
        let activity = {
            course_type: courseType,
            limit: search.perPage,
            branch_id: branch,
            teacher_id: teacherId,
            offset: search.start,
            query: search.searchTxt,
            start_date: startDate,
            end_date: endDate,
        };

        await apiCall(activity);
    };
    const handlePageClick = async (data) => {
        let currentPage = data.selected * search.perPage;
        setOffset(currentPage);
        let activity = {
            course_type: courseType,
            branch_id: branch,
            teacher_id: teacherId,
            limit: search.perPage,
            offset: currentPage,
            query: search.searchTxt,
            start_date: startDate,
            end_date: endDate,
        };
        await apiCall(activity);
    };
    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Payment History</li>
                </ol>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <PaymentFilter
                        setTeacherId={setTeacherId}
                        teacherId={teacherId}
                        branch={branch}
                        setBranch={setBranch}
                        setEndDate={setEndDate}
                        setStartDate={setStartDate}
                        courseType={courseType}
                        setCourseType={setCourseType}
                        getAllCourseData={getAllCourseData}
                    />
                    <PaymentTable
                        data={courseData && courseData}
                        handlePageClick={handlePageClick}
                        count={count}
                        search={search}
                        setSearch={setSearch}
                        offset={offset}
                        getAllCourseData={getAllCourseData}
                    />
                </div>
            </div>
        </>
    );
};

export default Payment;
