import React from "react";
import moment from "moment";
import { Link } from 'react-router-dom'
import ReactPaginate from "react-paginate";

const Payment = (props) => {
    return (
        <>
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header ">
                        <h4 className="card-title">Payment History</h4>
                    </div>
                    <div className="card-body ">
                        <div id="basic-1_wrapper" className="dataTables_wrapper no-footer">
                            <div id="basic-1_filter" className="dataTables_filter">
                                <label>
                                    Search:
                                    <input
                                        type="search"
                                        placeholder="Search..."
                                        onChange={(e) => {
                                            props.setSearch({
                                                ...props.search,
                                                searchTxt: e.target.value,
                                            });
                                        }}
                                    />
                                </label>
                            </div>
                            <table className="table table-bordered table-hover dataTable table-responsive-xl">
                                <thead>
                                    <tr>
                                        <th className="text-center">Sr. No.</th>
                                        <th>Student Details</th>
                                        <th>Branch Details</th>
                                        <th>Course</th>
                                        <th className="text-center">Price</th>
                                        <th className="text-center">Purchase Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props?.data?.length > 0 ? (
                                        props?.data.map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <th className="text-center"> {i + props?.offset + 1}</th>
                                                    <td>
                                                        <div className="media">
                                                            <img
                                                                src={item?.student?.image}
                                                                alt={item?.student?.name}
                                                                className="me-3 rounded-circle"
                                                            />
                                                            <div className="media-body">
                                                                <Link to={`/teacher-dashboard/student-profile/${item?.student?.id}`}>
                                                                    {item?.student?.name}
                                                                </Link>
                                                                <p className="mb-0 text-muted">
                                                                    {item?.student?.email}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {item?.institute_branch ? (
                                                            <div className="media">
                                                                <img
                                                                    src={item?.institute_branch?.image}
                                                                    alt={item?.institute_branch?.name}
                                                                    className="me-3 rounded-circle"
                                                                />
                                                                <div className="media-body">
                                                                    <Link to={`/teacher-dashboard/branch-detail/${item?.institute_branch?.id}`}>

                                                                        {item?.institute_branch?.name}
                                                                    </Link>
                                                                    <p className="mb-0 text-muted">
                                                                        {item?.institute_branch?.email}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        ) : (<p className="text-center">---</p>)}
                                                    </td>
                                                    <td> {item.course_type === "online_course" ? (
                                                        <div className="media">
                                                            <img
                                                                src={item?.online_course?.teaser_thumb_image}
                                                                alt={item?.online_course?.title}
                                                                className="me-3 rounded-circle"
                                                            />
                                                            <div className="media-body">
                                                                <Link to={`/teacher-dashboard/video-course-detail/1/${item?.online_course?.id}`}>
                                                                    {item?.online_course?.title}
                                                                </Link>

                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="media">
                                                            <img
                                                                src={item?.class_course?.thumbnail}
                                                                alt={item?.class_course?.title}
                                                                className="me-3 rounded-circle"
                                                            />
                                                            <div className="media-body">
                                                                <Link to={`/teacher-dashboard/class-detail/${item?.class_course?.id}`}>
                                                                    {item?.class_course?.title}
                                                                </Link>
                                                            </div>
                                                        </div>

                                                    )}</td>
                                                    <td className="text-center">
                                                        {item?.is_enroll == 1 ? (<>
                                                            <p className="mb-0">Free </p>
                                                        </>) : (
                                                            <>
                                                                <p className="mb-0">MRP:  ₹{item?.mrp} </p>
                                                                <p className="mb-0">Price:  ₹{item?.total_price}</p>
                                                            </>
                                                        )}

                                                    </td>
                                                    <td className="text-center">{moment(item?.payment_date).format("DD-MM-YYYY hh:mm A")}</td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td className="text-center" colSpan={7}>No Data Available !</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            {props?.data?.length > 0 && (
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    breakLabel={"..."}
                                    pageCount={Math.ceil(props.count)}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={props.handlePageClick}
                                    containerClassName={"pagination"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default Payment;
