import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { addToCart } from "../../../../apis_web";
import Moment from "react-moment";
import url from "../../../../constants/url";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import utils from "../../../../utils/utils";
import VideoPopup from "../../../common/VideoPopup";

const VideoCourseLeft = (props) => {
    const history = useHistory();
    const [UIValues, setUIValues] = useState({});
    const [active, setActive] = useState(false);
    const [data, setCourseDataDetails] = useState(props.data);
    const [ShowButton, setShowButton] = useState(false);
    const [formValues, setFormValues] = useState([]);
    const [CheckLoginn, setCheckLoginn] = useState(false);
    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage?.auth_token;
    const user_type = local_storage?.type;
    const course_id = localStorage.getItem("courseid");
    let now = new Date().setHours(0, 0, 0, 0);

    useEffect(() => {
        if (props.data && props.data) setCourseDataDetails(props.data);
    }, [props.data]);

    const addCart = (id, type) => {
        if (localStorage.getItem("common-user-info")) {
            const auth_info = localStorage.getItem("common-user-info");
            const auth_json = JSON.parse(auth_info);
            const auth_token = auth_json.auth_token;
            var myHeaders = new Headers();
            myHeaders.append("AUTHTOKEN", auth_token);

            var formdata = new FormData();
            formdata.append("course_id", id);
            formdata.append("course_type", "online_course");

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
            };

            fetch(`${url.apiUrl}add-cart`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === "success") {
                        toast.success("Item Added In To Cart Successfully");
                        setTimeout(() => {
                            history.push("/cart");
                        }, 5000);
                    } else if (result.status === "auth") {
                        toast.error("Please sign in as a student.");
                    } else {
                        toast.error(result.message);
                    }
                })
                .catch((error) => console.log("error", error));
        } else {
            localStorage.setItem("course", id);
            history.push("/login");
        }
    };

    const EnrollNowApi = (id, type) => {
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);
        var formdata = new FormData();
        formdata.append("course_id", id);
        formdata.append("course_type", type);
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
        };
        if (auth_token) {
            fetch(`${url.apiUrl}student/enroll-course`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === "success") {
                        toast.success(result.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    } else {
                        toast.error(result.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                })
                .catch((error) => console.log("error", error));
        } else {
            history.push("/login");
        }
    };
    const handleLogin = () => {
        toast.error("Please sign in as a student", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="product-slider">
                        <ul className="list-unstyled video-list-thumbs">
                            <li className="mb-0">
                                <div className="item-card7-img">
                                    <img src={data?.teaser_thumb_image} alt={data?.title} className="cover-image" />
                                    {(
                                        data?.price == "paid" && (
                                            data?.is_discount == 1 && (
                                                (data?.is_limited_offer == 1 && (new Date(data?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(data?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                (data?.is_limited_offer == 0 && new Date(data?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                        )
                                    ) && (
                                            <Link to="#" className="bg-danger top left">
                                                {data?.discount_price}% off
                                            </Link>
                                        )}
                                    <Link to="#" className="bg-danger right top">
                                        Video Course
                                    </Link>
                                    {data?.is_professional === 0 && (
                                        <Link to="#" className="bg-danger right bottom">
                                            {data?.board}
                                        </Link>
                                    )}
                                    <span className="fa fa-play-circle text-white class-icon" onClick={() => { setActive(true); }}></span>
                                </div>
                            </li>
                            {active ? (
                                <VideoPopup
                                    className="videoIntor"
                                    active={active}
                                    setActive={setActive}
                                    data={data}
                                />
                            ) : (
                                ""
                            )}
                        </ul>
                    </div>
                    <div className="mt-4 mb-4 text-center">
                        <div className="mb-2">
                            {data?.price === "paid" && (
                                <span className="font-weight-semibold h2 mb-0">
                                    ₹ {Math.round(data?.payable_price)} {(
                                        data?.price == "paid" && (
                                            data?.is_discount == 1 && (
                                                (data?.is_limited_offer == 1 && (new Date(data?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(data?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                (data?.is_limited_offer == 0 && new Date(data?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                        )
                                    ) && (<del className="fs-14 text-muted">₹ {Math.round(data?.regular_price)} </del>
                                        )}
                                </span>
                            )}
                            {data?.price === "free" && (
                                <span className="font-weight-semibold h2 mb-0">
                                    Free
                                </span>
                            )}
                        </div>
                    </div>
                    {ShowButton === false && (
                        <div>
                            {data?.is_order === 1 ? (
                                <Link to={`/student-dashboard/video-course-detail/${data?.id}`} className="btn btn-block btn-primary">
                                    Already Purchased
                                </Link>
                            ) : (
                                <>
                                    {data?.price === "paid" ? (
                                        <>
                                            {data?.is_cart == 0 ? (
                                                <Link
                                                    to="#"
                                                    onClick={() => addCart(data?.id, data?.type)}
                                                    className="btn btn-block btn-primary mb-3 mb-xl-0"
                                                >Add to Cart <i className="fe fe-shopping-cart mt-1 ms-2 fs-14"></i>
                                                </Link>
                                            ) : (
                                                <Link
                                                    to="/cart"
                                                    className="btn btn-block btn-primary mb-3 mb-xl-0"
                                                >Go To Cart <i className="fe fe-shopping-cart mt-1 ms-2 fs-14"></i>
                                                </Link>
                                            )}

                                        </>
                                    ) : (
                                        <>

                                            {data?.price === "free" && user_type === "student" ? (
                                                <Link
                                                    to="#"
                                                    className="btn btn-block btn-danger mb-3 mb-xl-0"
                                                    onClick={() => {
                                                        EnrollNowApi(data.id, data?.type);
                                                    }}
                                                >
                                                    Enroll Now
                                                    <i className="fe fe-arrow-right mt-1 ms-2 fs-14"></i>
                                                </Link>
                                            ) : (data?.price === "free" && user_type !== "student" ? (
                                                <Link
                                                    to="#"
                                                    className="btn btn-block btn-danger mb-3 mb-xl-0"
                                                    onClick={() => {
                                                        handleLogin()
                                                    }}
                                                >
                                                    Enroll Now
                                                    <i className="fe fe-arrow-right mt-1 ms-2 fs-14"></i>
                                                </Link>
                                            ) : (
                                                <Link
                                                    to="/login"
                                                    className="btn btn-block btn-danger mb-3 mb-xl-0"
                                                >
                                                    Enroll Now
                                                    <i className="fe fe-arrow-right mt-1 ms-2 fs-14"></i>
                                                </Link>
                                            ))}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </div >
                <div className="card-header">
                    <h4 className="card-title">Video Course Info :</h4>
                </div>
                <table className="table table-hover dataTable">
                    <tbody>
                        <tr>
                            <td className="text-nowrap font-weight-semibold">
                                Institute Name
                            </td>
                            <td className="w-0 text-center"> : </td>
                            <td className="text-end">
                                <Link className="text-primary" to={`/institute-detail/${data?.institute?.institute_id}`}>
                                    {data?.institute?.name}
                                </Link>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-nowrap font-weight-semibold">
                                Branch Name
                            </td>
                            <td className="w-0 text-center"> : </td>
                            <td className="text-end">
                                <Link className="text-primary" to={`/branch-detail/${data?.institute_branch?.branch_id}`}>
                                    {data?.institute_branch?.name}
                                </Link>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-weight-semibold">
                                Course Level
                            </td>
                            <td className="w-0 text-center"> : </td>
                            <td className="text-end text-capitalize">
                                {data?.course_level}
                            </td>
                        </tr>
                        {data?.is_professional === 0 && (
                            <>
                                <tr>
                                    <td className="font-weight-semibold">
                                        Board
                                    </td>
                                    <td className="w-0 text-center"> : </td>
                                    <td className="text-end text-capitalize">
                                        {data?.board}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-weight-semibold">
                                        Standard
                                    </td>
                                    <td className="w-0 text-center"> : </td>
                                    <td className="text-end text-capitalize">
                                        {data?.standard}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-weight-semibold">
                                        Medium
                                    </td>
                                    <td className="w-0 text-center"> : </td>
                                    <td className="text-end text-capitalize">
                                        {data?.medium}
                                    </td>
                                </tr>
                            </>
                        )}
                        <tr>
                            <td className="font-weight-semibold">
                                Audio Language
                            </td>
                            <td className="w-0 text-center"> : </td>
                            <td className="text-end text-capitalize">
                                {data?.audio_language}
                            </td>
                        </tr>
                        <tr>
                            <td className="font-weight-semibold">
                                Videos
                            </td>
                            <td className="w-0 text-center"> : </td>
                            <td className="text-end">
                                {data?.lectures_count} Videos
                            </td>
                        </tr>
                        <tr>
                            <td className="font-weight-semibold">
                                Duration
                            </td>
                            <td className="w-0 text-center"> : </td>
                            <td className="text-end">
                                {data?.total_duretion ? utils.toHHMMSS(data?.total_duretion) : 0} Hours
                            </td>
                        </tr>
                        <tr>
                            <td className="font-weight-semibold">
                                Last Update
                            </td>
                            <td className="w-0 text-center"> : </td>
                            <td className="text-end">
                                <Moment format="DD-MM-YYYY hh:mm A">
                                    {data?.updated_at}
                                </Moment>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div >
            <ToastContainer />
        </>
    );
};

export default VideoCourseLeft;
