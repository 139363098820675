import React from "react";

const NoDataAvailable = () => {
    return (
        <div className="col-md-12">
            <div className="card mb-0">
                <div className="card-body">
                    <p className="text-center mb-0">No Data Available !</p>
                </div>
            </div>
        </div>
    )
}

export default NoDataAvailable;