import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Headerinner from "../../Header/Headerinner";
import Curve from '../../Header/Curve';
import Footer from "../../Footer/Footer";
import HomePageService from "../../../../Services/HomePageService";
import ReactPaginate from "react-paginate";
import axios from "axios";
import NoDataAvailable from "../../../common/NoDataAvailable";
let rating = [1, 2, 3, 4, 5];

const Institutes = () => {
    const serve = new HomePageService();
    const [institutesList, setInstitutesList] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [offset, setOffset] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [search, setSearch] = useState({
        start: 0,
        perPage: 12,
        searchTxt: "",
        searchField: "",
    });
    useEffect(() => {
        InstituteListApi();
    }, []);

    const apiCall = async (limit, offset) => {
        const config = {
            headers: {
                content: "multipart/form-data",
            },
        };
        try {
            const response = await axios.get(
                "https://educationmandal.com/em-admin/api/" + `institute-list/${limit}/${offset}`,

                config
            );
            if (response) {
                setInstitutesList(response.data?.data);
                setTotalPages(response.data?.count / 12);
                setTotalCount(response.data?.count);
            }
        } catch (err) {
            throw err;
        }
    };

    const InstituteListApi = async () => {
        await apiCall(search.perPage, search.start);
    };

    const handlePageClick = async (data) => {
        let currentPage = data.selected * search?.perPage;
        setOffset(currentPage);

        await apiCall(search.perPage, currentPage);
    };

    return (
        <>
            <Headerinner />
            <div
                className="cover-image bg-background-1"
                data-bs-image-src="assets/images/banners/banner1.webp"
                style={{
                    background: 'url("assets/images/banners/banner1.webp") center center',
                }}
            >
                <div className="container header-text">
                    <h1>Top Rated Institutes</h1>
                </div>
            </div>
            <Curve />
            <section className="sptb">
                <div className="container">
                    <div className="row">
                        {institutesList?.length > 0 ? (
                            <>
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="mb-0">
                                                Showing {offset + 1} to {offset + institutesList?.length} of {totalCount} entries
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                {institutesList.map((item, i) => (
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12" key={i}>
                                        <div className="card">
                                            <Link to={`/institute-detail/${item?.institute_id}`} >
                                                <div className="card-body ">
                                                    <div className="item-card7-img">
                                                        <img
                                                            src={item?.image}
                                                            alt={item?.name}
                                                            className="cover-image"
                                                        />
                                                    </div>
                                                    <div className="item-card2-desc">
                                                        <div className="d-inline-flex mb-2">
                                                            <div className="br-widget">
                                                                {rating.map((el, i) => (
                                                                    <i
                                                                        data-rating-value={el}
                                                                        data-rating-text={el}
                                                                        className={
                                                                            el <= item?.average_review
                                                                                ? "br-selected"
                                                                                : ""
                                                                        }
                                                                        key={i}
                                                                    />
                                                                ))}
                                                            </div>
                                                            <span>({item?.review_count} Reviews)</span>
                                                        </div>
                                                        <h4 className="mb-2 leading-tight">
                                                            {item?.name}
                                                        </h4>
                                                        <p className="leading-tight3 education-mandal" dangerouslySetInnerHTML={{ __html: item?.about }}></p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <>
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="mb-0">
                                                Showing {0} to {offset + institutesList?.length} of {totalCount} entries
                                            </h5>
                                        </div>
                                    </div>
                                </div>

                                <NoDataAvailable />
                            </>
                        )}
                    </div>
                    {institutesList.length > 0 && (
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={Math.ceil(totalPages)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={
                                "pagination"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                        />
                    )}
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Institutes;
