import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Formik,FieldArray } from "formik";
import SubjectAddForm from "./SubjectAddForm";
import { ToastContainer, toast } from "react-toastify";
import axios from '../../../../../config/axios';
import SubmitLoader from "../../../../common/SubmitLoader";
const SubjectAdd = () => {
    const initialValues = {
        boardSelected: '',
        branchSelected: "",
        classSelected: "",
        lectureSelected: "",
        sectionsSelected: [],
        semesterSelected: [],
        tuitionType: '',
        universitySelected: '',
        subjects: [
            {
                teacher: [],
                subject: "",
            },
        ],
    };

    const history = useHistory()

    const [values, setValues] = useState({ data: [initialValues] });
    const [loader, setLoader] = useState(false);

    const ValidateSchema = Yup.object().shape({
        data: Yup.array().of(Yup.object().shape({
            branchSelected: Yup.object().required('Please select at least one branch'),
            lectureSelected: Yup.string().required('Required'),
            boardSelected: Yup.string().when(['lectureSelected', 'tuitionType'], {
                is: (lectureSelected, tuitionType) => (lectureSelected && lectureSelected === 'school') || (lectureSelected === 'tuition' && tuitionType === 'school'),
                then: Yup.string().required('Required')
            }),
            classSelected: Yup.string().required('Required'),
            sectionsSelected: Yup.array().when(['lectureSelected'], {
                is: (lectureSelected, tuitionType) => ((lectureSelected && lectureSelected === 'school') || (lectureSelected === 'tuition' && tuitionType === 'school')),
                then: Yup.array().required('Required').min(1, 'Please select atleast one section')
            }),
            semesterSelected: Yup.array().when(['lectureSelected', 'tuitionType'], {
                is: (lectureSelected, tuitionType) => (lectureSelected && lectureSelected === 'university') || (lectureSelected === 'tuition' && tuitionType === 'university'),
                then: Yup.array().required('Required').min(1, 'Please select atleast one semester')
            }),
            tuitionType: Yup.string().when('lectureSelected', {
                is: (lectureSelected) => lectureSelected && lectureSelected === 'tuition',
                then: Yup.string().required('Required')
            }),
            universitySelected: Yup.string().when(['lectureSelected', 'tuitionType'], {
                is: (lectureSelected, tuitionType) => (lectureSelected && lectureSelected === 'university') || (lectureSelected === 'tuition' && tuitionType === 'university'),
                then: Yup.string().required('Required')
            }),
            subjects: Yup.array().of(Yup.object().shape({
                teacher: Yup.array().min(1, 'Please select at least one teacher'),
                subject: Yup.string().required('Required').min(2, 'Subject name should be more than 2 letters'),
            }))
        })),
    });

    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage.auth_token;

    const handleSubmit = async (values) => {
        try {
            setLoader(true)
            var items = []
            values?.data?.map(value => {
                items.push({
                    branch_ids: value?.branchSelected.id.toString(),
                    "class_type": value?.lectureSelected,
                    "class_name": value?.classSelected,
                    "board": value?.boardSelected,
                    "section": value?.lectureSelected == "school" ? value?.sectionsSelected?.map(section => section.id).join(',') : "",
                    "university": value?.universitySelected,
                    "semester": value?.semesterSelected?.map(sem => sem.id)?.join(','),
                    "tuition_type": value?.tuitionType,
                    subject: value?.subjects?.map(sub => ({ teacher_ids: sub?.teacher?.map(teacher => teacher.value)?.join(','), name: sub.subject }))
                })
            })
            const data = {
                data: items
            }
            const response = await axios.post('institute/create-class-subject', data, { headers: { AUTHTOKEN: auth_token } })
            if (response.status === 'success') {
                toast.success('Subject Added Successfully')
                setTimeout(() => {
                    history.push('/institute-dashboard/subject')
                }, 5000);
            } else {
                toast.error(response.message)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || error.message)
        } finally {
            setLoader(false)
        }
    }

    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard/subject">Subject</Link>
                    </li>
                    <li className="breadcrumb-item active">Add Subject</li>
                </ol>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Add Subject</h4>
                        </div>
                        <Formik
                            initialValues={values}
                            onSubmit={handleSubmit}
                            enableReinitialize={true}
                            validationSchema={ValidateSchema}
                        >
                            {(props) => (
                                <>
                                    <div className="card-body">
                                        <form onSubmit={props.handleSubmit}>
                                            <FieldArray
                                                name="data"
                                                render={(arrayHelpers) => (
                                                    <div>
                                                        {arrayHelpers?.form?.values?.data?.map(
                                                            (v, index) => (
                                                                <React.Fragment key={index}>
                                                                    <SubjectAddForm data={props.values?.data?.[index]} index={index} props={props} />
                                                                    {arrayHelpers?.form?.values?.data?.length > 1 && (
                                                                        <div className="row">
                                                                            <div className="col-md-3">
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                                    className="btn btn-danger"
                                                                                >
                                                                                    Remove Section
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    <hr />
                                                                </React.Fragment>
                                                            )
                                                        )}

                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary"
                                                                    onClick={() =>
                                                                        arrayHelpers.push(initialValues)
                                                                    }
                                                                >
                                                                    Add More Section
                                                                </button>
                                                                <p>(<span className="text-danger">Note*</span> Add more subject to the branches)</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 text-end">
                                                            {loader ? (
                                                                <SubmitLoader />
                                                            ) : (<>
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-primary me-2"
                                                                >
                                                                    Submit
                                                                </button>
                                                                <Link
                                                                    to="/institute-dashboard/subject"
                                                                    className="btn btn-danger"
                                                                >
                                                                    Cancel
                                                                </Link>
                                                            </>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                        </form>
                                    </div>
                                </>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
};

export default SubjectAdd;
