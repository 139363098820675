import React, { useState, useEffect } from "react";
import axios from "axios";
import NoDataAvailable from "../../../../common/NoDataAvailable";

export default function BlogCategory({ }) {
    const [category, setCategory] = useState([]);
    const BlogCategoriesApi = async () => {
        const config = {
            headers: {
                content: "multipart/form-data",
            },
        };
        try {
            const response = await axios.get(
                "https://educationmandal.com/em-admin/api/" + `get-blog-category`,
                config
            );
            if (response) {
                setCategory(response.data?.data);
            }
        } catch (err) {
            throw err;
        }
    };
    useEffect(() => {
        BlogCategoriesApi();
    }, []);
    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-title">Popular Blogs Categories</h4>
            </div>
            <div className="card-body">
                <ul className="list-group mb-0">
                    {category?.length > 0 ? (
                        category.map((item, i) => (
                            <li
                                className="list-group-item d-flex justify-content-between"
                                key={i}
                            >
                                <a
                                    href={`/blog/${item.id}`}
                                    className="font-weight-semibold"
                                >
                                    <i className="fa fa-code bg-primary-light text-primary" />
                                    {item.name}
                                </a>
                                <span className="badge mb-0 text-muted">
                                    {item.blogs_count} Post
                                    {item.blogs_count > 1 ? "s" : ""}
                                </span>
                            </li>
                        ))

                    ) : (
                        <NoDataAvailable/>
                    )}
                </ul>
            </div>
        </div>
    );
}
