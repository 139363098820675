import Utils from "../utils/utils";
export default class HomePageService {
  institutionList(limit, offset) {
    return Utils.sendApiRequest(
      `institute-list/${limit}/${offset}`,
      "GET",
      true
    )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  subscribe(payload) {
    return Utils.sendApiRequest(`subscriber`, "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
}
