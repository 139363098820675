import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";
import url from "../../../../../constants/url";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import Moment from "react-moment";
import { replaceWord } from "../../../../../utils/utils";
import { ToastContainer, toast } from "react-toastify";
import SubmitLoader from "../../../../common/SubmitLoader";

const SlotEdit = () => {

    const history = useHistory();
    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage.auth_token;

    var myHeaders = new Headers();
    myHeaders.append("AUTHTOKEN", auth_token);
    myHeaders.append("Content-Type", "application/json");

    const { slot_id } = useParams();
    const [values, setValues] = useState({})
    const [isSubmit, setIsSubmit] = useState(false)
    const [teachers, setTeachers] = useState([])
    const [classSlots, setClassSlots] = useState([]);
    const [teacherSlots, setTeacherSlots] = useState([]);

    const getSlotDetail = () => {
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        fetch(`${url.apiUrl}institute/class-slot-detail/${slot_id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setValues({ ...result.data, subject: { value: result.data.subject, label: result.data.subject } });
                    getTeacher(result.data, result.data.subject)
                    getClassSchedule(result.data, result.data.subject)
                    getTeachersBookedSlot(result.data.teacher_id)
                }
            })
            .catch((error) => console.log("error", error));
    }

    const getTeacher = async (result, subject) => {
        var data = {
            branch_ids: result?.class_course?.branch_id,
            class_type: result?.class_course?.class_type,
            required_field: "teacher",
            class_name: result?.class_course?.class_name,
            subject: subject,
        }
        if (result?.class_course?.class_type === "tuition") {
            data.tuition_type = result?.class_course?.tuition_type;
        }

        if (result?.class_course?.class_type === "school" || (result?.class_course?.class_type === "tuition" && result?.class_course?.class_type === "school")) {
            data.board = result?.class_course?.board;
            if (result?.class_course?.class_type === "school") {
                data.section = result?.class_course?.section;
            }
        }
        if (result?.class_course?.class_type === "university" || (result?.class_course?.class_type === "tuition" && result?.class_course?.class_type === "university")) {
            data.university = result?.class_course?.university;
            data.semesters = result?.class_course?.semesters;
        }
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(data),
        };

        fetch(url.apiUrl + "institute/lecture-class-name", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setTeachers(result.data.map((item) => ({ label: item.name, value: item.id })));
                } else {
                    setTeachers([]);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const getClassSchedule = (result, subject) => {

        var data = {
            branch_id: result?.class_course?.branch_id,
            class_type: result?.class_course?.class_type,
            required_field: "teacher",
            class_name: result?.class_course?.class_name,
            subject: subject,
        }
        if (result?.class_course?.class_type === "tuition") {
            data.tuition_type = result?.class_course?.tuition_type;
        }
        if (result?.class_course?.class_type === "school" || (result?.class_course?.class_type === "tuition" && result?.class_course?.tuition_type === "school")) {
            data.board = result?.class_course?.board;
            if (result?.class_course?.class_type === "school") {
                data.section = result?.class_course?.section;
            }

        }
        if (result?.class_course?.class_type === "university" || (result?.class_course?.class_type === "tuition" && result?.class_course?.tuition_type === "university")) {
            data.university = result?.class_course?.university;
            data.semester = result?.class_course?.semester;
        }
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(data),
        };
        fetch(
            url.apiUrl +
            `institute/get-class-time-slot`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result && result?.data) {
                    setClassSlots(result?.data);
                }
            })
            .catch((error) => console.log("error", error));
    }

    const getTeachersBookedSlot = async (teacher_id) => {
        if (auth_token) {

            var requestOptions = {
                method: "GET",
                headers: myHeaders,
            };
            fetch(
                url.apiUrl +
                `institute/get-teacher-time-schedule/${teacher_id}`,
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result && result?.data) {
                        setTeacherSlots(result?.data);
                    }
                })
                .catch((error) => console.log("error", error));
        }
    };

    const ValidateSchema = Yup.object().shape({
        teacher: Yup.object().required("Required"),
        subject: Yup.object().required("Required"),
        start_time: Yup.string().required("Required"),
        end_time: Yup.string().when('start_time', {
            is: (start_time) => !!start_time,
            then: Yup.string()
                .required('Required')
                .test({
                    name: 'is-greater',
                    message: 'End time must be greater than start time',
                    test: function (end_time) {
                        const start_time = this.resolve(Yup.ref('start_time'));
                        return !start_time || !end_time || end_time > start_time;
                    },
                }),
            otherwise: Yup.string(),
        })
    });

    const FormSubmit = async (values) => {
        try {
            setIsSubmit(true)
            var data = {
                slot_id: values.id,
                subject: values.subject.value,
                teacher_id: values.teacher.value,
                start_time: values.start_time,
                end_time: values.end_time,
            }
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify(data),
            };
            fetch(url.apiUrl + "institute/update-class-slot", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === "success") {
                        setIsSubmit(false);
                        toast.success(result.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setTimeout(() => {
                            history.push("/institute-dashboard/slot-view/" + values.class_id);
                        }, 3000);
                    } else {
                        toast.error(result.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                })
                .catch((error) => console.log("error", error));
        } catch (err) {
            throw err;
        }
    }

    useEffect(() => {
        getSlotDetail()
    }, [])
    return (
        <>
            <ToastContainer />
            <div className='page-header'>
                <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                        <Link to='/institute-dashboard'>Dashboard</Link>
                    </li>
                    <li className='breadcrumb-item'>
                        <Link to='/institute-dashboard/slot'>Time Slot</Link>
                    </li>
                    <li className='breadcrumb-item active'>Edit Time Slot</li>
                </ol>
            </div>

            <div className='row'>
                <div className='col-lg-12'>
                    <div className='card'>
                        <div className='card-header'>
                            <h4 className='card-title'>Edit Time Slot</h4>
                        </div>
                        <div className='card-body'>
                            <Formik
                                initialValues={values}
                                onSubmit={FormSubmit}
                                enableReinitialize={true}
                                validationSchema={ValidateSchema}
                            >
                                {(props) => {
                                    return (
                                        <form noValidate='novalidate' onSubmit={props.handleSubmit} >
                                            <div className='row'>
                                                <div className="col-md-6 form-group">
                                                    <label>
                                                        Branch <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter other branch"
                                                        onChange={props.handleChange}
                                                        name={`branch_id`}
                                                        value={props.values?.class_course?.institute_branch?.name}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-md-6 form-group">
                                                    <label>
                                                        Class Type <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Class Type"
                                                        onChange={props.handleChange}
                                                        name={`class_type`}
                                                        value={replaceWord(props.values?.class_course?.class_type)}
                                                        disabled
                                                    />
                                                </div>
                                                {props.values?.class_course?.class_type === "tuition" && (
                                                    <div className="col-md-6 form-group">
                                                        <label>
                                                            Tuition Type <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Tuition Type"
                                                            onChange={props.handleChange}
                                                            name={`tuition_type `}
                                                            value={replaceWord(props.values?.class_course?.tuition_type)}
                                                            disabled
                                                        />
                                                    </div>
                                                )}
                                                {(props.values?.class_course?.class_type === "school" || props.values?.class_course?.tuition_type === "school") && (
                                                    <>
                                                        <div className="col-md-6 form-group">
                                                            <label>
                                                                Board <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Board"
                                                                onChange={props.handleChange}
                                                                name={`board`}
                                                                value={props.values?.class_course?.board}
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <label>
                                                                Section <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Section"
                                                                onChange={props.handleChange}
                                                                name={`section`}
                                                                value={props.values?.class_course?.section}
                                                                disabled
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                                {(props.values?.class_course?.class_type === "university" || props.values?.class_course?.tuition_type === "university") && (
                                                    <>
                                                        <div className="col-md-6 form-group">
                                                            <label>University <span className="text-danger"> *</span></label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter University"
                                                                onChange={props.handleChange}
                                                                name={`university`}
                                                                value={props.values?.class_course?.universities.name}
                                                                disabled
                                                            />

                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <label>Semester <span className="text-danger">*</span></label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter semester"
                                                                onChange={props.handleChange}
                                                                name={`semester`}
                                                                value={props.values?.class_course?.semester}
                                                                disabled
                                                            />

                                                        </div>
                                                    </>
                                                )}
                                                <div className="col-md-6 form-group">
                                                    <label>
                                                        Class Name <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter class name"
                                                        onChange={props.handleChange}
                                                        name={`class_name`}
                                                        value={props.values?.class_course?.class_name}
                                                        disabled
                                                    />

                                                </div>
                                                <div className="col-md-4 form-group">
                                                    <label htmlFor="school-name">Subject Name<span className="text-danger"> *</span></label>
                                                    <Select
                                                        name="subject"
                                                        onChange={(e) => {
                                                            props.setFieldValue('subject', e)
                                                            props.setFieldValue('teacher', '')
                                                            getTeacher(props.values, e.value)
                                                            getClassSchedule(props.values, e.value)

                                                        }}
                                                        value={props.values.subject}
                                                        options={props.values.subjects}
                                                    />
                                                    {props.errors.subject && props.touched.subject && (
                                                        <p className='text-danger mb-0'>{props.errors.subject}</p>
                                                    )}
                                                </div>
                                                <div className="col-md-4 form-group">
                                                    <label htmlFor="school-name">Teacher<span className="text-danger"> *</span></label>
                                                    <Select
                                                        name="teacher"
                                                        onChange={(e) => {
                                                            props.setFieldValue('teacher', e)
                                                            getTeachersBookedSlot(e.value)
                                                        }}
                                                        value={props.values.teacher}
                                                        options={teachers}
                                                    />
                                                    {props.errors.teacher && props.touched.teacher && (
                                                        <p className='text-danger mb-0'>{props.errors.teacher}</p>
                                                    )}
                                                </div>
                                                <div className="col-md-4 form-group">
                                                    <label>Date <span className="text-danger">*</span></label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        name="date"
                                                        value={props.values.date}
                                                        disabled
                                                    />
                                                </div>
                                                {/* <div className="col-md-4 form-group">
                                                    <label>Start Date <span className="text-danger">*</span></label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        name="start_date"
                                                        value={props.values.start_date}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-md-4 form-group">
                                                    <label>End Date <span className="text-danger">*</span></label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        name="end_date"
                                                        value={props.values.end_date}
                                                        disabled
                                                    />
                                                </div> */}
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <h4 className="card-title">Classroom Slot - Booked</h4>
                                                            </div>
                                                            <div className="card-body h-400 overflow-auto">
                                                                {classSlots?.length === 0 ? (
                                                                    <div className='d-flex justify-content-center'>
                                                                        <p>No Data Available !</p>
                                                                    </div>
                                                                ) : (
                                                                    <div className="row">
                                                                        {classSlots?.map((TimeTableValue, index) => {
                                                                            const { date, start_date, end_date, start_time, end_time, teacher } = TimeTableValue;
                                                                            return (
                                                                                <div className="col-md-6" key={index}>
                                                                                    <div className='card'>
                                                                                        <div className='card-body'>
                                                                                            <h4 className='font-weight-semibold text-center mb-0'>
                                                                                                Date : <Moment format="DD-MM-YYYY">{date}</Moment>
                                                                                            </h4>
                                                                                            {/* <h4 className='font-weight-semibold text-center mb-0'>
                                                                                                Date : <Moment format="DD-MM-YYYY">{start_date}</Moment> to <Moment format="DD-MM-YYYY">{end_date}</Moment>
                                                                                            </h4> */}
                                                                                            <p className="text-center mb-0"><b>Teacher : </b>{teacher?.name}</p>
                                                                                            <p className="text-center mb-0"> {moment(start_time, ["HH:mm"]).format("hh:mm A")} - {moment(end_time, ["HH:mm"]).format("hh:mm A")} </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className='card'>
                                                            <div className='card-header'>
                                                                <h4 className='card-title'>Teacher Slot - Booked</h4>
                                                            </div>
                                                            <div className='card-body h-400 overflow-auto'>
                                                                {teacherSlots.length === 0 ? (
                                                                    <div className='d-flex justify-content-center'>
                                                                        <p>No Data Available !</p>
                                                                    </div>
                                                                ) : (
                                                                    <div className="row">
                                                                        {teacherSlots.map((TimeTableValue, index) => {
                                                                            const { date, start_date, end_date, start_time, end_time } = TimeTableValue;
                                                                            return (
                                                                                <div className="col-md-6" key={index}>
                                                                                    <div className='card'>
                                                                                        <div className='card-body'>
                                                                                            <h4 className='font-weight-semibold text-center mb-0'>
                                                                                                Date : <Moment format="DD-MM-YYYY">{date}</Moment>
                                                                                            </h4>
                                                                                            {/* <h4 className='font-weight-semibold text-center mb-0'>
                                                                                                Date : <Moment format="DD-MM-YYYY">{start_date}</Moment> to <Moment format="DD-MM-YYYY">{end_date}</Moment>
                                                                                            </h4> */}
                                                                                            <p className="text-center mb-0">
                                                                                                {moment(start_time, ["HH:mm"]).format("hh:mm A")}{" "}
                                                                                                - {moment(end_time, ["HH:mm"]).format("hh:mm A")}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="card">
                                                        <div className="card-header d-flex justify-content-between">
                                                            <h4 className="card-title">
                                                                From <Moment format="DD-MM-YYYY">{props.values.start_date}</Moment> to <Moment format="DD-MM-YYYY">{props.values.end_date}</Moment>
                                                            </h4>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className='row'>
                                                                <div className='col-md-4 form-group'>
                                                                    <label>Slot Type <span className='text-danger'>*</span> </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Select slot type"
                                                                        onChange={props.handleChange}
                                                                        name={`slot_type`}
                                                                        value={replaceWord(props.values?.slot_type)}
                                                                        disabled
                                                                    />
                                                                </div>
                                                                <div className='col-md-3 form-group'>
                                                                    <label>
                                                                        Start Time <span className='text-danger'>*</span>
                                                                    </label>
                                                                    <input
                                                                        type="time"
                                                                        className='form-control'
                                                                        name="start_time"
                                                                        id='start_time'
                                                                        placeholder='Start time'
                                                                        value={props.values.start_time}
                                                                        onChange={props.handleChange}
                                                                    />
                                                                    {props.errors.start_time && props.touched.start_time && (
                                                                        <p className='text-danger mb-0'>{props.errors.start_time}</p>
                                                                    )}
                                                                </div>
                                                                <div className='col-md-3 form-group'>
                                                                    <label>
                                                                        End Time <span className='text-danger'>*</span>
                                                                    </label>
                                                                    <input
                                                                        type="time"
                                                                        className='form-control'
                                                                        name="end_time"
                                                                        id='end_time'
                                                                        placeholder='End time'
                                                                        value={props.values.end_time}
                                                                        onChange={props.handleChange}
                                                                    />
                                                                    {props.errors.end_time && props.touched.end_time && (
                                                                        <p className='text-danger mb-0'>{props.errors.end_time}</p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 text-end">
                                                    {isSubmit ? (
                                                        <SubmitLoader />
                                                    ) : (
                                                        <>
                                                            <button type="submit" className="btn btn-primary me-2">Submit</button>
                                                            <button className="btn btn-danger">Cancel</button>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SlotEdit;
