import React from 'react';
import { Link } from "react-router-dom";

const EducationalAggregator = () => {
    return (
        <>
            <section className="sptb bg-white">
                <div className="container">
                    <div className="section-title">
                        <h2>Educational Aggregator</h2>
                        <div className="ms-auto"><Link className="btn btn-primary" to="/about-us">View More <i className="fe fe-arrow-right" /></Link></div>
                    </div>
                    <div className="row ">
                        <p>Our educational aggregator service is a one-stop-shop for all your education needs.
                            We bring together the best educational resources from around the web into one place,
                            making it easy for you to find the information you need. With our intuitive search engine,
                            you can find the perfect course, Institute, Teacher to meet your needs and goals.
                        </p>
                    </div >
                </div >
            </section >
        </>
    );
};

export default EducationalAggregator;