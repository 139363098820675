import React, { useEffect, useState } from "react";
import Routing from "./Routes/Routing";
import { HMSRoomProvider } from "@100mslive/react-sdk";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Preloader from "./Preloader";
import AOS from 'aos';

function App() {
  
// AOS Library code
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration (in milliseconds)
      offset: 100,
      once: false,     // Whether animation should happen only once
    });
  }, []);


  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      {loading ? (
        <Preloader />  // Show the preloader when loading is true
      ) : (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <HMSRoomProvider>
            <Routing />
          </HMSRoomProvider>
        </LocalizationProvider>
      )}
    </>
  );
}

export default App;
