import React, { useState } from "react";
import * as Yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SubmitLoader from "../../../../common/SubmitLoader"
import { Formik } from "formik";

export default function VideoContent({
    active,
    setActive,
    values,
    param,
    setValues,
    SectionlistApi,
    setSectionHeadingForm,
}) {
    const [loader, setLoader] = useState(false);

    const onSubmit = async (values, { resetForm }) => {
        const formData = new FormData();

        formData.set("course_id", sessionStorage.getItem("course_id"));
        formData.set("title", values.headline);
        formData.set("is_main_course", sessionStorage.getItem("is_main"));
        if (values.id) {
            formData.set("id", values.id);
        }

        let auth = JSON.parse(localStorage.getItem("common-user-info"));
        const config = {
            headers: {
                content: "multipart/form-data",
                AUTHTOKEN: auth && auth?.auth_token,
            },
        };
        setLoader(true);
        try {
            if (values.id) {
                const response = await axios.post(
                    "https://educationmandal.com/em-admin/api/" + `teacher-course/update-section`,
                    formData,
                    config
                );
                if (response.data?.status === "success") {
                    setLoader(false);
                    toast.success(response.message);
                    setSectionHeadingForm(false);
                    SectionlistApi(sessionStorage.getItem("course_id"));
                    setActive((prev) => ({ [response.data.id]: false }));
                    setValues({ headline: "" });
                } else {
                    setValues({ headline: "" });
                    setLoader(false);
                    toast.error(response.data?.message);
                }
            } else {
                setLoader(true);
                const response = await axios.post(
                    "https://educationmandal.com/em-admin/api/" + `teacher-course/create-section`,
                    formData,
                    config
                );
                if (response.data?.status === "success") {
                    setLoader(false);
                    toast.success(response.message);
                    setSectionHeadingForm(false);
                    setValues({ headline: "" });
                    SectionlistApi(param.id);
                    setActive((prev) => ({ [response.data.id]: false }));
                    setTimeout(() => {
                        resetForm();
                    }, 5000);
                } else {
                    setValues({ headline: "" });
                    setLoader(false);
                    toast.error(response.data?.message);
                }
            }
        } catch (err) {
            throw err;
        }
    };

    const VoucherSchema = Yup.object().shape({
        headline: Yup.string().required("Required"),
    });

    return (
        <>
            <div
                className="card-header d-flex justify-content-between"
                id="mainsectionheading"
            >
                <h5 className="mb-0">Add Video Course Content</h5>
            </div>
            <Formik
                initialValues={values}
                validationSchema={VoucherSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {(props) => {

                    return <div className="card-body">
                        <form onSubmit={props.handleSubmit}>
                            <div className="row">
                                <div className="form-group col-lg-10 col-md-9 col-sm-12">
                                    <label>
                                        Chapter <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        name="headline"
                                        value={props.values.headline}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        type="text"
                                        placeholder="Enter chapter"
                                    />
                                    {props.touched.headline && props.errors.headline ? (
                                        <div className="bg-error">
                                            {props.errors.headline}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-12 text-end open-button">
                                    {loader ? (
                                        <SubmitLoader />
                                    ) : (
                                        <button className="btn btn-primary" type="submit">
                                            <i className="fa fa-plus"></i> Add Section
                                        </button>
                                    )}

                                </div>

                            </div>
                            <ToastContainer />
                        </form>
                    </div>
                }}
            </Formik>
        </>
    );
}
