import React, { useState, useEffect } from "react";
import VideoContent from "./VideoContent";
import Section from "./Section";
import { useParams } from "react-router-dom";
import CourseVideoService from "../../../../../Services/CourseVideoService";

export default function CreateCourseVideo() {
    const [sectionHeadingForm, setSectionHeadingForm] = useState(false);
    const [sectionList, setSectionList] = useState([]);
    const param = useParams();
    const [sectionId, setSectionId] = useState("");
    const [active, setActive] = useState(false);
    const [values, setValues] = useState({
        headline: "",
        description: "",
    });

    const serve = new CourseVideoService();

    const SectionlistApi = async () => {
        try {
            let response = await serve.sectionlist(
                sessionStorage.getItem("is_main"),
                sessionStorage.getItem("course_id")
            );
            if (response) {
                setSectionList(response.data);
                for (const i in response.data) {
                }
            }
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        SectionlistApi();

    }, []);

    const getSectionDetailApi = async (id) => {
        setSectionHeadingForm(true);

        try {
            let response = await serve.sectiondetail(
                sessionStorage.getItem("is_main"),
                id
            );
            if (response) {
                setValues({
                    id: response.data.id,
                    headline: response.data.title,
                    description: response.data.what_will_able_to_do,
                });
            }
        } catch (err) {
            throw err;
        }


    };
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <VideoContent
                            active={active}
                            setActive={setActive}
                            values={values}
                            setValues={setValues}
                            param={param}
                            sectionList={sectionList}
                            sectionHeadingForm={sectionHeadingForm}
                            setSectionHeadingForm={setSectionHeadingForm}
                            SectionlistApi={SectionlistApi}
                        />
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    {sectionList?.length > 0 && (
                                        <Section
                                            param={param}
                                            sectionId={sectionId}
                                            active={active}
                                            setActive={setActive}
                                            sectionList={sectionList}
                                            setSectionList={setSectionList}
                                            SectionlistApi={SectionlistApi}
                                            sectionHeadingForm={sectionHeadingForm}
                                            setSectionHeadingForm={setSectionHeadingForm}
                                            getSectionDetailApi={getSectionDetailApi}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
