import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { getInstituteBranchGetAllBranches } from "../../../../../apis";
import url from "../../../../../constants/url";

export default function SingleSubject({ data, index, props }) {
	const [branch, setBranch] = useState([]);
	const [classes, setClasses] = useState([]);
	const [sections, setsections] = useState([]);
	const [teachers, setteachers] = useState([]);
	const [boards, setboards] = useState([]);
	const [universities, setuniversities] = useState([]);
	const [semester, setsemester] = useState([]);

	const teacherRef = useRef(null);

	const local_data = localStorage.getItem("common-user-info");
	const local_storage = JSON.parse(local_data);
	const auth_token = local_storage.auth_token;

	const getAllBraches = async () => {
		let res = await getInstituteBranchGetAllBranches();
		if (res.status === "success") {
			const arr =
				res.data &&
				res.data.map((v) => {
					return { name: v.text, id: v.id, label: v.text, value: v.id };
				});
			setBranch(arr);
		}
	};

	const getBoards = async () => {
		var myHeaders = new Headers();
		myHeaders.append("AUTHTOKEN", auth_token);
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				branch_ids: data.branchSelected.id.toString(),
				class_type: data.lectureSelected,
				tuition_type:
					data.lectureSelected === "tuition"
						? data.tuitionType
						: data.tuitionType,
				required_field: "board",
			}),
		};

		fetch(url.apiUrl + "institute/lecture-class-name", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status === "success") {
					let found = result.data.find(
						(cls) => cls.board === data.boardSelected
					);
					if (!found) {
						props.setFieldValue(`data.${index}.boardSelected`, "");
					}
					setboards(result.data);
				} else {
					props.setFieldValue(`data.${index}.boardSelected`, "");
					setboards([]);
				}
			})
			.catch((error) => console.log("error", error));
	};

	const getUniversities = async () => {
		var myHeaders = new Headers();
		myHeaders.append("AUTHTOKEN", auth_token);
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				branch_ids: data.branchSelected.id.toString(),
				class_type: data.lectureSelected,
				tuition_type:
					data.lectureSelected === "tuition"
						? data.tuitionType
						: data.tuitionType,
				required_field: "university",
			}),
		};

		fetch(url.apiUrl + "institute/lecture-class-name", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status === "success") {
					let found = result.data.find(
						(cls) => cls.id === data.universitySelected
					);
					if (!found) {
						props.setFieldValue(`data.${index}.universitySelected`, "");
					}
					setuniversities(result.data);
				} else {
					props.setFieldValue(`data.${index}.universitySelected`, "");
					setuniversities([]);
				}
			})
			.catch((error) => console.log("error", error));
	};

	const getClass = async (pass_data) => {
		setClasses([]);
		props.setFieldValue(`data.${index}.classSelected`, "");
		var myHeaders = new Headers();
		myHeaders.append("AUTHTOKEN", auth_token);
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify(pass_data),
		};

		fetch(url.apiUrl + "institute/lecture-class-name", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status === "success") {
					let found = result.data.find(
						(cls) => cls.class_name === data.classSelected
					);
					if (!found) {
						props.setFieldValue(`data.${index}.classSelected`, "");
					}
					setClasses(result.data);
				} else {
					props.setFieldValue(`data.${index}.classSelected`, "");
					setClasses([]);
				}
			})
			.catch((error) => console.log("error", error));
	};

	const getsections = async () => {
		var myHeaders = new Headers();
		myHeaders.append("AUTHTOKEN", auth_token);
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				branch_ids: data.branchSelected.id.toString(),
				class_type:
					data.lectureSelected === "tuition"
						? data.tuitionType
						: data.lectureSelected,
				required_field: "section",
				class_name: data.classSelected,
				board: data.boardSelected,
			}),
		};

		fetch(url.apiUrl + "institute/lecture-class-name", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status === "success") {
					setsections(
						result.data.map((section) => ({
							name: section.section,
							id: section.section,
							label: section.section,
							value: section.section,
						}))
					);
				} else {
					props.setFieldValue(`data.${index}.sectionsSelected`, []);
					setsections([]);
				}
			})
			.catch((error) => console.log("error", error));
	};

	const getsemesters = async () => {
		var myHeaders = new Headers();
		myHeaders.append("AUTHTOKEN", auth_token);
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				branch_ids: data.branchSelected.id.toString(),
				class_type: data.lectureSelected,
				tuition_type:
					data.lectureSelected === "tuition"
						? data.tuitionType
						: data.tuitionType,

				required_field: "semester",
				class_name: data.classSelected,
				university: data.universitySelected,
			}),
		};

		fetch(url.apiUrl + "institute/lecture-class-name", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status === "success") {
					setsemester(
						result.data.map((section) => ({
							name: section.semester,
							id: section.semester,
							label: section.semester,
							value: section.semester,
						}))
					);
				} else {
					props.setFieldValue(`data.${index}.semesterSelected`, "");
					setsemester([]);
				}
			})
			.catch((error) => console.log("error", error));
	};

	const getteachers = async () => {
		var myHeaders = new Headers();
		myHeaders.append("AUTHTOKEN", auth_token);
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				branch_ids: data.branchSelected.id.toString(),
			}),
		};

		fetch(url.apiUrl + "institute-teacher/get-teachers", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status === "success") {
					props.setFieldValue(
						`data.${index}.subjects`,
						data.subjects.map((subj, id) => ({
							...subj,
							teacher: [],
						}))
					);
					setteachers(
						result.data.map((item) => ({ label: item.name, value: item.id }))
					);
				} else {
					props.setFieldValue(
						`data.${index}.subjects`,
						data.subjects.map((subj, id) => ({
							...subj,
							teacher: [],
						}))
					);
					setteachers([]);
				}
			})
			.catch((error) => console.log("error", error));
	};

	useEffect(() => {
		getAllBraches();
	}, []);

	useEffect(() => {
		if (data.branchSelected.id) {
			if (data.lectureSelected === "school" || data?.tuitionType === "school") {
				getBoards();
			}
			if (
				data.lectureSelected === "university" ||
				data?.tuitionType === "university"
			) {
				getUniversities();
			}
		}
	}, [data.branchSelected, data.lectureSelected, data.tuitionType]);

	useEffect(() => {
		if (data.boardSelected && (data.lectureSelected === "school" || data?.tuitionType === "school")) {
			getClass({
				branch_ids: data.branchSelected.id.toString(),
				class_type: data.lectureSelected,
				tuition_type:
					data.lectureSelected === "tuition"
						? data.tuitionType
						: data.tuitionType,

				required_field: "class_name",
				board: data.boardSelected,
			});
		}
	}, [data.boardSelected]);

	useEffect(() => {
		if (data.universitySelected && (data.lectureSelected === "university" || data?.tuitionType === "university")) {
			getClass({
				branch_ids: data.branchSelected.id.toString(),
				class_type: data.lectureSelected,
				tuition_type:
					data.lectureSelected === "tuition"
						? data.tuitionType
						: data.tuitionType,

				required_field: "class_name",
				university: data.universitySelected,
			});
		}
	}, [data.universitySelected]);

	useEffect(() => {
		if (data.classSelected && data.branchSelected.id) {
			if (data.lectureSelected === "school" || data?.tuitionType === "school") {
				setsections([]);
				props.setFieldValue(`data.${index}.sectionsSelected`, "");
				getsections();
			}
			if (data.lectureSelected === "university" || data?.tuitionType === "university") {
				setsemester([]);
				props.setFieldValue(`data.${index}.semesterSelected`, "");
				getsemesters();
			}
		}
	}, [data.classSelected]);

	useEffect(() => {
		if (data.branchSelected) {
			getteachers();
		}

		if (
			data.lectureSelected === "preparation" ||
			(data.lectureSelected === "tuition" && data.tuitionType === "preparation")
		) {
			getClass({
				branch_ids: data.branchSelected.id.toString(),
				class_type: data.lectureSelected,
				tuition_type:
					data.lectureSelected === "tuition"
						? data.tuitionType
						: data.tuitionType,
				required_field: "class_name",
			});
		}
	}, [data.branchSelected, data.lectureSelected, data.tuitionType]);

	return (
		<div className='row'>
			<div className='col-lg-3 form-group'>
				<label>
					Branch <span className='text-danger'>*</span>
				</label>
				<Select
					options={branch}
					onChange={(e) =>
						props.setFieldValue(`data.${index}.branchSelected`, e)
					}
					value={data.branchSelected}
				/>
				{props.errors.data?.[index]?.branchSelected &&
					props.touched.data?.[index]?.branchSelected && (
						<p className='text-danger'>
							{props.errors.data?.[index]?.branchSelected}
						</p>
					)}
			</div>
			{data.branchSelected.id !== undefined && (
				<>
					<div className='col-md-3 form-group'>
						<label>
							Class Type <span className='text-danger'>*</span>
						</label>
						<select
							className='form-select'
							onChange={props.handleChange}
							name={`data.${index}.lectureSelected`}
							value={data?.lectureSelected}
						>
							<option value="">Select class type</option>
							<option value="school">School (Till 12th Standard)</option>
							<option value="university">University</option>
							<option value="preparation">Entrance Exam / Professional Courses</option>
							<option value="tuition">Tuition</option>
						</select>
						{props.errors.data?.[index]?.lectureSelected &&
							props.touched.data?.[index]?.lectureSelected && (
								<p className='text-danger'>
									{props.errors.data?.[index]?.lectureSelected}
								</p>
							)}
					</div>
					{data?.lectureSelected === "tuition" && (
						<div className='col-md-3 form-group'>
							<label>
								Tuition Type <span className='text-danger'>*</span>
							</label>
							<select
								className='form-select'
								onChange={props.handleChange}
								name={`data.${index}.tuitionType`}
								value={data?.tuitionType}
							>
								<option value=''>Select tuition type</option>
								<option value="school">School (Till 12th Standard)</option>
								<option value="university">University</option>
								<option value="preparation">Entrance Exam / Professional Courses</option>
							</select>
							{props.errors.data?.[index]?.tuitionType &&
								props.touched.data?.[index]?.tuitionType && (
									<p className='text-danger'>
										{props.errors.data?.[index]?.tuitionType}
									</p>
								)}
						</div>
					)}
					{(data?.lectureSelected === "school" ||
						data?.tuitionType === "school") && (
							<div className='col-md-3 form-group'>
								<label>
									Board Name <span className='text-danger'>*</span>
								</label>
								<select
									onChange={props.handleChange}
									name={`data.${index}.boardSelected`}
									value={data?.boardSelected}
									className='form-select'
								>
									<option value=''>Select board name</option>
									{boards.map((cls, idx) => (
										<option key={idx} value={cls.board}>
											{cls.board}
										</option>
									))}
								</select>
								{props.errors.data?.[index]?.boardSelected &&
									props.touched.data?.[index]?.boardSelected && (
										<p className='text-danger'>
											{props.errors.data?.[index]?.boardSelected}
										</p>
									)}
							</div>
						)}
					{(data?.lectureSelected === "university" ||
						data?.tuitionType === "university") && (
							<div className='col-md-3 form-group'>
								<label>
									University Name <span className='text-danger'>*</span>
								</label>
								<select
									onChange={props.handleChange}
									name={`data.${index}.universitySelected`}
									value={data?.universitySelected}
									className='form-select'
								>
									<option value=''>Select university name</option>
									{universities.map((cls, idx) => (
										<option key={idx} value={cls.id}>
											{cls.name}
										</option>
									))}
								</select>
								{props.errors.data?.[index]?.universitySelected &&
									props.touched.data?.[index]?.universitySelected && (
										<p className='text-danger'>
											{props.errors.data?.[index]?.universitySelected}
										</p>
									)}
							</div>
						)}
					<div className='col-md-3 form-group'>
						<label>
							Class Name <span className='text-danger'>*</span>
						</label>
						<select
							onChange={props.handleChange}
							name={`data.${index}.classSelected`}
							value={data?.classSelected}
							className='form-select'
						>
							<option value=''>Select class name</option>
							{classes.map((cls, idx) => (
								<option key={idx} value={cls.class_name}>
									{cls.class_name}
								</option>
							))}
						</select>
						{props.errors.data?.[index]?.classSelected &&
							props.touched.data?.[index]?.classSelected && (
								<p className='text-danger'>
									{props.errors.data?.[index]?.classSelected}
								</p>
							)}
					</div>
					{data?.lectureSelected === "school" && (
						<div className='col-md-3 form-group'>
							<label>
								Section <span className='text-danger'>*</span>
							</label>
							<Select
								options={sections}
								onChange={(e) =>
									props.setFieldValue(`data.${index}.sectionsSelected`, e)
								}
								value={data.sectionsSelected}
								isMulti
							/>
							{props.errors.data?.[index]?.sectionsSelected &&
								props.touched.data?.[index]?.sectionsSelected && (
									<p className='text-danger'>
										{props.errors.data?.[index]?.sectionsSelected}
									</p>
								)}
						</div>
					)}
					{(data?.lectureSelected === "university" ||
						data?.tuitionType === "university") && (
							<div className='col-md-3 form-group'>
								<label>
									Semester
									<span className='text-danger'>*</span>
								</label>
								<Select
									options={semester}
									onChange={(e) =>
										props.setFieldValue(`data.${index}.semesterSelected`, e)
									}
									value={data.semesterSelected}
									isMulti
								/>
								{props.errors.data?.[index]?.semesterSelected &&
									props.touched.data?.[index]?.semesterSelected && (
										<p className='text-danger'>
											{props.errors.data?.[index]?.semesterSelected}
										</p>
									)}
							</div>
						)}

					<div className='col-md-10 open-button'>
						<div className="card">
							<div className="card-body">
								{data?.subjects?.map((subject, idx) => (
									<div className='row' key={idx}>
										<div className='col-md-5 form-group'>
											<label>
												Teacher
												<span className='text-danger'>*</span>
											</label>
											<Select
												options={teachers}
												onChange={(e) =>
													props.setFieldValue(
														`data.${index}.subjects`,
														data.subjects.map((subj, id) => ({
															...subj,
															teacher: id === idx ? e : subj.teacher,
														}))
													)
												}
												value={subject.teacher}
												isMulti
											/>
											{props.errors.data?.[index]?.subjects?.[idx]?.teacher &&
												props.touched.data?.[index]?.subjects?.[idx]?.teacher && (
													<p className='text-danger'>
														{props.errors.data?.[index]?.subjects?.[idx]?.teacher}
													</p>
												)}
										</div>
										<div className='col-md-5 form-group'>
											<label>
												Subject Name <span className='text-danger'>*</span>
											</label>
											<input
												type='text'
												className='form-control'
												name='teacherId'
												placeholder='Enter subject name'
												onChange={(e) =>
													props.setFieldValue(
														`data.${index}.subjects`,
														data.subjects.map((subj, id) => ({
															...subj,
															subject: id === idx ? e.target.value : subj.subject,
														}))
													)
												}
												value={subject.subject}
											/>
											{props.errors.data?.[index]?.subjects?.[idx]?.subject &&
												props.touched.data?.[index]?.subjects?.[idx]?.subject && (
													<p className='text-danger'>
														{props.errors.data?.[index]?.subjects?.[idx]?.subject}
													</p>
												)}
										</div>
										<div className='col-md-2 open-button'>
											<button
												type='button'
												onClick={() =>
													props.setFieldValue(
														`data.${index}.subjects`,
														data?.subjects?.filter((s, id) => id !== idx)
													)
												}
												className='btn btn-danger'
											>
												Remove Subject
											</button>
										</div>

									</div>

								))}
							</div>
						</div>
					</div>
					<div className='col-md-2 open-button'>
						<button
							type='button'
							onClick={() =>
								props.setFieldValue(`data.${index}.subjects`, [...data?.subjects,
								{
									teacher: [],
									subject: "",
								},
								])
							}
							className='btn btn-primary'
						>
							Add More Subject
						</button>
					</div>
				</>
			)
			}
		</div >
	);
}
