import instance from "./config/axios";
import webroutes from "./constants/web_routes";
import url from "./constants/url";

export const getInstituteRegister = (params) => {
    let headers = {
        "Content-Type": "multipart/form-data",
        Accept: "*",
    };
    return instance
        .post(`${url.apiUrl}${webroutes.institute_register}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};
export const getTeacherRegister = (params) => {
    let headers = {
        "Content-Type": "multipart/form-data",
        Accept: "*",
    };
    return instance
        .post(`${url.apiUrl}${webroutes.teacher_register}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};

export const getStudentRegister = (params) => {
    let headers = {
        "Content-Type": "multipart/form-data",
        Accept: "*",
    };
    return instance
        .post(`${url.apiUrl}${webroutes.student_register}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};

export const getOnlineAllCourse = (limit, offset) => {
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
    };
    return instance
        .get(`${url.apiUrl}${webroutes.get_online_course}/${limit}/${offset}`, [], {
            headers: headers,
        })
        .then((res) => {
            return res;            
        });
};


export const getCourseDetails = (params, offset) => {
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
    };
    return instance
        .get(`${url.apiUrl}${webroutes.get_online_course_id}/${offset}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};
const local_data = localStorage.getItem("common-user-info");
const local_storage = JSON.parse(local_data);
const auth_token = local_storage?.auth_token;

export const getCourseReview = (params) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    if (auth_token) {
        myHeaders.append("AUTHTOKEN", auth_token);
    }
    var requestOptions = {
        headers: myHeaders,
    };
    return instance
        .post(`${url.apiUrl}${webroutes.get_review}`, params, {
            headers: requestOptions,
        })
        .then((res) => {
            return res;
        });
};

export const getBestTeacher = (params) => {
    let headers = {
        "Content-Type": "multipart/form-data",
        Accept: "*",
    };
    return instance
        .get(`${url.apiUrl}${webroutes.get_best_teacher}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};

export const getGetTeacher = (params, limit, offset) => {
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
    };
    return instance
        .get(
            `${url.apiUrl}${webroutes.get_get_teacher}/${limit}/${offset}`,
            params,
            {
                headers: headers,
            }
        )
        .then((res) => {
            return res;
        });
};

export const getLatestReview = (params) => {
    let headers = {
        "Content-Type": "multipart/form-data",
        Accept: "*",
    };
    return instance
        .get(`${url.apiUrl}${webroutes.get_latest_review}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};

export const getCart = (params) => {
    let headers = {
        "Content-Type": "multipart/form-data",
        Accept: "*",
    };
    return instance
        .get(`${url.apiUrl}${webroutes.get_cart}`, params, { headers: headers })
        .then((res) => {
            return res;
        });
};

export const getClass = (limit, offset) => {
    let headers = {
        "Content-Type": "multipart/form-data",
        Accept: "*",
    };
    return instance
        .get(`${url.apiUrl}${webroutes.get_class_course}/${limit}/${offset}`, [], {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};

export const getClassDetails = (params, offset) => {
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
    };
    return instance
        .get(`${url.apiUrl}${webroutes.get_online_class_id}/${offset}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};
export const getTotal = (params, offset) => {
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
    };
    return instance
        .get(`${url.apiUrl}${webroutes.get_counter}`, params, { headers: headers })
        .then((res) => {
            return res;
        });
};
export const getLatestBlog = (params, offset) => {
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
    };
    return instance
        .get(`${url.apiUrl}${webroutes.get_latest_blog}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};

export const getBlogList = (params, offset) => {
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
    };
    return instance
        .get(`${url.apiUrl}${webroutes.get_blog_list}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};

export const getBlogDetails = (params, offset) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    if (auth_token) {
        myHeaders.append("AUTHTOKEN", auth_token);
    }
    var requestOptions = {
        headers: myHeaders,
    };

    return instance
        .get(`${url.apiUrl}${webroutes.get_blog_list_id}/${offset}`, params, {
            headers: requestOptions,
        })
        .then((res) => {
            return res;
        });
};

export const getTeacherProfileDetails = (params, offset) => {
    let headers = {
        "Content-Type": "application/json",
        Accept: "*",
    };
    return instance
        .get(`${url.apiUrl}${webroutes.get_teacher_profile_id}/${offset}`, params, {
            headers: headers,
        })
        .then((res) => {
            return res;
        });
};
