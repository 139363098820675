const routes = {
    teacher_get_profile: 'teacher/get-profile',
    teacher_edit_profile: 'teacher/edit-profile',
    teacher_education: 'teacher/get-all-educations',
    teacher_add_education: 'teacher/add-education',
    teacher_get_education_by_id: 'teacher/get-education-by-id',
    teacher_edit_education_by_id: 'teacher/edit-education-by-id',
    teacher_experience: 'teacher/get-all-experiences',
    teacher_add_experience: 'teacher/add-experience',
    teacher_get_experience_by_id: 'teacher/get-experience-by-id',
    teacher_edit_experience_by_id: 'teacher/edit-experience-by-id',
    teacher_achievement: 'teacher/get-all-achievements',
    teacher_add_achievement: 'teacher/add-achievement',
    teacher_edit_achievement_by_id: 'teacher/edit-achievement-by-id',
    teacher_get_achievement_by_id: 'teacher/get-achievement-by-id',
    teacher_branch_get_all_branches: 'teacher/get-branch-list',
    login: 'login',
    institute_get_profile: 'institute/get-profile',
    institute_get_subjects: 'institute/lecture-class-name',
    institute_teacher_new_register: 'institute-teacher/new-register',
    institute_branch_get_all_branches: 'institute-branch/get-branches',
    institute_teacher_teachers: 'institute-teacher/teachers',
    institute_board: 'get-board',
    get_mediums: 'get-mediums',
    get_standard: 'get-board',


    gat_category: "gat-category",
    institute_course_add_online_course: 'institute-course/add-online-course',
    institute_course_get_all_online_courses: 'institute-course/get-all-online-courses',
    get_institute: 'institute-branch/get-branches',

    get_degree: 'get-degree',
    get_teachers: 'institute-teacher/get-teachers',
    get_board: 'get-board',
    get_section: 'get-sections',
    get_standards: 'get-standards',
    get_category: 'gat-category',
    get_university: 'get-university',
    get_data: 'institute-course/add-class-course',


    student_get_profile: 'student/get-profile',
    student_add_education: 'student/add-education',
    student_update_education: 'student/edit-education-by-id',
    student_add_experience: 'student/add-experience',
    student_add_achievement: 'student/add-achievement',
    student_update_achievement: 'student/edit-achievement-by-id',
    student_achievement: 'student/get-all-achievements',
    student_edit_profile: 'student/edit-profile',
    student_online_course: 'student/online-course',
}
export default routes;