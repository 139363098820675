import React, { useState, useEffect } from 'react';
import { useHistory, Link, useParams } from "react-router-dom";
import url from '../../../../../constants/url';
import utils from '../../../../../utils/utils';
import { ToastContainer, toast } from "react-toastify";
import NoDataAvailable from "../../../../common/NoDataAvailable";
const CourseStudy = () => {
    const param = useParams();
    const [isAuth, setIsAuth] = useState(true);
    const [isAuthMessage, setIsAuthMessage] = useState("");
    const [active, setActive] = useState({});
    const [activeVideo, setActiveVideo] = useState({});
    const [activePreviewVideo, setActivePreviewVideo] = useState({});
    const [data, setData] = useState({});
    const [course, setCourse] = useState({});
    const getCourseVideo = async () => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        fetch(
            `${url.apiUrl}student/get-online-course-videos/${param.id}`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setData(result.data);
                    setCourse(result.online_course);
                    setIsAuth(false);
                } else {
                    setIsAuth(true);
                    setIsAuthMessage(result.message);
                    toast.error(result.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch((error) => console.log("error", error));
    };

    const activeHandler = (id) => {
        setActive({ [id]: true })
    }

    useEffect(() => {
        getCourseVideo();
    }, []);
    return (
        <>
            <ToastContainer />
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/student-dashboard">Dashboard</Link>
                    </li>

                    <li className="breadcrumb-item">
                        <Link to="/student-dashboard/OrderCourse">Video Courses</Link>
                    </li>
                    <li className="breadcrumb-item active">Video Course Study Detail</li>
                </ol>
            </div>

            <section className="card-body learning-env-header">
                <h3 className="text-white">{course?.title}</h3>
                <p className="text-white mb-0">{course?.short_discription}</p>
            </section>
            <div className="pb-5 pt-5 ">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="card mt--0">
                            <div className="card-header">
                                <h4 className="card-title">Video Course List</h4>
                            </div>
                            <div className="card-body scrolly">
                                <div className="accordion" id="accordionExample">
                                    {data?.length > 0 &&
                                        data?.map((item, i) => (
                                            <div className="accordion-item mb-3" key={i}>
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button
                                                        className={
                                                            active[item?.id]
                                                                ? "accordion-button"
                                                                : "accordion-button collapsed"
                                                        }
                                                        type="button"
                                                        onClick={() => activeHandler(item?.id)}
                                                    >
                                                        {item?.title}
                                                    </button>
                                                </h2>
                                                {active[item?.id] && (
                                                    <div
                                                        id="collapseOne"
                                                        className={
                                                            active[i]
                                                                ? "accordion-collapse collapse show"
                                                                : "accordion-collapse collapse"
                                                        }
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="accordion-body">
                                                            <div className="align-items-center">
                                                                {item?.lectures?.length > 0 ? (
                                                                    item?.lectures.map((item, i) => (
                                                                        <div
                                                                            className="d-flex align-items-center justify-content-between mb-4 cursor-pointer"
                                                                            key={i}
                                                                        >
                                                                            <div className="d-flex align-items-center">
                                                                                <i className="fa-solid fa-video"></i>
                                                                                <div className="ms-3" onClick={() => {
                                                                                    setActivePreviewVideo({})
                                                                                    setActiveVideo({ [item.id]: true })
                                                                                }}>
                                                                                    {item?.title}
                                                                                </div>
                                                                            </div>

                                                                            <div className="d-flex align-items-center">
                                                                                {item?.is_preview == 1 && (
                                                                                    <Link
                                                                                        className="text-danger d-flex me-5 align-items-center"
                                                                                        to="#"
                                                                                        onClick={() => {
                                                                                            setActiveVideo({})
                                                                                            setActivePreviewVideo({ [item.id]: true })
                                                                                        }}
                                                                                    >
                                                                                        <i className="fa-solid fa-play me-2"></i> preview
                                                                                    </Link>
                                                                                )}
                                                                                {item?.is_preview == 1 && (
                                                                                    <Link
                                                                                        className="text-danger d-flex me-5"
                                                                                        to="#"
                                                                                        onClick={() => {
                                                                                            setActivePreviewVideo({})
                                                                                            setActiveVideo({ [item.id]: true })
                                                                                        }}
                                                                                    >
                                                                                        <i className="fa-solid fa-play"></i>
                                                                                    </Link>
                                                                                )}
                                                                                <div className="badge btn-primary width-55px">
                                                                                    {utils.toHHMMSS(
                                                                                        item?.video_duration
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    <NoDataAvailable />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        {data?.length > 0 &&
                            data?.map((item, i) => (
                                item?.lectures?.length > 0 &&
                                item?.lectures.map((item, i) => (
                                    <>
                                        {activeVideo[item.id] && (
                                            <div className="card">
                                                <div className="card-header">
                                                    <h4 className="card-title">{item.title}</h4>
                                                </div>
                                                <div className="card-body">
                                                    <div className="video-popup-wrapper" key={i}>
                                                        {item.video && (
                                                            <video
                                                                controls unmuted loop controlsList="nodownload"
                                                                poster={course?.teaser_thumb_image}
                                                            >
                                                                <source
                                                                    src={item.video}
                                                                    type="video/mp4"
                                                                    height="100%"
                                                                    width="100%"
                                                                />
                                                            </video>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {activePreviewVideo[item.id] && (
                                            <div className="card">
                                                <div className="card-header">
                                                    <h4 className="card-title">{item.title}</h4>
                                                </div>
                                                <div className="card-body">
                                                    <div className="video-popup-wrapper" key={i}>
                                                        {item.preview_video && (
                                                            <video
                                                                controls unmuted loop controlsList="nodownload"
                                                                poster={course?.teaser_thumb_image}
                                                            >
                                                                <source
                                                                    src={item.preview_video}
                                                                    type="video/mp4"
                                                                    height="100%"
                                                                    width="100%"
                                                                />
                                                            </video>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ))
                            ))}
                        {(Object.keys(activeVideo).length === 0 && Object.keys(activePreviewVideo).length === 0) && (
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Teaser Video</h4>
                                </div>
                                <div className="card-body">
                                    <div className="video-popup-wrapper">
                                        {course?.teaser_media && (
                                            <video style={{ height: "500px" }}
                                                controls unmuted loop controlsList="nodownload"
                                                poster={course?.teaser_thumb_image}
                                            >
                                                <source style={{ height: "500px" }}
                                                    src={course?.teaser_media}
                                                    type="video/mp4"
                                                    height="100%"
                                                    width="100%"
                                                />
                                            </video>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div >
            </div >
        </>
    )
}
export default CourseStudy;