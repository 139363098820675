import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import url from "../../../../../constants/url";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import ApproveClassPopup from "../ApproveClassPopup";
import { replaceWord } from "../../../../../utils/utils";

const RevisedClass = () => {
    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage.auth_token;
    const [LiveBranch, setLiveBranch] = useState([]);
    const [values, setValues] = useState({});
    const [filters, setFilters] = useState({
        limit: 10,
        offset: 0,
        branch: '',
        lecture_type: '',
        status: '',
        query: ''
    })
    const [lectures, setLectures] = useState([])
    const [loading, setLoading] = useState(false)
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState();
    const [lectureId, setLectureId] = useState(0);
    const [aboutForm, setAboutForm] = useState(false);

    const getchBranch = async () => {
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
        };

        fetch(url.apiUrl + "institute-branch/get-branches", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    const arr =
                        result.data &&
                        result.data.map((v) => {
                            return { value: v.id, label: v.text }
                        });
                    setLiveBranch(arr);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const getLectures = async () => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
                is_main_course: '0',
                list_type: 'lecture',
                limit: filters.limit,
                offset: filters.offset,
                branch_id: filters.branch.value || undefined,
                class_type: filters.lecture_type || undefined,
                status: filters.status || undefined,
                query: filters.query || undefined
            })
        };

        fetch(url.apiUrl + "institute/class-lecture-list", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setLectures(result.data);
                    setCount(result.count);
                }
            })
            .catch((error) => console.log("error", error))
            .finally(() => {
                setLoading(false)
            })
    };

    const handlePageClick = async (lectures) => {
        let currentPage = lectures.selected * filters.limit;
        setOffset(currentPage);
        setFilters({
            ...filters,
            limit: filters.limit,
            offset: currentPage,
        })
        await getLectures();
    };

    useEffect(() => {
        getchBranch();
    }, []);

    useEffect(() => {
        getLectures()
    }, [])

    const onSubmit = async (values) => {
        getLectures();
    };

    const aboutHandler = (id) => {
        setLectureId(id);
        setAboutForm(true);
    };
    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Classroom Course</li>
                </ol>
                <Link
                    className="btn btn-primary"
                    to="/institute-dashboard/class-add"
                >
                    <i className="fa fa-plus"></i> Add Classroom Course
                </Link>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Classroom Course Filter</h4>
                        </div>
                        <div className="card-body">
                            <Formik
                                initialValues={values}
                                onSubmit={onSubmit}
                                enableReinitialize={true}
                            >
                                {(props) => (
                                    <form onSubmit={props.handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-3 form-group">
                                                <label>Branch</label>
                                                <Select
                                                    onChange={(value) => {
                                                        props.setFieldValue("branch", value)
                                                        setFilters({ ...filters, branch: value })
                                                    }
                                                    }
                                                    options={LiveBranch}
                                                    value={props.values?.branch}
                                                    name="branch"
                                                />
                                            </div>
                                            <div className="col-md-3 form-group">
                                                <label>Class Type </label>
                                                <select
                                                    className="form-select"
                                                    value={props.values.lecture_type}
                                                    onChange={(e) => {
                                                        props.setFieldValue(
                                                            "lecture_type",
                                                            e.target.value
                                                        );
                                                        setFilters({ ...filters, lecture_type: e.target.value })
                                                    }}
                                                >
                                                    <option value="">Select class type</option>
                                                    <option value="school">School (Till 12th Standard)</option>
                                                    <option value="university">University</option>
                                                    <option value="preparation">Entrance Exam / Professional Courses</option>
                                                    <option value="tuition">Tuition</option>
                                                </select>
                                            </div>
                                            <div className="col-md-3 form-group">
                                                <label>Status</label>
                                                <select
                                                    className="form-select"
                                                    name="status"
                                                    onChange={e => {
                                                        props.setFieldValue(
                                                            "status",
                                                            e.target.value
                                                        );
                                                        setFilters({ ...filters, status: e.target.value })
                                                    }}
                                                >
                                                    <option value="">Select status</option>
                                                    <option value="active">Active</option>
                                                    <option value="inactive">Inactive</option>
                                                    <option value="pending">Pending</option>
                                                    <option value="decline">Decline</option>
                                                </select>
                                            </div>
                                            <div className="open-button col-md-3">
                                                <button type="submit" className="btn btn-primary"><i className="fe fe-search"></i> Search</button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Revised Classroom Course List</h4>
                        </div>
                        <div className="card-body">
                            <div id="basic-1_wrapper" className="dataTables_wrapper no-footer">
                                <div id="basic-1_filter" className="dataTables_filter">
                                    <label>
                                        Search:
                                        <input
                                            type="search"
                                            placeholder="Search..."
                                            onChange={(e) => setFilters({ ...filters, query: e.target.value })}
                                        />
                                    </label>
                                </div>
                                <table className="table table-bordered table-hover dataTable table-responsive-xxl">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Sr. No.</th>
                                            <th>Class Details</th>
                                            <th>Branch Details</th>
                                            <th className="text-center">Class Type</th>
                                            <th className="text-center">Board/University</th>
                                            <th className="text-center">Section/Semester</th>
                                            <th className="text-center">Clasroom Type</th>
                                            <th className='text-center'>Medium</th>
                                            <th className='text-center'>Status</th>
                                            <th className="text-center text-nowrap">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading ? (
                                            <tr>
                                                <td className="text-center" colSpan={10}>Loading..</td>
                                            </tr>
                                        ) : (
                                            lectures.length === 0 ? (
                                                <tr>
                                                    <td className="text-center" colSpan={10}>No Data Available !</td>
                                                </tr>
                                            )
                                                : (
                                                    lectures.map((item, index) => (
                                                        <tr key={item.id}>
                                                            <th className="text-center text-nowrap">{index + offset + 1}</th>
                                                            <td>
                                                                <div className="media">
                                                                    <img src={item?.thumbnail} alt={item?.title} className="me-3 rounded-circle" />
                                                                    <div className="media-body">
                                                                        <Link to={"/institute-dashboard/class-detail/0/" + item?.id} className="font-weight-semibold">
                                                                            {item?.title}
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="media">
                                                                    <img src={item?.institute_branch?.image} alt={item?.institute_branch?.name} className="me-3 rounded-circle" />
                                                                    <div className="media-body">
                                                                        <Link to={"/institute-dashboard/branch-detail/" + item?.branch_id} className="font-weight-semibold">
                                                                            {item?.institute_branch?.name}
                                                                        </Link>
                                                                        <p className="mb-0">{item?.institute_branch?.email}</p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="text-center text-capitalize">{replaceWord(item?.class_type)} {item?.class_type == "tuition" ? " : " + (replaceWord(item?.tuition_type)) : ""}</td>
                                                            <td className="text-center text-capitalize">{item?.board || item?.universities?.name || '---'}</td>
                                                            <td className="text-center text-capitalize">{item?.section || item?.semester || '---'}</td>
                                                            <td className="text-center text-capitalize">{replaceWord(item?.lecture_type)}</td>
                                                            <td className="text-center text-capitalize">{item?.medium || '---'}</td>
                                                            <td className={`text-center text-capitalize ${item?.status === "decline" && " text-danger"}`}>
                                                                {(item?.status == "pending" &&
                                                                    item?.is_submit == 1)
                                                                    ? "Submitted"
                                                                    : item?.status}  {item?.status === "decline" && `(${item?.reason?.reason})`}
                                                            </td>
                                                            <td className="text-center text-nowrap">
                                                                {item?.status === "pending" &&
                                                                    item?.is_submit == 1 ? (
                                                                    <Link
                                                                        disabled
                                                                        className="btn btn-outline-grey btn-sm me-2"
                                                                        onClick={() => {
                                                                            alert(
                                                                                "You can't update the course until it gets approved !"
                                                                            );
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-pen-to-square fs-16"></i>
                                                                    </Link>
                                                                ) : (
                                                                    <>
                                                                        {(item?.status === "decline" || item?.status === 'pending') &&
                                                                            item?.is_submit == 1 ? (
                                                                            <Link
                                                                                disabled
                                                                                className="btn btn-primary btn-sm me-2"
                                                                                onClick={() => {
                                                                                    alert(
                                                                                        "You can't update the course until it gets approved !"
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <i className="fa fa-pen-to-square fs-16"></i>
                                                                            </Link>
                                                                        ) : (
                                                                            <Link className="btn btn-primary btn-sm me-2" title="Change Status" to={`/institute-dashboard/class-edit/0/${item?.id}`}>
                                                                                <i className="fa fa-pen-to-square fs-16"></i>
                                                                            </Link>
                                                                        )}
                                                                    </>
                                                                )}
                                                                <button
                                                                    type="button" title="Change Status"
                                                                    className="btn btn-primary btn-sm"
                                                                    onClick={() => aboutHandler(item.id)} 
                                                                >
                                                                    <i className="fa fa-eye fs-16"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                )
                                        )}
                                    </tbody>
                                </table>
                                {lectures.length > 0 && (
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        pageCount={Math.ceil(count / filters.limit)}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ApproveClassPopup getLectures={getLectures} setAboutForm={setAboutForm} aboutForm={aboutForm} lectureId={lectureId} setLectureId={setLectureId} isMain={0} />
            <ToastContainer />
        </>
    );
};

export default RevisedClass;
