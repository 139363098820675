import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import url from '../../../constants/url';

const Headerinner = () => {
    const [item, totalItem] = useState(0);
    const [openList, setOpenList] = useState(false);
    const [openList1, setOpenList1] = useState(false);
    const [UserProfile, setUserProfile] = useState(false);
    const [UserProfileType, setUserProfileType] = useState('');

    useEffect(() => {
        GetUserDetail()
        GetCartData()
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, [])

    const GetCartData = async () => {
        const token = JSON.parse(localStorage.getItem("common-user-info"))?.auth_token;

        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
        };

        fetch(`${url.apiUrl}get-cart`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                if (result.status === "success") {
                    totalItem(result.data.length);
                }
            })
            .catch(error => console.log('error', error));
    }
    const GetUserDetail = async () => {
        var data = localStorage.getItem('common-user-info');
        if (data !== null) {
            const userData = JSON.parse(data);
            if (userData.id !== 0 && userData.id !== null) {
                setUserProfile(true);
                if (userData.type === "institute") {
                    setUserProfileType('/institute-dashboard');
                } else if (userData.type === "teacher") {
                    setUserProfileType('/teacher-dashboard');
                } else {
                    setUserProfileType('/student-dashboard');
                }
            }
        } else {
            setUserProfile(false);
        }
    }

    const DashboardHandler = () => {
        setOpenList(!openList);
    };
    const DashboardHandler1 = () => {
        setOpenList1(!openList1);
    };
    const handleLogout = () => {
        localStorage.clear();
        window.location.replace("/");
    };

    const isSticky = (e) => {
        const header = document.querySelector(".header-main1");
        const scrollTop = window.scrollY;
        scrollTop >= 250
            ? header?.classList.add("is-sticky")
            : header?.classList.remove("is-sticky");
    };

    const [isActive, setActive] = useState("false");
    const ToggleClass = () => {
        setActive(!isActive);
    };
    return (
        <>
            <div className="header-main">
                {/* <div className="top-bar top-bar bg-primary">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="top-bar-start">
                                    <ul className="socials">
                                        <li>
                                            <a className="social-icon " target="_blank" href="https://www.facebook.com/profile.php?id=100090317913078&mibextid=LQQJ4d">
                                                <i className="fe fe-facebook" />
                                            </a>
                                        </li>
                                        <li>
                                            <a className="social-icon " target="_blank" href="https://wa.me/+919730063010">
                                                <i className="fa-brands fa-whatsapp"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="social-icon " target="_blank" href="https://www.linkedin.com/company/education-mandal/">
                                                <i className="fe fe-linkedin" />
                                            </a>
                                        </li>
                                        <li>
                                            <a className="social-icon " target="_blank" href="https://instagram.com/education_mandal?igshid=YmMyMTA2M2Y=">
                                                <i className="fe fe-instagram" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="top-bar-end">
                                    <ul className="custom">
                                        <li>
                                            <Link to="/cart" >
                                                <i className="fe fe-shopping-cart me-1" />
                                                {item > 0 && <span className="badge badge-danger text-white">{item}</span>}
                                            </Link>
                                        </li>
                                        {UserProfile === false &&
                                            <li className="dropdown">
                                                <Link to="/registration"
                                                    
                                                    onClick={DashboardHandler1}
                                                    title="Registration"
                                                >
                                                    <i
                                                        className="fe fe-user me-1"
                                                        style={{ paddingRight: 3.5 }}
                                                    />
                                                    <span>
                                                        Registration

                                                    </span>
                                                </Link>
                                            </li>
                                        }
                                        {UserProfile === false &&
                                            <li>
                                                <Link to="/login" target="_blank" rel="noreferrer"  title="Login">
                                                    <i className="fe fe-log-in me-1" /> <span>Login</span>
                                                </Link>
                                            </li>
                                        }
                                        {UserProfile === true &&
                                            <li className="dropdown">
                                                <>
                                                    <Link
                                                        to="#"
                                                        className={
                                                            openList ? " show" : ""
                                                        }
                                                        aria-expanded={openList ? "true" : "false"}
                                                        onClick={DashboardHandler}
                                                    >
                                                        <i className="fe fe-home me-1" />
                                                        <span>
                                                            My Dashboard
                                                            <i className="fe fe-chevron-down ms-1" />
                                                        </span>
                                                    </Link>
                                                    {openList ? (
                                                        <div
                                                            className={
                                                                openList
                                                                    ? "dropdown-menu dropdown-menu-end dropdown-menu-arrow show"
                                                                    : "dropdown-menu dropdown-menu-end dropdown-menu-arrow"
                                                            }
                                                        >
                                                            <Link to={`${UserProfileType}`}
                                                                className="dropdown-item"
                                                            > <i className="dropdown-icon fa-solid fa-user " /> My Profile
                                                            </Link>
                                                            <Link
                                                                to="#"
                                                                className="dropdown-item border-top"
                                                                onClick={handleLogout}
                                                            ><i className="dropdown-icon fa-solid fa-power-off"></i>  Log out
                                                            </Link>
                                                        </div>
                                                    ) : null}
                                                </>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div id="sticky-wrapper" className="sticky-wrapper header-main1">
                    <div className="sticky">
                        <div className="horizontal-header clearfix">
                            <div className="container">
                                <Link to="#"
                                    id="horizontal-navtoggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    className="animated-arrow"
                                    onClick={ToggleClass}
                                >
                                    <span />
                                </Link>
                                <div className={isActive ? "dropdown-menu dropdown-menu-end dropdown-menu-arrow " : "dropdown-menu dropdown-menu-end dropdown-menu-arrow education-menu-open"} >
                                    <div className="d-flex mt-auto menu-logo-education align-items-center">
                                        <span className="smllogo">
                                            <img src="/assets/images/EduM_White_New.png" alt="img" />
                                        </span>
                                        <Link to="#" className="animated-arrow1" onClick={ToggleClass}>
                                            <i className="fe fe-x" aria-hidden="true"></i>
                                        </Link>
                                    </div>
                                    <ul className="horizontalMenu-list">
                                        <li aria-haspopup="true">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li aria-haspopup="true">
                                            <Link to="/about-us">About us </Link>
                                        </li>
                                        <li aria-haspopup="true">
                                            <Link to="/explore">Explore</Link>
                                        </li>
                                        <li aria-haspopup="true">
                                            <Link to="/services">Services</Link>
                                        </li>
                                        <li aria-haspopup="true">
                                            <Link to="/blog">Blog</Link>
                                        </li>
                                        <li aria-haspopup="true">
                                            <Link to="/contact-us">Contact us</Link>
                                        </li>
                                        <li aria-haspopup="true">
                                            <Link to="/work-with-us">Work with us</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <Link to="/">
                                    <span className="smllogo">
                                        <img src="/assets/images/EduM_White_New.png" alt="logo" />
                                    </span>
                                </Link>
                                <a href="tel:+91 97300 63010" className="callus-btn">
                                    <i className="fe fe-phone" aria-hidden="true" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <Navbar />
                </div>
            </div>
        </>
    );
};

export default Headerinner;
