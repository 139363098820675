import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import url from "../../../../../constants/url";
import axios from "axios";

const PurchaseItems = () => {

    const { student_id } = useParams();
    const { payment_id } = useParams();
    const [list, setList] = useState([]);
    const [count, setCount] = useState();
    const [total, setTotal] = useState({
        "mrp": 0,
        "discount": 0,
        "after_discount_price": 0,
        "gst": 0,
        "total_price": 0,
    });
    const [offset, setOffset] = useState(0);
    const [search, setSearch] = useState({
        start: 0,
        perPage: 10,
        payment_id: payment_id,
        student_id: student_id
    });

    var localdata = localStorage.getItem("common-user-info");
    var jsondata = JSON.parse(localdata);
    var token_authorization = jsondata.auth_token;

    const apiCall = async (activity) => {
        const config = {
            headers: {
                content: "application/json",
                AUTHTOKEN: token_authorization,
            },
        };
        try {
            const response = await axios.post(
                `${url.apiUrl}teacher-student/student-order-items`,
                activity,
                config
            );
            if (response) {
                setList(response.data?.data);
                setCount(response.data?.count / 10);
                var mrp = 0, discount = 0, after_discount_price = 0, gst = 0, total_price = 0;
                response.data?.data.map((item) => {
                    if (item?.course_type == "class_course") {
                        mrp = mrp + Number(item?.class_course.regular_price ? item?.class_course?.regular_price : 0);
                    } else {
                        mrp = mrp + Number(item?.online_course.regular_price ? item?.online_course?.regular_price : 0);
                    }
                    discount = discount + Number((item?.discount) ? item?.discount : 0);
                    after_discount_price = total_price + Number((item?.total_price) ? item?.total_price : 0);
                    gst = gst + Number((item?.gst) ? item?.gst : 0);
                    total_price = total_price + Number((item?.total_payable_price) ? item?.total_payable_price : 0);
                    setTotal({
                        "mrp": mrp,
                        "discount": discount,
                        "after_discount_price": after_discount_price,
                        "gst": gst,
                        "total_price": total_price,
                    });
                });
            }
        } catch (err) {
            throw err;
        }
    };

    const GetAllStudentsHistory = async () => {
        const activity = {
            student_id: student_id,
            payment_id: payment_id,
            limit: search.perPage,
            offset: search.start,
        };

        await apiCall(activity);
    };
    const handlePageClick = async (data) => {
        let currentPage = data.selected * search.perPage;
        setOffset(currentPage);
        const activity = {
            student_id: student_id,
            payment_id: payment_id,
            limit: search.perPage,
            offset: currentPage,
        };
        await apiCall(activity);
    };

    useEffect(() => {
        GetAllStudentsHistory();
    }, []);

    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/teacher-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="/teacher-dashboard/student">Students</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="/teacher-dashboard/student-purchase-history">Purchase History</Link>
                    </li>
                    <li className="breadcrumb-item active">Student Purchase Items</li>
                </ol>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header ">
                            <h4 className="card-title">Student Purchase Items </h4>
                        </div>
                        <div className="card-body">
                            <div
                                id="basic-1_wrapper"
                                className="dataTables_wrapper no-footer"
                            >
                                <table className="table table-bordered table-hover dataTable table-responsive-xl">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Sr. No</th>
                                            <th>Item Details</th>
                                            <th className="text-center">Item Type </th>
                                            <th className="text-center">MRP </th>
                                            <th className="text-center">Discount </th>
                                            <th className="text-center">After Discount Price </th>
                                            <th className="text-center">GST </th>
                                            <th className="text-center">Purchase Price </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list?.length > 0 ?
                                            list.map((item, i) => (
                                                <tr key={i}>
                                                    <th className="text-center">{i + offset + 1}</th>
                                                    <td>
                                                        {item?.course_type == "class_course" ? (
                                                            <div className="media">
                                                                <img
                                                                    src={item?.class_course?.thumbnail}
                                                                    className="me-3 rounded-circle"
                                                                />
                                                                <div className="media-body">
                                                                    <Link className="font-weight-semibold"
                                                                        to={`/teacher-dashboard/class-detail/1/${item?.class_course?.id}`}
                                                                    >
                                                                        {item?.class_course?.title}
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="media">
                                                                <img
                                                                    src={item?.online_course?.teaser_thumb_image}
                                                                    className="me-3 rounded-circle"
                                                                />
                                                                <div className="media-body">
                                                                    <Link className="font-weight-semibold"
                                                                        to={`/teacher-dashboard/video-course-detail/1/${item?.online_course?.id}`}
                                                                    >
                                                                        {item?.online_course?.title}
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td className="text-center">{item?.course_type == "class_course" ? "Classroom Course" : "Video Courses"}</td>
                                                    <td className="text-center">₹ {item?.course_type == "class_course" ? item?.class_course.regular_price : item?.online_course.regular_price}</td>
                                                    <td className="text-center">₹ {Math.round(item?.discount)}</td>
                                                    <td className="text-center">₹ {Math.round(item?.total_price)}</td>
                                                    <td className="text-center">₹ {Math.round(item?.gst)}</td>
                                                    <td className="text-center">₹ {Math.round(item?.total_payable_price)}</td>
                                                </tr>
                                            )) : (
                                                <tr><td className="text-center" colSpan={8}>No Data Available !</td></tr>
                                            )}
                                        <tr>
                                            <td colSpan="3" className="font-weight-semibold text-end">Total :</td>
                                            <td className="font-weight-semibold text-center">₹ {Math.round(total?.mrp)}</td>
                                            <td className="font-weight-semibold text-center">₹ {Math.round(total?.discount)}</td>
                                            <td className="font-weight-semibold text-center">₹ {Math.round(total?.after_discount_price)}</td>
                                            <td className="font-weight-semibold text-center">₹ {Math.round(total?.gst)}</td>
                                            <td className="font-weight-semibold text-center">₹ {Math.round(total?.total_price)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PurchaseItems;
