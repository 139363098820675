import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Popup from "../../../common/Popup";
import ReactPaginate from "react-paginate";
import Tab from "react-bootstrap/Tab";
import Moment from "react-moment";
import Tabs from "react-bootstrap/Tabs";
import utils, { calculateExperience } from "../../../../utils/utils";
import NoDataAvailable from "../../../common/NoDataAvailable"
let rating = [1, 2, 3, 4, 5];
const VideoCourseRight = (props) => {
    const [openPopup, setOpenPopup] = useState({});
    const [active, setActive] = useState({});

    const [reviewdata, setReviewDatas] = useState(props.reviewData);
    const [data, setCourseDataDetails] = useState(props.data);
    useEffect(() => {
        if (props.data && props.data) setCourseDataDetails(props.data);
    }, [props.data]);

    useEffect(() => {
        if (props.reviewData && props.reviewData) setReviewDatas(props.reviewData);
    }, [props.reviewData]);

    const activeHandler = (id) => {
        setActive((prev) => ({ [id]: !prev[id] }));
    };

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Short Description </h4>
                </div>
                <div className="card-body">
                    <p className="mb-0">{data?.short_discription}</p>
                </div>
                <div className="card-body">
                    <div className="row ">
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="card mb-0 border-0 shadow-none">
                                <div className="d-flex pb-0">
                                    <img src={data?.teacher?.image} className="border-radius-50 d-md-flex avatar-lg me-3" alt={data?.teacher?.name} />
                                    <div>
                                        <span className="icons fs-16 ">Teacher</span>
                                        <Link to={`/teacher-detail/${data?.teacher?.teacher_id}`} className="icons h4 ">
                                            <span className="h4 d-block">{data?.teacher?.name}</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="card mb-0 border-0 shadow-none">
                                <div className="pb-0">
                                    <div>
                                        <span className="icons fs-16 ">Category</span>
                                        <span className="d-block h4">{data?.category?.title}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="card mb-0 border-0 shadow-none">
                                <span className="icons fs-16 ">Reviews</span>
                                <span className="d-inline-flex">
                                    <div className="br-widget">
                                        {rating.map((el, i) => (
                                            <i
                                                data-rating-value={el}
                                                data-rating-text={el}
                                                className={
                                                    el <= data?.average_review
                                                        ? "br-selected"
                                                        : ""
                                                }
                                                key={i}
                                            />
                                        ))}
                                    </div>
                                    <span>({data?.review_count} Reviews)</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <Tabs defaultActiveKey="overview" id="uncontrolled-tab-example">
                    <Tab eventKey="overview" title="overview">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Course Description</h4>
                            </div>
                            <div className="card-body education-mandal"
                                dangerouslySetInnerHTML={{ __html: data?.course_discription }}
                            >
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">What will students learn in this course?</h4>
                            </div>
                            <div className="card-body education-mandal"
                                dangerouslySetInnerHTML={{
                                    __html: data?.student_learn_in_cutse,
                                }}
                            >
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Requirements</h4>
                            </div>
                            <div className="card-body education-mandal"
                                dangerouslySetInnerHTML={{ __html: data?.requirements }}
                            >
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="curriculum" title="curriculum">
                        <div className="accordion" id="accordionExample">
                            {data?.section.length > 0 ? (
                                data?.section.map((item, i) => (
                                    <div className="accordion-item mb-3" key={i}>
                                        <h2 className="accordion-header" id="headingOne">
                                            <button
                                                className={
                                                    active[i]
                                                        ? "accordion-button"
                                                        : "accordion-button collapsed"
                                                }
                                                type="button"
                                                onClick={() => activeHandler(i)}
                                            >
                                                Chapter {i + 1} : {item?.title}
                                            </button>
                                        </h2>
                                        {active[i] && (
                                            <div
                                                id="collapseOne"
                                                className={
                                                    active[i]
                                                        ? "accordion-collapse collapse show"
                                                        : "accordion-collapse collapse"
                                                }
                                                data-bs-parent="#accordionExample"
                                            >
                                                <div className="accordion-body">
                                                    <div className="align-items-center">
                                                        {item?.lectures?.length > 0 ? (
                                                            item?.lectures.map((item, i) => (
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between mb-4"
                                                                    key={i}
                                                                >
                                                                    <div className="d-flex align-items-center">
                                                                        <i className="fa-solid fa-video"></i>
                                                                        <div className="ms-3">{item?.title}</div>
                                                                    </div>

                                                                    <div className="d-flex align-items-center">
                                                                        {item?.is_preview === 1 && (
                                                                            <Link
                                                                                className="text-danger d-flex align-items-center me-5"
                                                                                to="#"
                                                                                onClick={() => {
                                                                                    setOpenPopup({ [i]: true });
                                                                                }}
                                                                            >
                                                                                <i className="fa-solid fa-play me-1"></i>preview
                                                                            </Link>
                                                                        )}

                                                                        <div className="badge btn-primary width-55px">
                                                                            {utils.toHHMMSS(
                                                                                item?.video_duration
                                                                            )}
                                                                        </div>

                                                                        {openPopup[i] && (
                                                                            <Popup
                                                                                className="videoIntor"
                                                                                title={item?.title}
                                                                                data={item?.preview_video}
                                                                                openPopup={openPopup}
                                                                                setOpenPopup={setOpenPopup}
                                                                                id={i}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <NoDataAvailable />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <NoDataAvailable />
                            )}
                        </div>
                    </Tab>
                    <Tab eventKey="teacher" title="Teacher">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                <Link to={`/teacher-detail/${data?.teacher?.teacher_id}`}>
                                    <div className="card">
                                        <div className="card-body text-center">
                                            <div className="item-card7-img">
                                                <img
                                                    src={data?.teacher?.image}
                                                    className="cover-image teacher-student-image"
                                                    alt={data?.teacher.name} dfffvff
                                                />
                                            </div>
                                            <h4 className="mt-4 mb-2 leading-tight">
                                                {data?.teacher.name}
                                            </h4>
                                            <h6 className="team-section badge">
                                                {data?.teacher?.total_experiance !== 0 ? calculateExperience(data?.teacher?.total_experiance) : "---"} Experience
                                            </h6>
                                            <div className="d-flex justify-content-center">
                                                <div className="br-widget">
                                                    {rating.map((el, i) => (
                                                        <i data-rating-value={el}
                                                            data-rating-text={el}
                                                            className={
                                                                el <= data?.teacher?.average_review
                                                                    ? "br-selected"
                                                                    : ""
                                                            }
                                                            key={i}
                                                        />
                                                    ))}
                                                </div>
                                                <span>({data?.teacher?.review_count} Reviews)</span>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="reviews" title="reviews">
                        {reviewdata?.length > 0 ? (
                            reviewdata.map((item, i) => (
                                <div
                                    key={i}
                                    className="media mt-0 mb-3 p-3 border br-5 review-media"
                                >
                                    <div className="d-flex me-3">
                                        <img
                                            className="border-radius-50 avatar-lg"
                                            alt={item?.name}
                                            src={item?.student?.image}
                                        />
                                    </div>
                                    <div className="media-body">
                                        <h4 className="mt-0 mb-0">{item?.name}
                                            <small className="text-muted float-end">
                                                <Moment format="DD-MM-YYYY hh:mm A">
                                                    {item?.created_at}
                                                </Moment>
                                            </small>
                                        </h4>
                                        <span className="d-inline-flex">
                                            <div className="br-widget">
                                                {rating.map((el, i) => (
                                                    <i
                                                        data-rating-value={el}
                                                        data-rating-text={el}
                                                        className={
                                                            el <= item?.average_review
                                                                ? "br-selected"
                                                                : ""
                                                        }
                                                        key={i}
                                                    />
                                                ))}
                                            </div>
                                            <span>( {item?.average_review} Stars )</span>
                                        </span>
                                        <p className="mb-2 mt-2 education-mandal" dangerouslySetInnerHTML={{ __html: item?.comment }}>
                                        </p>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <NoDataAvailable />
                        )}
                        {reviewdata?.length > 0 && (
                            <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                pageCount={Math.ceil(reviewdata?.length / 10)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={props.handlePageClick}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        )}
                    </Tab>
                </Tabs >
            </div >

        </>
    );
};

export default VideoCourseRight;
