import React, { useEffect, useState } from "react";
import Headerinner from "../../Header/Headerinner";
import Footer from "../../Footer/Footer";
import Curve from '../../Header/Curve';
import moment from "moment";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { useParams } from "react-router-dom";
import url from "../../../../constants/url";
import { useHistory, Link } from "react-router-dom";
import utils, { calculateExperience, replaceWord } from "../../../../utils/utils";
import ReactPaginate from "react-paginate";
import NoDataAvailable from "../../../common/NoDataAvailable";
let rating = [1, 2, 3, 4, 5];

function InstituteProfile() {
    let now = new Date().setHours(0, 0, 0, 0);
    const history = useHistory();
    const [active, setActive] = useState("0");
    const param = useParams();

    const [data, setData] = useState({});
    const GetInstituteDetail = async (e) => {
        var requestOptions = {
            method: "GET",
        };

        fetch(`${url.apiUrl}institute-detail/${param.id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setData(result.data);
                } else {
                    history.push('/404');
                }
            })
            .catch((error) => console.log("error", error));
    };
    useEffect(() => {
        GetInstituteDetail();
    }, []);



    const [courseData, setCourseData] = useState([]);
    const [offset, setOffset] = useState(0);
    const [totalPage, setTotalPages] = useState(0);
    const [search, setSearch] = useState({
        start: 0,
        perPage: 9,
    });
    const InstituteCourseList = (course_type, limit, offset) => {
        var requestOptions = {
            method: "GET",
        };
        fetch(`${url.apiUrl}institute-course-list/${param.id}/${course_type}/${limit}/${offset}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    if (course_type == "online_course") {
                        setCourseData(result.data);
                        setTotalPages(result.count / search.perPage);
                    } else if (course_type == "class_course") {
                        setClassData(result.data);
                        setTotalClassPages(result.count / classSearch.perPage);

                    }
                }
            })
            .catch((error) => console.log("error", error));
    }

    useEffect(() => {
        InstituteCourseList('online_course', search.perPage, search.start);
    }, [search]);
    const handlePageClick = async (data) => {
        let currentPage = data.selected * search.perPage;
        setOffset(currentPage);
        search.start = currentPage
        InstituteCourseList('online_course', search.perPage, search.start);
    };



    const [classData, setClassData] = useState([]);
    const [classOffset, setClassOffset] = useState(0);
    const [totalClassPage, setTotalClassPages] = useState(0);
    const [classSearch, setClassSearch] = useState({
        start: 0,
        perPage: 9,

    });
    useEffect(() => {
        InstituteCourseList('class_course', classSearch.perPage, classSearch.start);
    }, [classSearch]);

    const handleClassPageClick = async (data) => {
        let currentPage = data.selected * classSearch.perPage;
        setClassOffset(currentPage);
        classSearch.start = currentPage
        InstituteCourseList('class_course', classSearch.perPage, currentPage);
    };



    const [careerData, setCareerData] = useState([]);
    const [careeroffset, setCareerOffset] = useState(0);
    const [totalCareerPage, setTotalCareerPages] = useState(0);
    const [careerSearch, setCareerSearch] = useState({
        start: 0,
        perPage: 9,

    });
    const InstituteCareerList = (limit, careeroffset) => {
        var requestOptions = {
            method: "GET",
        };
        fetch(`${url.apiUrl}institute-career-list/${param.id}/${limit}/${careeroffset}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setCareerData(result.data);
                    setTotalCareerPages(result.count / careerSearch.perPage);
                } else {
                    history.push('/404');
                }
            })
            .catch((error) => console.log("error", error));
    }
    useEffect(() => {
        InstituteCareerList(careerSearch.perPage, careerSearch.start);
    }, [careerSearch]);
    const handlePageCareerClick = async (data) => {
        let currentPage = data.selected * careerSearch.perPage;
        setCareerOffset(currentPage);
        careerSearch.start = currentPage
        InstituteCareerList(careerSearch.perPage, careerSearch.start);
    };

    return (
        <>
            <Headerinner />
            <div
                className="cover-image bg-background-1"
                data-bs-image-src="/assets/images/banners/banner1.webp"
                style={{
                    background: 'url("/assets/images/banners/banner1.webp") center center',
                }}
            >
                <div className="container header-text">
                    <h1>{data?.name}</h1>
                </div>
            </div>
            <Curve />
            <section className="sptb">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="wideget-user-desc">
                                        <div className="item-card7-img">
                                            <img
                                                className="cover-image"
                                                src={data?.image}
                                                alt={data?.name}
                                            />
                                        </div>
                                        <div className="user-wrap wideget-user-info">
                                            <h4>
                                                {data?.name}
                                            </h4>
                                            <p className="text-center"> Year of Incorporation - <strong>{data?.year_of_incap ? data?.year_of_incap : "---"}</strong> </p>
                                            <div className="d-flex justify-content-center">
                                                <div className="br-widget">
                                                    {rating.map((el, i) => (
                                                        <i
                                                            data-rating-value={el}
                                                            data-rating-text={el}
                                                            className={
                                                                el <= data?.average_review
                                                                    ? "br-selected"
                                                                    : ""
                                                            }
                                                            key={i}
                                                        />
                                                    ))}
                                                </div>
                                                <span>({data?.review_count} Reviews) </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-9 col-lg-8 col-md-12">
                            <div className="card">
                                <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example">
                                    <Tab eventKey="profile" title="Profile">
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">Institute Details</h4>
                                            </div>
                                            <div className="card-body">
                                                <p>
                                                    <span className="font-weight-semibold">Institute Name : </span>
                                                    <span className="user-1">{data?.name}</span>
                                                </p>
                                                <p>
                                                    <span className="font-weight-semibold">Contact Person Name : </span>
                                                    <span className="user-1">{data?.contact_person_name}</span>
                                                </p>
                                                <p>
                                                    <span className="font-weight-semibold">Address : </span>
                                                    <span className="user-1">{data?.address}, {data?.cities?.name}, {data?.states?.name} - {data?.pincode}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">About the Institute</h4>
                                            </div>
                                            <div className="card-body education-mandal" dangerouslySetInnerHTML={{ __html: data?.about }}>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="branch" title="Branch list">
                                        <div className="row">
                                            {data?.institute_branch?.length > 0 ? (
                                                data?.institute_branch.map((item, i) => (
                                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12" key={i}>
                                                        <Link to={`/branch-detail/${item?.branch_id}`}>
                                                            <div className="card">
                                                                <div className="card-body"  >
                                                                    <div className="text-center">
                                                                        <div className="item-card7-img">
                                                                            <img src={item?.image} className="cover-image" alt={item?.name} />
                                                                        </div>
                                                                        <h4 className="mt-4 mb-2 leading-tight2">
                                                                            {item?.name}
                                                                        </h4>
                                                                        <p className="text-center mb-0">
                                                                            Year of Incorporation - <strong>{item?.year_of_startup ? item?.year_of_startup : "---"}</strong>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                ))
                                            ) : (
                                                <NoDataAvailable />
                                            )}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="class" title="Classroom Course">
                                        <div className="row">
                                            {classData?.length > 0 ? (
                                                classData.map((item, i) => (
                                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12" key={i}>
                                                        <Link to={`/classroom-course-detail/${item?.id}`}>
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className="item-card7-img">
                                                                        <img src={item?.thumbnail} alt={item?.title} className="cover-image" />
                                                                        {(
                                                                            item?.price == "paid" && (
                                                                                item?.is_discount == 1 && (
                                                                                    (item?.is_limited_offer == 1 && (new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                                    (item?.is_limited_offer == 0 && new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                                            )
                                                                        ) && (
                                                                                <Link to="#" className="bg-danger top left">
                                                                                    {item?.discount_price}% Off
                                                                                </Link>
                                                                            )}
                                                                        <Link to="#" className="bg-danger top right">
                                                                            Classroom Course
                                                                        </Link>
                                                                        {item?.class_type === "school" ? (
                                                                            <Link to="#" className="bg-danger right bottom">
                                                                                {item?.board}
                                                                            </Link>
                                                                        ) : (item?.class_type === "university" ? (
                                                                            <Link to="#" className="bg-danger right bottom">
                                                                                {item?.universities?.name}
                                                                            </Link>
                                                                        ) : (item?.class_type === "tuition" && (
                                                                            <>
                                                                                {item?.tuition_type === "school" ? (
                                                                                    <Link to="#" className="bg-danger right bottom">
                                                                                        {item?.board}
                                                                                    </Link>
                                                                                ) : (item?.tuition_type === "university" && (
                                                                                    <Link to="#" className="bg-danger right bottom">
                                                                                        {item?.universities?.name}
                                                                                    </Link>
                                                                                ))}
                                                                            </>
                                                                        )))}
                                                                    </div>
                                                                    <div className="item-card2-desc">
                                                                        <div className="d-inline-flex mb-2">
                                                                            <div className="br-widget">
                                                                                {rating.map((el, i) => (
                                                                                    <i
                                                                                        data-rating-value={el}
                                                                                        data-rating-text={el}
                                                                                        className={
                                                                                            el <= item?.average_review
                                                                                                ? "br-selected"
                                                                                                : ""
                                                                                        }
                                                                                        key={i}
                                                                                    />
                                                                                ))}
                                                                            </div>
                                                                            <span>({item?.review_count} Reviews)</span>
                                                                        </div>
                                                                        <h4 className="mb-2 leading-tight2">
                                                                            {item?.title}
                                                                        </h4>
                                                                        <p className="leading-tight3 education-mandal" dangerouslySetInnerHTML={{ __html: item?.description }}>
                                                                        </p>
                                                                        <div className="pt-2 pb-2 border-bottom d-flex justify-content-between">
                                                                            <div className="d-flex justify-content-start">
                                                                                <i className="fa fa-shop me-2" />
                                                                                <span className="text-muted text-data-over-cut">
                                                                                    {item?.institute?.name}
                                                                                </span>
                                                                            </div>
                                                                            <div className="float-end d-flex justify-content-start">
                                                                                <i className="fa fa-building me-2" />
                                                                                <span className="text-muted text-capitalize text-data-over-cut">
                                                                                    {replaceWord(item?.class_type)}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="pt-2 d-flex justify-content-between">
                                                                            <div className="d-flex justify-content-start">
                                                                                <i className="fa fa-file me-2" />
                                                                                <span className="text-muted text-data-over-cut">
                                                                                    {replaceWord(item?.lecture_type)}
                                                                                </span>
                                                                            </div>
                                                                            <div className="float-end">
                                                                                {item?.price === "paid" && (
                                                                                    <h4 className="mb-0"> ₹ {Math.round(item?.payable_price)} {(
                                                                                        item?.price == "paid" && (
                                                                                            item?.is_discount == 1 && (
                                                                                                (item?.is_limited_offer == 1 && (new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                                                (item?.is_limited_offer == 0 && new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                                                        )
                                                                                    ) && (<del className="fs-14 text-muted"> ₹ {Math.round(item?.regular_price)} </del>
                                                                                        )}
                                                                                    </h4>
                                                                                )}
                                                                                {item?.price === "free" && (
                                                                                    <h4 className="mb-0">Free</h4>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                ))
                                            ) : (
                                                <NoDataAvailable />
                                            )}
                                            {classData?.length > 0 && (
                                                <ReactPaginate
                                                    previousLabel={"Previous"}
                                                    nextLabel={"Next"}
                                                    breakLabel={"..."}
                                                    pageCount={Math.ceil(totalClassPage)}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={handleClassPageClick}
                                                    containerClassName={"pagination"}
                                                    pageClassName={"page-item"}
                                                    pageLinkClassName={"page-link"}
                                                    previousClassName={"page-item"}
                                                    previousLinkClassName={"page-link"}
                                                    nextClassName={"page-item"}
                                                    nextLinkClassName={"page-link"}
                                                    breakClassName={"page-item"}
                                                    breakLinkClassName={"page-link"}
                                                    activeClassName={"active"}
                                                />
                                            )}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="course" title="Video Course">
                                        <div className="row">
                                            {courseData?.length > 0 ? (
                                                courseData.map((item, i) => (
                                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12" key={i}>
                                                        <Link to={`/video-course-detail/${item?.id}`}>
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className="item-card7-img">
                                                                        <img
                                                                            src={item?.teaser_thumb_image}
                                                                            className="cover-image"
                                                                            alt={item?.title}
                                                                        />
                                                                        {(
                                                                            item?.price == "paid" && (
                                                                                item?.is_discount == 1 && (
                                                                                    (item?.is_limited_offer == 1 && (new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                                    (item?.is_limited_offer == 0 && new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                                            )
                                                                        ) && (
                                                                                <Link to="#" className="bg-danger top left">
                                                                                    {item?.discount_price}% off
                                                                                </Link>
                                                                            )}

                                                                        <Link to="#" className="bg-danger right top">
                                                                            Video Course
                                                                        </Link>
                                                                        {item?.is_professional === 0 && (
                                                                            <Link to="#" className="bg-danger right bottom">
                                                                                {item?.board}
                                                                            </Link>
                                                                        )}
                                                                    </div>
                                                                    <div className="item-card2-desc">
                                                                        <div className="d-inline-flex mb-2">
                                                                            <div className="br-widget">
                                                                                {rating.map((el, i) => (
                                                                                    <i
                                                                                        data-rating-value={el}
                                                                                        data-rating-text={el}
                                                                                        className={
                                                                                            el <= item?.average_review
                                                                                                ? "br-selected"
                                                                                                : ""
                                                                                        }
                                                                                        key={i}
                                                                                    />
                                                                                ))}
                                                                            </div>
                                                                            <span>({item?.review_count} Reviews)</span>
                                                                        </div>
                                                                        <h4 className="mb-2 leading-tight2">
                                                                            {item?.title}
                                                                        </h4>
                                                                        <p className="leading-tight3 education-mandal">
                                                                            {item?.short_discription}
                                                                        </p>
                                                                        <div className="pt-2 pb-2 border-bottom d-flex justify-content-between">
                                                                            <div className="d-flex justify-content-start">
                                                                                <i className="fa fa-shop me-2" />
                                                                                <span className="text-muted text-data-over-cut">
                                                                                    {item?.institute?.name}
                                                                                </span>
                                                                            </div>
                                                                            <div className="float-end">
                                                                                <i className="fa fa-signal me-2" />
                                                                                <span className="text-muted text-capitalize">
                                                                                    {item?.course_level}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="pt-2 pb-2 border-bottom">
                                                                            <i className="fa fa-video me-2" />
                                                                            <span className="text-muted">
                                                                                {item?.lectures_count} Videos
                                                                            </span>
                                                                            <div className="float-end ">
                                                                                <i className="fa fa-clock-o me-2" />
                                                                                <span className="text-muted">
                                                                                    {item?.total_duretion ? utils.toHHMMSS(item?.total_duretion) : 0} Hours
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="pt-2 d-flex justify-content-between">
                                                                            <div className="d-flex justify-content-start">
                                                                                <i className="fa fa-microphone me-2" />
                                                                                <span className="text-muted text-data-over-cut">
                                                                                    {item?.audio_language}
                                                                                </span>
                                                                            </div>
                                                                            <div className="float-end ">
                                                                                {item?.price === "paid" && (
                                                                                    <h4 className="mb-0">
                                                                                        ₹ {Math.round(item?.payable_price)} {(
                                                                                            item?.price == "paid" && (
                                                                                                item?.is_discount == 1 && (
                                                                                                    (item?.is_limited_offer == 1 && (new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                                                    (item?.is_limited_offer == 0 && new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                                                            )
                                                                                        ) && (
                                                                                                <del className="fs-14 text-muted">
                                                                                                    ₹ {Math.round(item?.regular_price)}
                                                                                                </del>
                                                                                            )}
                                                                                    </h4>
                                                                                )}
                                                                                {item?.price === "free" && (
                                                                                    <h4 className="mb-0">Free</h4>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                ))

                                            ) : (
                                                <NoDataAvailable />
                                            )}
                                            {courseData?.length > 0 && (
                                                <ReactPaginate
                                                    previousLabel={"Previous"}
                                                    nextLabel={"Next"}
                                                    breakLabel={"..."}
                                                    pageCount={Math.ceil(totalPage)}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={handlePageClick}
                                                    containerClassName={"pagination"}
                                                    pageClassName={"page-item"}
                                                    pageLinkClassName={"page-link"}
                                                    previousClassName={"page-item"}
                                                    previousLinkClassName={"page-link"}
                                                    nextClassName={"page-item"}
                                                    nextLinkClassName={"page-link"}
                                                    breakClassName={"page-item"}
                                                    breakLinkClassName={"page-link"}
                                                    activeClassName={"active"}
                                                />
                                            )}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="teacher" title="Teacher list">
                                        <div className="row">
                                            {data?.institute_teacher?.length > 0 ? (
                                                data?.institute_teacher.map((item, i) => (
                                                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12" key={i}>
                                                        <Link to={`/teacher-detail/${item?.teacher_id}`}>
                                                            <div className="card">
                                                                <div className="card-body text-center" >
                                                                    <div className="item-card7-img">
                                                                        <img src={item?.image} className="cover-image teacher-student-image" alt={data?.name} />
                                                                    </div>
                                                                    <h4 className="mt-4 mb-2 leading-tight">
                                                                        {item?.name}
                                                                    </h4>
                                                                    <h6 className="team-section badge">
                                                                        {item?.total_experiance != 0 ? calculateExperience(item?.total_experiance) : "---"} Experience
                                                                    </h6>
                                                                    <div className="d-flex justify-content-center">
                                                                        <div className="br-widget">
                                                                            {rating.map((el, i) => (
                                                                                <i
                                                                                    data-rating-value={el}
                                                                                    data-rating-text={el}
                                                                                    className={
                                                                                        el <= item?.average_review
                                                                                            ? "br-selected"
                                                                                            : ""
                                                                                    }
                                                                                    key={i}
                                                                                />
                                                                            ))}
                                                                        </div>
                                                                        <span>({item?.review_count} Reviews)</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                ))
                                            ) : (
                                                <NoDataAvailable />
                                            )}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="career" title="Career List">
                                        <div className="row">
                                            {careerData?.length > 0 ? (
                                                careerData.map((item, i) => (
                                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12" key={i}>
                                                        <Link to={`/career-detail/${item?.slug}`} >
                                                            <div className="card">
                                                                <div className="card-body ">
                                                                    <div className="item-card7-img">
                                                                        <img className="cover-image" src={item?.thumb_image}
                                                                            alt={item?.title} />
                                                                        <Link to="#" className="bg-danger right top">
                                                                            {item?.employee_type}
                                                                        </Link>
                                                                        <a className="bg-danger right bottom" href="/"> {replaceWord(item?.class_type)} {item?.position}</a>
                                                                        {item?.position === "other" && (
                                                                            <a className="bg-danger right bottom" href="/">  {item?.other_position}</a>
                                                                        )}
                                                                    </div>
                                                                    <div className="item-card2-desc">
                                                                        <h4 className="mb-2 leading-tight2">
                                                                            {item?.title}
                                                                        </h4>
                                                                        <p className="leading-tight3 education-mandal" dangerouslySetInnerHTML={{ __html: item?.description }}>
                                                                        </p>
                                                                        <div className="pt-2 pb-2 border-bottom d-flex justify-content-between">
                                                                            <div className="d-flex justify-content-start">
                                                                                <i className="fa fa-shop me-2" />
                                                                                <span className="text-muted text-data-over-cut">
                                                                                    {item?.institute_branch?.name ? item?.institute_branch?.name : "Education Mandal"}
                                                                                </span>
                                                                            </div>
                                                                            <div className="float-end d-flex justify-content-start">
                                                                                <i className="fa fa-signal me-2" />
                                                                                <span className="text-muted text-capitalize text-data-over-cut">
                                                                                    {item?.job_type}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="pt-2 pb-2 border-bottom">
                                                                            <i className="fa-solid fa-indian-rupee-sign me-2"></i>
                                                                            {item?.is_disclosed_salary === 1 ? (
                                                                                <span className="text-muted">
                                                                                    {item?.salary}
                                                                                </span>
                                                                            ) : (
                                                                                <span className="text-muted">Not Disclosed</span>
                                                                            )}
                                                                            <div className="float-end">
                                                                                <i className="fa fa-clock-o me-1" />
                                                                                <span className="text-muted text-capitalize">
                                                                                    {item?.experience}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="pt-2">
                                                                            <i className="fa-solid fa-location-dot me-2"></i>
                                                                            <span className="text-muted">
                                                                                {item?.location}, {item?.city}, {item?.state}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                ))

                                            ) : (
                                                <NoDataAvailable />
                                            )}
                                            {careerData?.length > 0 && (
                                                <ReactPaginate
                                                    previousLabel={"Previous"}
                                                    nextLabel={"Next"}
                                                    breakLabel={"..."}
                                                    pageCount={Math.ceil(totalCareerPage)}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={handlePageCareerClick}
                                                    containerClassName={"pagination"}
                                                    pageClassName={"page-item"}
                                                    pageLinkClassName={"page-link"}
                                                    previousClassName={"page-item"}
                                                    previousLinkClassName={"page-link"}
                                                    nextClassName={"page-item"}
                                                    nextLinkClassName={"page-link"}
                                                    breakClassName={"page-item"}
                                                    breakLinkClassName={"page-link"}
                                                    activeClassName={"active"}
                                                />
                                            )}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="review" title="reviews">
                                        {data?.review?.length > 0 ? (
                                            data?.review.map((item, i) => (
                                                <div
                                                    key={i}
                                                    className="media mt-0 mb-3 p-3 border br-5 review-media"
                                                >
                                                    <div className="d-flex me-3">
                                                        <Link to="#">
                                                            <img
                                                                className="border-radius-50 avatar-lg"
                                                                alt={item?.name}
                                                                src={item?.student?.image}
                                                            />
                                                        </Link>
                                                    </div>
                                                    <div className="media-body">
                                                        <h4 className="mt-0 mb-1 font-weight-semibold">
                                                            {item?.name}
                                                            <small className="text-muted float-end">
                                                                {moment(item?.created_at).format(
                                                                    "DD-MM-YYYY HH:MM A"
                                                                )}
                                                            </small>
                                                        </h4>
                                                        <div className="d-flex justify-content-start">
                                                            <div className="br-widget">
                                                                {rating.map((el, i) => (
                                                                    <i
                                                                        data-rating-value={el}
                                                                        data-rating-text={el}
                                                                        className={
                                                                            el <= item?.average_review
                                                                                ? "br-selected"
                                                                                : ""
                                                                        }
                                                                        key={i}
                                                                    />
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <p className="mb-2 mt-2 education-mandal" dangerouslySetInnerHTML={{ __html: item?.comment }}>
                                                        </p>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <NoDataAvailable />
                                        )}
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div >
                </div >
            </section >

            <Footer />
        </>
    );
}

export default InstituteProfile;
