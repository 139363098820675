import React, { useEffect, useState } from "react";
import url from "../../../../constants/url";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import axios from "axios";
import ApplicantsStatusPopup from "./ApplicantsStatusPopup";
import { ToastContainer } from "react-toastify";
import { replaceWord } from "../../../../utils/utils";

const ApplicantList = ({ params, status }) => {
    const [data, setData] = useState([]);
    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const [offset, setOffset] = useState(0);
    const [applicationId, setApplicationId] = useState(0);
    const auth_token = local_storage.auth_token;
    const [aboutForm, setAboutForm] = useState(false);
    const [search, setSearch] = useState({
        start: 0,
        perPage: 10,
        searchTxt: "",
        searchField: "",
    });
    const activity = {
        status: status,
        career_id: params.id,
        limit: search.perPage,
        offset: search.start,
        query: search.searchTxt,
    }
    const [aplicantCount, setAplicantCount] = useState();
    const getAllData = async () => {
        const activity = {
            status: status,
            career_id: params.id,
            limit: search.perPage,
            offset: search.start,
            query: search.searchTxt,
        };

        await apiCallForApplicant(activity);
    };

    const apiCallForApplicant = async (activity) => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        const config = {
            headers: {
                content: "application/json",
                AUTHTOKEN: auth_token,
            },
        };
        try {
            const response = await axios.post(
                `${url.apiUrl}institute/career-applications`,
                activity,
                config
            );
            if (response) {
                setData(response.data?.data);
                setAplicantCount(response.data?.count / 10);
            }
        } catch (err) {
            throw err;
        }
    };
    const handlePageClick = async (data) => {
        let currentPage = data.selected * search.perPage;
        setOffset(currentPage);
        const activity = {
            status: status,
            career_id: params.id,
            limit: search.perPage,
            offset: currentPage,
            query: search.searchTxt,
        };

        await apiCallForApplicant(activity);
    };

    useEffect(() => {
        getAllData();
    }, [search]);

    const aboutHandler = (id) => {
        setApplicationId(id);
        setAboutForm(true);
    };
    return (
        <div
            id="basic-1_wrapper"
            className="dataTables_wrapper no-footer"
        >
            <div id="basic-1_filter" className="dataTables_filter">
                <label>
                    Search:
                    <input
                        type="search"
                        placeholder="Search..."
                        onChange={(e) => {
                            setSearch({
                                ...search,
                                searchTxt: e.target.value,
                            });
                        }}
                        value={search.searchTxt}
                    />
                </label>
            </div>
            <table className="table table-bordered table-hover dataTable table-responsive-xl">
                <thead>
                    <tr>
                        <th className="text-center">Sr. No.</th>
                        <th>Applicant Details</th>
                        <th className="text-center text-nowrap">Contact No.</th>
                        <th className="text-center text-nowrap">Highest Qualication</th>
                        <th className="text-center text-nowrap">Experience</th>
                        <th className="text-center text-nowrap">Current - Expected CTC</th>
                        <th className="text-center">Notice Period</th>
                        <th className="text-center">Resume Download</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.length > 0 ? (
                        data.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <th className="text-center">
                                        {i + offset + 1}
                                    </th>
                                    <td>
                                        <div className="media">
                                            <img
                                                src={item?.image}
                                                alt={item?.name}
                                                className="me-3 rounded-circle"
                                            />
                                            <div className="media-body">
                                                <Link
                                                    className="font-weight-semibold"
                                                >
                                                    {item?.name}
                                                </Link>
                                                <p className="text-muted mb-0">
                                                    {item?.email}
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        {item?.contact}
                                    </td>
                                    <td className="text-center">
                                        {item?.qualification}
                                    </td>
                                    <td className="text-center">
                                        {item?.experience}
                                    </td>
                                    <td className="text-center">
                                        ₹ {item?.current_ctc} - ₹ {item?.expected_ctc}
                                    </td>
                                    <td className="text-center">
                                        {item?.notice_period}
                                    </td>
                                    <td className="text-center">
                                        <a
                                            className="btn btn-primary btn-sm"
                                            href={item?.resume} target="_blank"
                                        >
                                            <i className="fa-solid fa-download"></i> Downlaod
                                        </a>
                                    </td>
                                    <td className="text-center text-capitalize">
                                        {replaceWord(item?.status)}
                                    </td>
                                    <td className="text-center">
                                        <button  title="Change Status"
                                            type="button"
                                            className="btn btn-primary btn-sm"
                                            onClick={() => aboutHandler(item.id)}
                                        >
                                            <i className="fa fa-eye fs-16"></i>
                                        </button>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td className="text-center" colSpan={10}>No Data Available !</td>
                        </tr>
                    )}
                </tbody>
            </table>
            {data?.length > 0 && (
                <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={Math.ceil(aplicantCount)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                />
            )}
            <ApplicantsStatusPopup apiCallForApplicant={apiCallForApplicant} activity={activity} applicationId={applicationId} setApplicationId={setApplicationId} setAboutForm={setAboutForm} aboutForm={aboutForm} status={status} />
            <ToastContainer />

        </div>

    );
};
export default ApplicantList;