import React from "react";
import ContactUsForm from "./ContactUsForm";

const ContactHelp = () => {
    return (
        <>
            <div className="sptb">
                <div className="container">
                    <div className="match-height row">
                        <div className="col-md-6 col-12">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="fs-25 mb-6 text-center">We look forward to hear from you!</h5>
                                    <ContactUsForm />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="fs-25 text-center">
                                        Let us help you !!!
                                    </h5>
                                    <p className="mb-6 text-center">
                                        At Education Mandal, we are dedicated to provide the
                                        best possible educational experience for all our users.
                                        If you have any questions, feedback, or suggestions,
                                        please don't hesitate to get in touch with us.
                                    </p>
                                    <div className="contacts row match-height">
                                        <div className="col-lg-6 col-md-12">
                                            <div className="card">
                                                <div className="support-service bg-primary">
                                                    <i className="fa fa-phone" />
                                                    <h5>
                                                        <a href="tel:+919730063010">+91 9730063010</a>
                                                    </h5>
                                                    <p>Call us</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <div className="card">
                                                <div className="support-service bg-orange">
                                                    <i className="fa-solid fa-envelope"></i>
                                                    <h5>
                                                        <a href="mailto:info@educationmandal.com">
                                                            info@educationmandal.com
                                                        </a>
                                                    </h5>
                                                    <p>Write to us</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <div className="card">
                                                <div className="support-service bg-success">
                                                    <i className="fa fa-map-marker" />
                                                    <h5>
                                                        D 401, Bachraj Landmark, Near club one, Global
                                                        City, Virar, Maharashtra - 401303
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <div className="card">
                                                <div className="support-service bg-secondary">
                                                    <i className="fa-solid fa-clock"></i>
                                                    <h5>Mon - Sat (10 AM - 06 PM)</h5>
                                                    <p>Working Hours!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="card mb-0">
                                                <div className="card-body">
                                                    <p className="font-weight-normal text-center">
                                                        Follow us on social media for updates and news
                                                        about Education Mandal
                                                    </p>
                                                    <ul className="list-unstyled list-inline mt-4 text-center">
                                                        <li className="list-inline-item">
                                                            <a
                                                                className="social-icons"
                                                                href="https://www.facebook.com/profile.php?id=100090317913078&mibextid=LQQJ4d"
                                                                target="_blank" rel="noreferrer"
                                                            >
                                                                <i className="fe fe-facebook"></i>
                                                            </a>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <a
                                                                className="social-icons"
                                                                href="https://wa.me/+919730063010"
                                                                target="_blank" rel="noreferrer"
                                                            >
                                                                <i className="fa-brands fa-whatsapp"></i>
                                                            </a>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <a
                                                                className="social-icons"
                                                                href="https://www.linkedin.com/company/education-mandal/"
                                                                target="_blank" rel="noreferrer"
                                                            >
                                                                <i className="fe fe-linkedin"></i>
                                                            </a>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <a
                                                                className="social-icons"
                                                                href="https://instagram.com/education_mandal?igshid=YmMyMTA2M2Y="
                                                                target="_blank" rel="noreferrer"
                                                            >
                                                                <i className="fe fe-instagram"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sptb bg-white">
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3761.62703158809!2d72.80527901531677!3d19.471640894475772!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7a9f4fcf0ad47%3A0xe114fbbb6c86ebd1!2sBachraj%20Landmark%2C%20Evershine%20Globle%20City%2C%20Dongarpada%2C%20Rustomjee%20Global%20City%2C%20Virar%20West%2C%20Virar%2C%20Maharashtra%20401303!5e0!3m2!1sen!2sin!4v1675227539036!5m2!1sen!2sin"
                                width="100%"
                                height="450"
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactHelp;
