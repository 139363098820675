import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NoDataAvailable from "../../common/NoDataAvailable";

const LatestBlogNews = (props) => {
    const [data, setBlogData] = useState(props.data);
    useEffect(() => {
        if (props.data && props.data) setBlogData(props.data);
    }, [props.data]);

    const [UIValues, setUIValues] = useState({});
    const [selectedValue, setSelectedValue] = useState([]);
    const handleChangeGen = (event) => {
        UIValues[event.target.name] = event.target.value;
        setUIValues((state) => UIValues);
    };
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ]
    };
    return (
        <>
            <section className="sptb bg-white mt-5">
                <div className="container">
                    <div className="section-title">
                        <h2>Blogs</h2>
                        <div className="ms-auto">
                            <Link className="btn btn-primary" to="/blog">
                                View More <i className="fe fe-arrow-right" />
                            </Link>
                        </div>
                    </div>
                    {data?.length >= 3 ? (
                        <Slider {...settings}>
                            {data?.map((item, i) => (
                                <div className="col-md-12" key={i}>
                                    <Link to={`/blog-detail/${item?.id}`}>
                                        <div className="card blog-Card">
                                            <div className="card-body">
                                                <div className="item-card7-img">
                                                    <img
                                                        src={item?.thumb_image}
                                                        alt={item?.category?.name}
                                                        className="cover-image"
                                                        onError={(e) => { e.target.src = "/assets/images/default.webp"; }}
                                                    />
                                                </div>
                                                <div className="item7-card-desc d-flex justify-content-between mt-3">
                                                    <span className="item-card-badge">
                                                        {item?.category?.name}
                                                    </span>
                                                    <span>
                                                        <i className="fe fe-calendar me-2" />
                                                        <Moment format="DD-MM-YYYY">{item?.updated_at}</Moment>
                                                    </span>
                                                </div>

                                                <div className="item-card2-desc">
                                                    <h4 className="mb-2 leading-tight mt-2 border p-1 rounded bg-primary text-white text-center">
                                                        {item?.name}
                                                    </h4>
                                                    <p
                                                        className="mb-4 education-mandal leading-tight3 border border-primary p-3 pb-4 rounded"
                                                        dangerouslySetInnerHTML={{ __html: item?.description }}
                                                    ></p>
                                                </div>
                                                <div className="d-flex align-items-center p-2 rounded mt-auto blog-bottom">
                                                    <img
                                                        src={item?.user_profile}
                                                        className="avatar border-radius-50 avatar-md me-3"
                                                        alt={item?.user_name}
                                                    />
                                                    <div>
                                                        {item?.user_name}
                                                        {/* <small className="d-block text-muted">
                                                            <Moment fromNow ago>{(item?.updated_at)}</Moment> ago
                                                        </small> */}
                                                    </div>
                                                    <div className="ms-auto text-primary">
                                                        <span className="fa-regular fa-thumbs-up"></span> {item?.like_count} Likes
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </Slider>
                    ) : (
                        <div className="row">
                            {data?.length > 0 ? (
                                data.map((item, i) => (
                                    // {data?.map((item, i) => (
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12" key={i}>
                                        <Link to={`/blog-detail/${item?.id}`}>
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="item-card7-img">
                                                        <img
                                                            src={item?.thumb_image}
                                                            alt={item?.category?.name}
                                                            className="cover-image"
                                                            onError={(e) => { e.target.src = "/assets/images/default.webp"; }}
                                                        />
                                                    </div>
                                                    <div className="item7-card-desc d-flex justify-content-between mt-3">
                                                        <span className="item-card-badge">
                                                            {item?.category?.name}
                                                        </span>
                                                        <span>
                                                            <i className="fe fe-calendar me-2" />
                                                            <Moment format="DD-MM-YYYY">{item?.updated_at}</Moment>
                                                        </span>
                                                    </div>

                                                    <div className="item-card2-desc">
                                                        <h4 className="mb-2 leading-tight mt-2 border p-1 rounded bg-primary text-white text-center">
                                                            {item?.name}
                                                        </h4>
                                                        <p
                                                            className="mb-4 education-mandal leading-tight3 border border-primary p-3 pb-4 rounded"
                                                            dangerouslySetInnerHTML={{ __html: item?.description }}
                                                        ></p>
                                                    </div>
                                                    <div className="d-flex align-items-center p-2 rounded mt-auto blog-bottom">
                                                        <img
                                                            src={item?.user_profile}
                                                            className="avatar border-radius-50 avatar-md me-3"
                                                            alt={item?.user_name}
                                                        />
                                                        <div>
                                                            {item?.user_name}
                                                            {/* <small className="d-block text-muted">
                                                            <Moment fromNow ago>{(item?.updated_at)}</Moment> ago
                                                        </small> */}
                                                        </div>
                                                        <div className="ms-auto text-primary">
                                                            <span className="fa-regular fa-thumbs-up"></span> {item?.like_count} Likes
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))
                            ) : (
                                <NoDataAvailable />
                            )}
                        </div>
                    )}
                </div>
            </section>
        </>
    );
};

export default LatestBlogNews;
