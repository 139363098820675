import React from "react";
import { ToastContainer } from "react-toastify";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ApprovePendingCourse from "./ApprovePendingCourse";
import ApproveUpdatedCourse from "./ApproveUpdatedCourse";

const CourseList = () => {


    return (
        <>
            <div className="col-md-12">
                <div className="card">
                    <Tabs defaultActiveKey="PendingList" id="uncontrolled-tab-example">
                        <Tab eventKey="PendingList" title="Pending Course List">
                            <ApprovePendingCourse />
                        </Tab>
                        <Tab eventKey="UpdatedList" title="Updated Course List">
                            <ApproveUpdatedCourse />
                        </Tab>
                    </Tabs>
                </div>

            </div>
            <ToastContainer />
        </>
    );
};

export default CourseList;
