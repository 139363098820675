import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import url from "../../../../constants/url";
import ReactPaginate from "react-paginate";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Moment from "react-moment";
import { replaceWord } from "../../../../utils/utils";

const ClassApproval = () => {
    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage.auth_token;
    const [lectures, setLectures] = useState([]);
    const [errors, setErrors] = useState([]);
    const [filters, setFilters] = useState({ limit: 10, offset: 0, status: 'pending', query: '' });
    const [statusList, setStatusList] = useState("pending")
    const [loading, setLoading] = useState(false)
    const [approveModel, setApproveModel] = useState({ display: false, lecture_id: "" });
    const [changeStatus, setChangeStatus] = useState(false);
    const [count, setCount] = useState();
    const [offset, setOffset] = useState(0);
    const [addStatus, setAddStatus] = useState({ time_slot_id: '', status: '', reason: '' });
    const [checkedItems, setCheckedItems] = useState({});
    const [selectAll, setSelectAll] = useState(false);

    const handleCheckboxChange = (event, id) => {
        const { checked } = event.target;
        setCheckedItems({ ...checkedItems, [id]: checked });
    };

    const handleSelectAll = () => {
        const updatedCheckedItems = {};
        if (!selectAll) {
            lectures.forEach((item) => {
                updatedCheckedItems[item.id] = true;
            });
        }
        setCheckedItems(updatedCheckedItems);
        setSelectAll(!selectAll);
    };

    const manageMultipleSlotStatus = async () => {
        var slot_ids = [];
        for (const [key, value] of Object.entries(checkedItems)) {
            if (value) {
                slot_ids.push(key)
            }
        }

        if (slot_ids.length === 0) {
            alert("Please Select Any Slot For Change Status");
        } else {
            setApproveModel({ display: true, lecture_id: slot_ids.join(',') })
            // var activity = {
            //     class_id: class_id,
            //     deleted_slot_id: slot_ids.join(','),
            // }
            // DeleteSlot(activity);
        }
    }

    const getLectures = async (activity) => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(activity)
        };

        fetch(url.apiUrl + "teacher/teacher-class-time-schedule", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setLectures(result.data);
                    setCount(result.count);
                }
            })
            .catch((error) => console.log("error", error))
            .finally(() => {
                setLoading(false)
            })
    };
    const handleSubmit = async (lecture_id) => {
        let error = [];
        if (addStatus.status.length == 0) {
            error['error_status'] = "required field";
        }
        if (addStatus.status.length != 0 && addStatus.status == "decline" && addStatus.reason.length == 0) {
            error['error_reason'] = "required field";
        }
        setErrors(error);
        if (error.length == 0) {

            setLoading(true);
            var myHeaders = new Headers();
            myHeaders.append("AUTHTOKEN", auth_token);
            myHeaders.append("Content-Type", "application/json");
            let redData = addStatus;
            redData["time_slot_id"] = lecture_id;

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify(redData)
            };

            fetch(url.apiUrl + "teacher/approve-decline-class-time", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    setAddStatus({ time_slot_id: '', status: '', reason: '' });
                    setApproveModel({ display: false, lecture_id: "" });
                    if (result.status === "success") {
                        toast.success(result.message);
                        getData()
                    } else {
                        result.data.map((value) => {
                            toast.error(value);
                        })
                        getData()
                    }
                    setCheckedItems({})
                    setSelectAll(false);
                })
                .catch((error) => console.log("error", error))
                .finally(() => {
                    setLoading(false)
                })
        }
    };

    const handlePageClick = async (lectures) => {
        let currentPage = lectures.selected * filters.limit;
        setOffset(currentPage);
        var activity = {
            ...filters,
            limit: filters.limit,
            offset: currentPage,
            query: filters.query,
        }
        setFilters(activity)
        await getLectures(activity);
    };

    const handlePagination = async (value) => {
        setOffset(0);
        var activity = {
            ...filters,
            limit: value,
            offset: 0,
        }
        setFilters(activity)
        await getLectures(activity);
    };

    const handleChangeStatus = (value, key) => {
        setAddStatus({ ...addStatus, [key]: value });
    };

    const getData = () => {
        getLectures({
            limit: filters.limit,
            offset: filters.offset,
            status: filters.status,
            query: filters.query,
        })
    }

    useEffect(() => {
        getData()
    }, [])

    const manageFilterStatus = () => {
        setOffset(0);
        var activity = {
            ...filters,
            limit: 10,
            offset: 0,
            query: filters.query,
        }
        getLectures(activity)
        setFilters(activity)
        setStatusList(filters.status)
    }
    return (
        <>
            <ToastContainer />
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/teacher-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Classroom Course</li>
                </ol>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Classroom Course for Approval</h4>
                        </div>
                        <div className="card-body ">
                            <div className="row">
                                <div className="dataTables_filter col-md-2">
                                    <label>Classroom Course Status </label>
                                    <select
                                        className="form-select select2"
                                        onChange={(e) => {
                                            setFilters({ ...filters, status: e.target.value })
                                        }
                                        }
                                        name="status"
                                        id="data_status"
                                        tabIndex={0}
                                        aria-hidden="false"
                                    >
                                        <option value="pending" selected> Pending </option>
                                        <option value="active"> Active </option>
                                        <option value="decline" > Decline </option>
                                    </select>
                                </div>
                                <div className="open-button col-md-3">
                                    <button type="button" className="btn btn-primary" onClick={manageFilterStatus}><i className="fe fe-search"></i> Search</button>
                                </div>
                            </div>
                            <div className="row mt-5 mb-2">
                                <div className="col-md-6">
                                    <button
                                        className='btn btn-danger'
                                        type="button"
                                        onClick={manageMultipleSlotStatus}
                                    >
                                        Change Status
                                    </button>
                                </div>
                                <div
                                    id="basic-1_wrapper"
                                    className="dataTables_wrapper no-footer col-md-6"
                                >
                                    <div id="basic-1_filter" className="dataTables_filter">
                                        <label>
                                            Search:
                                            <input
                                                type="search"
                                                placeholder="Search..."
                                                onChange={(e) => {
                                                    setFilters({ ...filters, query: e.target.value })
                                                }}
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <table className="table table-bordered table-hover dataTable table-responsive-xl">
                                <thead>
                                    <tr>
                                        <th className="text-center">
                                            <div className="checkbox checkbox-info">
                                                <label className="custom-control mt-2 checkbox-inline mb-0" htmlFor="checked">
                                                    <input
                                                        type="checkbox"
                                                        checked={selectAll}
                                                        onChange={handleSelectAll}
                                                        name="selectall"
                                                    />
                                                </label>
                                            </div>
                                        </th>
                                        <th className="text-center">Sr. No.</th>
                                        <th>Class Details</th>
                                        <th>Branch Details</th>
                                        <th className="text-center">Subject</th>
                                        <th className="text-center">Class Type</th>
                                        <th className="text-center">Classroom Type</th>
                                        <th className="text-center">Class Date & Time</th>
                                        <th className="text-center">Status</th>
                                        {statusList == "decline" && (
                                            <th>Reason</th>
                                        )}
                                        {statusList != "active" && (
                                            <th className="text-center">Action</th>
                                        )}

                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        <tr>
                                            <td className="text-center" colSpan={10}>Loading..</td>
                                        </tr>
                                    ) : (
                                        lectures.length === 0 ? (
                                            <tr>
                                                <td className="text-center" colSpan={10}>No Data Available !</td>
                                            </tr>
                                        ) : (
                                            <>
                                                {lectures.map((item, i) => (
                                                    <tr key={i} >
                                                        <td className="text-center">
                                                            <div className="checkbox checkbox-info">
                                                                <label className="custom-control mt-2 checkbox-inline mb-0" htmlFor="checked">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={checkedItems[item.id] || false}
                                                                        onChange={(e) => handleCheckboxChange(e, item?.id)}
                                                                    />
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <th className="text-center">{offset + i + 1}</th>
                                                        <td>
                                                            <div className="media">
                                                                <img src={item?.class_course?.thumbnail} alt={item?.class_course?.title} className="me-3 rounded-circle" />
                                                                <div className="media-body">
                                                                    <Link to={"/teacher-dashboard/class-detail/" + item?.branch_id} className="font-weight-semibold">
                                                                        {item?.class_course?.title}
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="media">
                                                                <img src={item?.institute_branch?.image} alt={item?.class_name} className="me-3 rounded-circle" />
                                                                <div className="media-body">
                                                                    <Link to={"/teacher-dashboard/branch-detail/" + item?.id} className="font-weight-semibold">
                                                                        {item?.institute_branch?.name}
                                                                    </Link>
                                                                    <p className="mb-0">{item?.institute_branch?.email}</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="text-center text-capitalize"> {item?.subject}</td>
                                                        <td className="text-center text-capitalize">{replaceWord(item?.class_course?.class_type)} {item?.class_course?.class_type == "tuition" ? " : " + (replaceWord(item?.class_course?.tuition_type)) : ""}</td>
                                                        <td className="text-center text-capitalize">{replaceWord(item?.class_course?.lecture_type)}</td>
                                                        <td className="text-center"><Moment format="DD-MM-YYYY">{item?.date}</Moment> {moment(item?.start_time, ["HH:mm"]).format("hh:mm A")} to {moment(item?.end_time, ["HH:mm"]).format("hh:mm A")}</td>
                                                        <td className="text-capitalize text-center">{item?.teacher_status}</td>
                                                        {statusList == "decline" && (
                                                            <td className="text-capitalize">{item?.teacher_decline_reason}</td>
                                                        )}
                                                        {statusList != "active" && (
                                                            <td className="text-capitalize text-center">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary btn-sm"
                                                                    id={`close_edit_${item.id}`}
                                                                    data-bs-dismiss="modal"
                                                                    aria-label="Close"
                                                                    onClick={() => setApproveModel({ display: true, lecture_id: item.id })}
                                                                >
                                                                    <i className="fa fa-pen-to-square fs-16"></i>
                                                                </button>
                                                            </td>
                                                        )}
                                                    </tr>
                                                )
                                                )}
                                            </>
                                        )
                                    )}
                                </tbody>
                            </table>
                            {lectures.length > 0 && (
                                <div className="row">
                                    <div className="col-md-2 mt-3">
                                        <select className="form-select" onChange={(e) => {
                                            handlePagination(e.target.value)
                                        }}>
                                            <option value="10">10</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value={count}>all</option>
                                        </select>
                                    </div>
                                    <div className="col-md-10">
                                        <ReactPaginate
                                            previousLabel={"Previous"}
                                            nextLabel={"Next"}
                                            breakLabel={"..."}
                                            pageCount={Math.ceil(count / filters.limit)}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                        />
                                    </div>
                                </div>
                            )}

                            <div
                                className={`modal fade ${approveModel?.display === true ? 'show' : ''}`}
                                style={{ display: approveModel?.display === true ? 'block' : 'none' }}
                                id={`#changeApprove`}
                                tabIndex={-1}
                                aria-labelledby="myModalLabel33"
                                aria-hidden="true"
                            >
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title" id={`changeApprove`}>
                                                Classroom Course Change Status
                                            </h4>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                id={`close_edit`}
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                                onClick={() => setApproveModel({ display: false, lecture_id: "" })}
                                            >

                                            </button>
                                        </div>
                                        <form action="#">
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        <label htmlFor="employee-type" >
                                                            Status
                                                            <span className="text-danger"> *</span>
                                                        </label>
                                                        <select
                                                            className="form-select select2"
                                                            onChange={(e) => {
                                                                handleChangeStatus(e.target.value, "status")
                                                                setChangeStatus(e.target.value)
                                                            }}
                                                            value={addStatus.status}
                                                            name="status"
                                                            id="status"
                                                            tabIndex={0}
                                                            aria-hidden="false"
                                                        >
                                                            <option value="">Select status </option>
                                                            <option value="active">Approve</option>
                                                            <option value="decline">Resubmit</option>
                                                        </select>
                                                        <p className='bg-error'>{errors.error_status}</p>
                                                    </div>
                                                    <div className="mb-3 col-md-12" style={{ display: changeStatus == 'decline' ? 'block' : 'none' }}>
                                                        <label htmlFor="start-date">
                                                            Reason
                                                        </label>
                                                        <input
                                                            type="text"
                                                            onChange={(e) => handleChangeStatus(e.target.value, "reason")}
                                                            name="reason"
                                                            value={addStatus.reason}
                                                            id="reason"
                                                            className="form-control"
                                                            placeholder="Enter reason"
                                                        />
                                                        {errors?.error_reason && (
                                                            <p className='bg-error'>{errors.error_reason}</p>
                                                        )}

                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="modal-footer">
                                            <div
                                                type="button"
                                                onClick={(e) => handleSubmit(approveModel.lecture_id)}
                                                className="btn btn-primary "
                                            >
                                                Submit
                                            </div>
                                            <button
                                                id={`reset_close_edit`}
                                                className="btn btn-danger "
                                                onClick={() => setApproveModel({ display: false, lecture_id: "" })}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClassApproval;
