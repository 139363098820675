import React, { useEffect, useState } from 'react';
import url from '../../../../constants/url';

const CardStatistics = () => {
    const [data, setData] = useState([])
    const local_data = localStorage.getItem('common-user-info');
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage.auth_token

    const getDashboardData = () => {
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
        };

        fetch(`${url.apiUrl}student/dashboard`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                if (result?.status === "success") {
                    setData(result?.data)
                }
            })
            .catch(error => console.log('error', error));
    }
    useEffect(() => {
        getDashboardData()
    }, [])

    return (
        <>
            <div className="col-xl-12">
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                        <div className="card">
                            <div className="card-body text-center">
                                <div className="counter-status dash3-counter d-flex">
                                    <div className="counter-icon bg-primary-dark my-auto br-100">
                                        <i className="fa-solid fa-calendar-week leading-loose"></i>
                                    </div>
                                    <div className="ms-auto">
                                        <h5 className="mb-0">Total Class</h5>
                                        <h2 className="counter mb-0">{data?.total_class}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                        <div className="card">
                            <div className="card-body text-center">
                                <div className="counter-status dash3-counter d-flex">
                                    <div className="counter-icon bg-primary-dark my-auto br-100">
                                        <i className="fa-solid fa-calendar-week leading-loose"></i>
                                    </div>
                                    <div className="ms-auto">
                                        <h5 className="mb-0">Total Course</h5>
                                        <h2 className="counter mb-0">{data?.total_course}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                        <div className="card">
                            <div className="card-body text-center">
                                <div className="counter-status dash3-counter d-flex">
                                    <div className="counter-icon bg-primary-dark my-auto br-100">
                                        <i className="fa-solid fa-calendar-week leading-loose"></i>
                                    </div>
                                    <div className="ms-auto">
                                        <h5 className="mb-0">Ongoing Course</h5>
                                        <h2 className="counter mb-0">{data?.total_continue_course}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                        <div className="card">
                            <div className="card-body text-center">
                                <div className="counter-status dash3-counter d-flex">
                                    <div className="counter-icon bg-primary-dark my-auto br-100">
                                        <i className="fa-solid fa-calendar-week leading-loose"></i>
                                    </div>
                                    <div className="ms-auto">
                                        <h5 className="mb-0">Complete Course</h5>
                                        <h2 className="counter mb-0">{data?.total_complete_course}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CardStatistics;