import React, { useEffect, useState } from "react";
import url from "../../../../constants/url";

const Card = () => {
    const [revenue, setRevenue] = useState({});
    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage.auth_token;
    const getProfileData = async () => {
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        fetch(`${url.apiUrl}institute/revenue`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setRevenue(result?.data);
                }
            })
            .catch((error) => console.log("error", error));
    };

    useEffect(() => {
        getProfileData();
    }, []);
    return (
        <>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card">
                    <div className="card-body">
                        <div className="counter-status dash3-counter d-flex">
                            <div className="counter-icon bg-primary-dark my-auto br-100">
                                <i className="fa-solid fa-calendar-week leading-loose"></i>
                            </div>
                            <div className="ms-auto text-end">
                                <h5 className="mb-0">Today</h5>
                                <h2 className="mb-0 mt-2">₹ {Math.round(revenue?.today) || 0}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card">
                    <div className="card-body">
                        <div className="counter-status dash3-counter d-flex">
                            <div className="counter-icon bg-primary-dark my-auto br-100">
                                <i className="fa-solid fa-calendar-week leading-loose"></i>
                            </div>
                            <div className="ms-auto text-end">
                                <h5 className="mb-0">Yesterday</h5>
                                <h2 className="mb-0 mt-2">₹ {Math.round(revenue?.yesterday) || 0}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card">
                    <div className="card-body">
                        <div className="counter-status dash3-counter d-flex">
                            <div className="counter-icon bg-primary-dark my-auto br-100">
                                <i className="fa-solid fa-calendar-week leading-loose"></i>
                            </div>
                            <div className="ms-auto text-end">
                                <h5 className="mb-0">Current Month</h5>
                                <h2 className="mb-0 mt-2">₹ {Math.round(revenue?.current_month) || 0}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card">
                    <div className="card-body">
                        <div className="counter-status dash3-counter d-flex">
                            <div className="counter-icon bg-primary-dark my-auto br-100">
                                <i className="fa-solid fa-calendar-week leading-loose"></i>
                            </div>
                            <div className="ms-auto text-end">
                                <h5 className="mb-0">Last Month</h5>
                                <h2 className="mb-0 mt-2">₹ {Math.round(revenue?.last_month) || 0}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card">
                    <div className="card-body">
                        <div className="counter-status dash3-counter d-flex">
                            <div className="counter-icon bg-primary-dark my-auto br-100">
                                <i className="fa-solid fa-calendar-week leading-loose"></i>
                            </div>
                            <div className="ms-auto text-end">
                                <h5 className="mb-0">Last 6 Months</h5>
                                <h2 className="mb-0 mt-2">₹ {Math.round(revenue?.last_6_month) || 0}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card">
                    <div className="card-body">
                        <div className="counter-status dash3-counter d-flex">
                            <div className="counter-icon bg-primary-dark my-auto br-100">
                                <i className="fa-solid fa-calendar-week leading-loose"></i>
                            </div>
                            <div className="ms-auto text-end">
                                <h5 className="mb-0">Current Year</h5>
                                <h2 className="mb-0 mt-2">₹ {Math.round(revenue?.current_year) || 0}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card">
                    <div className="card-body">
                        <div className="counter-status dash3-counter d-flex">
                            <div className="counter-icon bg-primary-dark my-auto br-100">
                                <i className="fa-solid fa-calendar-week leading-loose"></i>
                            </div>
                            <div className="ms-auto text-end">
                                <h5 className="mb-0">Last Year</h5>
                                <h2 className="mb-0 mt-2">₹ {Math.round(revenue?.last_year) || 0}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card">
                    <div className="card-body ">
                        <div className="counter-status dash3-counter d-flex">
                            <div className="counter-icon bg-primary-dark my-auto br-100">
                                <i className="fa-solid fa-calendar-week leading-loose"></i>
                            </div>
                            <div className="ms-auto text-end">
                                <h5 className="mb-0">Total Revenue</h5>
                                <h2 className="mb-0 mt-2">₹ {Math.round(revenue?.total_revenue) || 0}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Card;
