import React, { useState } from 'react';
import Headerinner from '../../Header/Headerinner';
import Footer from '../../Footer/Footer';
import Loader from "../../../common/loader";
import Curve from '../../Header/Curve';
import "react-phone-number-input/style.css";
import InstituteRegisterFormFields from "./InstituteRegisterFormFields";

const Institute = () => {
    const [isLoader, setIsLoader] = useState(false);
    return (
        <>
            <Headerinner />
            <div
                className="cover-image bg-background-1"
                data-bs-image-src="assets/images/banners/banner1.webp"
                style={{
                    background: 'url("assets/images/banners/banner1.webp") center center',
                }}
            >
                <div className="container header-text">
                    <h1>Institute / Teacher Registration</h1>
                </div>
            </div>
            <Curve/>
            <section className="sptb">
                <div className="container customer-page">
                    <div className="col-md-10 d-block mx-auto">
                        <div className="card">
                            <div className="card-header"> <h4 className="card-title">Registration</h4> </div>
                            <div className="card-body">
                                <InstituteRegisterFormFields
                                    isLoader={isLoader}
                                    setIsLoader={setIsLoader}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {isLoader && <Loader />}
            <Footer />
        </>
    );
};

export default Institute;