import React, { useState } from 'react';

const ProductServices = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

   
    return (
        <>
            <div className="PService-area">
                <div className="container">
                    <div className="row align-items-center justify-content-center pb-5">
                        <div className="pb-lg-0 pb-5 col-lg-6">
                            <div className="PService-thumb">
                                <img data-aos="fade-right"
                                    src="/assets/images/Pservice1.jpg"
                                    alt=""
                                    className="hover-effect"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6" >
                            <div className="PService-right">
                                <div className="Psection-title text-animation">
                                    <div>
                                        <h4>Educational Aggregator</h4>
                                    </div>
                                    <div>
                                        <h3>Our Educational Aggregator Service</h3>
                                    </div>
                                    <div>
                                        <p>Our educational aggregator service is a comprehensive platform for all your educational needs, bringing together top educational resources from across the web in one convenient location. With our platform, finding the information you need has never been easier.</p>
                                    </div>
                                    <div className="accordion" id="educationalAggregatorAccordion">
                                        <div className="accordion-item">
                                            <h2  data-aos="flip-up" className="accordion-header" id="headingOne">
                                                <button  
                                                    className={`accordion-button ${openIndex === 0 ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => toggleAccordion(0)}
                                                    aria-expanded={openIndex === 0}
                                                    aria-controls="collapseOne"
                                                >
                                                    Diverse Resources
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseOne"
                                                className={`accordion-collapse collapse ${openIndex === 0 ? "show" : ""}`}
                                                aria-labelledby="headingOne"
                                            >
                                                <div className="accordion-body">
                                                    A wide array of educational resources collected from reputable sources, ensuring you have access to quality information and learning tools.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 data-aos="flip-up"  className="accordion-header" id="headingTwo">
                                                <button 
                                                    className={`accordion-button ${openIndex === 1 ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => toggleAccordion(1)}
                                                    aria-expanded={openIndex === 1}
                                                    aria-controls="collapseTwo"
                                                >
                                                    Course Discovery
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseTwo"
                                                className={`accordion-collapse collapse ${openIndex === 1 ? "show" : ""}`}
                                                aria-labelledby="headingTwo"
                                            >
                                                <div className="accordion-body">
                                                    An intuitive search engine that helps you quickly find the perfect course tailored to your learning objectives.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 data-aos="flip-up"  className="accordion-header" id="headingThree">
                                                <button 
                                                    className={`accordion-button ${openIndex === 2 ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => toggleAccordion(2)}
                                                    aria-expanded={openIndex === 2}
                                                    aria-controls="collapseThree"
                                                >
                                                    Institute Finder
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseThree"
                                                className={`accordion-collapse collapse ${openIndex === 2 ? "show" : ""}`}
                                                aria-labelledby="headingThree"
                                            >
                                                <div className="accordion-body">
                                                    Locate the right educational institutions that match your field of interest and career goals.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 data-aos="flip-up"  className="accordion-header" id="headingThree">
                                                <button 
                                                    className={`accordion-button ${openIndex === 3 ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => toggleAccordion(3)}
                                                    aria-expanded={openIndex === 3}
                                                    aria-controls="collapseThree"
                                                >
                                                    Teacher Matching
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseThree"
                                                className={`accordion-collapse collapse ${openIndex === 3 ? "show" : ""}`}
                                                aria-labelledby="headingThree"
                                            >
                                                <div className="accordion-body">
                                                    Connect with expert teachers who align with your educational needs, helping you achieve your learning targets effectively.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 data-aos="flip-up"  className="accordion-header" id="headingThree">
                                                <button 
                                                    className={`accordion-button ${openIndex === 4 ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => toggleAccordion(4)}
                                                    aria-expanded={openIndex === 4}
                                                    aria-controls="collapseThree"
                                                >
                                                    Personalized Recommendations
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseThree"
                                                className={`accordion-collapse collapse ${openIndex === 4 ? "show" : ""}`}
                                                aria-labelledby="headingThree"
                                            >
                                                <div className="accordion-body">
                                                    Receive customized suggestions based on your preferences, making your educational journey smooth and tailored to your goals.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 data-aos="flip-up"  className="accordion-header" id="headingThree">
                                                <button 
                                                    className={`accordion-button ${openIndex === 5 ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => toggleAccordion(5)}
                                                    aria-expanded={openIndex === 5}
                                                    aria-controls="collapseThree"
                                                >
                                                    One-Stop Solution
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseThree"
                                                className={`accordion-collapse collapse ${openIndex === 5 ? "show" : ""}`}
                                                aria-labelledby="headingThree"
                                            >
                                                <div className="accordion-body">
                                                    A single platform that eliminates the need to search multiple websites, simplifying your path to finding courses, institutes, and mentors.
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center pb-5">
                        <div className="pb-lg-0 pb-5 col-lg-6 d-block d-lg-none">
                            <div className="PService-thumb">
                                <img data-aos="fade-right"
                                    src="/assets/images/Pservice2.jpg"
                                    alt=""
                                    className="hover-effect"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="PService-right">
                                <div className="Psection-title text-animation">
                                    <div>
                                        <h4>Learning Management System</h4>
                                    </div>
                                    <div>
                                        <h3>Our Learning Management System Service</h3>
                                    </div>
                                    <div>
                                        <p>Our Learning Management System (LMS) is designed to provide you with a seamless and effective learning experience. Our platform is user-friendly and intuitive, making it easy for you to access your courses and manage your learning progress. With our LMS, you can track your progress, set goals, and receive feedback from your Teachers.</p>
                                    </div>
                                    <div className="accordion" id="educationalAggregatorAccordion">
                                        <div className="accordion-item">
                                            <h2 data-aos="flip-up"  className="accordion-header" id="headingOne">
                                                <button 
                                                    className={`accordion-button ${openIndex === 6 ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => toggleAccordion(6)}
                                                    aria-expanded={openIndex === 6}
                                                    aria-controls="collapseOne"
                                                >
                                                    Attendance Management
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseOne"
                                                className={`accordion-collapse collapse ${openIndex === 6 ? "show" : ""}`}
                                                aria-labelledby="headingOne"
                                            >
                                                <div className="accordion-body">
                                                    An Attendance Management Tool can help Institutes streamline their attendance processes, reduce administrative burden, and improve student engagement and accountability.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 data-aos="flip-up"  className="accordion-header" id="headingTwo">
                                                <button 
                                                    className={`accordion-button ${openIndex === 7 ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => toggleAccordion(7)}
                                                    aria-expanded={openIndex === 7}
                                                    aria-controls="collapseTwo"
                                                >
                                                    Video Hosting
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseTwo"
                                                className={`accordion-collapse collapse ${openIndex === 7 ? "show" : ""}`}
                                                aria-labelledby="headingTwo"
                                            >
                                                <div className="accordion-body">
                                                    Integrated video hosting capabilities, making it easy to upload, manage, and share videos as part of your educational content.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 data-aos="flip-up"  className="accordion-header" id="headingThree">
                                                <button 
                                                    className={`accordion-button ${openIndex === 8 ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => toggleAccordion(8)}
                                                    aria-expanded={openIndex === 8}
                                                    aria-controls="collapseThree"
                                                >
                                                    Virtual Classrooms
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseThree"
                                                className={`accordion-collapse collapse ${openIndex === 8 ? "show" : ""}`}
                                                aria-labelledby="headingThree"
                                            >
                                                <div className="accordion-body">
                                                    Virtual classrooms and video conferencing tools, making it easy to attend live sessions and collaborate with Teachers and peers.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 data-aos="flip-up"  className="accordion-header" id="headingThree">
                                                <button 
                                                    className={`accordion-button ${openIndex === 9 ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => toggleAccordion(9)}
                                                    aria-expanded={openIndex === 9}
                                                    aria-controls="collapseThree"
                                                >
                                                    Reporting and Analytics
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseThree"
                                                className={`accordion-collapse collapse ${openIndex === 9 ? "show" : ""}`}
                                                aria-labelledby="headingThree"
                                            >
                                                <div className="accordion-body">
                                                    Reporting and analytics tools to help Teachers and administrators make data-driven decisions about course content, delivery, and assessment.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 data-aos="flip-up"  className="accordion-header" id="headingThree">
                                                <button 
                                                    className={`accordion-button ${openIndex === 10 ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => toggleAccordion(10)}
                                                    aria-expanded={openIndex === 10}
                                                    aria-controls="collapseThree"
                                                >
                                                    Alerts and Reminders
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseThree"
                                                className={`accordion-collapse collapse ${openIndex === 10 ? "show" : ""}`}
                                                aria-labelledby="headingThree"
                                            >
                                                <div className="accordion-body">
                                                    Automated alerts and reminders for Students, Teachers and Institutes to keep them informed and on track.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 data-aos="flip-up"  className="accordion-header" id="headingThree">
                                                <button 
                                                    className={`accordion-button ${openIndex === 11 ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => toggleAccordion(11)}
                                                    aria-expanded={openIndex === 11}
                                                    aria-controls="collapseThree"
                                                >

                                                    Collaboration and Communication
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseThree"
                                                className={`accordion-collapse collapse ${openIndex === 11 ? "show" : ""}`}
                                                aria-labelledby="headingThree"
                                            >
                                                <div className="accordion-body">
                                                    Features for enabling collaboration and communication between Students and Teachers, discussion forums, private messaging, notes sharing, etc.
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pb-lg-0 pb-5 col-lg-6 d-none d-lg-block">
                            <div className="PService-thumb1">
                                <img data-aos="fade-right"
                                    src="/assets/images/Pservice2.jpg"
                                    alt=""
                                    className="hover-effect"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center pb-5">
                        <div className="pb-lg-0 pb-5 col-lg-6">
                            <div className="PService-thumb">
                                <img data-aos="fade-right"
                                    src="/assets/images/Pservice3.jpg"
                                    alt=""
                                    className="hover-effect"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="PService-right">
                                <div className="Psection-title text-animation">
                                    <div>
                                        <h4>Career Guidance</h4>
                                    </div>
                                    <div>
                                        <h3>Our Career Guidance Service</h3>
                                    </div>
                                    <div>
                                        <p>We understand that choosing a career can be a challenging decision, and that's why we offer career guidance services to our users. Our experts will help you understand your skills and interests, and provide you with tailored recommendations for careers that are best suited for you. Whether you're just starting out or looking for a change, our career guidance services can help you make informed decisions about your future.</p>
                                    </div>
                                    <div className="accordion" id="educationalAggregatorAccordion">
                                        <div className="accordion-item">
                                            <h2 data-aos="flip-up"  className="accordion-header" id="headingOne">
                                                <button 
                                                    className={`accordion-button ${openIndex === 12 ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => toggleAccordion(12)}
                                                    aria-expanded={openIndex === 12}
                                                    aria-controls="collapseOne"
                                                >
                                                    Assessment
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseOne"
                                                className={`accordion-collapse collapse ${openIndex === 12 ? "show" : ""}`}
                                                aria-labelledby="headingOne"
                                            >
                                                <div className="accordion-body">
                                                Career guidance typically begins with an assessment of an individual's interests, skills, values, personality, and other factors that impact career choice.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 data-aos="flip-up"  className="accordion-header" id="headingTwo">
                                                <button 
                                                    className={`accordion-button ${openIndex === 13 ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => toggleAccordion(13)}
                                                    aria-expanded={openIndex === 13}
                                                    aria-controls="collapseTwo"
                                                >
                                                    Decision-making Support
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseTwo"
                                                className={`accordion-collapse collapse ${openIndex === 13 ? "show" : ""}`}
                                                aria-labelledby="headingTwo"
                                            >
                                                <div className="accordion-body">
                                                It helps individuals make informed decisions about their careers by providing guidance on job search strategies, resume building, and interview preparation.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 data-aos="flip-up"  className="accordion-header" id="headingThree">
                                                <button 
                                                    className={`accordion-button ${openIndex === 14 ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => toggleAccordion(14)}
                                                    aria-expanded={openIndex === 14}
                                                    aria-controls="collapseThree"
                                                >
                                                    Skill Development
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseThree"
                                                className={`accordion-collapse collapse ${openIndex === 14 ? "show" : ""}`}
                                                aria-labelledby="headingThree"
                                            >
                                                <div className="accordion-body">
                                                It can help individuals identify their strengths and weaknesses and develop the skills needed to succeed in their chosen careers.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 data-aos="flip-up"  className="accordion-header" id="headingThree">
                                                <button 
                                                    className={`accordion-button ${openIndex === 15 ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => toggleAccordion(15)}
                                                    aria-expanded={openIndex === 15}
                                                    aria-controls="collapseThree"
                                                >
                                                    Networking Opportunities
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseThree"
                                                className={`accordion-collapse collapse ${openIndex === 15 ? "show" : ""}`}
                                                aria-labelledby="headingThree"
                                            >
                                                <div className="accordion-body">
                                                It can connect individuals with alumni, industry professionals, and other resources that can provide valuable support.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 data-aos="flip-up"  className="accordion-header" id="headingThree">
                                                <button 
                                                    className={`accordion-button ${openIndex === 16 ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => toggleAccordion(16)}
                                                    aria-expanded={openIndex === 16}
                                                    aria-controls="collapseThree"
                                                >
                                                    Insight into job market trends
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseThree"
                                                className={`accordion-collapse collapse ${openIndex === 16 ? "show" : ""}`}
                                                aria-labelledby="headingThree"
                                            >
                                                <div className="accordion-body">
                                                Current and future job market trends, such as emerging industries and in-demand skills, which can help them make better decisions about their career paths.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center pb-0">
                        <div className="pb-lg-0 pb-5 col-lg-6 d-block d-lg-none">
                            <div className="PService-thumb">
                                <img data-aos="fade-right"
                                    src="/assets/images/Pservice4.jpg"
                                    alt=""
                                    className="hover-effect"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="PService-right">
                                <div className="Psection-title text-animation">
                                    <div>
                                        <h4>Education Loan</h4>
                                    </div>
                                    <div>
                                        <h3>Our Education Loan Service</h3>
                                    </div>
                                    <div>
                                        <p>Education Mandal offers a third-party education loan to assist students in financing their education. This product is designed to provide students with the financial support they need to pursue their academic goals and overcome financial obstacles.</p>
                                    </div>
                                    <div className="accordion" id="educationalAggregatorAccordion">
                                        <div className="accordion-item">
                                            <h2 data-aos="flip-up"  className="accordion-header" id="headingOne">
                                                <button 
                                                    className={`accordion-button ${openIndex === 17 ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => toggleAccordion(17)}
                                                    aria-expanded={openIndex === 17}
                                                    aria-controls="collapseOne"
                                                >
                                                    Loan Amount
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseOne"
                                                className={`accordion-collapse collapse ${openIndex === 17 ? "show" : ""}`}
                                                aria-labelledby="headingOne"
                                            >
                                                <div className="accordion-body">
                                                Students can borrow the amount they need to cover the cost of their education, including tuition, fees, books, and living expenses.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 data-aos="flip-up"  className="accordion-header" id="headingTwo">
                                                <button 
                                                    className={`accordion-button ${openIndex === 18 ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => toggleAccordion(18)}
                                                    aria-expanded={openIndex === 18}
                                                    aria-controls="collapseTwo"
                                                >
                                                    Repayment Terms
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseTwo"
                                                className={`accordion-collapse collapse ${openIndex === 18 ? "show" : ""}`}
                                                aria-labelledby="headingTwo"
                                            >
                                                <div className="accordion-body">
                                                Repayment terms are flexible, allowing students to repay the loan over a period of time that works best for them.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 data-aos="flip-up"  className="accordion-header" id="headingThree">
                                                <button 
                                                    className={`accordion-button ${openIndex === 19 ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => toggleAccordion(19)}
                                                    aria-expanded={openIndex === 19}
                                                    aria-controls="collapseThree"
                                                >
                                                    Low Interest Rates
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseThree"
                                                className={`accordion-collapse collapse ${openIndex === 19 ? "show" : ""}`}
                                                aria-labelledby="headingThree"
                                            >
                                                <div className="accordion-body">
                                                It offers competitive interest rates, making it easier for students to manage the cost of their education.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 data-aos="flip-up"  className="accordion-header" id="headingThree">
                                                <button 
                                                    className={`accordion-button ${openIndex === 20 ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => toggleAccordion(20)}
                                                    aria-expanded={openIndex === 20}
                                                    aria-controls="collapseThree"
                                                >
                                                    Easy Application Process
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseThree"
                                                className={`accordion-collapse collapse ${openIndex === 20 ? "show" : ""}`}
                                                aria-labelledby="headingThree"
                                            >
                                                <div className="accordion-body">
                                                The loan application process is straightforward and designed to be user-friendly, making it easy for students to apply for the loan.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 data-aos="flip-up"  className="accordion-header" id="headingThree">
                                                <button 
                                                    className={`accordion-button ${openIndex === 21 ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => toggleAccordion(21)}
                                                    aria-expanded={openIndex === 21}
                                                    aria-controls="collapseThree"
                                                >
                                                    Personalized Customer Support
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseThree"
                                                className={`accordion-collapse collapse ${openIndex === 21 ? "show" : ""}`}
                                                aria-labelledby="headingThree"
                                            >
                                                <div className="accordion-body">
                                                The loan product comes with personalized customer support, ensuring that students have the support they need throughout the loan process
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pb-lg-0 pb-5 col-lg-6 d-none d-lg-block">
                            <div className="PService-thumb1">
                                <img data-aos="fade-right"
                                    src="/assets/images/Pservice4.jpg"
                                    alt=""
                                    className="hover-effect"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductServices;
