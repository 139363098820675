import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import HomePageService from "../../../Services/HomePageService";
import SubmitLoader from "../../common/SubmitLoader"

const Subscribe = () => {
    const [loader, setLoader] = useState(false);
    const serve = new HomePageService();
    const [value, setValue] = useState({
        email: "",
    });
    const onSubmit = async (values, { resetForm }) => {
        const formData = new FormData();
        formData.set("email", values.email);
        setLoader(true);
        const config = {
            headers: {
                content: "multipart/form-data",
            },
        };
        try {
            const response = await axios.post(
                "https://educationmandal.com/em-admin/api/" + `subscriber`,
                formData,
                config
            );
            if (response.data.status === "success") {
                toast.success(response?.data?.message);
                resetForm();
                setTimeout(() => {
                    document.getElementById("work_with_us").reset();
                }, 5000);
                setLoader(false);
            } else {
                toast.error(response.data?.data?.email);
                setLoader(false);
            }
        } catch (err) {
            throw err;
        }

    };
    const ValidateSchema = Yup.object().shape({
        email: Yup.string().required("Required"),
    });
    return (
        <>
            <section
                className=""
            >
                <div className="">
                    <Formik
                        initialValues={value}
                        onSubmit={onSubmit}
                        enableReinitialize={true}
                        validationSchema={ValidateSchema}
                    >
                        {(props) => (
                            <form onSubmit={props.handleSubmit}>
                                <div className="row">
                                    {/* <div className="col-lg-6">
                                            <h1 className="mb-2 text-white">
                                                Subscribe
                                            </h1>
                                            <p className="fs-18 mb-0 text-white">
                                                Join our learning community - Subscribe now!
                                            </p>
                                        </div> */}
                                    <div className="col-lg-12 p-0">
                                        <div className="input-group sub-input mt-4 rounded" style={{ border: "2px solid rgb(0 0 0 / 12%)" }}>
                                            
                                            <input
                                                className="form-control input-lg"
                                                placeholder="Please enter your email"
                                                type="email"
                                                name="email"
                                                value={props.values.email}
                                                onChange={props.handleChange}

                                            />
                                            <div className="input-group-text">
                                                {loader ? (
                                                    <SubmitLoader />
                                                ) : (
                                                    <button
                                                        type="submit"
                                                        className="btn btn-danger br-ts-md-0 br-bs-md-0 btn-sm"
                                                    >
                                                        Subscribe
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                        {props.touched.email && props.errors.email ? (
                                            <div className="bg-error">
                                                {props.errors.email}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
                <ToastContainer />
            </section>
        </>
    );
};

export default Subscribe;
