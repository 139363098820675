import React, { useContext } from "react";
import { Context } from "../../Context";
import { useHistory } from "react-router-dom";

export default function PasswordPopup() {
    const history = useHistory()
    const { activities } = useContext(Context);
    const [active, setActive] = activities

    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const user_type = local_storage.type;

    const PasswordChangeHandler = () => {
        if (user_type == "institute") {
            history.push("/institute-dashboard/change-password")
        } else {
            history.push("/teacher-dashboard/change-password")
        }
    }
    return (
        <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={active ? { display: "block" } : { display: "none" }}
        >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Please change password</h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => setActive(false)}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>To enhance the security of your Education Mandal account, we request you to change your password.</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={PasswordChangeHandler}>
                            Change
                        </button>
                        <button
                            type="button"
                            className="btn btn-danger"
                            data-dismiss="modal"
                            onClick={() => setActive(false)}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
