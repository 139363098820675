import React, { useState } from "react";
import { Rating } from "react-simple-star-rating";
import axios from "axios";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SubmitLoader from "../../../../common/SubmitLoader";
import { Formik } from "formik";
import { Editor } from "@tinymce/tinymce-react";

export default function ReviewForm({ data }) {
    const [error, setError] = useState(true);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState({
        review: "",
        discription: "",
    });

    const ValidateSchema = Yup.object().shape({
        review: Yup.string().required("Required"),
        discription: Yup.string()
            .required("Required")
            .max(500, "Please write less than 500 characters !"),
    });

    const onSubmit = async (values, { resetForm }) => {
        const formdata = new FormData();
        formdata.append("course_id", data?.id);
        formdata.append("course_type", data?.type);
        formdata.append("overall_review", values.review);
        formdata.append("comment", values.discription);

        let auth = JSON.parse(localStorage.getItem("common-user-info"));
        const config = {
            headers: {
                content: "multipart/form-data",
                AUTHTOKEN: auth && auth?.auth_token,
            },
        };
        setLoading(true);
        try {
            const response = await axios.post(
                "https://educationmandal.com/em-admin/api/" + `add-course-review`,
                formdata,
                config
            );
            if (response.data?.status === "success") {
                toast.success(response.data?.message);
                setLoading(false);
                resetForm();
            } else {
                toast.error(response.data?.message);
                setLoading(false);
            }
        } catch (err) {
            throw err;
        }
    };

    const handleRating = (rate, props) => {
        props.setFieldValue("review", rate);
        setError(false);
    };
    const onPointerEnter = () => console.log("Enter");
    const onPointerLeave = () => console.log("Leave");
    const onPointerMove = (value, index) => console.log(value, index);
    return (
        <>
            <Formik
                initialValues={value}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validationSchema={ValidateSchema}
            >
                {(props) => (
                    <form onSubmit={props.handleSubmit}>
                        <div className="form-group col-md-12">
                            <Rating
                                onClick={(rate) => handleRating(rate, props)}
                                onPointerEnter={onPointerEnter}
                                onPointerLeave={onPointerLeave}
                                onPointerMove={onPointerMove}
                            />
                            {error && <div className="bg-error">Required</div>}
                        </div>
                        <div className="form-group col-md-12">
                            <Editor
                                textareaName='discription'
                                placeholder='Enter discription'
                                apiKey='9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d'
                                init={{
                                    plugins: [
                                        "advlist",
                                        "autolink",
                                        "lists",
                                        "link",
                                        "image",
                                        "charmap",
                                        "preview",
                                        "anchor",
                                        "searchreplace",
                                        "visualblocks",
                                        "code",
                                        "fullscreen",
                                        "insertdatetime",
                                        "media",
                                        "table",
                                        "help",
                                        "wordcount",
                                        "spellchecker",
                                    ],

                                    toolbar:
                                        " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help |spellchecker ",
                                }}
                                value={props?.values?.discription}
                                onEditorChange={(e) =>
                                    props.handleChange({
                                        target: {
                                            name: `discription`,
                                            value: e,
                                        },
                                    })
                                }
                            />
                            <p className="text-end mb-0">{props.values.discription?.length} / 500 Characters</p>
                            {props.touched.discription && props.errors.discription ? (
                                <div className="bg-error">
                                    {props.errors.discription}
                                </div>
                            ) : null}
                        </div>
                        <div className="col-md-12 text-end">
                            {loading ? (
                                <SubmitLoader />
                            ) : (
                                <button type="submit" className="btn btn-primary">
                                    Submit
                                </button>
                            )}
                        </div>
                    </form>
                )}
            </Formik>
            <ToastContainer />
        </>
    );
}
