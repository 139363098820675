import React, { useEffect, useState, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { handleLogout } from "../../../utils/utils";
import url from "../../../constants/url";
import NavbarLogo from "../../common/NavbarLogo";
import useWindowSize from "../../../hooks/useWindowSize";
import NotificationData from "../Services/NotificationData";

const SidebarNav = (props) => {
    const { width: windowWidth } = useWindowSize();
    const history = useHistory();
    const [active, setActive] = useState({});
    const [isTabActive, setIsTabActive] = useState("Dashboard");
    const [notification, setNotification] = useState([]);
    const [count, setCount] = useState([]);
    const params = useParams();
    const id = params?.id;
    const serve = new NotificationData();
    const handleActiveChange = (activeName) => {
        setIsTabActive(activeName);
    };
    const clickHandle = (i) => {
        setActive((prev) => ({ [i]: !prev[i] }));
    };
    const ref = useRef();
    const [openList, setOpenList] = useState(false);
    const [openList1, setOpenList1] = useState(false);
    const dashBoardHandler = () => {
        setOpenList(!openList);
    };
    const dashBoardHandler1 = () => {
        setOpenList1(!openList1);
    };
    const handleSidebar = () => {
        props.setIsSidebarOpen(!props.isSidebarOpen);
    };
    const [data, setData] = useState([]);
    const [greetings, setGreetings] = useState([]);

    const CardDetails = async () => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        fetch(`${url.apiUrl}institute/dashboard`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setData(result.data);
                    setGreetings(result.greetings);
                }
            })
            .catch((error) => console.log("error", error));
    };

    useEffect(() => {
        CardDetails();
        getNotificatinListData();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!ref?.current?.contains(event.target)) {
                setOpenList1(false);
            } else {
            }
        };
        document.addEventListener("click", handleClickOutside, true);
    }, [ref]);


    const getNotificatinListData = async () => {
        try {
            let response = await serve.getNotificatinList({
                limit: 4,
                offset: 0,
                status: 'unread',
            });
            if (response) {
                setNotification(response.data);
                setCount(response.count);
            }
        } catch (err) {
            throw err;
        }
    }

    const statusHandler = async (id, url) => {
        try {
            let response = await serve.getNotificatinStatusCheck(id);
            if (response) {
                history.push(url);
            }
        } catch (err) {
            throw err;
        }
    };

    const notificationProfile = (item, image_url) => {
        if (item?.type == "student") {
            var profile_url = "/institute-dashboard/payment";
            var profile_image = item?.notify?.image;
        } else if (item?.type == "class_course") {
            var profile_url = "/institute-dashboard/class-detail/1/" + item?.redirect_id + "/class_course";
            var profile_image = item?.notify?.thumbnail;
        } else if (item?.type == "updated_class_course") {
            var profile_url = "/institute-dashboard/class-detail/0/" + item?.redirect_id + "/class_course";
            var profile_image = item?.notify?.thumbnail;
        } else if (item?.type == "class_slot") {
            var profile_url = "/institute-dashboard/slot-view/" + item?.redirect_id;
            var profile_image = item?.notify?.thumbnail;
        } else if (item?.type == "teacher") {
            var profile_url = "/institute-dashboard/teacher-detail/" + item?.redirect_id;
            var profile_image = item?.notify?.image;
        } else if (item?.type == "career") {
            var profile_url = "/institute-dashboard/career-detail/1/" + item?.redirect_id + "/career";
            var profile_image = item?.notify?.thumb_image;
        } else if (item?.type == "updated_career") {
            var profile_url = "/institute-dashboard/career-detail/0/" + item?.redirect_id + "/career";
            var profile_image = item?.notify?.thumb_image;
        } else if (item?.type == "career_application") {
            var profile_url = "/institute-dashboard/applicants-list/" + item?.redirect_id + "/career";
            var profile_image = item?.notify?.thumb_image;
        } else if (item?.type == "online_course") {
            var profile_url = "/institute-dashboard/video-course-detail/1/" + item?.redirect_id + "/online_course";
            var profile_image = item?.notify?.teaser_thumb_image;
        } else if (item?.type == "updated_online_course") {
            var profile_url = "/institute-dashboard/video-course-detail/0/" + item?.redirect_id + "/online_course";
            var profile_image = item?.notify?.teaser_thumb_image;
        } else if (item?.type == "approve_teacher_course ") {
            var profile_url = "/institute-dashboard/video-course-approval" + item?.redirect_id + "/online_course";
            var profile_image = item?.notify;
        } else if (item?.type == "attendance") {
            var profile_url = "/institute-dashboard/attendance-edit" + item?.redirect_id;
            var profile_image = item?.notify?.thumbnail;
        }
        if (image_url == "image") {
            return profile_image;
        } else if (image_url == "url") {
            return profile_url;
        }
    }
    return (
        <>
            <div className="app-header header py-2 d-flex">
                <div className="container-fluid">
                    <div className="d-flex">
                        <Link className="header-brand" to="/">
                            <NavbarLogo />
                        </Link>
                        <Link
                            className="app-sidebar__toggle text-center"
                            to="#"
                            onClick={handleSidebar}
                        >
                            <i className="fa-solid fs-18 fa-bars"></i>
                        </Link>

                        <div className="d-flex ms-md-auto">
                            <div className="p-0 mb-0 navbar navbar-expand-lg responsive-navbar">
                                <div className="navbar-collapse collapse" id="navbarSupportedContent-4" >
                                    <div className="d-flex">
                                        <div className="dropdown header-notification">
                                            <Link
                                                to="#"
                                                className={
                                                    openList1 ? "nav-link icon show" : "nav-link icon"
                                                }
                                                data-bs-toggle="dropdown"
                                                aria-expanded={openList1 ? "true" : "false"}
                                                onClick={dashBoardHandler1}
                                            >
                                                <i className="fa-regular fa-bell"></i>
                                                <span className="badge badge-danger badge-pill">
                                                    {count}
                                                </span>
                                            </Link>
                                            <div
                                                ref={ref}
                                                className={
                                                    openList1
                                                        ? "dropdown-menu dropdown-menu-end dropdown-menu-arrow show"
                                                        : "dropdown-menu dropdown-menu-end dropdown-menu-arrow"
                                                }
                                            >
                                                <Link
                                                    to="#"
                                                    className="dropdown-item font-weight-semibold text-center header-message-dropdown w-auto"
                                                >
                                                    You have {count} notification
                                                </Link>
                                                <div className="dropdown-divider"></div>
                                                {notification?.length > 0 ? (
                                                    notification?.map((item, i) => (
                                                        <div className="col-xl-12" key={i}>
                                                            <Link to="#" onClick={() => { statusHandler(item?.id, notificationProfile(item, 'url')); }} className="dropdown-item d-flex p-0 bg-gray">
                                                                <div className="notify-img">
                                                                    <img alt={item?.title} src={notificationProfile(item, 'image')} className="me-3 rounded-circle" />
                                                                </div>
                                                                <div className="header-message-dropdown">
                                                                    <div className="d-flex justify-content-start"><span className="text-data-over-cut1 font-weight-semibold">{item?.title} </span></div>

                                                                    <div className="small text-muted"><i className="fa fa-clock-o me-2"></i>{item?.created_at}</div>
                                                                </div>
                                                            </Link>
                                                            <div className="dropdown-divider"></div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <>
                                                        <p className="text-center mb-0">No Data Available !</p>
                                                        <div className="dropdown-divider"></div>
                                                    </>
                                                )}
                                                <Link
                                                    to='/institute-dashboard/notifications'
                                                    className="dropdown-item text-center header-message-dropdown w-auto"
                                                >
                                                    See all Notification
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="dropdown header-user">
                                            <Link
                                                to="#"
                                                className="nav-link leading-none user-img d-flex"
                                                onClick={dashBoardHandler}
                                            >
                                                {windowWidth >= 768 && (
                                                    <div className="user-nav me-2">
                                                        <p className="user-name text-capitalize text-end mb-0">{greetings},</p>
                                                        <p className="user-status mb-0"> {data?.name}</p>
                                                    </div>
                                                )}
                                                <img
                                                    src={data?.image}
                                                    alt="profile-img"
                                                    className="avatar img-thumbnail"
                                                />
                                            </Link>
                                            <div
                                                className={
                                                    openList
                                                        ? "dropdown-menu dropdown-menu-end dropdown-menu-arrow show"
                                                        : "dropdown-menu dropdown-menu-end dropdown-menu-arrow"
                                                }
                                            >
                                                <Link
                                                    className="dropdown-item"
                                                    to="/institute-dashboard/institute-profile"
                                                    onClick={dashBoardHandler}
                                                ><i className="dropdown-icon fa-solid fa-user "></i> My Profile
                                                </Link>
                                                <Link
                                                    className="dropdown-item border-top"
                                                    to="#"
                                                    onClick={handleLogout}
                                                ><i className="dropdown-icon fa-solid fa-power-off"></i>  Log out
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {props.isSidebarOpen && (
                <aside className="app-sidebar">
                    <Link className="header-brand sidemenu-header-brand" to="/">
                        <NavbarLogo />
                    </Link>
                    <ul className="side-menu open"> 
                        <li className="slide">
                            <Link className={`side-menu__item slide-show ${isTabActive === "Dashboard" ? "active" : ""}`}
                                to="/institute-dashboard"
                                onClick={() => handleActiveChange("Dashboard")}
                            >
                                <i className="fa-solid fa-house side-menu__icon"></i>
                                Dashboard
                            </Link>
                        </li>
                        <li className="slide">
                            <Link
                                className={`side-menu__item slide-show ${isTabActive === "Branch" ? "active" : ""}`}
                                to="/institute-dashboard/branch"
                                onClick={() => handleActiveChange("Branch")}
                            >
                                <i className="fa-solid fa-code-branch side-menu__icon"></i>
                                Branch
                            </Link>
                        </li>
                        <li className="slide">
                            <Link
                                className={`side-menu__item slide-show ${isTabActive === "Teachers" ? "active" : ""}`}
                                onClick={() => handleActiveChange("Teachers")}
                                to="/institute-dashboard/teacher"
                            >
                                <i className="fa-solid fa-chalkboard-user side-menu__icon"></i>
                                Teachers
                            </Link>
                        </li>
                        <li className={active[1] ? "slide is-expanded" : "slide"} onClick={() => clickHandle(1)} >
                            <Link className={`side-menu__item slide-show ${isTabActive === "Class" ? "active" : ""}`} to="#"
                                onClick={() => handleActiveChange("Class")} >
                                <i className="fa-solid fa-school side-menu__icon"></i>
                                <span className="side-menu__label">Classroom Course</span>
                                {active[1] ? (
                                    <i className="fa-sharp fa fa-chevron-down"></i>
                                ) : (
                                    <i className="fa fa-chevron-right"></i>
                                )}
                            </Link>
                            {active[1] && (
                                <ul className="slide-menu">
                                    <li>
                                        <Link to="/institute-dashboard/class" className="slide-item">
                                            Classroom List
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="/institute-dashboard/subject"
                                            className="slide-item"
                                        >
                                            Subject List
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/institute-dashboard/slot" className="slide-item">
                                            Time Slot List
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/institute-dashboard/revised-class" className="slide-item">
                                            Revised Class
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </li>
                        <li className={active[0] ? "slide is-expanded" : "slide"} onClick={() => clickHandle(0)} >
                            <Link className={`side-menu__item slide-show ${isTabActive === "Video Course" ? "active" : ""}`}
                                onClick={() => handleActiveChange("Video Course")}
                                to="#"
                            >
                                <i className="fa-solid fa-video side-menu__icon"></i>
                                <span className="side-menu__label">Video Course</span>
                                {active[0] ? (
                                    <i className="fa-sharp fa fa-chevron-down"></i>
                                ) : (
                                    <i className="fa fa-chevron-right"></i>
                                )}
                            </Link>
                            {active[0] && (
                                <ul className="slide-menu">
                                    <li>
                                        <Link to="/institute-dashboard/video-course" className="slide-item">
                                            Video Course
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/institute-dashboard/revised-video-course" className="slide-item">
                                            Revised Course
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/institute-dashboard/video-course-approval" className="slide-item">
                                            Approval Pending
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </li>
                        <li className="slide">
                            <Link
                                className={`side-menu__item slide-show ${isTabActive === "Attendance" ? "active" : ""
                                    }`}
                                to="/institute-dashboard/attendance"
                                onClick={() => handleActiveChange("Attendance")}
                            >
                                <i className="fa-solid fa-clipboard-user side-menu__icon"></i>
                                Attendance
                            </Link>
                        </li>
                        <li className="slide">
                            <Link
                                className={`side-menu__item slide-show ${isTabActive === "Students" ? "active" : ""
                                    }`}
                                to="/institute-dashboard/student"
                                onClick={() => handleActiveChange("Students")}
                            >
                                <i className="fa-solid fa-users side-menu__icon"></i>
                                Students
                            </Link>
                        </li>

                        <li className="slide">
                            <Link
                                className={`side-menu__item slide-show ${isTabActive === "Payment" ? "active" : ""
                                    }`}
                                to="/institute-dashboard/payment"
                                onClick={() => handleActiveChange("Payment")}
                            >
                                <i className="fa-solid fa-indian-rupee-sign side-menu__icon"></i>
                                Payment
                            </Link>
                        </li>
                        <li className="slide">
                            <Link
                                className={`side-menu__item slide-show ${isTabActive === "Revenue" ? "active" : ""
                                    }`}
                                to="/institute-dashboard/revenue"
                                onClick={() => handleActiveChange("Revenue")}
                            >
                                <i className="fa-solid fa-piggy-bank side-menu__icon"></i>
                                Revenue
                            </Link>
                        </li>
                        <li
                            className={active[3] ? "slide is-expanded" : "slide"}
                            onClick={() => clickHandle(3)}
                        >
                            <Link className={`side-menu__item slide-show ${isTabActive === "Careers" ? "active" : ""}`}
                                to="#"
                                onClick={() => handleActiveChange("Careers")} >
                                <i className="fa-solid fa-bag-shopping side-menu__icon"></i>
                                <span className="side-menu__label">Careers</span>
                                {active[3] ? (<i className="fa-sharp fa fa-chevron-down"></i>) : (<i className="fa fa-chevron-right"></i>)}
                            </Link>
                            {active[3] && (
                                <ul className="slide-menu">
                                    <li>
                                        <Link
                                            to="/institute-dashboard/careers"
                                            className="slide-item"
                                        >
                                            Careers
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="/institute-dashboard/revised-careers"
                                            className="slide-item"
                                        >
                                            Revised Careers
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </li>
                        <li
                            className={active[2] ? "slide is-expanded" : "slide"}
                            onClick={() => clickHandle(2)}
                        >
                            <Link
                                className={`side-menu__item slide-show ${isTabActive === "Setting" ? "active" : ""
                                    }`}
                                to="#"
                                onClick={() => handleActiveChange("Setting")}
                            >
                                <i className="fa-solid fa-gear side-menu__icon"></i>
                                <span className="side-menu__label">Setting</span>
                                {active[2] ? (
                                    <i className="fa-sharp fa fa-chevron-down"></i>
                                ) : (
                                    <i className="fa fa-chevron-right"></i>
                                )}
                            </Link>
                            {active[2] && (
                                <ul className="slide-menu">
                                    <li>
                                        <Link
                                            to="/institute-dashboard/change-password"
                                            className="slide-item"
                                        >
                                            Change Password
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </li>
                        <hr />
                        <li className="slide">
                            <Link
                                className={`side-menu__item slide-show ${isTabActive === "Alumni" ? "active" : ""
                                    }`}
                                to="/institute-dashboard/alumni"
                                onClick={() => handleActiveChange("Alumni")}
                            >
                                <i className="fa-solid fa-piggy-bank side-menu__icon"></i>
                                Alumni (On Development)
                            </Link>
                        </li>
                    </ul >
                </aside >
            )}
        </>
    );
};

export default SidebarNav;
