import React from "react";

const SubmitLoader = () => {
    return (
        <button className="display-line-block align-items-center btn btn-primary" disabled="disabled">
            <strong className="me-2">Loading...</strong>
            <div className="spinner-border" ></div>
        </button>
    )
}

export default SubmitLoader; 