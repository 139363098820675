import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { ToastContainer, toast } from "react-toastify";
import SubmitLoader from "../../../../common/SubmitLoader";
import url from "../../../../../constants/url";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import { replaceWord } from "../../../../../utils/utils";
import moment from "moment";

const ClassEdit = () => {
    const history = useHistory()
    const [loader, setLoader] = useState(false);
    const [values, setValues] = useState({
        branch_id: "",
        class_type: "",
        tuition_type: "",
        board: "",
        section: "",
        university: "",
        semester: "",
        class_name: "",
        otherMedium: "",
        medium: "",
        lecture_type: "",
        video_conference_link: "",
        price: "",
        regular_price: "",
        is_discount: "",
        discount_price: "",
        discount_start_date: "",
        limited_offer: "",
        discount_end_date: "",
        description: "",
        thumbnail: "",
        requirements: "",
        curriculum: "",
        status: "",
        admin_remark: "",
    });
    const [medium, setMedium] = useState([]);
    const [sections, setSections] = useState([
        { title: 'A', id: 'A' },
        { title: 'B', id: 'B' },
        { title: 'C', id: 'C' },
        { title: 'D', id: 'D' },
        { title: 'E', id: 'E' },
        { title: 'F', id: 'F' },
        { title: 'G', id: 'G' },
        { title: 'H', id: 'H' },
        { title: 'I', id: 'I' },
        { title: 'J', id: 'J' },
        { title: 'K', id: 'K' },
        { title: 'L', id: 'L' },
        { title: 'M', id: 'M' },
        { title: 'N', id: 'N' },
        { title: 'O', id: 'O' },
        { title: 'P', id: 'P' },
        { title: 'Q', id: 'Q' },
        { title: 'R', id: 'R' },
        { title: 'S', id: 'S' },
        { title: 'T', id: 'T' },
        { title: 'U', id: 'U' },
        { title: 'V', id: 'V' },
        { title: 'W', id: 'W' },
        { title: 'X', id: 'X' },
        { title: 'Y', id: 'Y' },
        { title: 'Z', id: 'Z' }
    ]);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const param = useParams();
    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage.auth_token;
    if (typeof local_data && local_data !== undefined && local_data !== null) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("AUTHTOKEN", auth_token);
    }

    const getMedium = async (e) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
                is_main: "1",
            }),
        };

        fetch(url.apiUrl + "get-mediums", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setMedium(result.data);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const handleFileInputChange = (e, name, props, image_dummy_field) => {
        let { file } = e.target.files[0];
        file = e.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function (e) {
            var image = new Image();
            image.src = e.target.result;
            image.onload = function () {
                var height = this.height;
                var width = this.width;
                if (height > 200 || width > 368) {
                    props.setFieldValue(name, "");
                    alert(
                        "Image size: width = 368px and height = 200px"
                    );

                } else if (height < 200 || width < 368) {
                    props.setFieldValue(name, "");

                    alert(
                        "Image size: width = 368px and height = 200px"
                    );
                } else {
                    getBase64(file)
                        .then((result) => {
                            file["base64"] = "true";
                            props.setFieldValue(name, file);
                            props.setFieldValue(image_dummy_field, result);
                        })
                        .catch((err) => { });
                }

            };
        };
    };

    const getBase64 = (file) => {
        return new Promise((resolve) => {
            let fileInfo;
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    const getClassDetail = async () => {
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        fetch(
            `${url.apiUrl}institute/class-lecture-detail/${param.is_main}/${param.id}`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    const assignValue = {
                        id: result.data.id,
                        class_id: result.data.class_id,
                        branch_id: result.data.branch_id,
                        medium: result.data.medium || "",
                        thumbnail: result.data.thumbnail,
                        class_type: result.data.class_type,
                        class_name: result.data.class_name,
                        institute_branch: result.data.institute_branch,
                        lecture_type: result.data.lecture_type,
                        video_conference_link: result.data.video_conference_link || "",
                        price: result.data.price,
                        regular_price: result.data.regular_price || "",
                        is_discount: result.data.is_discount,
                        discount_price: result.data.discount_price || "",
                        discount_start_date: result.data.discount_start_date || "",
                        is_limited_offer: result.data.is_limited_offer,
                        limited_offer: result.data.limited_offer || "",
                        discount_end_date: result.data.discount_end_date || "",
                        description: result.data.description || "",
                        requirements: result.data.requirements || "",
                        curriculum: result.data.curriculum || "",
                        status: result.data.status,
                        is_submit: result.data.is_submit,
                        is_update: result.data.is_update,
                        admin_remark: result?.data?.admin_remark?.admin_remark,
                    }
                    if (result.data.class_type === "school" || (result.data.class_type === "tuition" && result.data.tuition_type === "school")) {
                        assignValue.board = result.data.board || "";
                        if (result.data.class_type === "school") {
                            assignValue.section = result.data.section || "";
                        }
                    } else if (result.data.class_type === "university" || (result.data.class_type === "tuition" && result.data.tuition_type === "university")) {
                        assignValue.university = result.data.university || "";
                        assignValue.universities = result.data.universities;
                        assignValue.semester = result.data.semester || "";
                    }
                    if (result.data.class_type === "tuition") {
                        assignValue.tuition_type = result.data.tuition_type;
                    }
                    setValues(assignValue)
                }
            })
            .catch((error) => console.log("error", error));
    };

    const ValidateSchema = Yup.object().shape({
        branch_id: Yup.string().required('Required'),
        class_type: Yup.string().required('Required'),
        tuition_type: Yup.string().when('class_type', {
            is: (class_type) => class_type && class_type === 'tuition',
            then: Yup.string().required('Required')
        }),
        board: Yup.mixed().when(['class_type', 'tuition_type'], {
            is: ((class_type, tuition_type) => class_type && (class_type === 'school' || (class_type === 'tuition' && tuition_type === "school"))),
            then: Yup.string().required('Required')
        }).nullable(),
        section: Yup.mixed().when(['class_type', 'tuition_type'], {
            is: ((class_type, tuition_type) => class_type && class_type === 'school'),
            then: Yup.string().required('Required')
        }).nullable(),
        university: Yup.mixed().when(['class_type', 'tuition_type'], {
            is: ((class_type, tuition_type) => class_type && (class_type === 'university' || (class_type === 'tuition' && tuition_type === "university"))),
            then: Yup.string().required('Required')
        }).nullable(),
        semester: Yup.mixed().when(['class_type', 'tuition_type'], {
            is: ((class_type, tuition_type) => class_type && (class_type === 'university' || (class_type === 'tuition' && tuition_type === "university"))),
            then: Yup.string().required('Required')
        }).nullable(),
        class_name: Yup.string().required('Required'),
        otherMedium: Yup.string().when('medium', {
            is: (board) => board && board === 'other',
            then: Yup.string().required('Required')
        }),
        medium: Yup.string().required('Required'),
        lecture_type: Yup.string().required('Required'),
        video_conference_link: Yup.string().matches(
            /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/,
            'Enter correct url!'
        ).when('lecture_type', {
            is: (lecture_type) => lecture_type && (lecture_type === 'both' || lecture_type === "video_conference"),
            then: Yup.string().required('Required')
        }).nullable(),
        price: Yup.string().required('Required'),
        regular_price: Yup.mixed().when('price', {
            is: (price) => price && price === 'paid',
            then: Yup.string().required('Required')
        }).nullable(),
        is_discount: Yup.mixed().when('price', {
            is: (price) => price && price === 'paid',
            then: Yup.string().required('Required')
        }).nullable(),
        discount_price: Yup.mixed().when('price', {
            is: (price) => price && price === 'paid',
            then: Yup.string().when('is_discount', {
                is: (is_discount) => is_discount && is_discount === '1',
                then: Yup.string()
                    .required('Required')
                    .test('is-between-1-to-99', 'Value must be between 1 to 99', (value) => {
                        return value >= 1 && value <= 99;
                    })
            })
        }).nullable(),
        discount_start_date: Yup.mixed()
            .when('price', {
                is: (price) => price && price === 'paid',
                then: Yup.string().when('is_discount', {
                    is: (is_discount) => is_discount && is_discount === '1',
                    then: Yup.string().required('Required')
                })
            }).nullable(),
        is_limited_offer: Yup.mixed()
            .when('price', {
                is: (price) => price && price === 'paid',
                then: Yup.string().when('is_discount', {
                    is: (is_discount) => is_discount && is_discount === '1',
                    then: Yup.string().required('Required')
                })
            }).nullable(),
        discount_end_date: Yup.mixed().when(['price', 'is_limited_offer'], {
            is: (price, is_limited_offer) => price && price === 'paid' && is_limited_offer === '1',
            then: Yup.date().required('Required')
        }).nullable(),
        otherMedium: Yup.string().when('medium', {
            is: (board) => board && board === 'other',
            then: Yup.string().required('Required')
        }),
        admin_remark: Yup.string().when(['status', 'is_submit', 'is_update'], {
            is: (status, is_submit, is_update) => (status && status === 'active') || (is_submit === 0 && is_update !== 0),
            then: Yup.string().required('Required')
        }),
        medium: Yup.string().required('Required'),
        description: Yup.string().required('Required'),
        thumbnail: Yup.mixed().required('Required'),
        requirements: Yup.string().required('Required'),
        curriculum: Yup.string().required('Required'),
    });

    const getchClassnameValid = async (values, setFieldError) => {
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);
        myHeaders.append("Content-Type", "application/json");
        var payloads = {};
        switch (values.class_type) {
            case 'school': {
                payloads = {
                    section: values.section,
                    fieldName: `class_name`,
                    board: values.board,
                }
                break;
            }
            case 'university': {
                payloads = {
                    semester: values.semester,
                    fieldName: `class_name`,
                    university: values.university,
                }
                break;
            }
            case 'preparation': {
                payloads = {
                    fieldName: `class_name`
                }
                break;
            }
            case 'tuition': {
                switch (values.tuition_type) {
                    case 'school': {
                        payloads = {
                            section: values.section,
                            fieldName: `class_name`,
                            tuition_type: values.tuition_type,
                            board: values.board,
                        }
                        break;
                    }
                    case 'university': {
                        payloads = {
                            semester: values.semester,
                            fieldName: `class_name`,
                            tuition_type: values.tuition_type,
                            university: values.university,
                        }
                        break;
                    }
                    case 'preparation': {
                        payloads = {
                            fieldName: `class_name`,
                            tuition_type: values.tuition_type
                        }
                        break;
                    }
                }
            }
        }
        let data = {
            branch_ids: values?.branch_id,
            class_type: values?.class_type,
            class_name: values?.class_name,
            class_id: (param.is_main == "1") ? values.id : values.class_id
        }
        var hasErrors = false
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
                ...data,
                ...payloads
            }),
        };

        return fetch(url.apiUrl + "institute/check-class-exist", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                } else {
                    hasErrors = true
                    if (result.data) {
                        setFieldError(result.data);
                    } else {
                        setFieldError(payloads.fieldName, result.message)
                    }
                    toast.error(result.message);
                }
            }).catch(error => { setFieldError(payloads.fieldName, 'Classname not available.'); hasErrors = true; })
        return hasErrors
    };

    const onSubmit = async (values, { setFieldError }) => {
        try {
            const hasErrors = await getchClassnameValid(values, setFieldError);
            if (hasErrors) {
                return
            }
            setLoader(true)

            const data = new FormData();
            data.append('branch_id', values.branch_id)
            data.append(`class_type`, values.class_type);
            if (values.class_type === 'tuition') {
                data.append(`tuition_type`, values.tuition_type);
            }
            if (values.class_type === 'school' || (values.class_type === 'tuition' && values.tuition_type === "school")) {
                data.append(`board`, values.board);
                if (values.class_type === 'school') {
                    data.append(`section`, values.section);
                }
            } else if (values.class_type === 'university' || (values.class_type === 'tuition' && values.tuition_type === "university")) {
                data.append(`university`, values.university);
                data.append(`semester`, values.semester);
            }

            data.append(`class_name`, values.class_name);
            data.append(`lecture_type`, values.lecture_type);
            if (values.lecture_type === "both" || values.lecture_type === "video_conference") {
                data.append(`video_conference_link`, values.video_conference_link);
            }
            data.append(`description`, values.description);
            data.append(`price`, values.price);
            if (values.price == "paid") {
                data.append(`is_discount`, values.is_discount);
                data.append(`regular_price`, values.regular_price);
                data.append(`discount_price`, values.discount_price);
                data.append(`is_limited_offer`, values.is_limited_offer);
                data.append(`discount_start_date`, values.discount_start_date);
                data.append(`discount_end_date`, values.discount_end_date);
            }
            data.append(`curriculum`, values.curriculum);
            data.append(`requirements`, values.requirements);
            data.append(`medium`, values.medium);
            data.append(`other_medium`, values.otherMedium);
            data.append(`thumbnail`, values.thumbnail);
            if (values.status === "active" || (values.is_submit === 0 && values.is_update !== 0)) {
                data.append(`admin_remark`, values.admin_remark);
            }

            const response = await axios.post("https://educationmandal.com/em-admin/api/" + `institute/class-lecture-update/${param.is_main}/${param.id}`, data, { headers: { AUTHTOKEN: auth_token, 'Content-Type': 'multipart/form-data' } })
            if (response.data.status === 'success') {
                toast.success(response.data.message)
                setTimeout(() => {
                    if (response.data.data.is_main_course == "1") {
                        history.push('/institute-dashboard/class')
                    } else {
                        history.push('/institute-dashboard/revised-class')
                    }
                }, 5000);
            } else {
                toast.error(response.data?.message || 'Something went wrong')
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || error.message)
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        getClassDetail()
        getMedium();
    }, [])
    return (
        <>
            <ToastContainer />
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard/class">Classroom Course</Link>
                    </li>
                    <li className="breadcrumb-item active">Edit Classroom Course</li>
                </ol>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Edit Classroom Course</h4>
                        </div>
                        <div className="card-body">
                            <Formik
                                initialValues={values}
                                onSubmit={onSubmit}
                                enableReinitialize={true}
                                validationSchema={ValidateSchema}
                            >
                                {(props) => (
                                    <form onSubmit={props.handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-3 form-group">
                                                <label>
                                                    Branch <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter other branch"
                                                    onChange={props.handleChange}
                                                    name={`branch_id`}
                                                    value={props.values?.institute_branch?.name}
                                                    disabled
                                                />
                                                {props.errors.branch_id &&
                                                    props.touched.branch_id && (
                                                        <p className="bg-error">
                                                            {props.errors.branch_id}
                                                        </p>
                                                    )}
                                            </div>
                                            <div className="col-md-3 form-group">
                                                <label>
                                                    Class Type <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Class Type"
                                                    onChange={props.handleChange}
                                                    name={`class_type`}
                                                    value={replaceWord(props.values?.class_type)}
                                                    disabled
                                                />
                                                {props.errors.class_type &&
                                                    props.touched.class_type && (
                                                        <p className="bg-error">
                                                            {props.errors.class_type}
                                                        </p>
                                                    )}
                                            </div>
                                            {props.values?.class_type === "tuition" && (
                                                <div className="col-md-3 form-group">
                                                    <label>
                                                        Tuition Type <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Tuition Type"
                                                        onChange={props.handleChange}
                                                        name={`tuition_type `}
                                                        value={replaceWord(props.values?.tuition_type)}
                                                        disabled
                                                    />
                                                    {props.errors.tuition_type &&
                                                        props.touched.tuition_type && (
                                                            <p className="bg-error">
                                                                {props.errors.tuition_type}
                                                            </p>
                                                        )}
                                                </div>
                                            )}
                                            {(props.values?.class_type === "school" || props.values?.tuition_type === "school") && (
                                                <>
                                                    <div className="col-md-3 form-group">
                                                        <label>
                                                            Board <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Board"
                                                            onChange={props.handleChange}
                                                            name={`board`}
                                                            value={props.values?.board}
                                                            disabled
                                                        />
                                                        {props.errors.board &&
                                                            props.touched.board && (
                                                                <p className="bg-error">
                                                                    {props.errors.board}
                                                                </p>
                                                            )}
                                                    </div>
                                                    {props.values?.class_type === "school" && (
                                                        <div className="col-md-3 form-group">
                                                            <label>
                                                                Section <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Section"
                                                                onChange={props.handleChange}
                                                                name={`section`}
                                                                value={props.values?.section}
                                                                disabled
                                                            />
                                                            {props.errors.section &&
                                                                props.touched.section && (
                                                                    <p className="bg-error">
                                                                        {props.errors.section}
                                                                    </p>
                                                                )}
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                            {(props.values?.class_type === "university" || props.values?.tuition_type === "university") && (
                                                <>
                                                    <div className="col-md-3 form-group">
                                                        <label>University <span className="text-danger"> *</span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter University"
                                                            onChange={props.handleChange}
                                                            name={`university`}
                                                            value={props.values?.universities.name}
                                                            disabled
                                                        />
                                                        {props.errors.university &&
                                                            props.touched.university && (
                                                                <p className="bg-error">
                                                                    {props.errors.university}
                                                                </p>
                                                            )}
                                                    </div>
                                                    <div className="col-md-3 form-group">
                                                        <label>Semester <span className="text-danger">*</span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter semester"
                                                            onChange={props.handleChange}
                                                            name={`semester`}
                                                            value={props.values?.semester}
                                                            disabled
                                                        />
                                                        {props.errors.semester &&
                                                            props.touched.semester && (
                                                                <p className="bg-error">
                                                                    {props.errors.semester}
                                                                </p>
                                                            )}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 form-group">
                                                <label>
                                                    Class Name <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter class name"
                                                    onChange={props.handleChange}
                                                    name={`class_name`}
                                                    value={props.values?.class_name}
                                                />
                                                {props.errors.class_name &&
                                                    props.touched.class_name && (
                                                        <p className="bg-error">
                                                            {props.errors.class_name}
                                                        </p>
                                                    )}
                                            </div>
                                            <div className="col-md-3 form-group">
                                                <label>
                                                    Medium <span className="text-danger">*</span>
                                                </label>
                                                <select
                                                    className="form-select"
                                                    onChange={props.handleChange}
                                                    name={`medium`}
                                                    value={props.values?.medium}
                                                >
                                                    <option value="">Select medium</option>
                                                    {medium &&
                                                        medium.map((v, i) => (
                                                            <option value={v.title} key={i}>
                                                                {v.title}
                                                            </option>
                                                        ))}
                                                    <option value="other">Other</option>
                                                </select>
                                                {props.errors.medium &&
                                                    props.touched.medium && (
                                                        <p className="bg-error">
                                                            {props.errors.medium}
                                                        </p>
                                                    )}
                                            </div>
                                            {props.values?.medium === "other" && (
                                                <div className="col-md-3 form-group">
                                                    <label>
                                                        Other Medium <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter other medium"
                                                        onChange={props.handleChange}
                                                        name={`otherMedium`}
                                                        value={props.values?.otherMedium}
                                                    />
                                                    {props.errors.otherMedium &&
                                                        props.touched.otherMedium && (
                                                            <p className="bg-error">
                                                                {props.errors.otherMedium}
                                                            </p>
                                                        )}
                                                </div>
                                            )}
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 form-group">
                                                <label>
                                                    Thumbnail Image <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    accept="image/*"
                                                    onChange={(e) => {
                                                        handleFileInputChange(e, `thumbnail`, props, `image`);
                                                    }}
                                                    name={`thumbnail`}
                                                />
                                                <p className="fs-12 mb-0"><span className="text-danger">Note:</span> Image size: width = 368px and height = 200px</p>
                                                {props.errors.thumbnail &&
                                                    props.touched.thumbnail && (
                                                        <p className="bg-error">
                                                            {props.errors.thumbnail}
                                                        </p>
                                                    )}
                                            </div>
                                            <div className="col-md-2 form-group" >
                                                <img
                                                    src={props.values?.image || props.values?.thumbnail}
                                                    alt="image"
                                                    className="img-thumbnail"
                                                />
                                            </div>
                                            <div className="col-md-3 form-group">
                                                <label>
                                                    Classroom Type <span className="text-danger"> *</span>
                                                </label>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        type="radio"
                                                        name={`lecture_type`}
                                                        checked={
                                                            props.values?.lecture_type ===
                                                            "video_conference"
                                                        }
                                                        onChange={(e) =>
                                                            props.setFieldValue(
                                                                `lecture_type`,
                                                                e.target.value
                                                            )
                                                        }
                                                        className="form-check-input"
                                                        value="video_conference"
                                                    />
                                                    <label className="form-check-label">Online</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        type="radio"
                                                        name={`lecture_type`}
                                                        checked={
                                                            props.values?.lecture_type ===
                                                            "manual"
                                                        }
                                                        onChange={(e) =>
                                                            props.setFieldValue(
                                                                `lecture_type`,
                                                                e.target.value
                                                            )
                                                        }
                                                        className="form-check-input"
                                                        value="manual"
                                                    />
                                                    <label className="form-check-label">Offline</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        type="radio"
                                                        name={`lecture_type`}
                                                        checked={
                                                            props.values?.lecture_type ===
                                                            "both"
                                                        }
                                                        onChange={(e) =>
                                                            props.setFieldValue(
                                                                `lecture_type`,
                                                                e.target.value
                                                            )
                                                        }
                                                        className="form-check-input"
                                                        value="both"
                                                    />
                                                    <label className="form-check-label">Hybrid (Online + Offline)</label>
                                                </div>
                                                {props.errors.lecture_type &&
                                                    props.touched.lecture_type && (
                                                        <p className="bg-error">
                                                            {props.errors.lecture_type}
                                                        </p>
                                                    )}
                                            </div>
                                            {props.values?.lecture_type !== "manual" && (

                                                <div className="col-md-3 form-group">
                                                    <label>Video Conference Link <span className="text-danger"> *</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter video conference link"
                                                        onChange={props.handleChange}
                                                        name={`video_conference_link`}
                                                        value={props.values?.video_conference_link}
                                                    />
                                                    {props.errors.video_conference_link &&
                                                        props.touched.video_conference_link && (
                                                            <p className="bg-error">
                                                                {props.errors.video_conference_link}
                                                            </p>
                                                        )}

                                                </div>
                                            )}
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 form-group">
                                                <label>
                                                    Price Type <span className="text-danger"> *</span>
                                                </label>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        type="radio"
                                                        className="form-check-input"
                                                        value="free"
                                                        onChange={props.handleChange}
                                                        name={`price`}
                                                        checked={
                                                            props.values.price === "free"
                                                        }
                                                    />
                                                    <label className="form-check-label">Free</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        type="radio"
                                                        className="form-check-input"
                                                        value="paid"
                                                        onChange={props.handleChange}
                                                        name={`price`}
                                                        checked={
                                                            props.values?.price === "paid"
                                                        }
                                                    />
                                                    <label className="form-check-label">Paid</label>
                                                </div>
                                                {props.errors.price &&
                                                    props.touched.price && (
                                                        <p className="bg-error">
                                                            {props.errors.price}
                                                        </p>
                                                    )}
                                            </div>
                                            {props.values?.price === "paid" && (
                                                <>
                                                    <div className="col-md-3 form-group">
                                                        <label>
                                                            Regular Price <span className="text-danger"> *</span>
                                                        </label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="Enter regular price"
                                                            name={`regular_price`}
                                                            onChange={props.handleChange}
                                                            value={props.values?.regular_price}
                                                        />
                                                        {props.errors.regular_price &&
                                                            props.touched.regular_price && (
                                                                <p className="bg-error">
                                                                    {props.errors.regular_price}
                                                                </p>
                                                            )}
                                                    </div>
                                                    <div className="col-md-3 form-group">
                                                        <label>
                                                            Discount Type <span className="text-danger"> *</span>
                                                        </label>
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                type="radio"
                                                                className="form-check-input"
                                                                value="1"
                                                                name={`is_discount`}
                                                                onChange={props.handleChange}
                                                                checked={
                                                                    props.values?.is_discount == "1"
                                                                }
                                                            />
                                                            <label className="form-check-label">Yes</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                type="radio"
                                                                className="form-check-input"
                                                                value="0"
                                                                name={`is_discount`}
                                                                onChange={props.handleChange}
                                                                checked={
                                                                    props.values?.is_discount == "0"
                                                                }
                                                            />
                                                            <label className="form-check-label">No</label>
                                                        </div>
                                                        {props.errors.is_discount &&
                                                            props.touched.is_discount && (
                                                                <p className="bg-error">
                                                                    {props.errors.is_discount}
                                                                </p>
                                                            )}
                                                    </div>
                                                </>
                                            )}
                                            {props.values?.is_discount == "1" &&
                                                props.values?.price === "paid" && (
                                                    <>
                                                        <div className="col-md-3 form-group">
                                                            <label>
                                                                Discount Price (in %){" "}
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                placeholder="Enter discount price"
                                                                name={`discount_price`}
                                                                onChange={props.handleChange}
                                                                value={
                                                                    props.values?.discount_price
                                                                }
                                                            />
                                                            {props.errors.discount_price &&
                                                                props.touched.discount_price && (
                                                                    <p className="bg-error">
                                                                        {props.errors.discount_price}
                                                                    </p>
                                                                )}
                                                        </div>
                                                        <div className="col-md-3 form-group">
                                                            <label>
                                                                Price After Discount{" "}
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                placeholder="Price after discount "
                                                                disabled
                                                                name={`discount_price`}
                                                                value={props.values?.regular_price - (props.values?.regular_price * props.values?.discount_price / 100)}
                                                            />
                                                        </div>
                                                        <div className="col-md-3 form-group">
                                                            <label>
                                                                Discount Start Date{" "}
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                name={`discount_start_date`}
                                                                value={
                                                                    props.values?.discount_start_date
                                                                }
                                                                onChange={props.handleChange}
                                                                min={props.values?.discount_start_date || moment().format("YYYY-MM-DD")}
                                                            />
                                                            {props.errors.discount_start_date &&
                                                                props.touched
                                                                    ?.discount_start_date && (
                                                                    <p className="bg-error">
                                                                        {
                                                                            props.errors
                                                                                ?.discount_start_date
                                                                        }
                                                                    </p>
                                                                )}
                                                        </div>
                                                        <div className="col-md-3 form-group">
                                                            <label>
                                                                Limited Offer <span className="text-danger"> *</span>
                                                            </label>
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    value="1"
                                                                    checked={
                                                                        props.values?.is_limited_offer == "1"
                                                                    }
                                                                    onChange={props.handleChange}
                                                                    name={`is_limited_offer`}
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="validationRadio7"
                                                                >
                                                                    Yes
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    value="0"
                                                                    checked={
                                                                        props.values?.is_limited_offer == "0"
                                                                    }
                                                                    onChange={props.handleChange}
                                                                    name={`is_limited_offer`}
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="validationRadio8"
                                                                >
                                                                    No
                                                                </label>
                                                            </div>
                                                            {props.errors.is_limited_offer &&
                                                                props.touched.is_limited_offer && (
                                                                    <p className="bg-error">
                                                                        {props.errors.is_limited_offer}
                                                                    </p>
                                                                )}
                                                        </div>
                                                        {props.values?.is_limited_offer == "1" && (
                                                            <div className="col-md-3 form-group">
                                                                <label>
                                                                    Discount End Date{" "}
                                                                    <span className="text-danger">*</span>
                                                                </label>
                                                                <input
                                                                    type="date"
                                                                    className="form-control"
                                                                    name={`discount_end_date`}
                                                                    value={
                                                                        props.values?.discount_end_date
                                                                    }
                                                                    min={props.values?.discount_start_date || moment().format("YYYY-MM-DD")}
                                                                    onChange={props.handleChange}
                                                                />
                                                                {props.errors.discount_end_date &&
                                                                    (
                                                                        <p className="bg-error">
                                                                            {
                                                                                props.errors?.discount_end_date
                                                                            }
                                                                        </p>
                                                                    )}
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 form-group">
                                                <label>
                                                    Class Description <span className="text-danger">*</span>
                                                </label>
                                                <Editor
                                                    textareaName="course_discription"
                                                    placeholder="Enter course decryption"
                                                    apiKey="9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d"
                                                    init={{
                                                        plugins: [
                                                            "advlist",
                                                            "autolink",
                                                            "lists",
                                                            "link",
                                                            "image",
                                                            "charmap",
                                                            "preview",
                                                            "anchor",
                                                            "searchreplace",
                                                            "visualblocks",
                                                            "code",
                                                            "fullscreen",
                                                            "insertdatetime",
                                                            "media",
                                                            "table",
                                                            "help",
                                                            "wordcount",
                                                        ],

                                                        toolbar:
                                                            " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help ",
                                                    }}
                                                    value={props.values?.description}
                                                    onEditorChange={(e) =>
                                                        props.handleChange({
                                                            target: {
                                                                name: `description`,
                                                                value: e,
                                                            },
                                                        })
                                                    }
                                                />
                                                {props.errors.description &&
                                                    props.touched.description && (
                                                        <p className="bg-error">
                                                            {props.errors.description}
                                                        </p>
                                                    )}
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label>
                                                    What will students learn in this classroom? <span className="text-danger">*</span>
                                                </label>
                                                <Editor
                                                    textareaName="course_discription"
                                                    placeholder="Enter course decryption"
                                                    apiKey="9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d"
                                                    init={{
                                                        plugins: [
                                                            "advlist",
                                                            "autolink",
                                                            "lists",
                                                            "link",
                                                            "image",
                                                            "charmap",
                                                            "preview",
                                                            "anchor",
                                                            "searchreplace",
                                                            "visualblocks",
                                                            "code",
                                                            "fullscreen",
                                                            "insertdatetime",
                                                            "media",
                                                            "table",
                                                            "help",
                                                            "wordcount",
                                                        ],

                                                        toolbar:
                                                            " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help ",
                                                    }}
                                                    value={props.values?.curriculum}
                                                    onEditorChange={(e) =>
                                                        props.handleChange({
                                                            target: {
                                                                name: `curriculum`,
                                                                value: e,
                                                            },
                                                        })
                                                    }
                                                />
                                                {props.errors.curriculum &&
                                                    props.touched.curriculum && (
                                                        <p className="bg-error">
                                                            {props.errors.curriculum}
                                                        </p>
                                                    )}
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label>
                                                    Requirements <span className="text-danger">*</span>
                                                </label>
                                                <Editor
                                                    textareaName="course_discription"
                                                    placeholder="Enter course decryption"
                                                    apiKey="9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d"
                                                    init={{
                                                        plugins: [
                                                            "advlist",
                                                            "autolink",
                                                            "lists",
                                                            "link",
                                                            "image",
                                                            "charmap",
                                                            "preview",
                                                            "anchor",
                                                            "searchreplace",
                                                            "visualblocks",
                                                            "code",
                                                            "fullscreen",
                                                            "insertdatetime",
                                                            "media",
                                                            "table",
                                                            "help",
                                                            "wordcount",
                                                        ],

                                                        toolbar:
                                                            " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help ",
                                                    }}
                                                    value={props.values?.requirements}
                                                    onEditorChange={(e) =>
                                                        props.handleChange({
                                                            target: {
                                                                name: `requirements`,
                                                                value: e,
                                                            },
                                                        })
                                                    }
                                                />
                                                {props.errors.requirements &&
                                                    props.touched.requirements && (
                                                        <p className="bg-error">
                                                            {props.errors.requirements}
                                                        </p>
                                                    )}
                                            </div>

                                            {(props.values.status === "active" || (props.values.is_submit === 0 && props.values.is_update !== 0)) && (
                                                <div className="col-md-6 form-group">
                                                    <label>
                                                        Admin Remark <span className="text-danger">*</span>
                                                    </label>
                                                    <Editor
                                                        textareaName="admin_remark"
                                                        placeholder="Enter Admin Remark"
                                                        apiKey="9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d"
                                                        init={{
                                                            plugins: [
                                                                "advlist",
                                                                "autolink",
                                                                "lists",
                                                                "link",
                                                                "image",
                                                                "charmap",
                                                                "preview",
                                                                "anchor",
                                                                "searchreplace",
                                                                "visualblocks",
                                                                "code",
                                                                "fullscreen",
                                                                "insertdatetime",
                                                                "media",
                                                                "table",
                                                                "help",
                                                                "wordcount",
                                                            ],

                                                            toolbar:
                                                                " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help ",
                                                        }}
                                                        value={props.values?.admin_remark}
                                                        onEditorChange={(e) =>
                                                            props.handleChange({
                                                                target: {
                                                                    name: `admin_remark`,
                                                                    value: e,
                                                                },
                                                            })
                                                        }
                                                    />
                                                    {props.errors.admin_remark && (
                                                        <p className="bg-error">
                                                            {props.errors.admin_remark}
                                                        </p>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-12 text-end">
                                            {loader ? (
                                                <SubmitLoader />
                                            ) : (
                                                <>
                                                    <button type="submit" className="btn btn-primary me-2">
                                                        Submit
                                                    </button>
                                                    <Link
                                                        to="/institute-dashboard/class"
                                                        className="btn btn-danger"
                                                    >
                                                        Cancel
                                                    </Link>
                                                </>
                                            )}
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default ClassEdit;