import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import url from "../../../../../constants/url";
import moment from "moment";
const CourseClassStudent = () => {
    const [studentList, setStudentList] = useState([]);

    const params = useParams();
    const location = useLocation();
    const getStudentList = async (data) => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            limit: 10,
            offset: 0,
            course_type: params.type,
            course_id: Number(params.id),
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
        };

        fetch(`${url.apiUrl}institute-student/get-all-student-list`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setStudentList(result?.data);
                }
            })
            .catch((error) => console.log("error", error));
    };
    useEffect(() => {
        getStudentList(location.state);
    }, [location.state]);
    return (
        <>
            <div className='page-header'>
                <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                        <Link to='/institute-dashboard'>Dashboard</Link>
                    </li>
                    <li className='breadcrumb-item'>
                        <Link to='/institute-dashboard/student'>Students</Link>
                    </li>
                    <li className='breadcrumb-item active'>Course Wise Student Purchase History</li>
                </ol>
            </div>

            <div className='row'>
                <div className='col-md-12'>
                    <div className='card'>
                        <div className='card-header '>
                            <h4 className='card-title'>Course Wise Student Purchase History </h4>
                        </div>
                        <div className='card-body'>
                            <div
                                id='basic-1_wrapper'
                                className='dataTables_wrapper no-footer'
                            >
                                <table className='table table-bordered table-hover dataTable table-responsive-xl'>
                                    <thead>
                                        <tr>
                                            <th className='text-center'>Sr. No.</th>
                                            <th className='text-center'>Order ID</th>
                                            <th>Student Details</th>
                                            <th className='text-center'>Purchase Date </th>
                                            <th className='text-center'>Amount </th>
                                            <th className='text-center'>Payment Status </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {studentList?.length > 0 ? (
                                            studentList?.map((value, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <th className='text-center'>{1 + index}</th>
                                                        <td className='text-center'>{value?.payment?.orderId}</td>
                                                        <td>
                                                            <div className='media'>
                                                                <img
                                                                    src={value?.student?.image}
                                                                    className='me-3 rounded-circle' alt={value?.student?.name}
                                                                />
                                                                <div className='media-body'>
                                                                    <Link className="font-weight-semibold" to={`/institute-dashboard/student-profile/${value?.student?.id}`}>
                                                                        {value?.student?.name}
                                                                    </Link>
                                                                    <p className='mb-0 text-muted'>
                                                                        {value?.student?.email}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className='text-center'>
                                                            {moment(value?.payment_date.split(" ")[0]).format("DD-MM-YYYY hh:mm A")}
                                                        </td>
                                                        <td className='text-center'>
                                                            ₹ {Math.round(value?.total_price)}
                                                        </td>
                                                        <td className='text-center text-capitalize'>
                                                            {value?.payment_status}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td className='text-center' colSpan={7}>No Data Available !</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CourseClassStudent;
