import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from "react-router-dom";
import { replaceWord } from '../../../../../utils/utils';
import Moment from "react-moment";
import NoDataAvailable from "../../../../common/NoDataAvailable";

const StudentDetails = ({ student }) => {
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Student Details</h4>
                        </div>

                        <div className="card-body">
                            <div className="card">
                                <Tabs defaultActiveKey="about" id="uncontrolled-tab-example">
                                    <Tab eventKey="about" title="about">
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">About</h4>
                                            </div>

                                            <div className="card-body education-mandal" dangerouslySetInnerHTML={{ __html: student?.about ? student?.about : "No Data Available !" }}>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="education" title="education">
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">Education</h4>
                                            </div>
                                            {student?.education?.length > 0 && student?.education?.map((education, index) => (
                                                <div className="card-body" key={index}>
                                                    <ul className="d-flex mb-0">
                                                        <li>
                                                            <div className="activity1 bg-primary"><i className="fe fe-shopping-bag"></i></div>
                                                        </li>
                                                        <li>
                                                            <div className="font-weight-semibold">{education?.degree} ({education?.field_of_study})</div>
                                                            <p className="text-muted mb-0">{education?.school_college}</p>
                                                        </li>
                                                        <div className="ms-auto mt-3">
                                                            <Moment format='DD-MM-YYYY'>{education?.start_date}</Moment> to {education?.is_present === 1 ? (
                                                                "Present "
                                                            ) : (
                                                                <React.Fragment>
                                                                    <Moment format='DD-MM-YYYY'>{education?.end_date}</Moment>{" "}
                                                                </React.Fragment>
                                                            )}
                                                        </div>
                                                    </ul>
                                                </div>
                                            ))}
                                            {student?.education?.length == 0 && (
                                                <div className="card-body">
                                                    <NoDataAvailable />
                                                </div>
                                            )}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="experience" title="experience">
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">Experience</h4>
                                            </div>
                                            {student?.experience?.length > 0 && student?.experience?.map((experience, index) => (
                                                <div className="card-body" key={index}>
                                                    <ul className="d-flex mb-0">
                                                        <li>
                                                            <div className="activity1 bg-primary"><i className="fe fe-shopping-bag"></i></div>
                                                        </li>
                                                        <li>
                                                            <div className="font-weight-semibold">{experience?.title} ({experience?.employement_type === "full_time" ? "Full Time" : "Part Time"})</div>
                                                            <p className="text-muted mb-0">{experience?.company_name}</p>
                                                            <p className="text-muted mb-0">{experience?.location}</p>
                                                        </li>
                                                        <div className="ms-auto mt-3">

                                                            <Moment format='DD-MM-YYYY'>
                                                                {experience?.start_date}</Moment> to {experience?.is_present === 1 ? (
                                                                    "Present "
                                                                ) : (
                                                                    <React.Fragment>
                                                                        <Moment format='DD-MM-YYYY'>{experience?.end_date}</Moment>{" "}
                                                                    </React.Fragment>
                                                                )}
                                                        </div>
                                                    </ul>
                                                    <p className='text-muted mb-0 margin-left education-mandal'>{experience?.description}</p>
                                                </div>
                                            ))}
                                            {student?.experience?.length == 0 && (
                                                <div className="card-body">
                                                    <NoDataAvailable />
                                                </div>
                                            )}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="achievement" title="achievement">
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">Achievement</h4>
                                            </div>
                                            {student?.achievement?.length > 0 && student?.achievement?.map((achievement, index) => (
                                                <div className="card-body" key={index}>
                                                    <ul className="d-flex mb-0">
                                                        <li>
                                                            <div className="activity1 bg-primary"><i className="fe fe-shopping-bag"></i></div>
                                                        </li>
                                                        <li>
                                                            <div className="font-weight-semibold">{achievement?.title}</div>
                                                        </li>
                                                        <div className="ms-auto mt-3">
                                                            <Moment format='DD-MM-YYYY'>
                                                                {achievement?.date}</Moment>
                                                        </div>
                                                    </ul>
                                                    <p className='text-muted mb-0 margin-left education-mandal'>{achievement?.description}</p>
                                                </div>
                                            ))}
                                            {student?.achievement?.length == 0 && (
                                                <div className="card-body">
                                                    <NoDataAvailable />
                                                </div>
                                            )}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="class" title="Classroom Course">
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">Classroom Course</h4>
                                            </div>

                                            <div className="card-body">
                                                <table className="table table-bordered table-hover dataTable table-responsive-xl">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center">Sr. No.</th>
                                                            <th>Class Title</th>
                                                            <th>Branch Details</th>
                                                            <th className="text-center">Class Type</th>
                                                            <th className="text-center">Board/University</th>
                                                            <th className="text-center">Section/Semester</th>
                                                            <th className="text-center">Price</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {student?.class_course?.length > 0 && student?.class_course?.map((class_course, index) => (
                                                            <tr key={index}>
                                                                <th className="text-center">{index + 1}</th>
                                                                <td>
                                                                    <div className="media">
                                                                        <img
                                                                            src={class_course?.class_course?.thumbnail}
                                                                            alt={class_course?.class_course?.title}
                                                                            className="me-3 rounded-circle"
                                                                        />
                                                                        <div className="media-body">
                                                                            <Link
                                                                                to={"/institute-dashboard/class-detail/1/" + class_course?.class_course?.id}
                                                                                className="font-weight-semibold"
                                                                            >
                                                                                {class_course?.class_course?.title}
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="media">
                                                                        <img
                                                                            src={class_course?.class_course?.institute_branch?.image}
                                                                            alt={class_course?.class_course?.institute_branch?.name}
                                                                            className="me-3 rounded-circle"
                                                                        />
                                                                        <div className="media-body">
                                                                            <Link
                                                                                to={"/institute-dashboard/branch-detail/" + class_course?.class_course?.institute_branch?.id}
                                                                                className="font-weight-semibold"
                                                                            >
                                                                                {class_course?.class_course?.institute_branch?.name}
                                                                            </Link>
                                                                            <p className="mb-0">
                                                                                {class_course?.class_course?.institute_branch?.email}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="text-center text-capitalize">{replaceWord(class_course?.class_course?.class_type)} {class_course?.class_course?.class_type == "tuition" ? " : " + (replaceWord(class_course?.class_course?.tuition_type)) : ""}</td>
                                                                <td className="text-center">{class_course?.class_course?.board || class_course?.class_course?.universities?.name || "---"}</td>
                                                                <td className="text-center">{class_course?.class_course?.section || class_course?.class_course?.semester || "---"}</td>
                                                                <td className="text-center"> {class_course?.price_type == "free" ? "Free" : "₹" + class_course?.total_price} </td>
                                                            </tr>
                                                        ))}
                                                        {student?.class_course?.length == 0 && (
                                                            <tr className="text-center">
                                                                <td colSpan={7}>No Data Available !</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="course" title="Video Course">
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">Video Course</h4>
                                            </div>

                                            <div className="card-body">
                                                <table className="table table-bordered table-hover dataTable table-responsive-xl">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center">Sr. No.</th>
                                                            <th>Course Details</th>
                                                            <th>Course Category</th>
                                                            <th>Branch Details</th>
                                                            <th className="text-center">Price</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {student?.online_course?.length > 0 && student?.online_course?.map((online_course, index) => (
                                                            <tr key={index}>
                                                                <th className="text-center">{index + 1}</th>
                                                                <td>
                                                                    <div className="media">
                                                                        <img
                                                                            src={online_course?.online_course?.teaser_thumb_image}
                                                                            alt={online_course?.online_course?.title}
                                                                            className="me-3 rounded-circle"
                                                                        />
                                                                        <div className="media-body">
                                                                            <Link className="font-weight-semibold" to={"/institute-dashboard/video-course-detail/1/" + online_course?.online_course?.id}>
                                                                                {online_course?.online_course?.title}
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>{online_course?.online_course?.category?.title}</td>
                                                                <td>
                                                                    <div className="media">
                                                                        <img
                                                                            src={online_course?.online_course?.institute_branch?.image}
                                                                            alt={online_course?.online_course?.institute_branch?.name}
                                                                            className="me-3 rounded-circle"
                                                                        />
                                                                        <div className="media-body">
                                                                            <Link
                                                                                to={"/institute-dashboard/branch-detail/" + online_course?.online_course?.institute_branch?.id}
                                                                                className="font-weight-semibold"
                                                                            >
                                                                                {online_course?.online_course?.institute_branch?.name}

                                                                            </Link><p className="mb-0">
                                                                                {online_course?.online_course?.institute_branch?.email}</p>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="text-center">
                                                                    {online_course?.price_type == "free" ? "Free" : "₹" + online_course?.total_price}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        {student?.online_course?.length == 0 && (
                                                            <tr className="text-center">
                                                                <td colSpan={5}>No Data Available !</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div >
                    </div >
                </div >
            </div >
        </>
    );
};


export default StudentDetails;