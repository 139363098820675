import React, { useEffect, useState, useRef } from "react";
import Headerinner from "../../Header/Headerinner";
import Footer from "../../Footer/Footer";
import { useLocation, useHistory } from "react-router-dom";
import url from "../../../../constants/url";
import MapboxAutocomplete from "react-mapbox-autocomplete";
import LeftSectionFilters from "./LeftSectionFilters";
import VideoCourse from "./VideoCourse";
import OfflineCourse from "./OfflineCourse";
import OnlineCourse from "./OnlineCourse";
import HybridCourse from "./HybridCourse";
import InstituteList from "./InstituteList";
import Teacher from "./Teacher";
import StudentList from "./StudentList";
import Curve from '../../Header/Curve';
import NoDataAvailable from "../../../common/NoDataAvailable";

function Explore() {
	const ref = useRef();
	const [error, setError] = useState("");
	const search = useLocation().search;
	const [courseFilters, setCourseFilters] = useState(false);
	const params = new URLSearchParams(search);
	const [language, setLanguage] = useState([]);
	const [languages, setLanguages] = useState([]);
	const [duration, setDuration] = useState();
	const [skill, setSkill] = useState([]);
	const [courseType, setCourseType] = useState([]);
	const [teacherCount, setTeachersCount] = useState();
	const [instituteCount, setInstituteCount] = useState();
	const [studentCount, setStudentCount] = useState();
	const [recordedVideoCount, setRecordedVideoCount] = useState();
	const [onlineCount, setOnlineCount] = useState();
	const [offlineCount, setOfflineCount] = useState();
	const [hybridCount, setHybridCount] = useState();
	const [standard, setStandard] = useState([]);
	const [medium, setmedium] = useState([]);
	const [schedule, setSchedule] = useState();
	const [range, setRange] = useState({ min: 0, max: 10000 });
	const [searchValues, setSearchValues] = useState([]);
	const [orderBy, setOrderBy] = useState("latest");
	const [board, setBoard] = useState([]);
	const [discount, setDiscount] = useState();
	const [classroomType, setClassroomType] = useState();
	const [type, setType] = useState("all");
	let foo = params.get("query");
	const [searchs, setSearch] = useState({
		start: 0,
		perpage: 3,
		query: "",
	});
	const [teacherList, setTeacherList] = useState([]);
	const [instituteList, setInstituteList] = useState([]);
	const [studentList, setStudentList] = useState([]);
	const [onlineCourseList, setOnlineCourseList] = useState([]);
	const [oflineCourseList, setOflineCourseList] = useState([]);
	const [hybridCourseList, setHybridCourseList] = useState([]);
	const [VideoCourseList, setVideoCourseList] = useState([]);
	const [isSearchData, setIsSearchData] = useState(false);
	const history = useHistory();

	useEffect(() => {
		sessionStorage.setItem("orderBy", orderBy);
		sessionStorage.setItem("classroomType", classroomType);
		sessionStorage.setItem("discount", discount);
		sessionStorage.setItem("skill", skill);
		sessionStorage.setItem("courseType", courseType);
		languageApi();
		return () => {
			sessionStorage.removeItem("text");
		};
	}, []);

	useEffect(() => {
		searchFilterApi();
	}, [
		foo,
		type,
		language,
		skill,
		courseType,
		discount,
		classroomType,
		searchs,
		range,
		orderBy,
		duration,
		board,
		schedule,
		standard,
		medium,
	]);

	const mapAccess = {
		mapboxApiAccessToken:
			"pk.eyJ1Ijoic2FuY2hpdHNoYXJtYSIsImEiOiJjbGZocmRoMm0yMTJmM3FwZ21nZ2NqdG91In0.Ngsso-JXR08GApV2N1rmUw",
	};
	function _suggestionSelect(result, lat, long, text) {
		sessionStorage.setItem("text", result);
		sessionStorage.setItem("longitude", long);
		sessionStorage.setItem("latitude", lat);
	}
	const searchFilterApi = () => {
		let searchD = foo && foo.split("-");

		let searchValues = searchD && searchD.join(" ");
		sessionStorage.setItem("searchValues", searchValues);
		var formdata = new FormData();
		if (searchValues) {
			formdata.append("query", searchValues);
		}

		formdata.append("limit", searchs.perpage);
		formdata.append("offset", searchs.start);
		if (sessionStorage.getItem("text")) {
			formdata.append("location", sessionStorage.getItem("text"));
		}
		if (sessionStorage.getItem("longitude")) {
			formdata.append("longitude", sessionStorage.getItem("longitude"));
		}
		if (sessionStorage.getItem("latitude")) {
			formdata.append("latitude", sessionStorage.getItem("latitude"));
		}

		formdata.append("search_type", type || "all");
		if (language.join(",")) {
			formdata.append("language", language.join(","));
		}
		if (standard.join(",")) {
			formdata.append("standard", standard.join(","));
		}
		if (medium.join(",")) {
			formdata.append("medium", medium.join(","));
		}
		if (board.join(",")) {
			formdata.append("board", board.join(","));
		}
		if (skill.join(",")) {
			formdata.append("skill", skill.join(","));
		}
		if (duration) {
			formdata.append("duration", duration);
		}
		if (schedule) {
			formdata.append("class_schedule", schedule);
		}
		if (courseType.join(",")) {
			formdata.append("price_type", courseType.join(","));
		}
		if (courseType.join(",") === "paid") {
			formdata.append("min_price", range.min);
			formdata.append("max_price", range.max);
		}
		if (discount) {
			formdata.append("discount", discount);
		}
		if (classroomType) {
			formdata.append("classroom_type", classroomType);
		}

		formdata.append("order_by", orderBy);

		var requestOptions = {
			method: "POST",
			body: formdata,
		};

		fetch(`${url.apiUrl}search`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result?.status === "success") {
					if (result?.data?.hybride_course_count == 0 && result?.data?.institute_count == 0 && result?.data?.offline_course_count == 0 && result?.data?.online_course_count == 0 && result?.data?.student_count == 0 && result?.data?.teacher_count == 0 && result?.data?.video_course_count == 0) {
						setIsSearchData(false);
					} else {
						setIsSearchData(true);
						setTeacherList(result?.data?.teacher);
						setInstituteList(result?.data?.institute);
						setStudentList(result?.data?.student);
						setOnlineCourseList(result?.data?.online_course);
						setOflineCourseList(result?.data?.offline_course);
						setHybridCourseList(result?.data?.hybride_course);
						setVideoCourseList(result?.data?.video_course);
						setTeachersCount(result.data?.teacher_count);
						setRecordedVideoCount(result.data?.video_course_count);
						setInstituteCount(result.data?.institute_count);
						setStudentCount(result.data?.student_count);
						setOnlineCount(result.data?.online_course_count);
						setOfflineCount(result.data?.offline_course_count);
						setHybridCount(result.data?.hybride_course_count);
					}
				}
			})
			.catch((error) => console.log("error", error));
	};

	const languageApi = () => {
		var formdata = new FormData();
		formdata.append("is_main", 1);
		var requestOptions = {
			method: "GET",
		};

		fetch(`${url.apiUrl}get-all-language`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result?.status === "success") {
					sessionStorage.setItem("language", result?.data);
					setLanguages(result?.data);
				}
			})
			.catch((error) => console.log("error", error));
	};
	const SearchTyping = (e) => {
		setSearchValues({ ...searchValues, ["query"]: e.target.value });
	};

	const SearchQuery = () => {
		let searchD = searchValues?.query?.split(" ");
		let searchJoin = searchD?.join("-");
		history.push(`/search?query=${searchJoin ? searchJoin : ""}`);
	};
	const ClassroomTypeArray = (e) => {
		setClassroomType(e.target.value);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (!ref?.current?.contains(event.target)) {
				var elems = document.getElementsByClassName("react-mapbox-ac-menu");
				for (var i = 0; i < elems.length; i += 1) {
					elems[i].style.display = "none";
				}
			} else {
			}
		};
		document.addEventListener("click", handleClickOutside, true);
	}, [ref]);
	return (
		<>
			<Headerinner />
			<div
				className="cover-image bg-background-1"
				data-bs-image-src="assets/images/banners/banner1.webp"
				style={{
					background: 'url("assets/images/banners/banner1.webp") center center',
				}}
			>
				<div className="container header-text">
					<div className="row">
						<div className="col-xl-8 col-lg-10 col-md-10 d-block mx-auto">
							<div className="search-background">
								<div className="row g-0 ">
									<div className="form-group col-lg-3 col-md-12 select2-lg" ref={ref}>
										<MapboxAutocomplete
											publicKey={mapAccess.mapboxApiAccessToken}
											onSuggestionSelect={_suggestionSelect}
											country="IN"
											resetSearch={false}
											closeOnClick={true}
											placeholder="Search Location..."
										/>
									</div>
									<div className="form-group col-lg-7 col-md-12">
										<input type="text"
											name="title"
											className="form-control input-xl border-em"
											defaultValue={foo}
											placeholder="Search Courses, Institutes, Teachers, Students, etc."
											onChange={SearchTyping}
										/>
										{error && (
											<p className="bg-error ml-5">{error}</p>
										)}
									</div>
									<div className="col-lg-2 mb-0">
										<div className="btn btn-xl btn-block btn-primary br-ts-md-0 br-bs-md-0" onClick={SearchQuery} title="Search">
											<i className="fe fe-search" /> Search
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Curve />
			<section className="sptb">
				<div className="container">
					<div className="row">
						<LeftSectionFilters
							setSkill={setSkill}
							setLanguage={setLanguage}
							languages={languages}
							language={language}
							skill={skill}
							setDiscount={setDiscount}
							discount={discount}
							setCourseType={setCourseType}
							courseType={courseType}
							setClassroomType={setClassroomType}
							classroomType={classroomType}
							courseFilters={courseFilters}
							ClassroomTypeArray={ClassroomTypeArray}
							range={range}
							setRange={setRange}
							duration={duration}
							setDuration={setDuration}
							board={board}
							setBoard={setBoard}
							schedule={schedule}
							setSchedule={setSchedule}
							standard={standard}
							setStandard={setStandard}
							medium={medium}
							setmedium={setmedium}

						/>
						<div className="col-xl-9 col-lg-8 col-md-12">
							<div className="card mb-0">
								<div className="card-header d-flex justify-content-between align-items-center">
									<h4 className="card-title"></h4>
									<div className="d-flex align-items-center">
										<label className="text-nowrap me-3 mb-0">Sort By :</label>
										<select name="item" className="form-select" onChange={(e) => setOrderBy(e.target.value)}>
											<option value="rating">Rating</option>
											<option value="populer">Popularity</option>
											<option value="price_low_high">Price: Low-to-High</option>
											<option value="price_high_low"> Price: Hight-to-Low </option>
										</select>
									</div>
								</div>
								<div className="card-body">
									<div className="row">
										<div className="col-md-12 mb-3">
											<div className="form-controls-stacked d-md-flex">
												<label className="custom-control custom-radio me-4">
													<input
														type="radio"
														className="custom-control-input"
														name="example-radios"
														value="all"
														checked={type === "all"}
														onChange={() => {
															setType("all");
															setCourseFilters(false);
															setClassroomType();
														}}
													/>
													<span className="custom-control-label">All</span>
												</label>
												<label className="custom-control custom-radio me-4">
													<input
														type="radio"
														className="custom-control-input"
														name="example-radios1"
														checked={type === "course"}
														value="course"
														onChange={() => {
															setType("course");
															setCourseFilters(true);
														}}
													/>
													<span className="custom-control-label">
														Courses
													</span>
												</label>
												<label className="custom-control custom-radio me-4">
													<input
														type="radio"
														className="custom-control-input"
														name="example-radios2"
														value="course"
														checked={type === "institute"}
														onChange={() => {
															setType("institute");
															setClassroomType();
															setCourseFilters(false);
														}}
													/>
													<span className="custom-control-label">
														Institute
													</span>
												</label>
												<label className="custom-control custom-radio me-4">
													<input
														type="radio"
														className="custom-control-input"
														name="example-radios3"
														value="teacher"
														checked={type === "teacher"}
														onChange={() => {
															setType("teacher");
															setCourseFilters(false);
															setClassroomType();
														}}
													/>
													<span className="custom-control-label">
														Teacher
													</span>
												</label>
												<label className="custom-control custom-radio">
													<input
														type="radio"
														className="custom-control-input"
														name="example-radios4"
														value="teacher"
														checked={type === "student"}
														onChange={() => {
															setType("student");
															setCourseFilters(false);
															setClassroomType([]);
														}}
													/>
													<span className="custom-control-label">
														Student
													</span>
												</label>
											</div>
										</div>
										{courseFilters ? (
											<div className="form-controls-stacked d-md-flex">
												<label
													className="custom-control custom-radio me-4"
													htmlFor="video_conference"
												>
													<input
														type="radio"
														id="video_conference"
														className="custom-control-input"
														name="course"
														defaultValue="video_course"
														onChange={(e) => {
															ClassroomTypeArray(e);
														}}
													/>
													<span className="custom-control-label">
														Video Course
													</span>
												</label>
												<label
													className="custom-control custom-radio me-4"
													htmlFor="manual"
												>
													<input
														type="radio"
														id="manual"
														className="custom-control-input"
														name="course"
														defaultValue="manual"
														onChange={(e) => {
															ClassroomTypeArray(e);
														}}
													/>
													<span className="custom-control-label">
														Offline
													</span>
												</label>
												<label
													className="custom-control custom-radio me-4"
													htmlFor="online"
												>
													<input
														type="radio"
														id="online"
														className="custom-control-input"
														name="course"
														defaultValue="video_conference"
														onChange={(e) => {
															ClassroomTypeArray(e);
														}}
													/>
													<span className="custom-control-label">
														Online
													</span>
												</label>
												<label
													className="custom-control custom-radio"
													htmlFor="both"
												>
													<input
														type="radio"
														id="both"
														className="custom-control-input"
														name="course"
														defaultValue="both"
														onChange={(e) => {
															ClassroomTypeArray(e);
														}}
													/>
													<span className="custom-control-label">
														Hybrid (Online + Offline)
													</span>
												</label>
											</div>
										) : (
											""
										)}
										{isSearchData ? (
											<>
												{(type === "course" && (VideoCourseList?.length == 0 && oflineCourseList?.length == 0 && onlineCourseList?.length == 0 && hybridCourseList?.length == 0)) && (<NoDataAvailable/>)}
												{(type === "course" ||
													type === "all" ||
													classroomType === "video_course") && (
														<VideoCourse
															VideoCourseList={VideoCourseList}
															searchs={searchs}
															setSearch={setSearch}
															recordedVideoCount={recordedVideoCount}
															type={type}
															classroomType={classroomType}
														/>
													)}

												{(classroomType === "manual" ||
													type === "all" ||
													type === "course") && (
														<OfflineCourse
															oflineCourseList={oflineCourseList}
															searchs={searchs}
															setSearch={setSearch}
															offlineCount={offlineCount}
															type={type}
															classroomType={classroomType}
														/>
													)}
												{(classroomType === "video_conference" ||
													type === "all" ||
													type === "course") && (
														<OnlineCourse
															onlineCourseList={onlineCourseList}
															searchs={searchs}
															setSearch={setSearch}
															onlineCount={onlineCount}
															type={type}
															classroomType={classroomType}
														/>
													)}
												{(classroomType === "both" ||
													type === "all" ||
													type === "course") && (
														<HybridCourse
															hybridCourseList={hybridCourseList}
															searchs={searchs}
															setSearch={setSearch}
															hybridCount={hybridCount}
															type={type}
															classroomType={classroomType}
														/>
													)}

												{(type === "institute" || type === "all") && (
													<InstituteList
														instituteList={instituteList}
														searchs={searchs}
														setSearch={setSearch} instituteCount={instituteCount}
														type={type}
													/>
												)}

												{(type === "teacher" || type === "all") && (
													<Teacher
														teacherList={teacherList}
														searchs={searchs}
														setSearch={setSearch} teacherCount={teacherCount}
														type={type}
													/>
												)}
												{(type === "student" || type === "all") && (
													<StudentList
														studentList={studentList}
														searchs={searchs}
														setSearch={setSearch} studentCount={studentCount}
														type={type}
													/>
												)}
											</>
										) : (
											(<NoDataAvailable/>)
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default Explore;
