import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getTeacherGetProfile } from '../../../../apis'
import ProfileStudent from './ProfileStudent';
import StudentDtails from './StudentDetails';


const TeacherProfileStudent = () => {

    const [tearchdata, setTeacherdata] = useState({})
    const getTeacherGetProfiles = async () => {
        let res = await getTeacherGetProfile()
        if (res.status === 'success') {
            setTeacherdata(res.data)
        }

    }
    useEffect(() => {
        getTeacherGetProfiles()
    }, [])

    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/teacher-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Classroom Course</li>
                </ol>
            </div>
            <ProfileStudent data={tearchdata && tearchdata} />
            <StudentDtails data={tearchdata && tearchdata} />
        </>
    );
};

export default TeacherProfileStudent;