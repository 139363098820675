import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import StudentProfile1 from './StudentProfile1';
import StudentDetail from './StudentDetail';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

const Profile = () => {
    const { student_id } = useParams();
    const [student, setStudent] = useState({});
    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage.auth_token;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("AUTHTOKEN", auth_token);
 
    const getStudentDetail = async () => {
        try {
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
            };
            fetch("https://educationmandal.com/em-admin/api/" + `institute-student/student-detail/${student_id}`, requestOptions)
                .then(response => response.json())
                .then((result) => {
                    setStudent(result.data);
                })
                .catch(error => console.log('error', error));
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        getStudentDetail();
    }, []);

    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard/">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard/student">Student</Link>
                    </li>
                    <li className="breadcrumb-item active">Student Profile</li>
                </ol>
            </div>
            <StudentProfile1 student={student} />
            <StudentDetail student={student} />

        </>
    );
};

export default Profile;