import React, { useEffect, useState } from "react";
import Headerinner from "../../Header/Headerinner";
import Footer from "../../Footer/Footer";
import Curve from '../../Header/Curve';
import { useLocation, Link, useHistory, useParams } from "react-router-dom";
import url from "../../../../constants/url";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Moment from "react-moment";
import { getCourseReview } from "../../../../../src/apis_web";
import ReactPaginate from "react-paginate";
import { replaceWord } from "../../../../utils/utils";
import NoDataAvailable from "../../../common/NoDataAvailable";

function ClassDetail() {
    let now = new Date().setHours(0, 0, 0, 0);
    const history = useHistory();
    const location = useLocation();
    const { id } = useParams();
    const [ShowButton, setShowButton] = useState(false);
    const [CheckLoginn, setCheckLoginn] = useState(false);
    const [formValues, setFormValues] = useState([]);
    const course_id = localStorage.getItem('courseid');
    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage?.auth_token;
    const user_type = local_storage?.type;
    const [UIValues, setUIValues] = useState({});
    const [reviewData, setReviewData] = useState([]);
    const [offset, setOffset] = useState();
    const [data, setData] = useState({});
    const [search, setSearch] = useState({
        perPage: 10,
        start: 0,
    }); const [errors, seterrors] = useState({
        name: '',
        contact: '',
        email: ''
    });
    let rating = [1, 2, 3, 4, 5];

    const handleChangeGen = (event) => {
        UIValues[event.target.name] = event.target.value;
        setUIValues((state) => UIValues);
    };

    const getClassDetail = async (e) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        if (auth_token) {
            myHeaders.append("AUTHTOKEN", auth_token);
        }
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
        };
        fetch(`${url.apiUrl}get-class-course-id/${id}`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setData(result.data);
                    getCourseReviewData(result.data);
                }
            })
            .catch(error => console.log('error', error));
    }

    const addCart = (id, type) => {
        if (localStorage.getItem("common-user-info")) {
            const auth_info = localStorage.getItem("common-user-info");
            const auth_json = JSON.parse(auth_info);
            const auth_token = auth_json.auth_token;
            var myHeaders = new Headers();
            myHeaders.append("AUTHTOKEN", auth_token);

            var formdata = new FormData();
            formdata.append("course_id", id);
            formdata.append("course_type", "class_course");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
            };

            fetch(`${url.apiUrl}add-cart`, requestOptions)
                .then(response => response.json())
                .then((result) => {
                    if (result.status === "success") {
                        toast.success("Item Added In To Cart Successfully",
                            { position: toast.POSITION.TOP_RIGHT }
                        )
                        setTimeout(() => {
                            history.push("/cart");
                        }, 5000);
                    } else {
                        toast.error(result.message,
                            { position: toast.POSITION.TOP_RIGHT }
                        )
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            localStorage.setItem('course', id);
            history.push("/login");
        }
    }

    const EnrollNowApi = (id, type) => {
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);

        var formdata = new FormData();
        formdata.append("course_id", id);
        formdata.append("course_type", type);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
        };
        if (auth_token) {
            fetch(`${url.apiUrl}student/enroll-course`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === "success") {
                        toast.success(result.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    } else {
                        toast.error(result.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                })
                .catch((error) => console.log("error", error));
        } else {
            history.push("/login");
        }
    };

    const apiCall = async (activity) => {
        let res = await getCourseReview(activity);
        if (res.status === "success") {
            setReviewData(res.data);
        }
    };
    const getCourseReviewData = async (course_detail) => {
        let reqData = new FormData();
        reqData.append(`course_id`, course_detail?.id);
        reqData.append(`course_type`, course_detail?.type);
        reqData.append(`limit`, search.perPage);
        reqData.append(`offset`, search.start);
        await apiCall(reqData);
    };
    const handlePageClick = async (data) => {
        let currentPage = data.selected * search.perPage;
        setOffset(currentPage);
        let activity = {
            course_id: data?.id,
            course_type: data?.type,
            limit: search.perPage,
            offset: currentPage,
        };
        await getCourseReviewData(activity);
    };
    const handleLogin = () => {
        toast.error("Please sign in as a student", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }


    useEffect(() => {
        getClassDetail()
    }, [id])
    return (
        <>
            <Headerinner />
            <div
                className="cover-image bg-background-1"
                data-bs-image-src="/assets/images/banners/banner1.webp"
                style={{
                    background: 'url("/assets/images/banners/banner1.webp") center center',
                }}
            >
                <div className="container header-text">
                    <h1>{data?.title}</h1>
                </div>
            </div>
            <Curve />
            <section className="sptb">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="product-slider">
                                        <ul className="list-unstyled video-list-thumbs">
                                            <li className="mb-0">
                                                <div className="item-card7-img">
                                                    <img src={data?.thumbnail} alt={data?.title} className="cover-image"
                                                        onError={(e) => { e.target.src = "/assets/images/default.webp"; }}
                                                    />
                                                    {(
                                                        data?.price == "paid" && (
                                                            data?.is_discount == 1 && (
                                                                (data?.is_limited_offer == 1 && (new Date(data?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(data?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                (data?.is_limited_offer == 0 && new Date(data?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                        )
                                                    ) && (
                                                            <Link to="#" className="bg-danger top left">
                                                                {data?.discount_price}% off
                                                            </Link>
                                                        )}
                                                    <Link to="#" className="bg-danger right top">
                                                        Classroom Course
                                                    </Link>
                                                    {data?.class_type === "school" ? (
                                                        <Link to="#" className="bg-danger right bottom">
                                                            {data?.board}
                                                        </Link>
                                                    ) : (data?.class_type === "university" ? (
                                                        <Link to="#" className="bg-danger right bottom">
                                                            {data?.universities?.name}
                                                        </Link>
                                                    ) : (data?.class_type === "tuition" && (
                                                        <>
                                                            {data?.tuition_type === "school" ? (
                                                                <Link to="#" className="bg-danger right bottom">
                                                                    {data?.board}
                                                                </Link>
                                                            ) : (data?.tuition_type === "university" && (
                                                                <Link to="#" className="bg-danger right bottom">
                                                                    {data?.universities?.name}
                                                                </Link>
                                                            ))}
                                                        </>
                                                    )))}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="mt-4 mb-4 text-center">
                                        <div className="mb-2">
                                            {data?.price === "paid" && (
                                                <span className="font-weight-semibold h2 mb-0">
                                                    ₹ {Math.round(data?.payable_price)} {(
                                                        data?.price == "paid" && (
                                                            data?.is_discount == 1 && (
                                                                (data?.is_limited_offer == 1 && (new Date(data?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(data?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                (data?.is_limited_offer == 0 && new Date(data?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                        )
                                                    ) &&
                                                        (<del className="fs-14 text-muted">₹ {Math.round(data?.regular_price)} </del>
                                                        )}
                                                </span>
                                            )}
                                            {data?.price === "free" && (
                                                <span className="font-weight-semibold h2 mb-0">
                                                    Free
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    {ShowButton === false && (
                                        <div>
                                            {data?.is_order === 1 ? (
                                                <Link to={`/student-dashboard/class-detail/${data?.id}`} className="btn btn-block btn-primary mb-3 mb-xl-0">
                                                    Already Purchased
                                                </Link>
                                            ) : (
                                                <>
                                                    {data?.price === "paid" ? (
                                                        <>
                                                            {data?.is_cart == 0 && user_type === "student" ? (
                                                                <Link
                                                                    to="#"
                                                                    onClick={() => addCart(data?.id, data?.type)}
                                                                    className="btn btn-block btn-primary mb-3 mb-xl-0"
                                                                >Add to Cart<i className="fe fe-shopping-cart mt-1 ms-2 fs-14"></i>
                                                                </Link>
                                                            ) : (data?.is_cart == 0 && user_type !== "student" ? (
                                                                <Link
                                                                    to="#"
                                                                    className="btn btn-block btn-primary mb-3 mb-xl-0"
                                                                    onClick={() => {
                                                                        handleLogin()
                                                                    }}
                                                                >Add to Cart
                                                                    <i className="fe fe-shopping-cart mt-1 ms-2 fs-14"></i>
                                                                </Link>
                                                            ) : (
                                                                <Link
                                                                    to="/cart"
                                                                    className="btn btn-block btn-primary mb-3 mb-xl-0"
                                                                >Go To Cart
                                                                    <i className="fe fe-shopping-cart mt-1 ms-2 fs-14"></i>
                                                                </Link>
                                                            ))}

                                                        </>
                                                    ) : (
                                                        <>
                                                            {data?.price === "free" && user_type === "student" ? (
                                                                <Link
                                                                    to="#"
                                                                    className="btn btn-block btn-danger mb-3 mb-xl-0"
                                                                    onClick={() => {
                                                                        EnrollNowApi(data?.id, data?.type);
                                                                    }}
                                                                >
                                                                    Enroll Now
                                                                    <i className="fe fe-arrow-right mt-1 ms-2 fs-14"></i>
                                                                </Link>
                                                            ) : (data?.price === "free" && user_type !== "student" ? (
                                                                <Link
                                                                    to="#"
                                                                    className="btn btn-block btn-danger mb-3 mb-xl-0"
                                                                    onClick={() => {
                                                                        handleLogin()
                                                                    }}
                                                                >
                                                                    Enroll Now
                                                                    <i className="fe fe-arrow-right mt-1 ms-2 fs-14"></i>
                                                                </Link>
                                                            ) : (
                                                                <Link
                                                                    to="/login"
                                                                    className="btn btn-block btn-danger mb-3 mb-xl-0"
                                                                >
                                                                    Enroll Now
                                                                    <i className="fe fe-arrow-right mt-1 ms-2 fs-14"></i>
                                                                </Link>
                                                            ))}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className="card-header">
                                    <h4 className="card-title">Classroom Course Info :</h4>
                                </div>
                                <table className="table table-hover dataTable">
                                    <tbody>
                                        <tr>
                                            <td className="text-nowrap font-weight-semibold">Institute Name</td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end">
                                                <Link className="text-primary" to={`/institute-detail/${data?.institute?.institute_id}`}>
                                                    {data?.institute?.name}
                                                </Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-nowrap font-weight-semibold">Branch Name</td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end">
                                                <Link className="text-primary" to={`/branch-detail/${data?.institute_branch?.branch_id}`}>
                                                    {data?.institute_branch?.name}
                                                </Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-nowrap font-weight-semibold">Class Type</td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end text-capitalize">
                                                {replaceWord(data?.class_type)}
                                            </td>
                                        </tr>
                                        {data?.class_type === "school" ? (
                                            <>
                                                <tr>
                                                    <td className="text-nowrap font-weight-semibold">Board</td>
                                                    <td className="w-0 text-center"> : </td>
                                                    <td className="text-end text-capitalize">
                                                        {data?.board}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-nowrap font-weight-semibold">Section</td>
                                                    <td className="w-0 text-center"> : </td>
                                                    <td className="text-end text-capitalize">
                                                        {data?.section}
                                                    </td>
                                                </tr>
                                            </>
                                        ) : (data?.class_type === "university" ? (
                                            <>
                                                <tr>
                                                    <td className="text-nowrap font-weight-semibold">University</td>
                                                    <td className="w-0 text-center"> : </td>
                                                    <td className="text-end text-capitalize">
                                                        {data?.universities?.name}
                                                    </td>
                                                </tr>
                                                {/* <tr>
                                                    <td className="text-nowrap font-weight-semibold">Semester</td>
                                                    <td className="w-0 text-center"> : </td>
                                                    <td className="text-end text-capitalize">
                                                        {data?.semester}
                                                    </td>
                                                </tr> */}
                                            </>
                                        ) : (data?.class_type === "tuition" && (
                                            <>
                                                <tr>
                                                    <td className="text-nowrap font-weight-semibold">Tuition Type</td>
                                                    <td className="w-0 text-center"> : </td>
                                                    <td className="text-end text-capitalize">
                                                        {replaceWord(data?.tuition_type)}
                                                    </td>
                                                </tr>
                                                {
                                                    data?.tuition_type === "school" ? (
                                                        <tr>
                                                            <td className="text-nowrap font-weight-semibold">Board</td>
                                                            <td className="w-0 text-center"> : </td>
                                                            <td className="text-end text-capitalize">
                                                                {data?.board}
                                                            </td>
                                                        </tr>
                                                    ) : (data?.tuition_type === "university" && (
                                                        <>
                                                            <tr>
                                                                <td className="text-nowrap font-weight-semibold">University</td>
                                                                <td className="w-0 text-center"> : </td>
                                                                <td className="text-end text-capitalize">
                                                                    {data?.universities?.name}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-nowrap font-weight-semibold">Semester</td>
                                                                <td className="w-0 text-center"> : </td>
                                                                <td className="text-end text-capitalize">
                                                                    {data?.semester}
                                                                </td>
                                                            </tr>
                                                        </>
                                                    ))}
                                            </>
                                        )))}
                                        <tr>
                                            <td className="text-nowrap font-weight-semibold">Medium</td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end"> {data?.medium}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-nowrap font-weight-semibold">Classroom Type</td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end"> {replaceWord(data?.lecture_type)}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-nowrap font-weight-semibold">Last Update</td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end">
                                                <Moment format="DD-MM-YYYY hh:mm A">
                                                    {data?.last_update}
                                                </Moment>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-12">
                            <div className="card">
                                <Tabs defaultActiveKey="overview" id="uncontrolled-tab-example">
                                    <Tab eventKey="overview" title="overview">
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">Class Description</h4>
                                            </div>

                                            <div className="card-body">
                                                <p className="education-mandal" dangerouslySetInnerHTML={{ __html: data?.description }} />
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">What will students learn in this classroom?</h4>
                                            </div>

                                            <div className="card-body">
                                                <p className="education-mandal" dangerouslySetInnerHTML={{ __html: data?.curriculum }} />
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">Requirements</h4>
                                            </div>

                                            <div className="card-body">
                                                <p className="education-mandal" dangerouslySetInnerHTML={{ __html: data?.requirements }} />
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="reviews" title="reviews">
                                        {reviewData?.length > 0 ? (
                                            reviewData.map((item, i) => (
                                                <div
                                                    key={i}
                                                    className="media mt-0 mb-3 p-3 border br-5 review-media"
                                                >
                                                    <div className="d-flex me-3">
                                                        <img
                                                            className="border-radius-50 avatar-lg"
                                                            alt={item?.name}
                                                            src={item?.student?.image}
                                                        />
                                                    </div>
                                                    <div className="media-body">
                                                        <h4 className="mt-0 mb-2 font-weight-semibold">
                                                            {item?.name}
                                                            <small className="text-muted float-end">
                                                                <Moment format="DD-MM-YYYY hh:mm A">
                                                                    {item?.created_at}
                                                                </Moment>
                                                            </small>
                                                        </h4>
                                                        <span className="d-inline-flex">
                                                            <div className="br-widget">
                                                                {rating.map((el, i) => (
                                                                    <i
                                                                        data-rating-value={el}
                                                                        data-rating-text={el}
                                                                        className={
                                                                            el <= item?.average_review
                                                                                ? "br-selected"
                                                                                : ""
                                                                        }
                                                                        key={i}
                                                                    />
                                                                ))}
                                                            </div>
                                                        </span>
                                                        <p className="mb-2 mt-2" dangerouslySetInnerHTML={{ __html: item?.comment }}>
                                                        </p>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <NoDataAvailable />
                                        )}
                                        {reviewData?.length > 0 && (
                                            <ReactPaginate
                                                previousLabel={"Previous"}
                                                nextLabel={"Next"}
                                                breakLabel={"..."}
                                                pageCount={Math.ceil(reviewData?.length / 10)}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination"}
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}
                                            />
                                        )}
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div >
                </div >
            </section >
            <Footer />
            <ToastContainer />

        </>
    )
}

export default ClassDetail;