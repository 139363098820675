import React from 'react';

const Founder = () => {
    return (
        <>
            <section className="sptb bg-white">
                <div className="container">
                    <div className="section-title justify-content-center">
                        <h2>Leadership Team</h2>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="row align-items-center justify-content-center">
                                {/* <div className="col-md-2 col-12 text-center mb-5">
                                    <img src="assets/images/founder1.webp" className="img-thumbnail" alt="img" />
                                </div> */}
                                <div className="col-md-10 col-12">
                                    {/* <h3 className="font-weight-semibold mb-2">Shashank Kalambe</h3> */}
                                    <h6 className="font-weight-semibold mb-2">Founder & CEO</h6>
                                    <h6 className="font-weight-semibold mb-2">CS, Gen. LLB, B.Com</h6>
                                    <p className="mb-2">Mr. Shashank Kalambe, the founder of Education Mandal, is passionate about
                                        improving access to high-quality education. He saw a need for a platform that would
                                        connect institutions, teachers, students and other stakeholders and make education more accessible and
                                        affordable for all.</p>
                                    <p className="mb-2">We are committed to provide the best possible educational experience for all our
                                        users, and we believe that our platform will make a positive impact on the education
                                        industry.</p>
                                    <p className="font-weight-semibold mb-0">“An honest effort to contribute towards the growth of society at large via
                                        Education”</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default Founder;