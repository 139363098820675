import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TeacherList from "./TeacherList";
import TeacherFilter from "./TeacherFilter";
import url from "../../../../../src/constants/url";
let token = localStorage.getItem("common-user-info");

const Teacher = () => {
    const [TeacherDataInstitute, setTeacherDataInstitute] = useState([]);
    const [branch, setBranch] = useState("");
    const [status, setStatus] = useState("all");
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState();
    const [search, setSearch] = useState({
        start: 0,
        perPage: 10,
        searchTxt: "",
        searchField: "",
    });
    useEffect(() => {
        getAllTeacher();
    }, [search]);
    var localdata = localStorage.getItem("common-user-info");
    var jsondata = JSON.parse(localdata);
    var token_authorization = jsondata.auth_token;
    const apiCall = async (activity) => {
        let result = await fetch(`${url.apiUrl}institute-teacher/teachers`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                AUTHTOKEN: token_authorization,
            },
            body: JSON.stringify({
                status: activity.status,
                limit: activity.limit,
                offset: activity.offset,
                query: activity.query,
                branch_id: activity.branch_id,
            }),
        });
        if (result?.status === 200) {
            let varResult = await result.json();
            if (varResult?.status == "success") {
                setTeacherDataInstitute(varResult?.data);
                setCount(varResult?.count);
            }
        }
    };
    const getAllTeacher = async () => {
        let activity = {
            status: status,
            limit: search.perPage,
            offset: search.start,
            query: search.searchTxt,
            branch_id: branch,
        };
        await apiCall(activity);
    };

    const handlePageClick = async (data) => {
        let currentPage = data.selected * search.perPage;
        setOffset(currentPage);
        let activity = {
            status: status,
            limit: search.perPage,
            offset: currentPage,
            query: search.searchTxt,
            branch_id: branch,
        };
        await apiCall(activity);
    };
    const searchData = (e) => {
        setSearch({ ...search, searchTxt: e.target.value });
    };

    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a href="/institute-dashboard">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">Teacher's List</li>
                </ol>
                <Link
                    className="btn btn-primary"
                    to="/institute-dashboard/teacher-add"
                >
                    <i className="fa fa-plus"></i> Add Teacher
                </Link>
            </div>

            <div className="row">
                <TeacherFilter
                    getAllTeacher={getAllTeacher}
                    setBranch={setBranch}
                    branch={branch}
                    status={status}
                    setStatus={setStatus}
                />
                <TeacherList
                    data={TeacherDataInstitute}
                    handlePageClick={handlePageClick}
                    searchData={searchData}
                    offset={offset}
                    count={count}
                />
            </div>
        </>
    );
};

export default Teacher;
