import utils from "../../../utils/utils";

export default class CommonService {
    changeCourseStatus(payload) {
        return utils.sendApiRequest(`teacher/online-courses-status-manage`, "POST", true, payload)
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    }

}