import React from "react";
import Curve from '../../Header/Curve';

const AboutEdu = () => {
    return (
        <>
            <div
                className="cover-image bg-background-1"
                data-bs-image-src="assets/images/banners/banner1.webp"
                style={{
                    background: 'url("assets/images/banners/banner1.webp") center center',
                }}
            >
                <div className="container header-text">
                    <h1>About us</h1>
                </div>
            </div>
            <Curve/>
        </>
    );
};

export default AboutEdu;
