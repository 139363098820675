import React from 'react';

const AlumniDetails = () => {
    return (
        <div>
            AlumniDetails
        </div>
    );
};

export default AlumniDetails;