import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Moment from "react-moment";
import AddAchievement from "./AddAchievement";
import useToken from "../../../../../../hooks/useToken";
import url from "../../../../../../constants/url";
import EditAchievement from "./EditAchievement";
import NoDataAvailable from "../../../../../common/NoDataAvailable";
const Achievement = () => {
    const [allAchievements, setAllAchievements] = useState([]);
    const [singleAchievement, setSingleAchievement] = useState({});
    const [show, setShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const { token } = useToken();
    const handleIsEditShow = () => setIsEdit(true);
    const handleShow = () => setShow(true);

    const getAllAchievements = () => {

        fetch(`${url.apiUrl}institute-teacher/get-all-achievements`, {
            method: "POST",
            headers: { "Content-Type": "application/json", AUTHTOKEN: token },
            body: JSON.stringify({
                limit: 10,
                offset: 0,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setAllAchievements(result.data);
                }
            })
            .catch((error) => console.log("error", error));
    };
    useEffect(() => {
        getAllAchievements();
    }, []);
    return (
        <React.Fragment>
            <div className='row '>
                <div className='col-md-12'>
                    <div className='card'>
                        <div className='card-header justify-content-between'>
                            <h4 className='card-title'>Teacher's Achievement Details</h4>
                            <div className='d-flex align-items-center'>
                                <Button
                                    variant='primary'
                                    className='btn btn-primary'
                                    onClick={handleShow}
                                >
                                    <i className='fa fa-plus'></i>
                                </Button>
                                <AddAchievement
                                    show={show}
                                    setShow={setShow}
                                    getAllAchievements={getAllAchievements}
                                />
                            </div>
                        </div>
                        <EditAchievement
                            show={isEdit}
                            setShow={setIsEdit}
                            data={singleAchievement}
                            getAllAchievements={getAllAchievements}
                        />
                        {allAchievements && allAchievements?.length > 0 ? (
                            allAchievements?.map((achievement, index) => {
                                const { title, description, date } = achievement;
                                return (
                                    <div className='card-body' key={achievement.id}>
                                        <ul className='d-flex mb-0'>
                                            <li>
                                                <div className='activity1 bg-primary'>
                                                    <i className='fe fe-shopping-bag'></i>
                                                </div>
                                            </li>
                                            <li className="font-weight-semibold">
                                                {title}
                                            </li>
                                            <div className='text-muted ms-auto'>
                                                <Moment format='DD-MM-YYYY'>{date}</Moment>
                                                <Button
                                                    variant='primary'
                                                    className='btn btn-primary ms-3'
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        setSingleAchievement(achievement);
                                                        handleIsEditShow();
                                                    }}
                                                >
                                                    <i className='fa fa-edit'></i>
                                                </Button>
                                            </div>
                                        </ul>
                                        <p className='text-muted mb-0 margin-left education-mandal' dangerouslySetInnerHTML={{ __html: description }}>{ }</p>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="card-body">
                                <NoDataAvailable />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default React.memo(Achievement);
