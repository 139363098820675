import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from "axios";
import url from '../../../../constants/url';
import moment from 'moment';
import NavbarLogo from "../../../common/NavbarLogo"

const Invoice = () => {
    const { order_id } = useParams();
    const [data, setData] = useState();



    const apiCall = async () => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        const config = {
            headers: {
                content: "application/json",
                AUTHTOKEN: auth_token
            },
        };
        try {
            const response = await axios.get(
                `${url.apiUrl}student/get-invoice/${order_id}`,
                config
            );
            if (response) {
                setData(response.data?.data);
            }
        } catch (err) {
            throw err;
        }
    };
    useEffect(() => {
        apiCall();
    }, []);
    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/student-dashboard">Dashboard</Link></li>
                    <li className="breadcrumb-item active">Invoice</li>
                </ol>
            </div>

            <div className="card">
                <div className="card-body">
                    {data ? (
                        <>
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <NavbarLogo/>
                                </div>
                                <div className="col-lg-6 text-end">
                                    <p className="mb-1 text-end"><span className="font-weight-bold">Order Number :</span> {data?.orderId}</p>
                                    <p className="mb-1 text-end"><span className="font-weight-bold">Invoice Number :</span> {data?.invice_id}</p>
                                    <p className="mb-1 text-end"><span className="font-weight-bold">Invoice Date :</span> {moment(data?.txndate).format('Do MMMM YYYY')}</p>
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row mb-5">
                                <div className="col-lg-6">
                                    <p className="h3">Sold By</p>
                                    <h5 className="mb-0">Education Mandal</h5>
                                    <p className="mb-0">info@educationmandal.com</p>
                                    <address>
                                        D 401, Bachraj Landmark, Near club one, Global City, Virar,<br /> Maharashtra - 401303
                                    </address>
                                    <p className="mb-0"><span className="font-weight-bold">PAN No:</span> BIEPK8383F</p>
                                </div>
                                <div className="col-lg-6 text-end">
                                    <p className="h3 text-end">Invoice To</p>
                                    <h5 className="mb-0 text-end">{data?.user?.name}</h5>
                                    <p className="mb-0 text-end">{data?.user?.email}</p>
                                    <p className="mb-0 text-end">{data?.user?.contact}</p>
                                    <address>{data?.user.address}</address>
                                </div>
                            </div>

                            <div className="table-responsive push">
                                <table className="table table-bordered table-hover text-nowrap">
                                    <tbody>
                                        <tr>
                                            <th className="font-weight-bold text-center">SR. NO.</th>
                                            <th className="font-weight-bold">ITEM DETAILS</th>
                                            <th className="font-weight-bold">ITEM TYPE</th>
                                            <th className="text-center font-weight-bold">MRP</th>
                                            <th className="text-end font-weight-bold">DISCOUNT</th>
                                            <th className="text-end font-weight-bold">GST</th>
                                            <th className="text-end font-weight-bold">PURCHASE PRICE</th>
                                        </tr>
                                        {data?.orders.length > 0 && (data?.orders?.map((item, key) => {
                                            return (
                                                <tr>
                                                    <td className="text-center">{key + 1}</td>
                                                    <td>
                                                        <p className="font-weight-semibold mb-1">{item?.course_type === "class_course" ? item?.class_course.title : item?.online_course.title}</p>
                                                    </td>
                                                    <td className="text-start">{item?.course_type === "class_course" ? "Classroom Course" : "Video Course"}</td>
                                                    <td className="text-center">₹ {Math.round(item?.mrp)}</td>
                                                    <td className="text-end">₹ {Math.round(item?.discount)}</td>
                                                    <td className="text-end">₹ {Math.round(item?.gst)}</td>
                                                    <td className="text-end">₹ {Math.round(item?.total_payable_price)}</td>
                                                </tr>
                                            )
                                        }))}
                                        <tr>
                                            <td colspan="6" className="font-weight-semibold text-end">Total MRP</td>
                                            <td className="text-end">₹ {Math.round(data?.price)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="6" className="font-weight-semibold text-end">Discount</td>
                                            <td className="text-end">₹ {Math.round(data?.discount)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="6" className="font-weight-semibold text-uppercase text-end">GST</td>
                                            <td className="text-end">₹ {Math.round(data?.gst)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="6" className="font-weight-semibold text-uppercase text-end">Total Amount</td>
                                            <td className="font-weight-semibold text-end">₹ {Math.round(data?.total_price)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="7" className="text-end">
                                                <a href={`${url.apiUrl}student/download-invoice/${order_id}`} className="btn btn-primary me-2" target='_blank'>Download Invoice</a>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <p className="text-muted text-center mt-5">Thank you very much for doing business with us. We look forward to working with you again!</p>
                        </>
                    ) : (
                        <div className='row'><div className='col-md-12 text-center'> No Data Available !</div></div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Invoice;