import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import url from "../../../../constants/url";
import Moment from "react-moment";
import moment from "moment";
import { toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
import NoDataAvailable from "../../../common/NoDataAvailable";

const Experience = (props) => {
    const [form, setForm] = useState(false);
    const currentDate = moment().format("YYYY-MM-DD");
    const [data, setData] = useState(props);
    const [isLegnth, setIsLegnth] = useState(0);
    const [experienceValidation, setExperienceValidation] = useState({
        title: "",
        employement_type: "",
        company_name: "",
        location: "",
        is_present: "",
        description: "",
        start_date: "",
        end_date: "",
    });
    const initialValue = {
        is_present: 0,
        title: "",
        employement_type: "part_time",
        company_name: "",
        location: "",
        description: "",
        start_date: "",
        end_date: "",
    }
    const [addexperience, setAddexperience] = useState(initialValue);

    const handleChangeIsPresent = (event) => {
        let value = 0;
        if (event.target.checked) {
            value = 1;
        } else {
            value = 0;
        }
        setAddexperience({
            ...addexperience,
            ["is_present"]: value,
        });
    };

    const handleChangeExp = (e, key) => {
        e.preventDefault();
        let value = e.target.value;
        setAddexperience({ ...addexperience, [key]: value });
    };

    const EditDataFunction = async (e, id) => {
        RemoveState(true);
        setExperienceValidation([]);
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("AUTHTOKEN", auth_token);

        var raw = JSON.stringify({
            experience_id: id,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
        };

        fetch(`${url.apiUrl}student/get-experience-by-id`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setAddexperience(result.data);
                    setIsLegnth(result?.data?.description?.length);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const handleAddExp = async () => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        const errors = {};


        if (addexperience.title === undefined || addexperience.title === "") {
            errors.title = "Required";
        }
        if (
            addexperience.employement_type === undefined ||
            addexperience.employement_type === ""
        ) {
            errors.employement_type = "Required";
        }
        if (
            addexperience.company_name === undefined ||
            addexperience.company_name === ""
        ) {
            errors.company_name = "Required";
        }
        if (addexperience.location === undefined || addexperience.location === "") {
            errors.location = "Required";
        }
        if (
            addexperience.start_date === undefined ||
            addexperience.start_date === ""
        ) {
            errors.start_date = "Required";
        }
        if (
            addexperience.is_present === undefined ||
            addexperience.is_present === 0
        ) {
            if (
                addexperience.end_date === undefined ||
                addexperience.end_date === ""
            ) {
                errors.end_date = "Required";
            } else {
                const start_date = moment(addexperience.start_date, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
                const end_date = moment(addexperience.end_date, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
                if (start_date.isValid() && end_date.isAfter(start_date)) {
                } else {
                    errors.end_date = "Please enter end date grater then start date";
                }
            }
        }
        if (addexperience.description === undefined || addexperience.description === "") {
            errors.description = "Required";
        } else if (addexperience.description.length > 500) {
            errors.description = "Please write less than 500 characters"
        }

        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);

        var formdata = new FormData();
        formdata.append("title", addexperience.title);
        formdata.append("company_name", addexperience.company_name);
        formdata.append("employement_type", addexperience.employement_type);
        formdata.append("location", addexperience.location);
        formdata.append("start_date", addexperience.start_date);
        if (
            addexperience.is_present !== 1 ||
            addexperience.is_present === undefined
        ) {
            formdata.append("end_date", addexperience.end_date);
        }
        formdata.append(
            "is_present",
            addexperience.is_present !== undefined ? addexperience.is_present : 0
        );
        formdata.append("description", addexperience.description);

        if (Object.keys(errors).length === 0 && isLegnth < 500) {
            if (addexperience.id) {
                formdata.append("experience_id", addexperience.id);
                formdata.append("status", 'active');
                var requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: formdata,
                };
                fetch(`${url.apiUrl}student/edit-experience-by-id`, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.status === "success") {
                            setForm(false);
                            getStudentExperienceGetProfiles();
                            toast.success(result.message, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        } else {
                            toast.error(result.message, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }
                    })
                    .catch((error) => console.log("error", error));
            } else {
                var requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: formdata,
                };

                fetch(`${url.apiUrl}student/add-experience`, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.status === "success") {
                            setForm(false);
                            getStudentExperienceGetProfiles();
                            toast.success(result.message, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        } else {
                            toast.error(result.message, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }
                    })
                    .catch((error) => console.log("error", error));
            }
        } else {
            setExperienceValidation(errors);
        }
    };

    const getStudentExperienceGetProfiles = async () => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            per_page: 10,
            order_column: "id",
            order_by: "asc",
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
        };

        fetch(`${url.apiUrl}student/get-all-experiences`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setData(result.data);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const RemoveState = async (value) => {
        setAddexperience(initialValue);
        setForm(value);
    };
    useEffect(() => {
        getStudentExperienceGetProfiles();
    }, []);

    return (
        <>
            <div className="row ">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header justify-content-between">
                            <h4 className="card-title">Experience Details</h4>
                            <div
                                className="d-flex align-items-center"
                                onClick={() => {
                                    RemoveState(true);
                                }}
                            >
                                <Link
                                    to="#"
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#experience-add"
                                >
                                    <i className="fa fa-plus"></i>
                                </Link>
                            </div>
                            <div
                                className={
                                    form ? "modal fade show" : "modal fade"
                                }
                                id="experience-add"
                                tabIndex={-1}
                                aria-labelledby="myModalLabel33"
                                aria-hidden="true"
                                style={form ? { display: "block" } : { display: "none" }}
                            >
                                <div className="modal-dialog modal-dialog-centered modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title" id="myModalLabel33">
                                                Add Experience Details
                                            </h4>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                id="experience-add"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                                onClick={() => setForm(false)}
                                            />
                                        </div>
                                        {form ? (
                                            <form action="#">
                                                <div className="modal-body">
                                                    <div className="row">
                                                        <div className="col-md-6 form-group">
                                                            <label

                                                                htmlFor="title-name"
                                                            >
                                                                Title <span className="text-danger"> *</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                onChange={(e) => handleChangeExp(e, "title")}
                                                                name="title"
                                                                autoComplete="off"
                                                                value={addexperience.title}
                                                                id="title-name"
                                                                className="form-control"
                                                                placeholder="Enter title"
                                                            />
                                                            <p className="bg-error">
                                                                {experienceValidation.title}
                                                            </p>
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <label

                                                                htmlFor="employee-type"
                                                            >
                                                                Employment Type
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <select
                                                                className="form-select select2"
                                                                onChange={(e) => handleChangeExp(e, "employement_type")}
                                                                name="employement_type"
                                                                value={addexperience.employement_type}
                                                                id="employee-type"
                                                                tabIndex={0}
                                                                aria-hidden="false"
                                                            >
                                                                <option disabled="">Select Employee Type</option>
                                                                <option value="full_time">Full Time</option>
                                                                <option value="part_time">Part Time</option>
                                                                <option value="internship">Internship</option>
                                                                <option value="contract">Contract</option>
                                                                <option value="freelancer">Freelancer</option>
                                                                <option value="seasonal">Seasonal</option>
                                                                <option value="temporary">Temporary</option>
                                                            </select>
                                                            <p className="bg-error">
                                                                {experienceValidation.employement_type}
                                                            </p>
                                                        </div>

                                                        <div className="col-md-6 form-group">
                                                            <label

                                                                htmlFor="company-name"
                                                            >
                                                                Company Name
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                onChange={(e) =>
                                                                    handleChangeExp(e, "company_name")
                                                                }
                                                                autoComplete="off"
                                                                name="company_name"
                                                                value={addexperience.company_name}
                                                                id="company-name"
                                                                className="form-control"
                                                                placeholder="Enter company name"
                                                            />
                                                            <p className="bg-error">
                                                                {experienceValidation.company_name}
                                                            </p>
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <label

                                                                htmlFor="location-name"
                                                            >
                                                                Location<span className="text-danger"> *</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                onChange={(e) => handleChangeExp(e, "location")}
                                                                autoComplete="off"
                                                                name="location"
                                                                value={addexperience.location}
                                                                id="location"
                                                                className="form-control"
                                                                placeholder="Enter company location"
                                                            />
                                                            <p className="bg-error">
                                                                {experienceValidation.location}
                                                            </p>
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <label

                                                                htmlFor="start-date"
                                                            >
                                                                Start Date
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <input
                                                                type="date"
                                                                onChange={(e) => handleChangeExp(e, "start_date")}
                                                                name="start_date"
                                                                value={addexperience.start_date}
                                                                max={currentDate}
                                                                id="start-date"
                                                                placeholder="DD-MM-YYYY"
                                                                autoComplete="off"
                                                                className="form-control"
                                                            />
                                                            <p className="bg-error">
                                                                {experienceValidation.start_date}
                                                            </p>
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <div className="clr"></div>
                                                            <input
                                                                type="checkbox"
                                                                onChange={(e) => handleChangeIsPresent(e)}
                                                                autoComplete="off"
                                                                name="is_present"
                                                                checked={addexperience.is_present}
                                                                id="company-names"
                                                            />
                                                            <label>
                                                                I am currently working this company.
                                                            </label>
                                                            <p className="bg-error">
                                                                {experienceValidation.is_present}
                                                            </p>
                                                        </div>
                                                        {addexperience.is_present === 0 && (
                                                            <div className="col-md-6 form-group">
                                                                <label

                                                                    htmlFor="end-date"
                                                                >
                                                                    End Date
                                                                    <span className="text-danger"> *</span>
                                                                </label>
                                                                <input
                                                                    type="date"
                                                                    onChange={(e) => handleChangeExp(e, "end_date")}
                                                                    name="end_date"
                                                                    value={addexperience.end_date}
                                                                    min={addexperience.start_date}
                                                                    id="end-date"
                                                                    placeholder="DD-MM-YYYY"
                                                                    autoComplete="off"
                                                                    className="form-control"
                                                                />
                                                                <p className="bg-error">
                                                                    {experienceValidation.end_date}
                                                                </p>
                                                            </div>
                                                        )}
                                                        <div className="mb-1 col-md-12">
                                                            <label htmlFor="last-name">
                                                                Description
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Editor
                                                                textareaName='description'
                                                                placeholder='Enter description'
                                                                apiKey='9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d'
                                                                init={{
                                                                    plugins: [
                                                                        "advlist",
                                                                        "autolink",
                                                                        "lists",
                                                                        "link",
                                                                        "image",
                                                                        "charmap",
                                                                        "preview",
                                                                        "anchor",
                                                                        "searchreplace",
                                                                        "visualblocks",
                                                                        "code",
                                                                        "fullscreen",
                                                                        "insertdatetime",
                                                                        "media",
                                                                        "table",
                                                                        "help",
                                                                        "wordcount",
                                                                        "spellchecker",
                                                                    ],

                                                                    toolbar:
                                                                        " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help |spellchecker ",
                                                                }}
                                                                value={addexperience?.description}
                                                                onEditorChange={(e) =>
                                                                    setAddexperience({ ...addexperience, description: e })
                                                                }
                                                            />
                                                            <p className="bg-error">
                                                                {experienceValidation.description}
                                                            </p>
                                                            <p
                                                                style={{ position: "absolute", right: "15px" }}
                                                            >
                                                                {addexperience?.description?.length} / 500 Characters
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        ) : (
                                            ""
                                        )}

                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                onClick={handleAddExp}
                                                className="btn btn-primary "
                                            >
                                                Submit
                                            </button>
                                            <button
                                                type="reset"
                                                id="experience-add-reset"
                                                className="btn btn-danger"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                                onClick={() => setForm(false)}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {data?.length > 0 ? (
                            data?.map((item, key
                            ) => (
                                <div className="card-body" key={key}>
                                    <ul className="d-flex mb-0">
                                        <li>
                                            <div className="activity1 bg-primary">
                                                <i className="fe fe-shopping-bag"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="font-weight-semibold">
                                                {item?.title} - ({item?.employement_type})
                                            </div>
                                            <p className="text-muted mb-0">
                                                {item?.company_name}
                                            </p>
                                        </li>

                                        <div className="text-muted ms-auto">
                                            <Moment format="DD-MM-YYYY">
                                                {item?.start_date}
                                            </Moment>
                                            {" - "}
                                            {item?.is_present === 1 ? (
                                                "Present"
                                            ) : (
                                                <Moment format="DD-MM-YYYY">
                                                    {item?.end_date}
                                                </Moment>
                                            )}
                                            <Link
                                                to="#"
                                                className="btn btn-primary ms-3"
                                                title="Edit"
                                                data-bs-toggle="modal"
                                                data-bs-target={`#editexperience_${key}`}
                                                onClick={(e) => EditDataFunction(e, item?.id)}
                                            >
                                                <i className="fa fa-edit"></i>
                                            </Link>
                                        </div>
                                    </ul>
                                    <p className="text-muted mb-0 margin-left education-mandal" dangerouslySetInnerHTML={{ __html: item?.description }}>
                                    </p>
                                </div>
                            ))
                        ) : (
                            <NoDataAvailable />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Experience;
