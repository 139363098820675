import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import Moment from "react-moment";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { replaceWord } from "../../../../../utils/utils";
import NoDataAvailable from "../../../../common/NoDataAvailable"
let rating = [1, 2, 3, 4, 5];

const ClassDetails = () => {
    const { id, is_main } = useParams();
    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage.auth_token; var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("AUTHTOKEN", auth_token);
    const [reviews, SetReviews] = useState([]);
    const [course, SetCourse] = useState({});
    const [search, setSearch] = useState({
        start: 0,
        perPage: 1,
        course_type: 'class_course',
        course_id: id
    });
    const [totalPages, setTotalPages] = useState(0);
    const [offset, setOffset] = useState(0);
    let now = new Date().setHours(0, 0, 0, 0);

    const getCourseData = async () => {
        try {
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
            };

            fetch("https://educationmandal.com/em-admin/api/" + `institute/class-lecture-detail/${is_main}/${id}`, requestOptions)
                .then(response => response.json())
                .then((result) => {

                    SetCourse(result.data);
                })
                .catch(error => console.log('error', error));

        } catch (err) {
            throw err;
        }
    }
    const getReviews = async (activity) => {
        const config = {
            headers: {
                content: "application/json",
                AUTHTOKEN: auth_token,
            },
        };
        try {
            const response = await axios.post(
                "https://educationmandal.com/em-admin/api/" + `institute/course-review`,
                activity,
                config
            );
            if (response) {
                SetReviews(response.data?.data);
                setTotalPages(response.data?.count / search.perPage);
            }

        } catch (err) {
            throw err;
        }
    }

    const getAllReviews = async () => {
        setOffset(search.start);
        let activity = {
            limit: search.perPage,
            offset: search.start,
            course_type: search.course_type,
            course_id: search.course_id
        };
        await getReviews(activity);
    };

    const handlePageClick = async (data) => {

        let currentPage = data.selected * search.perPage;
        setOffset(currentPage);
        const activity = {
            course_type: search.course_type,
            course_id: search.course_id,
            limit: search.perPage,
            offset: currentPage,
        };
        await getReviews(activity);
    };
    useEffect(() => {
        getCourseData();
    }, []);
    useEffect(() => {
        getAllReviews();
    }, [search]);
    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard/class">Classroom Course</Link>
                    </li>
                    <li className="breadcrumb-item active">Class Details</li>
                </ol>
            </div>

            <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="product-slider">
                                <ul className="list-unstyled video-list-thumbs">
                                    <li className="mb-0">
                                        <div className="item-card7-img">
                                            <img alt={course?.title} className="cover-image" src={course?.thumbnail} />
                                            {(
                                                course?.price == "paid" && (
                                                    course?.is_discount == 1 && (
                                                        (course?.is_limited_offer == 1 && (new Date(course?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(course?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                        (course?.is_limited_offer == 0 && new Date(course?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                )
                                            ) && (
                                                    <Link to="#" className="bg-danger top left">{course?.discount_price}%  off</Link>
                                                )}
                                            <Link to="#" className="bg-danger right top">
                                                Classroom Course
                                            </Link>
                                            {course?.class_type === "school" ? (
                                                <Link to="#" className="bg-danger right bottom">
                                                    {course?.board}
                                                </Link>
                                            ) : (course?.class_type === "university" ? (
                                                <Link to="#" className="bg-danger right bottom">
                                                    {course?.universities?.name}
                                                </Link>
                                            ) : (course?.class_type === "tuition" && (
                                                <>
                                                    {course?.tuition_type === "school" ? (
                                                        <Link to="#" className="bg-danger right bottom">
                                                            {course?.board}
                                                        </Link>
                                                    ) : (course?.tuition_type === "university" && (
                                                        <Link to="#" className="bg-danger right bottom">
                                                            {course?.universities?.name}
                                                        </Link>
                                                    ))}
                                                </>
                                            )))}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="mt-4 text-center">
                                <div className="mb-2">
                                    {course?.price === "paid" && (
                                        <span className="font-weight-semibold h2 mb-0">
                                            ₹ {Math.round(course?.payable_price)} {(
                                                course?.price == "paid" && (
                                                    course?.is_discount == 1 && (
                                                        (course?.is_limited_offer == 1 && (new Date(course?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(course?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                        (course?.is_limited_offer == 0 && new Date(course?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                )
                                            ) && (<del className="fs-14 text-muted">₹ {Math.round(course?.regular_price)} </del>
                                                )}
                                        </span>
                                    )}
                                    {course?.price === "free" && (
                                        <span className="font-weight-semibold h2 mb-0">
                                            Free
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="card-header">
                            <h4 className="card-title">Classroom Course Info :</h4>
                        </div>
                        <table className="table table-hover dataTable">
                            <tbody>
                                <tr>
                                    <td className="text-nowrap font-weight-semibold">Branch Name</td>
                                    <td className="w-0 text-center"> : </td>
                                    <td className="text-end">
                                        <Link className="text-primary" to={"/institute-dashboard/branch-detail/" + course?.branch_id}>
                                            {course?.institute_branch?.name}
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-nowrap font-weight-semibold">Class Type</td>
                                    <td className="w-0 text-center"> : </td>
                                    <td className="text-end text-capitalize">
                                        {replaceWord(course?.class_type)}
                                    </td>
                                </tr>
                                {course?.class_type === "school" ? (
                                    <>
                                        <tr>
                                            <td className="text-nowrap font-weight-semibold">Board</td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end text-capitalize">
                                                {course?.board}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-nowrap font-weight-semibold">Section</td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end text-capitalize">
                                                {course?.section}
                                            </td>
                                        </tr>
                                    </>
                                ) : (course?.class_type === "university" ? (
                                    <>
                                        <tr>
                                            <td className="text-nowrap font-weight-semibold">University</td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end text-capitalize">
                                                {course?.universities?.name}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-nowrap font-weight-semibold">Semester</td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end text-capitalize">
                                                {course?.semester}
                                            </td>
                                        </tr>
                                    </>
                                ) : (course?.class_type === "tuition" && (
                                    <>
                                        <tr>
                                            <td className="text-nowrap font-weight-semibold">Tuition Type</td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end text-capitalize">
                                                {replaceWord(course?.tuition_type)}
                                            </td>
                                        </tr>
                                        {
                                            course?.tuition_type === "school" ? (
                                                <tr>
                                                    <td className="text-nowrap font-weight-semibold">Board</td>
                                                    <td className="w-0 text-center"> : </td>
                                                    <td className="text-end text-capitalize">
                                                        {course?.board}
                                                    </td>
                                                </tr>
                                            ) : (course?.tuition_type === "university" && (
                                                <>
                                                    <tr>
                                                        <td className="text-nowrap font-weight-semibold">University</td>
                                                        <td className="w-0 text-center"> : </td>
                                                        <td className="text-end text-capitalize">
                                                            {course?.universities?.name}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-nowrap font-weight-semibold">Semester</td>
                                                        <td className="w-0 text-center"> : </td>
                                                        <td className="text-end text-capitalize">
                                                            {course?.semester}
                                                        </td>
                                                    </tr>
                                                </>
                                            ))}
                                    </>
                                )))}
                                <tr>
                                    <td className="text-nowrap font-weight-semibold">Medium</td>
                                    <td className="w-0 text-center"> : </td>
                                    <td className="text-end"> {course?.medium}</td>
                                </tr>
                                <tr>
                                    <td className="text-nowrap font-weight-semibold">Classroom Type</td>
                                    <td className="w-0 text-center"> : </td>
                                    <td className="text-end"> {replaceWord(course?.lecture_type)}</td>
                                </tr>
                                {(course?.lecture_type === "both" || course?.lecture_type === "video_conference") ? (
                                    <tr>
                                        <td className="text-nowrap font-weight-semibold">VC Link</td>
                                        <td className="w-0 text-center"> : </td>
                                        <td className="text-end">
                                            <a href={course?.video_conference_link} target="_blank" rel="noreferrer" className="btn btn-primary">
                                                Link Here
                                            </a>
                                        </td>
                                    </tr>
                                ) : ("")}
                                <tr>
                                    <td className="text-nowrap font-weight-semibold">Last Update</td>
                                    <td className="w-0 text-center"> : </td>
                                    <td className="text-end">
                                        <Moment format="DD-MM-YYYY hh:mm A">{course?.updated_at}</Moment>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-xl-9 col-lg-8 col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Class Name </h4>
                        </div>
                        <div className="card-body">
                            {course?.title}
                        </div>
                    </div>
                    <div className="card">
                        <Tabs defaultActiveKey="overview" id="uncontrolled-tab-example">
                            <Tab eventKey="overview" title="overview">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Class Description </h4>
                                    </div>
                                    <div className="card-body education-mandal" dangerouslySetInnerHTML={{ __html: course?.description }}>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">What will students learn in this classroom? </h4>
                                    </div>
                                    <div className="card-body education-mandal" dangerouslySetInnerHTML={{ __html: course?.curriculum }}>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Requirements </h4>
                                    </div>
                                    <div className="card-body education-mandal" dangerouslySetInnerHTML={{ __html: course?.requirements }}>
                                    </div>
                                </div>

                            </Tab>
                            {course?.schedule && (
                                <Tab eventKey="timeTable" title="Time Table">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">Time Table</h4>
                                        </div>

                                        <div className="card-body">
                                            <table className="table table-bordered table-hover dataTable">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center">Sr. No.</th>
                                                        <th>Subject</th>
                                                        <th className="text-center">Teacher's Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {course?.schedule?.length > 0 ?
                                                        course?.schedule.map((subject, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="text-center">{index + 1}</td>
                                                                    <td className="text-center">{subject?.subject}</td>
                                                                    {subject.teacher_schedule.length != 0 ? (
                                                                        <table className="table table-bordered table-hover dataTable">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Teacher Details</th>
                                                                                    {/* <th className="text-center">Start Date</th>
                                                                                    <th className="text-center">End Date</th> */}
                                                                                    <th className="text-center">Date</th>
                                                                                    <th className="text-center">Start Time</th>
                                                                                    <th className="text-center">End Time</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {subject.teacher_schedule.map((teacher_schedule, index) => (
                                                                                    <tr key={index}>
                                                                                        <td> {teacher_schedule?.teacher && (
                                                                                            <div className="media">
                                                                                                <img
                                                                                                    src={teacher_schedule?.teacher?.image}
                                                                                                    alt={teacher_schedule?.teacher?.name}
                                                                                                    className="me-3 rounded-circle"
                                                                                                />
                                                                                                <div className="media-body">
                                                                                                    <Link to={`/institute-dashboard/teacher-detail/${teacher_schedule?.teacher_id}`} className="font-weight-semibold" >
                                                                                                        {teacher_schedule?.teacher?.name}
                                                                                                    </Link>
                                                                                                </div>
                                                                                            </div>
                                                                                        )}</td>
                                                                                        <td className="text-center"><Moment format="DD-MM-YYYY">{teacher_schedule?.date}</Moment></td>
                                                                                        {/* <td className="text-center"><Moment format="DD-MM-YYYY">{teacher_schedule?.start_date}</Moment></td>
                                                                                        <td className="text-center"><Moment format="DD-MM-YYYY">{teacher_schedule?.end_date}</Moment></td> */}
                                                                                        <td className="text-center">{moment(teacher_schedule?.start_time, ["HH:mm"]).format("hh:mm A")}</td>
                                                                                        <td className="text-center">{moment(teacher_schedule?.end_time, ["HH:mm"]).format("hh:mm A")}</td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    ) : (
                                                                        <p className="text-center mb-0">Timeslot for teacher is not added</p>
                                                                    )}
                                                                </tr>
                                                            );
                                                        })
                                                        : <tr>
                                                            <td className="text-center mb-0" colSpan={9}>No Data Available !</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Tab>
                            )}
                            <Tab eventKey="reviews" title="reviews">
                                {reviews.length != 0 ? (
                                    <>
                                        {reviews.map((item, i) => (
                                            <div className="media mt-0 p-4 border br-5 review-media" key={i} >
                                                <div className="d-flex me-3">
                                                    <Link to="#">
                                                        <img
                                                            className="border-radius-50 avatar-lg"
                                                            alt={item?.name}
                                                            src={item?.student?.image}
                                                        />
                                                    </Link>
                                                </div>
                                                <div className="media-body">
                                                    <h4 className="mt-0 mb-1 font-weight-semibold">
                                                        {item?.name}
                                                        <small className="text-muted float-end">
                                                            <Moment format="DD-MM-YYYY hh:mm A">{item?.created_at}</Moment>
                                                        </small>
                                                    </h4>
                                                    <div className="br-widget">
                                                        {rating.map((el, i) => (
                                                            <i
                                                                data-rating-value={el}
                                                                data-rating-text={el}
                                                                className={
                                                                    el <= item?.average_review
                                                                        ? "br-selected"
                                                                        : ""
                                                                }
                                                                key={i}
                                                            />
                                                        ))}
                                                    </div>
                                                    <p className="mb-2 mt-2" dangerouslySetInnerHTML={{ __html: item?.comment }}>
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                        <ReactPaginate
                                            previousLabel={"Previous"}
                                            nextLabel={"Next"}
                                            breakLabel={"..."}
                                            pageCount={Math.ceil(totalPages)}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageClick}
                                            containerClassName={ "pagination" }
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                        />
                                    </>
                                ) : (
                                    <NoDataAvailable />
                                )}
                            </Tab>
                        </Tabs >
                    </div >
                </div>
            </div>
        </>
    );
};

export default ClassDetails;
