import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import url from "../../../../constants/url";
import { toast } from "react-toastify";
import SubmitLoader from "../../../common/SubmitLoader";
import { Editor } from "@tinymce/tinymce-react";

const About = (props) => {
    const [aboutForm, setAboutForm] = useState(false);
    const [loader, setLoader] = useState(false);
    const [data, setAboutInfo] = useState(props.data);
    const [formValidation, setFormValidation] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLegnth, setIsLegnth] = useState(0);

    const handleAboutUpdate = async (e) => {
        e.preventDefault();
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;
        let errors = {};

        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);

        var formdata = new FormData();
        formdata.append("about", data.about);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
        };

        if (data.about === undefined || data.about === "") {
            errors.about = "Required";
        } else if (data.about.length > 500) {
            errors.about = "Please write less than 500 characters"
        }

        if (Object.keys(errors).length === 0 && isLegnth < 500) {
            setLoader(true);
            fetch(`${url.apiUrl}student/edit-profile`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === "success") {
                        setLoader(false);
                        toast.success(result.message);
                        setAboutForm(false);
                        props.setStudentdata({ ...props.data, about: data.about });
                        setAboutInfo(result.data);
                        document.getElementById("about-close").click();
                        document.getElementById("reset-btn").click();
                    }
                })
                .catch((error) => console.log("error", error));
        } else {
            setFormValidation(errors);
        }
    };

    useEffect(() => {
        if (props.data && props.data) setAboutInfo(props.data);
    }, [props.data]);

    const handleChange = (e) => {
        e.preventDefault();
        const value = e.target.value;

        setAboutInfo({
            ...data,
            [e.target.name]: value,
        });
    };

    return (
        <>
            <div className="row ">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header justify-content-between">
                            <h4 className="card-title">About the Student</h4>
                            <div
                                className="d-flex align-items-center"
                                onClick={() => {
                                    setAboutForm(true);
                                }}
                            >
                                <Link
                                    to="#"
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#aboutus"
                                >
                                    <i className="fa-solid fa-pen-to-square"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="card-body education-mandal" dangerouslySetInnerHTML={{ __html: props?.data?.about ? props?.data?.about : "No Data Available !" }}>
                        </div>
                        <div
                            className={
                                aboutForm
                                    ? "modal fade show"
                                    : "modal fade"
                            }
                            id="aboutus"
                            aria-labelledby="myModalLabel33"
                            aria-hidden="true"
                            style={aboutForm ? { display: "block" } : { display: "none" }}
                        >
                            <div className="modal-dialog modal-dialog-centered modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title" id="myModalLabel33">
                                            About
                                        </h4>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            id="about-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => setAboutForm(false)}
                                        />
                                    </div>
                                    {aboutForm ? (
                                        <form>
                                            <div className="modal-body">
                                                <Editor
                                                    textareaName='about'
                                                    placeholder='Enter about'
                                                    apiKey='9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d'
                                                    init={{
                                                        plugins: [
                                                            "advlist",
                                                            "autolink",
                                                            "lists",
                                                            "link",
                                                            "image",
                                                            "charmap",
                                                            "preview",
                                                            "anchor",
                                                            "searchreplace",
                                                            "visualblocks",
                                                            "code",
                                                            "fullscreen",
                                                            "insertdatetime",
                                                            "media",
                                                            "table",
                                                            "help",
                                                            "wordcount",
                                                            "spellchecker",
                                                        ],

                                                        toolbar:
                                                            " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help |spellchecker ",
                                                    }}
                                                    value={data?.about}
                                                    onEditorChange={(e) =>
                                                        setAboutInfo({ ...data, "about": e })
                                                    }
                                                />
                                                <p className="bg-error">
                                                    {formValidation.about}
                                                </p>
                                                <p className="text-end"                                               >
                                                    {data?.about?.length} / 500 Characters
                                                </p>
                                            </div>
                                            <div className="modal-footer">
                                                {loader ? (
                                                    <SubmitLoader />
                                                ) : (
                                                    <>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={handleAboutUpdate}
                                                        >
                                                            Submit
                                                        </button>
                                                        <button
                                                            type="reset"
                                                            id="reset-btn"
                                                            className="btn btn-danger"
                                                            data-bs-dismiss="modal"
                                                            aria-label="Close"
                                                            onClick={() => setAboutForm(false)}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                        </form>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default About;
