import React, { useState } from "react";
import { Link } from "react-router-dom";

const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const faqData = [
        {
            question: "What is an FAQ page?",
            answer: "An FAQ page is a list of commonly asked questions and answers about a specific topic. It helps users find quick solutions without needing to contact support.",
        },
        {
            question: "Why create an FAQ page?",
            answer: "FAQ pages improve customer experience by addressing common questions, saving time for both users and support teams, and boosting website engagement.",
        },
        {
            question: "How do I design an FAQ page?",
            answer: "To design an FAQ page: 1. Identify key questions. 2. Group them into categories. 3. Use clear, concise answers. 4. Add search functionality. 5. Match your site's branding. 6. Update regularly.",
        },
        {
            question: "What questions should I include in an FAQ?",
            answer: "Focus on common queries related to your product or service.",
        },
        {
            question: "Can an FAQ page increase sales?",
            answer: "Yes! A well-structured FAQ page builds trust, clarifies uncertainties, and guides potential customers towards purchasing decisions, ultimately boosting conversions.",
        },
    ];

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <section className="Faq-section my-5 py-5">
            <div className="row justify-content-center align-items-center" data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="1500">
                <div className="faq-container col-12">
                    <h2 className="faq-header">Frequently <span className="text-primary" >Asked</span> Questions</h2>
                    <div className="faq-list">
                        {faqData.map((item, index) => (
                            <div key={index} className="faq-item">
                                <button
                                    className={`faq-question ${activeIndex === index ? "active" : ""}`}
                                    onClick={() => toggleAccordion(index)}
                                >
                                    {item.question}
                                    <span className={`faq-icon text-primary ${activeIndex === index ? "rotate" : ""}`}>
                                        ▶
                                    </span>
                                </button>
                                <div className={`faq-answer ${activeIndex === index ? "open" : ""}`}>
                                    <p>{item.answer}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="faq-footer">
                        <p>
                            Need more answers?{" "}
                            <Link to="/">
                                Visit our support page
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FAQ;
