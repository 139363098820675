import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import url from "../../../../../constants/url";
import * as Yup from "yup";
import { toast } from "react-toastify";
import SubmitLoader from "../../../../common/SubmitLoader"
import MapboxAutocomplete from "react-mapbox-autocomplete";
import Select from "react-select";
import PhoneInputWithCountrySelect from "react-phone-number-input";

const UpdateProfile = ({
    setProfileForm,
    formValues,
    setFormValues,
    getProfileData,
    setSelectedImage,
    selectedImage,
}) => {

    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [loader, setLoader] = useState(false);
    const [Values, setValues] = useState({
        name: "",
        contact: "",
        contact_person_name: "",
        image: "",
        year_of_incap: "",
        address: "",
        location: "",
        states: "",
        cities: "",
        state: "",
        city: "",
        pincode: "",
        latitude: "",
        longitude: "",
    });

    const profileSchema = Yup.object().shape({
        image: Yup.string().required("Required"),
        name: Yup.string().required("Required"),
        contact_person_name: Yup.string().required("Required"),
        year_of_incap: Yup.string().required("Required"),
        address: Yup.string().required("Required"),
        location: Yup.string().required("Required"),
        states: Yup.object().required("Required"),
        cities: Yup.object().required("Required"),
        pincode: Yup.string()
            .required("Required")
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(6, "Please enter valid pincode")
            .max(6, "Please enter valid pincode"),
        contact: Yup.string().required("Required")
            .min(13, "Please enter valid contact number")
            .max(13, "Please enter valid contact number"),
    });

    const [imageUpdate, setImageUpdate] = useState(false);
    const ChangeEvent = async (e, props) => {
        if (e.target.name === "image") {
        } else {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
        }
    };

    const handleFileInputChange = async (e, props) => {
        let { file } = e.target.files[0];

        file = e.target.files[0];

        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function (e) {
            var image = new Image();
            image.src = e.target.result;
            image.onload = function () {
                var height = this.height;
                var width = this.width;
                if (height > 200 || width > 368) {
                    alert(
                        "Image size: width = 368px and height = 200px"
                    );
                    props.setFieldValue("image", "");
                    props.setFieldError("image", "Image size: width = 368px and height = 200px")
                } else if (height < 200 || width < 368) {
                    alert(
                        "Image size: width = 368px and height = 200px"
                    );
                    props.setFieldValue("image", "");
                    props.setFieldError("image", "Image size: width = 368px and height = 200px")

                } else {
                    getBase64(file)
                        .then((result) => {
                            setSelectedImage(result);
                            setImageUpdate(true);
                            props.setFieldValue("image", file);
                        })
                        .catch((err) => { });
                }
            };
        };
    };

    const getBase64 = (file) => {
        return new Promise((resolve) => {
            let fileInfo;
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    useEffect(() => {
        setValues({
            name: formValues?.name,
            contact: formValues?.contact,
            contact_person_name: formValues?.contact_person_name,
            year_of_incap: formValues?.year_of_incap || "",
            image: formValues?.image,
            address: formValues?.address,
            location: formValues?.location,
            states: { label: formValues?.states?.name, value: formValues?.states?.value },
            cities: { label: formValues?.cities?.name, value: formValues?.cities?.value },
            state: formValues?.state,
            city: formValues?.city,
            pincode: formValues?.pincode,
            latitude: formValues?.latitude,
            longitude: formValues?.longitude,
        });
    }, []);


    const onSubmit = (values) => {
        const errors = {};
        const payload = values;
        setFormValues(payload);
        try {
            const local_data = localStorage.getItem("common-user-info");
            const local_storage = JSON.parse(local_data);
            const auth_token = local_storage.auth_token;
            var myHeaders = new Headers();
            myHeaders.append("AUTHTOKEN", auth_token);
            var formdata = new FormData();
            if (imageUpdate) {formdata.append("image", values?.image); }
            formdata.append("name", values.name);
            formdata.append("contact", values.contact);
            formdata.append("contact_person_name", values.contact_person_name);
            formdata.append("year_of_incap", values.year_of_incap);
            formdata.append("address", values.address);
            formdata.append("location", values.location);
            formdata.append("state", values.states.value || values.state);
            formdata.append("city", values.cities.value || values.city);
            formdata.append("pincode", values.pincode);
            formdata.append("latitude", values.latitude);
            formdata.append("longitude", values.longitude);
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
            };
            if (formValues.image === undefined || formValues.image === "") {
                errors.image = "Required";
            }

            if (Object.keys(errors).length === 0) {
                setLoader(true);
                fetch(`${url.apiUrl}institute/edit-profile`, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {

                        if (result.status === "success") {
                            setProfileForm(false);

                            setLoader(false);
                            getProfileData();
                            setProfileForm(false);
                            toast.success("Profile Edited Successfully", {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                            window.location.reload();
                        } else {
                            setLoader(false);
                            toast.error("Something went wrong", {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }
                    })
                    .catch((error) => console.log("error", error));
            } else {
                setProfileForm(true);
            }
        } catch (error) {
            console.log("error" + error);
        }
    };

    function _suggestionSelect(result, lat, long, text, props) {
        props.setFieldValue("location", result);
        props.setFieldValue("latitude", lat);
        props.setFieldValue("longitude", long);
    }

    const mapAccess = {
        mapboxApiAccessToken:
            "pk.eyJ1Ijoic2FuY2hpdHNoYXJtYSIsImEiOiJjbGZocmRoMm0yMTJmM3FwZ21nZ2NqdG91In0.Ngsso-JXR08GApV2N1rmUw",
    };

    const StateListApi = async (e) => {
        var requestOptions = {
            method: "GET",
        };

        fetch(`${url.apiUrl}state/101`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    const arr = result.data.map((v) => {
                        return { value: v.id, label: v.name };
                    });
                    setState(arr);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const CityListApi = async (id) => {
        var requestOptions = {
            method: "GET",
        };

        fetch(`${url.apiUrl}city/${id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    const arr = result.data.map((v) => {
                        return { value: v.id, label: v.name };
                    });
                    setCity(arr);
                }
            })
            .catch((error) => console.log("error", error));
    };

    useEffect(() => {
        StateListApi();
    }, []);
    return (
        <Formik
            initialValues={Values}
            onSubmit={onSubmit}
            validationSchema={profileSchema}
            enableReinitialize={true}
        >
            {(formProps) => {

                return (
                    <form onSubmit={formProps.handleSubmit}>
                        <div className='modal-body'>
                            <div className='row align-items-center'>
                                <div className='col-md-6 form-group'>
                                    <label htmlFor='name'>
                                        Profile Picture
                                        <span className='text-danger'>*</span>
                                    </label>
                                    <input
                                        type='file'
                                        className='form-control'
                                        id='image'
                                        name='image'
                                        onChange={(e) => handleFileInputChange(e, formProps)}
                                    />
                                    <p className="fs-12 mb-0"><span className="text-danger">Note:</span> Image size: width = 368px and height = 200px</p>

                                    {formValues?.image &&
                                        typeof formValues?.image === "string" ? (
                                        <div className='form-text text-muted'>
                                            {formValues?.image.split("/").at(-1)}
                                        </div>
                                    ) : null}

                                    {formProps?.errors?.image ? (
                                        <div className='bg-error'>
                                            {formProps?.errors?.image}
                                        </div>
                                    ) : null}
                                </div>
                                <div className=' form-group col-md-3'>
                                    {imageUpdate ? (
                                        <img className="img-thumbnail" src={selectedImage} />
                                    ) : (
                                        <img className="img-thumbnail" src={formValues?.image} />
                                    )}
                                </div>
                                <div className='col-md-6 form-group'>
                                    <label htmlFor='name'>
                                        Institute Name
                                        <span className='text-danger'>*</span>
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        onChange={formProps.handleChange}
                                        onBlur={formProps.handleBlur}
                                        value={formProps.values.name}
                                        name='name'
                                    />

                                    {formProps.touched.name && formProps.errors.name ? (
                                        <div className='bg-error'>
                                            {formProps.errors.name}
                                        </div>
                                    ) : null}
                                </div>
                                <div className='col-md-6 form-group'>
                                    <label htmlFor='contact_person_name'>
                                        Contact Person Name
                                        <span className='text-danger'>*</span>
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='contact_person_name'
                                        onChange={formProps.handleChange}
                                        onBlur={formProps.handleBlur}
                                        value={formProps.values.contact_person_name}
                                    />

                                    {formProps.touched.contact_person_name &&
                                        formProps.errors.contact_person_name ? (
                                        <div className='bg-error'>
                                            {formProps.errors.contact_person_name}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-lg-6 col-12 form-group">
                                    <label>
                                        House No. / Street / Ward No
                                        <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        name="address"
                                        className="form-control"
                                        value={formProps.values.address}
                                        onChange={formProps.handleChange}
                                        placeholder="Please enter house no. / street / ward no."
                                    />
                                    {formProps.touched.address &&
                                        formProps.errors.address ? (
                                        <div className='bg-error'>
                                            {formProps.errors.address}
                                        </div>
                                    ) : null}
                                </div>
                                {formProps.values.location && (
                                    <div className="col-lg-6 col-12 form-group">
                                        <label>
                                            Location <span className="text-danger">*</span>
                                        </label>
                                        <MapboxAutocomplete
                                            publicKey={mapAccess.mapboxApiAccessToken}
                                            inputclassName="form-control "
                                            onSuggestionSelect={(result, lat, long, text) => {
                                                _suggestionSelect(result, lat, long, text, formProps);
                                            }}
                                            country="IN"
                                            query={formProps.values.location}
                                            resetSearch={false}
                                            placeholder="Search Location..."
                                        />
                                        {formProps.touched.location &&
                                            formProps.errors.location ? (
                                            <div className='bg-error'>
                                                {formProps.errors.location}
                                            </div>
                                        ) : null}

                                    </div>
                                )}
                                <div className="col-lg-4 col-12 form-group">
                                    <label>
                                        State <span className="text-danger">*</span>
                                    </label>
                                    <Select
                                        value={formProps.values.states}
                                        name="state"
                                        onChange={(e) => {
                                            CityListApi(e.value);
                                            formProps.setFieldValue("states", e);
                                        }}
                                        options={state}
                                    />
                                    {formProps.touched.states &&
                                        formProps.errors.states ? (
                                        <div className='bg-error'>
                                            {formProps.errors.states}
                                        </div>
                                    ) : null}

                                </div>
                                <div className="col-lg-4 col-12 form-group">
                                    <label>
                                        City <span className="text-danger">*</span>
                                    </label>
                                    <Select
                                        value={formProps.values.cities}
                                        onChange={(e) => {
                                            formProps.setFieldValue("cities", e);
                                        }}
                                        options={city}
                                    />
                                    {formProps.touched.cities &&
                                        formProps.errors.cities ? (
                                        <div className='bg-error'>
                                            {formProps.errors.cities}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-lg-4 col-12 form-group">
                                    <label>
                                        Pincode <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        text="text"
                                        name="pincode"
                                        className="form-control"
                                        value={formProps.values.pincode}
                                        onChange={formProps.handleChange}
                                        placeholder="Please enter pincode"
                                    />
                                    {formProps.touched.pincode &&
                                        formProps.errors.pincode ? (
                                        <div className='bg-error'>
                                            {formProps.errors.pincode}
                                        </div>
                                    ) : null}
                                </div>

                                {/* end */}
                                <div className='col-md-6 form-group'>
                                    <label htmlFor='contact'>
                                        Contact No.
                                        <span className='text-danger'>*</span>
                                    </label>
                                    <PhoneInputWithCountrySelect
                                        name='contact'
                                        value={formProps.values.contact}
                                        min={10}
                                        max={10}
                                        onChange={(e) => {
                                            formProps.setFieldValue("contact", e);
                                        }}
                                        // onChange={formProps.handleChange}
                                        defaultCountry='IN'
                                        className='form-control'
                                    />
                                    {
                                        formProps.errors.contact ? (
                                            <div className='bg-error'>
                                                {formProps.errors.contact}
                                            </div>
                                        ) : null}
                                </div>
                                <div className='col-md-6 form-group'>
                                    <label htmlFor='year_of_incap'>
                                        Year of Incorporation
                                        <span className='text-danger'>*</span>
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        onChange={formProps.handleChange}
                                        onBlur={formProps.handleBlur}
                                        value={formProps.values.year_of_incap}
                                        name='year_of_incap'
                                        placeholder='Please enter year of incorporation'
                                    />
                                    {formProps.touched.year_of_incap &&
                                        formProps.errors.year_of_incap ? (
                                        <div className='bg-error'>
                                            {formProps.errors.year_of_incap}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            {loader ? (
                                <SubmitLoader />
                            ) : (
                                <>
                                    <button type='submit' className='btn btn-primary'>
                                        Submit
                                    </button>
                                    <button
                                        type='reset'
                                        className='btn btn-danger '
                                        onClick={() => {
                                            setProfileForm(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </>
                            )}
                        </div>
                    </form>
                );
            }}
        </Formik>
    );
};

export default UpdateProfile;
