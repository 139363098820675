import moment from "moment";
class Request {
    async sendApiRequest(url, method, setauth, body) {
        let auth = JSON.parse(localStorage.getItem("common-user-info"));

        const requestOptions = {
            method: method,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                AUTHTOKEN: auth && auth?.auth_token,
            },
            body: JSON.stringify(body),
        };
        if (method === "DELETE") {
            delete requestOptions.body;
        }
        if (method === "GET") {
            delete requestOptions.body;
        }

        try {
            const response = await fetch(
                "https://educationmandal.com/em-admin/api/" + url,
                requestOptions
            );
            let body = await response.text();
            if (response.status !== 200) {
                throw body;
            }
            const data = body.includes("{") ? JSON.parse(body) : body;
            return data;
        } catch (e) {
            throw e;
        }
    }
    start_time(time) {
        let appointmentTime = moment(time).format("DD/MM/YYYY");
        return appointmentTime;
    }

    alphabet = (num) => {
        const letter = String.fromCharCode(num + "A".charCodeAt(0));
        return letter;
    };

    titleCase(str) {
        var splitStr = str?.length > 0 && str?.toLowerCase()?.split(" ");
        for (var i = 0; i < splitStr?.length; i++) {
            splitStr[i] =
                splitStr?.[i]?.charAt(0)?.toUpperCase() + splitStr?.[i]?.substring(1);
        }
        return splitStr && splitStr?.join(" ");
    }

    getInitials(string) {
        var names = string.split(" "),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    }
    differenceTime(updated) {
        let diffTime = moment(updated).fromNow();
        return diffTime;
    }
    toHHMMSS(secs) {
        var sec_num = parseInt(secs, 10)
        var hours = Math.floor(sec_num / 3600)
        var minutes = Math.floor(sec_num / 60) % 60
        var seconds = sec_num % 60

        return [hours, minutes, seconds]
            // .map(v => v < 10 ? "0" + v : v)
            // .filter((v, i) => v !== "00" || i > 0)
            .join(":")
    }
}

export default new Request();

export const handleLogout = () => {
    localStorage.clear();
    window.location.replace("/");
};

export const formatDate = (date) => {
    var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
};

export const returnData = (data, startIndex, endIndex) => {
    return data && data.slice(startIndex, endIndex)
}

export const defaultRoute = (token) => {
    if (token?.type === "student") {
        window.location.replace("/student-dashboard");
    } else if (token?.type === "teacher") {
        window.location.replace("/teacher-dashboard");
    } else if (token?.type === "institute") {
        window.location.replace("/institute-dashboard");
    }
};

export const replaceWord = (value) => {
    if (value === "school") {
        return "School (till 12th Standard)"
    } else if (value === "university") {
        return "University"
    } else if (value === "preparation") {
        return "Entrance Exam / Professional Courses"
    } else if (value === "tuition") {
        return "Tuition"
    } else if (value === "video_conference") {
        return "Online"
    } else if (value === "both") {
        return "Hybrid (Online + Offline)"
    } else if (value === "manual") {
        return "Offline"
    } else if (value === "daily") {
        return "Daily"
    } else if (value === "week_day") {
        return "Every Weekday (Monday to Friday)"
    } else if (value === "week_end") {
        return "Weekend (Saturday to Sunday)"
    } else if (value === "custom") {
        return "Custom"
    } else if (value === "not_selected") {
        return "Not Selected"
    } else if (value === "not_interested") {
        return "Candidate Not Interested"
    } else if (value === "short_listed") {
        return "Shortlisted"
    } else {
        return value
    }
};

export const calculateExperience = (number) => {
    var year = Math.floor(number / 365);
    number %= 365;
    var month = Math.floor(number / 30);
    number %= 30;

    var total_year = year + ((year > 1) ? " Years " : " Year ");
    var total_month = month + ((month > 1) ? " Months " : " Month ");
    return total_year + total_month;
}

export const createSlug = (value) => {
    return value
        .trim()
        .toLowerCase()
        .replaceAll(" ", "-")
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '')
}

