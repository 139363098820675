import React from 'react';
import { calculateExperience } from "../../../../utils/utils";
let rating = [1, 2, 3, 4, 5];

const ProfileStudent = (props) => {
    const { data } = props;
    return (

        <>
            <section className="app-user-view">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Teacher Profile</h4>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className='col-xl-1 col-lg-2 col-md-3 col-12'>
                                        <div className="item-card7-img">
                                            <img
                                                className='cover-image'
                                                src={data?.image}
                                                alt={data?.name}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-xl-5 col-lg-4 col-md-3 col-12 mt-5 mt-xl-0'>
                                        <h4>{data?.name}</h4>
                                        <p className='card-text mb-2'>{data?.email}</p>
                                        <p className='card-text mb-2'>{data?.contact}</p>
                                        <p className='card-text mb-2'>
                                            <i className='fa fa-location-dot me-1'></i>
                                            {data?.address}, {data?.cities?.name},
                                            {data?.states?.name} - {data?.pincode}
                                        </p>
                                    </div>
                                    
                                    <div className="col-xl-6 col-lg-12 mt-5 mt-xl-0">
                                        <table className="user-details table mb-0">
                                            <tbody>
                                                <tr>
                                                    <td className="py-1 px-0 font-weight-semibold w-50">
                                                        Username / Referral Code
                                                    </td>
                                                    <td className="py-1 px-0 text-uppercase">
                                                        {data?.user_name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="py-1 px-0 font-weight-semibold w-50">
                                                        Experience
                                                    </td>
                                                    <td className="py-1 px-0">
                                                        {data?.total_experiance != 0 ? calculateExperience(data?.total_experiance) : "---"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="py-1 px-0 font-weight-semibold w-50">
                                                        Rating
                                                    </td>
                                                    <td className="py-1 px-0">
                                                        <div className="d-inline-flex mb-2">
                                                            <div className="br-theme-fontawesome-stars me-2">
                                                                <div className="br-widget">
                                                                    {rating.map((el, i) => (
                                                                        <i
                                                                            data-rating-value={el}
                                                                            data-rating-text={el}
                                                                            className={
                                                                                el <= data?.average_review
                                                                                    ? "br-selected"
                                                                                    : ""
                                                                            }
                                                                            key={i}
                                                                        />
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <span>({data?.review_count} Reviews)</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    );
};

export default ProfileStudent;