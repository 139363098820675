import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Footer from '../../../Footer/Footer';
import Headerinner from '../../../Header/Headerinner';
import Curve from '../../../Header/Curve';

const Disclaimer = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0)
        document.body.scrollTop = 0;
    }, [pathname]);

    return (
        <>
            <Headerinner />
            <div className="cover-image bg-background-1" data-bs-image-src="assets/images/banners/banner1.webp" style={{ background: 'url("assets/images/banners/banner1.webp") center center' }}>
                <div className="container header-text">
                    <h1>Disclaimer</h1>
                </div>
            </div>
            <Curve/>
            <section className="sptb">
                <div className="container">
                    <p>
                        By registering your yourself (Only in case you are 18+) / your child as a student on Education Mandal's
                        platform, you acknowledge and agree to the following terms and conditions:
                    </p>
                    <ul className="education-mandal-data">
                        <li>• Education Mandal is an educational aggregator website that provides resources and information to
                            students and parents. However, Education Mandal does not endorse or guarantee the accuracy, reliability,
                            or safety of any information, resources, or services provided by third-party providers that may be linked
                            to or featured on our platform.</li>
                        <li>• Education Mandal is not responsible for any actions or behaviors of your / your child or other users
                            of our platform. It is your / parent’s / legal guardian’s responsibility to monitor and
                            supervise your / your child's use of our platform and to ensure that you / they use it
                            in a safe and appropriate manner.</li>
                        <li>• Education Mandal does not collect or share personal information about yourself / your child without
                            your consent, except as required by law or as necessary to provide our services to you / your child.</li>
                        <li>• Education Mandal reserves the right to modify, suspend, or terminate our services or your / your child's
                            access to our platform at any time and for any reason, without notice or liability.</li>
                        <li>• By registering yourself / your child as a student on Education Mandal's platform, you agree to release
                            and hold harmless Education Mandal, its affiliates, and its officers, directors, employees, and agents
                            from any and all claims, damages, or liabilities arising out of your / your child's use of our platform.</li>
                    </ul>
                    <p>By accepting these terms and conditions, you acknowledge that you have read and understood the above disclaimer and
                        other terms and conditions and policies and that you agree to be bound by its terms. If you do not agree to these terms,
                        do not register yourself / your child as a student on Education Mandal's platform.</p>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Disclaimer;
