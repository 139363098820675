import React from 'react';

const AboutExist = () => {
    return (
        <>
            <section className="sptb bg-white">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-9">
                            <p>
                                Welcome to Education Mandal, a comprehensive educational aggregator platform that
                                brings together all the stakeholders of the education sector – institutions, teachers,
                                students and more. This platform aims to create an ecosystem where everyone can
                                benefit from the vast pool of knowledge and resources available.</p>
                            <p>
                                Education Mandal is designed to make it easier for students to find quality educational
                                resources and institutions. Our database contains information about top-notch
                                institutions, their programs, and the teachers who are providing their services. The
                                website also provides a platform for teachers to showcase their skills and reach out to a
                                wider audience, helping them to reach more students and grow their careers.</p>
                            <p>
                                With a user-friendly interface, Education Mandal makes it easier for students to find the
                                right institutions and resources, whether they are looking for information about colleges,
                                schools, universities, or vocational courses. It's an ideal platform for all who are seeking
                                quality education and want to be a part of an ecosystem that fosters learning, growth,
                                and development.</p>
                            <p>
                                Join us on our journey to make education accessible to everyone and start exploring the
                                world of education like never before.</p>
                        </div>
                        <div className="col-lg-3 text-center">
                            <img src="assets/images/about.webp" alt="about-us-image" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AboutExist;