import React from "react";
import { Link } from "react-router-dom";
let rating = [1, 2, 3, 4, 5];

const CourseCard = ({ item, key, deleteCartApi }) => {
    let now = new Date().setHours(0, 0, 0, 0);

    return (
        <div className="col-md-6" key={key}>
            <div className="card">
                <div className="card-body">
                    <div className="item-card7-img">
                        <img
                            src={item?.online_course?.teaser_thumb_image}
                            alt={item?.online_course?.title}
                            className="cover-image"
                        />
                        {(
                            item?.online_course?.price == "paid" && (
                                item?.online_course?.is_discount == 1 && (
                                    (item?.online_course?.is_limited_offer == 1 && (new Date(item?.online_course?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.online_course?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                    (item?.online_course?.is_limited_offer == 0 && new Date(item?.online_course?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                            )
                        ) && (
                                <Link to="#" className="bg-danger top left">
                                    {item?.online_course?.discount_price}% off
                                </Link>
                            )}
                        <Link to="#" className="bg-danger right top">
                            Video Course
                        </Link>
                        {item?.online_course?.is_professional === 0 && (
                            <Link to="#" className="bg-danger right bottom">
                                {item?.online_course?.board}
                            </Link>
                        )}
                    </div>
                    <div className="item-card2-desc">
                        <div className="d-inline-flex mb-2">
                            <div className="br-widget">
                                {rating.map((el, i) => (
                                    <i
                                        data-rating-value={el}
                                        data-rating-text={el}
                                        className={
                                            el <= item?.online_course?.average_review
                                                ? "br-selected"
                                                : ""
                                        }
                                        key={i}
                                    />
                                ))}
                            </div>
                            <span>({item?.online_course?.review_count} Reviews)</span>
                        </div>

                        <h4 className="mb-2 leading-tight2" >
                            {item?.online_course?.title}
                        </h4>
                        <p className="leading-tight3 education-mandal">
                            {item?.online_course?.short_discription}
                        </p>

                        <div className="pt-2 pb-2 border-bottom d-flex justify-content-between">
                            <div className="d-flex justify-content-start">
                                <i className="fa fa-shop me-2" />
                                <span className="text-muted text-data-over-cut">
                                    {item?.online_course?.institute.name}
                                </span>
                            </div>
                            <div className="float-end">
                                <i className="fa fa-signal me-2" />
                                <span className="text-muted text-capitalize">
                                    {item?.online_course?.course_level}
                                </span>
                            </div>
                        </div>
                        <div className="pt-2 pb-2 border-bottom">
                            <i className="fa fa-video me-2" />
                            <span className="text-muted">
                                {item?.online_course?.lectures_count} Videos
                            </span>
                            <div className="float-end">
                                {item?.online_course?.price === "paid" && (
                                    <h4 className="mb-0"> ₹ {Math.round(item?.online_course?.payable_price)} {(
                                        item?.online_course?.price == "paid" && (
                                            item?.online_course?.is_discount == 1 && (
                                                (item?.online_course?.is_limited_offer == 1 && (new Date(item?.online_course?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.online_course?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                (item?.online_course?.is_limited_offer == 0 && new Date(item?.online_course?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                        )
                                    ) && (<del className="fs-14 text-muted"> ₹ {Math.round(item?.online_course?.regular_price)} </del>
                                        )}
                                    </h4>
                                )}
                                {item?.online_course?.price === "free" && (
                                    <h4 className="mb-0">Free</h4>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="item-card2-desc text-center">
                        <Link onClick={() => deleteCartApi(item?.id)}
                            to="#"
                            className="btn btn-danger"
                        > <i className="fe fe-trash"></i> Delete
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CourseCard;