import React from "react";

const Loader = () => {
    return (
        <>
            <div id="global-loader">
                <img src="/assets/images/loader.svg" className="loader-img" alt="Loader Img" />
            </div>
        </>
    )
}

export default Loader;