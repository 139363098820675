import React, { useState } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { getInstituteBranchGetAllBranches } from "../../../../apis";
import url from "../../../../constants/url";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import SubmitLoader from "../../../common/SubmitLoader"
import { Editor } from "@tinymce/tinymce-react";

function TeacherEdit() {
    const [loader, setLoader] = useState(false);
    const history = useHistory();
    let param = useParams();
    let id_ = param.id;
    const [branch, setBranch] = useState([]);
    const [image, setImage] = useState("");

    const [uIValues, setUIValues] = useState({
    });
    const handleFileInputChange = async (e, props) => {
        let { file } = e.target.files[0];

        file = e.target.files[0];

        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function (e) {
            var image = new Image();
            image.src = e.target.result;
            image.onload = function () {
                var height = this.height;
                var width = this.width;
                if (height > 200 || width > 180) {
                    alert(
                        "Image size: width = 180px and height = 200px"
                    );
                    props.setFieldValue("image", "");
                    props.setFieldError("image", "Image size: width = 180px and height = 200px")
                } else if (height < 200 || width < 180) {
                    alert(
                        "Image size: width = 180px and height = 200px"
                    );
                    props.setFieldValue("image", "");
                    props.setFieldError("image", "Image size: width = 180px and height = 200px")

                } else {
                    getBase64(file)
                        .then((result) => {
                            file["base64"] = "true";
                            props.setFieldValue("image", file);
                            setImage(result);
                        })
                        .catch((err) => { });
                }
            };
        };
    };
    const getBase64 = (file) => {
        return new Promise((resolve) => {
            let fileInfo;
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    const ValidateSchema = Yup.object().shape({
        branch: Yup.array().min(1),
        email: Yup.string().required("Required"),
        contact: Yup.number().required("Required"),
        status: Yup.string().required("Required"),
        reason: Yup.string().when("status", {
            is: (status) => status === "inactive",
            then: Yup.string().required("Required"),
        }),
        gender: Yup.string().required("Required"),
        name: Yup.string().required("Required"),
        image: Yup.string().required("Required"),
        about: Yup.string().required("Required").max(500, "Please write less than 500 characters !"),
    });
    const handleSave = async (values) => {
        var selectedBranch = values.branch.map((value) => {
            return value.value;
        })
        const reqData = new FormData();
        reqData.set(`teacher_id`, id_);
        reqData.set(`name`, values?.name);
        reqData.set(`contact`, values?.contact);
        reqData.set(`gender`, values?.gender);
        reqData.set(`email`, values?.email);
        reqData.set(`reason`, values?.reason);
        reqData.set(`institute_branch_id`, selectedBranch.join());
        reqData.set(`image`, values?.image);
        reqData.set(`about`, values?.about);
        reqData.set(
            `status`,
            values?.status !== undefined ? values?.status : "active"
        );
        if (values?.status === "inactive") {
            reqData.set("reason", values?.reason);
        } else {
            reqData.set("reason", "");
        }

        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        var myHeaders = new Headers();
        myHeaders.set("AUTHTOKEN", auth_token);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: reqData,
        };
        setLoader(true);
        fetch(`${url.apiUrl}institute-teacher/edit-profile`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setLoader(false);
                    toast.success("Teacher Edited Successfully", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setTimeout(() => {
                        history.push("/institute-dashboard/teacher");
                    }, 5000);
                } else {
                    setLoader(false);
                    toast.error("something Want Wrong", {
                        position: toast.POSITION.TOP_RIGHT,
                    });

                }
            })
            .catch((error) => console.log("error", error));
    };
    const getAllBraches = async (id) => {
        let res = await getInstituteBranchGetAllBranches(id);
        if (res.status === "success") {
            const arr =
                res.data &&
                res.data.map((v) => {
                    return { label: v.text, value: v.id };
                });
            setBranch(arr);
        }
    };
    let languages = [
        { name: "Hindi", id: 1 },
        { name: "English", id: 2 },
        { name: "Spanish", id: 3 },
        { name: "Franch", id: 4 },
    ];
    const getTeacherDetail = async () => {
        let res = await getInstituteBranchGetAllBranches();
        let token = localStorage.getItem("common-user-info");
        let data = JSON.parse(token);
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", `${data.auth_token}`);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        fetch(`${url.apiUrl}institute-teacher/get-profile/${id_}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    const ids = ((result?.data?.institute_branch_id) ? result?.data?.institute_branch_id.split(",") : [])
                        .map((item) => Number(item));
                    const selectedBranches = res?.data?.filter(item => ids.includes(item?.id))
                        .map(item => ({ "label": item?.text, "value": item?.id }));


                    let array = [];
                    languages.forEach((element) => {
                        let languages_ = result?.data?.languages?.split(",");
                        languages_ &&
                            languages_.forEach((element_) => {
                                if (element?.name === element_) {
                                    let dataBranch = [element];
                                    array.push(dataBranch);
                                }
                            });
                    });

                    let editData = {
                        address: result?.data?.address,
                        institute_branch_id: result?.data?.institute_branch_id,
                        branch: selectedBranches,
                        contact: result?.data?.contact,
                        email: result?.data?.email,
                        gender: (result?.data?.gender) ? result?.data?.gender : "",
                        language: result?.data?.languages,
                        name: result?.data?.name,
                        status: result?.data?.status,
                        reason: (result?.data?.reason) ? result?.data?.reason : "",
                        image: result?.data?.image,
                        about: result?.data?.about || "",
                    };
                    setImage(result?.data?.image);
                    setUIValues(editData);
                    getAllBraches(result?.data?.institute_branch_id);
                }
            })
            .catch((error) => console.log("error", error));
    };
    useEffect(() => {
        getTeacherDetail();
    }, []);


    return (
        <>
            <div className='page-header'>
                <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                        <Link to='/institute-dashboard'>Dashboard</Link>
                    </li>
                    <li className='breadcrumb-item'>
                        <Link to='/institute-dashboard/teacher'>Teacher's List</Link>
                    </li>
                    <li className='breadcrumb-item active'>Edit Teacher</li>
                </ol>
            </div>
            <div className='card'>
                <div className='card-header'>
                    <h4 className='card-title'>Edit Teacher</h4>
                </div>
                <div className='card-body'>
                    <Formik
                        initialValues={uIValues}
                        onSubmit={handleSave}
                        enableReinitialize={true}
                        validationSchema={ValidateSchema}
                    >
                        {(props) => {
                            return (
                                <form noValidate='novalidate' onSubmit={props.handleSubmit} >
                                    <div className='row'>
                                        <div className='col-lg-4 col-12 form-group'>
                                            <label>
                                                Branch
                                                <span className='text-danger'>*</span>
                                            </label>
                                            <Select
                                                onChange={(value) =>
                                                    props.setFieldValue("branch", value)
                                                }
                                                options={branch}
                                                value={props.values.branch}
                                                name='institute_branch_id'
                                                isMulti
                                            />
                                            {props.touched.branch &&
                                                props.errors.branch && (
                                                    <p className='bg-error'>
                                                        {props.errors.branch}
                                                    </p>
                                                )}
                                        </div>

                                        <div className='col-lg-4 col-12 form-group'>
                                            <label>
                                                Name <span className='text-danger'>*</span>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='name'
                                                defaultValue={props.values.name}
                                                onChange={props.handleChange}
                                                onBlur={props.handleChange}
                                                placeholder='Enter teacher name'
                                            />
                                            {props.touched.name &&
                                                props.errors.name && (
                                                    <p className='bg-error'>
                                                        {props.errors.name}
                                                    </p>
                                                )}
                                        </div>
                                        <div className='col-lg-4 col-12 form-group'>
                                            <label>
                                                Gender <span className='text-danger'>*</span>
                                            </label>

                                            <div className='form-controls-stacked d-md-flex'>
                                                <input
                                                    type='radio'
                                                    id='male'
                                                    name='gender'
                                                    value='male'
                                                    onChange={props.handleChange}
                                                    checked={props.values.gender === "male"}
                                                    className="me-2 mt-0"
                                                />
                                                <label className="mb-0" htmlFor='male'>Male</label>
                                                <input
                                                    type='radio'
                                                    id='female'
                                                    name='gender'
                                                    value='female'
                                                    onChange={props.handleChange}
                                                    checked={props.values.gender === "female"}
                                                    className="me-2 mt-0"
                                                />
                                                <label className="mb-0" htmlFor='female'>Female</label>
                                                <input
                                                    type='radio'
                                                    id='other'
                                                    name='gender'
                                                    value='other'
                                                    onChange={props.handleChange}
                                                    checked={props.values.gender === "other"}
                                                    className="me-2 mt-0"
                                                />
                                                <label className="mb-0" htmlFor='other'>Other</label>
                                            </div>
                                            {props.touched.gender &&
                                                props.errors.gender && (
                                                    <p className='bg-error'>
                                                        {props.errors.gender}
                                                    </p>
                                                )}
                                        </div>
                                        <div className='col-lg-4 col-12 form-group'>
                                            <label htmlFor='classwise'>
                                                Contact No. <span className='text-danger'>*</span>
                                            </label>
                                            <PhoneInputWithCountrySelect
                                                name='mobile'
                                                defaultCountry='IN'
                                                className='form-control'
                                                placeholder='Please enter contact no'
                                                onChange={(e) => {
                                                    props.setFieldValue("contact", e);
                                                }}
                                                value={uIValues?.contact}
                                            />
                                            {props.touched.contact &&
                                                props.errors.contact && (
                                                    <p className='bg-error'>
                                                        {props.errors.contact}
                                                    </p>
                                                )}
                                        </div>
                                        <div className='col-lg-4 col-12 form-group'>
                                            <label>
                                                Email ID <span className='text-danger'>*</span>
                                            </label>
                                            <input
                                                type='email'
                                                className='form-control'
                                                defaultValue={props.values.email}
                                                onBlur={props.handleChange}
                                                name='email'
                                                onChange={props.handleChange}
                                                placeholder='Enter email id'
                                                disabled
                                            />
                                            {props.touched.email &&
                                                props.errors.email && (
                                                    <p className='bg-error'>
                                                        {props.errors.email}
                                                    </p>
                                                )}
                                        </div>
                                        <div className="col-lg-4 col-12 form-group">
                                            <label>
                                                Profile Picture <span className='text-danger'>* </span>
                                            </label>
                                            <input
                                                type='file'
                                                className='form-control'
                                                id='image'
                                                name='image'
                                                onChange={(e) => handleFileInputChange(e, props)}
                                            />
                                            <p className="fs-12 mb-0"><span className="text-danger">Note:</span> Image size: width = 180px and height = 200px</p>
                                            {props.touched.image &&
                                                props.errors.image && (
                                                    <p className='bg-error'>
                                                        {props.errors.image}
                                                    </p>
                                                )}
                                        </div>

                                        <div className='col-md-6 form-group'>
                                            <label htmlFor='reason'>About <span className='text-danger'>* </span></label>
                                            <Editor
                                                textareaName='about'
                                                placeholder='Enter about'
                                                apiKey='9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d'
                                                init={{
                                                    plugins: [
                                                        "advlist",
                                                        "autolink",
                                                        "lists",
                                                        "link",
                                                        "image",
                                                        "charmap",
                                                        "preview",
                                                        "anchor",
                                                        "searchreplace",
                                                        "visualblocks",
                                                        "code",
                                                        "fullscreen",
                                                        "insertdatetime",
                                                        "media",
                                                        "table",
                                                        "help",
                                                        "wordcount",
                                                        "spellchecker",
                                                    ],

                                                    toolbar:
                                                        " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help |spellchecker ",
                                                }}
                                                value={props?.values?.about}
                                                onEditorChange={(e) =>
                                                    props.handleChange({
                                                        target: {
                                                            name: `about`,
                                                            value: e,
                                                        },
                                                    })
                                                }
                                            />
                                            {props.touched.about &&
                                                props.errors.about && (
                                                    <p className='bg-error'>
                                                        {props.errors.about}
                                                    </p>
                                                )}
                                            <p className='text-end'>
                                                {props?.values?.about?.length} / 500 Characters
                                            </p>

                                        </div>

                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className='col-lg-6 col-12 form-group'>
                                                    <label htmlFor='classwise'>
                                                        Teacher Status <span className='text-danger'>*</span>
                                                    </label>
                                                    <select
                                                        className='form-select'
                                                        id='classwise'
                                                        name='status'
                                                        value={props.values.status}
                                                        onChange={props.handleChange}
                                                    >
                                                        <option value='active'>Active</option>
                                                        <option value='inactive'>In-Active</option>
                                                    </select>
                                                </div>
                                                <div className='col-md-6 form-group'>
                                                    {uIValues.image && (
                                                        <img
                                                            className='img-thumbnail'
                                                            src={image}
                                                            alt='Branch Image'
                                                        />
                                                    )}
                                                </div>
                                                {props.values?.status === "inactive" && (
                                                    <>
                                                        <div className='col-md-6 form-group'>
                                                            <label htmlFor='reason'>Reason</label>
                                                            <textarea
                                                                className={`form-control`}
                                                                name='reason'
                                                                id='reason'
                                                                placeholder='Please your enter Reason'
                                                                defaultValue={props.values.reason}
                                                                onChange={props.handleChange}
                                                            />
                                                            {props.touched.reason &&
                                                                props.errors.reason && (
                                                                    <p className='bg-error'>
                                                                        {props.errors.reason}
                                                                    </p>
                                                                )}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>




                                        <div className='col-lg-12 text-end mt-5'>
                                            {loader ? (
                                                <SubmitLoader />
                                            ) : (
                                                <>
                                                    <button
                                                        type="submit"
                                                        className='btn btn-primary me-2'
                                                    >
                                                        Submit
                                                    </button>
                                                    <Link
                                                        to='/institute-dashboard/teacher'
                                                        className='btn btn-danger'
                                                    >
                                                        Cancel
                                                    </Link>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </form>
                            );
                        }}
                    </Formik>
                </div>
                <ToastContainer />
            </div>
        </>
    );
}

export default TeacherEdit;
