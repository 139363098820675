import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Col, Row } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import SubmitLoader from "../../../../common/SubmitLoader"
import axios from "axios";
import {
    getInstituteBranchGetAllBranches,
    getAllCategorys,
    getGetAllBoard,
} from "../../../../../apis";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import url from "../../../../../constants/url";
import CreateCourseVideo from "./CreateCourseVideo";
import moment from "moment";

let courseLevel = [
    { label: "Beginner", value: "beginner" },
    { label: "Intermediate", value: "intermediate" },
    { label: "Expert", value: "expert" },
];
export default function AddCourse() {
    const [medium, setMedium] = useState();
    const [videoDuration, setVideoduration] = useState("");
    const [standard, setStandard] = useState();
    const param = useParams();
    const [loading, setLoading] = useState(false);

    const [languages, setLanguages] = useState();
    const history = useHistory();
    const [firstForm, setFirstForm] = useState(true);
    const [secondForm, setSecondForm] = useState(false);
    const [thirdForm, setThirdForm] = useState(false);
    const [fourthForm, setFourthForm] = useState(false);
    const [branch, setBranch] = useState([]);
    const [category, setCategory] = useState([]);
    const [teacher, setTeacher] = useState([]);
    const [board, setBoard] = useState([]);
    const [image, setImage] = useState("");

    const [firstFormValues, setFirstFormValues] = useState({
        course_title: "",
        short_description: "",
        course_description: "",
        student_learn: "",
        requirement: "",
        course_level: "",
        audio_language: "",
        course_category: "",
        branch: "",
        teacher_name: "",
        other_language: "",
        other_course_category: "",
        is_professional: "",
        board: "",
        other_board: "",
        standard: "",
        other_standard: "",
        medium: "",
        other_medium: "",
        status: "",
        admin_remark: "",
        user_remark: "",
    });
    const [secondFormValues, setSecondFormValues] = useState({
        video_link: "",
        thumbnail_image: "",
        video_duration: "",
        video_type: "",
        video: "",
    });
    const [thirdFormValues, setThirdFormValues] = useState({
        price_type: "",
        regular_price: "",
        price_after_dis: "",
        is_discount: "",
        discount_price: "",
        limited_offer: "",
        discount_start_date: "",
        discount_end_date: "",
    });
    var localdata = localStorage.getItem("common-user-info");
    var jsondata = JSON.parse(localdata);
    var token_authorization = jsondata.auth_token;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const getVideoDuration = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const media = new Audio(reader.result);
                media.onloadedmetadata = () => resolve(media.duration);
            };
            reader.readAsDataURL(file);
            reader.onerror = (error) => reject(error);
        });

    const getAllTeacherDropdown = async (id) => {
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            AUTHTOKEN: token_authorization,
        };

        fetch(url.apiUrl + `institute-teacher/get-teachers/${id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setTeacher(result.data);
                }
            })
            .catch((error) => console.log("error", error));
    };
    const getAllBoardDropdown = async () => {
        let res = await getGetAllBoard();
        if (res.status === "success") {
            setBoard(res.data);
        }
    };
    const getAllCategory = async () => {
        let res = await getAllCategorys();

        if (res.status === "success") {
            let data = res.data;
            data = data.map((item) => ({
                id: item.id,
                name: item.title,
            }));
            setCategory(data);
        }
    };
    const getAllBraches = async () => {
        let res = await getInstituteBranchGetAllBranches();
        if (res.status === "success") {
            let data = res.data;

            data = data.map((item) => ({
                id: item.id,
                name: item.text,
            }));
            setBranch(data);
        }
    };

    useEffect(() => {
        getAllBraches();
        getAllCategory();
        getlanguage();
        getAllBoardDropdown();
        if (param.id) {
            getCourseDetail();
        }

        getMedium();
        getStandard();
    }, []);

    const ValidateSchema = Yup.object().shape({
        course_title: Yup.string().required("Required").max(100, "Please write less than 100 characters !"),
        short_description: Yup.string().required("Required").max(250, "Please write less than 250 characters !"),
        course_description: Yup.string().required("Required"),
        student_learn: Yup.string().required("Required"),
        requirement: Yup.string().required("Required"),
        course_level: Yup.string().required("Required"),
        audio_language: Yup.string().required("Required"),
        course_category: Yup.string().required("Required"),
        branch: Yup.string().required("Required"),
        teacher_name: Yup.string().required("Required"),
        is_professional: Yup.string().required("Required"),
        board: Yup.string()
            .when("is_professional", {
                is: (is_professional) => is_professional && is_professional == "0",
                then: Yup.string().required("Required"),
            })

            .nullable(),
        other_board: Yup.string()
            .when("board", {
                is: (board) => board && board === "other",
                then: Yup.string().required("Required"),
            })
            .nullable(),
        medium: Yup.string()
            .when("is_professional", {
                is: (is_professional) => is_professional && is_professional == "0",
                then: Yup.string().required("Required"),
            })
            .nullable(),
        other_medium: Yup.string()
            .when("medium", {
                is: (medium) => medium && medium === "other",
                then: Yup.string().required("Required"),
            })
            .nullable(),
        standard: Yup.string()
            .when("is_professional", {
                is: (is_professional) => is_professional && is_professional == "0",
                then: Yup.string().required("Required"),
            })
            .nullable(),
        other_standard: Yup.string()
            .when("standard", {
                is: (standard) => standard && standard === "other",
                then: Yup.string().required("Required"),
            })
            .nullable(),
        other_course_category: Yup.string()
            .when("course_category", {
                is: (course_category) => course_category && course_category === "other",
                then: Yup.string().required("Required"),
            })
            .nullable(),
        admin_remark: Yup.string().when(['status', 'is_submit', 'is_update'], {
            is: (status, is_submit, is_update) => (status && status === 'active') || (is_submit === 0 && is_update !== 0),
            then: Yup.string().required('Required')
        }).nullable(),
        user_remark: Yup.string().when(['status', 'is_submit', 'is_update'], {
            is: (status, is_submit, is_update) => (status && status === 'active') || (is_submit === 0 && is_update !== 0),
            then: Yup.string().required('Required')
        }).nullable(),
    });
    const secondValidateSchema = Yup.object().shape({
        thumbnail_image: Yup.mixed().required("Required"),
        video: Yup.mixed().required("Required"),
    });

    const thirdValidateSchema = Yup.object().shape({
        price_type: Yup.string().required("Required"),
        regular_price: Yup.number()
            .when("price_type", {
                is: (price_type) => price_type && price_type === "paid",
                then: Yup.number().required("Required"),
            })
            .nullable(),
        is_discount: Yup.number()
            .when("price_type", {
                is: (price_type) => price_type === "paid",
                then: Yup.number().required("Required"),
            })
            .nullable(),
        discount_price: Yup.number()
            .when(["is_discount", "price_type"], {
                is: (is_discount, price_type) =>
                    is_discount == "1" && price_type === "paid",
                then: Yup.number()
                    .required("Required")
                    .test('is-between-1-to-99', 'Value must be between 1 to 99', (value) => {
                        return value >= 1 && value <= 99;
                    }),
            })
            .nullable(),
        discount_start_date: Yup.date()
            .when(["is_discount", "price_type"], {
                is: (is_discount, price_type) =>
                    is_discount == "1" && price_type === "paid",
                then: Yup.date().required("Required"),
            })
            .nullable(),
        limited_offer: Yup.number()
            .when(["is_discount", "price_type"], {
                is: (is_discount, price_type) =>
                    is_discount == "1" && price_type === "paid",
                then: Yup.number().required("Required"),
            })
            .nullable(),
        discount_end_date: Yup.date()
            .when(["limited_offer", "price_type"], {
                is: (limited_offer, price_type) =>
                    limited_offer == "1" && price_type === "paid",
                then: Yup.date().required("Required")
            })
            .nullable(),
    });

    const [errors, setErrors] = useState({});
    const firstFormSubmit = (values) => {
        const errors = {}
        if (param.id) {
            if ((values.status === 'active') || (values.is_submit === 0 && values.is_update !== 0)) {
                // errors.admin_remark = "require"
                // setErrors(errors)
                // errors.user_remark = "require"
                // setErrors(errors)
            }
        }
        setErrors({})
        setFirstForm(false);
        setSecondForm(false);
        setThirdForm(true);
        setFirstFormValues(values);
    };

    const secondFormSubmit = async (values) => {
        setSecondFormValues(values);
        const formData = new FormData();
        if ((param.id && (firstFormValues.status === "active" || (firstFormValues.is_submit === 0 && firstFormValues.is_update !== 0)))) {
            formData.set("admin_remark", firstFormValues.admin_remark);
            formData.set("user_remark", firstFormValues.user_remark);
        }
        formData.set("teacher_id", firstFormValues.teacher_name);
        formData.set("institute_branch_id", firstFormValues.branch);
        formData.set("title", firstFormValues.course_title);
        formData.set("short_discription", firstFormValues.short_description);
        formData.set("course_discription", firstFormValues.course_description);

        formData.set("student_learn_in_cutse", firstFormValues.student_learn);

        formData.set("requirements", firstFormValues.requirement);
        formData.set("course_level", firstFormValues.course_level);
        formData.set("is_professional", firstFormValues.is_professional);
        if (firstFormValues.is_professional == "0") {
            formData.set("board", firstFormValues.board);
            if (firstFormValues.board === "other") {
                formData.set("other_board", firstFormValues.other_board);
            }
            formData.set("medium", firstFormValues.medium);
            if (firstFormValues.medium === "other") {
                formData.set("other_medium", firstFormValues.other_medium);
            }
            formData.set("standard", firstFormValues.standard);
            if (firstFormValues.standard === "other") {
                formData.set("other_standard", firstFormValues.other_standard);
            }
        }
        formData.set("course_category", firstFormValues.course_category);
        if (firstFormValues.course_category === "other") {
            formData.set(
                "other_course_category",
                firstFormValues.other_course_category
            );
        }
        formData.set("audio_language", firstFormValues.audio_language);
        if (firstFormValues.audio_language === "other") {
            formData.set("other_audio_language", firstFormValues.other_language);
        }

        formData.set("price", thirdFormValues.price_type);
        if (thirdFormValues.price_type === "paid") {
            formData.set("regular_price", thirdFormValues.regular_price);
            formData.set("is_discount", thirdFormValues.is_discount);
            if (thirdFormValues.is_discount == "1") {
                formData.set("discount_price", thirdFormValues.discount_price);
                formData.set(
                    "discount_start_date",
                    thirdFormValues.discount_start_date
                );

                formData.set("is_limited_offer", thirdFormValues.limited_offer);
            }
            if (thirdFormValues.limited_offer == "1") {
                formData.set("discount_end_date", thirdFormValues.discount_end_date);
            }
        }
        if (values.video_type === "link") {
            formData.set("teaser_media", values.video_link);
        } else {
            formData.set("teaser_media", values.video);
        }

        formData.set("teaser_thumb_image", values.thumbnail_image);
        formData.set("teaser_video_duration", values.video_duration);
        let auth = JSON.parse(localStorage.getItem("common-user-info"));
        const config = {
            headers: {
                content: "multipart/form-data",
                AUTHTOKEN: auth && auth?.auth_token,
            },
        };
        setLoading(true);
        if (param.id) {
            try {
                const response = await axios.post(
                    "https://educationmandal.com/em-admin/api/" +
                    `institute-course/update-online-course/${param.is_main_course}/${param.id}`,
                    formData,
                    config
                );
                if (response.data?.status === "success") {
                    sessionStorage.setItem("course_id", response.data?.data?.id);
                    sessionStorage.setItem(
                        "is_main",
                        response.data?.data?.is_main_course
                    );
                    setLoading(false);

                    setFirstForm(false);
                    setSecondForm(false);
                    setThirdForm(false);
                    setFourthForm(true);
                } else {
                    toast.error(response.data?.message);
                    setLoading(false);
                    setFirstForm(false);
                    setSecondForm(true);
                    setThirdForm(false);
                    setFourthForm(false);
                }
            } catch (err) {
                setLoading(false);

                throw err;
            }
        } else {
            try {
                const response = await axios.post(
                    "https://educationmandal.com/em-admin/api/" +
                    `institute-course/add-online-course`,
                    formData,
                    config
                );
                if (response.data?.status === "success") {
                    sessionStorage.setItem("course_id", response.data?.data?.id);
                    sessionStorage.setItem(
                        "is_main",
                        response.data?.data?.is_main_course
                    );
                    setLoading(false);

                    setFirstForm(false);
                    setSecondForm(false);
                    setThirdForm(false);
                    setFourthForm(true);
                } else {
                    toast.error(response.data?.message);
                    setLoading(false);

                    setFirstForm(false);
                    setSecondForm(true);
                    setThirdForm(false);
                    setFourthForm(false);
                }
            } catch (err) {
                setLoading(false);
                throw err;

            }
        }
    };

    const thirdFormSubmit = (values) => {
        setFirstForm(false);
        setSecondForm(true);
        setThirdForm(false);
        setFourthForm(false);
        setThirdFormValues(values);
    };

    let courseId = "";
    courseId = localStorage.getItem("course");
    const local_data = localStorage.getItem("common-user-info");
    if (typeof local_data && local_data !== undefined && local_data !== null) {
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("AUTHTOKEN", auth_token);
    }

    const getCourseDetail = async () => {
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        fetch(
            `${url.apiUrl}institute-course/get-online-course/${param.is_main_course}/${param.id}`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {

                    getAllTeacherDropdown(result.data?.institute_branch_id);
                    setFirstFormValues({
                        course_title: result.data?.title,
                        short_description: result.data?.short_discription,
                        course_description: result.data?.course_discription,
                        student_learn: result.data?.student_learn_in_cutse,
                        requirement: result.data?.requirements,
                        course_level: result.data?.course_level,
                        audio_language: result.data?.audio_language,
                        course_category: result.data?.course_category,
                        is_professional: result.data?.is_professional,
                        board: result.data?.board,
                        medium: result.data?.medium,
                        standard: result.data?.standard,
                        branch: result.data?.institute_branch_id,
                        teacher_name: result.data?.teacher_id,
                        admin_remark: result.data?.admin_remark?.admin_remark,
                        user_remark: result.data?.admin_remark?.user_remark,
                        status: result?.data?.status,
                        is_submit: result?.data.is_submit,
                        is_update: result?.data.is_update,
                    });
                    setSecondFormValues({
                        thumbnail_image: result.data.teaser_thumb_image,
                        video_duration: result.data.teaser_video_duration,
                        video: result.data.teaser_media,
                    });
                    setThirdFormValues({
                        price_type: result.data.price,
                        regular_price: result.data.regular_price,
                        is_discount: result.data.is_discount,
                        discount_price: result.data.discount_price ? result.data.discount_price : "",
                        limited_offer: result.data.is_limited_offer,
                        discount_start_date: result.data.discount_start_date ? result.data.discount_start_date : "",
                        discount_end_date: result.data.discount_end_date ? result.data.discount_end_date : "",
                    });
                }
            })
            .catch((error) => console.log("error", error));
    };
    const getBase64 = (file) => {
        return new Promise((resolve) => {
            let fileInfo;
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    const handleFileInputChange = async (e, name, props) => {
        let { file } = e.target.files[0];

        file = e.target.files[0];

        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function (e) {
            var image = new Image();
            image.src = e.target.result;
            image.onload = function () {
                if (name === "thumbnail_image") {
                    var height = this.height;
                    var width = this.width;
                    if (height > 200 || width > 368) {
                        props.setFieldValue("thumbnail_image", "");
                        alert(
                            "Image size: width = 368px and height = 200px"
                        );
                    } else if (height < 200 || width < 368) {
                        props.setFieldValue("thumbnail_image", "");
                        alert(
                            "Image size: width = 368px and height = 200px"
                        );
                    } else {
                        getBase64(file)
                            .then((result) => {
                                file["base64"] = "true";
                                props.setFieldValue("thumbnail_image", file);
                                setImage(result);
                            })
                            .catch((err) => { });
                    }
                } else if (name === "video") {
                    props.setFieldValue("video", file);
                }
            };
        };
    };
    const getMedium = async (e) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
                is_main: "1",
            }),
        };

        fetch(url.apiUrl + "get-mediums", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setMedium(result.data);
                }
            })
            .catch((error) => console.log("error", error));
    };
    const getStandard = async (e) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
        };

        fetch(url.apiUrl + "get-standards", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setStandard(result.data);
                }
            })
            .catch((error) => console.log("error", error));
    };
    const getlanguage = async (e) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        fetch(url.apiUrl + "get-all-languages", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {

                    setLanguages(result.data);
                }
            })

            .catch((error) => console.log("error", error));
    };
    return (
        <>
            <div className='page-header'>
                <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                        <Link to='/institute-dashboard'>Dashboard</Link>
                    </li>
                    <li className='breadcrumb-item'>
                        <Link to='/institute-dashboard/video-course'>Video Courses</Link>
                    </li>
                    <li className='breadcrumb-item active'>Add Video Course</li>
                </ol>
            </div>
            <div className='card'>
                <div className='card-header'>
                    <h4 className='card-title'>Add Video Course</h4>
                </div>
                <div className='card-body'>
                    {/* <div className='bs-stepper-content'> */}
                    {firstForm && (
                        <div id='basic-details' className={"content_ active dstepper-block"} role='tabpanel' aria-labelledby='basic-details-trigger' >
                            <Formik
                                initialValues={firstFormValues}
                                onSubmit={firstFormSubmit}
                                enableReinitialize={true}
                                validationSchema={ValidateSchema}
                            >
                                {(props) => {
                                    return (

                                        <form noValidate='novalidate' onSubmit={props.handleSubmit} >
                                            <Row>
                                                <Col md={12} className='mb-5'>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Course Title
                                                            <span className='text-danger'>*</span>
                                                        </Form.Label>
                                                        <Form.Control
                                                            name='course_title'
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={props.values.course_title}
                                                            placeholder='Enter course title'
                                                        />
                                                        {props.touched.course_title &&
                                                            props.errors.course_title ? (
                                                            <div className='bg-error'>
                                                                {props.errors.course_title}
                                                            </div>
                                                        ) : null}
                                                        <p className="text-end mb-0">
                                                            {props.values.course_title?.length} / 100 Characters
                                                        </p>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12} className='mb-5'>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Short Description
                                                            <span className='text-danger'>*</span>
                                                        </Form.Label>
                                                        <Form.Control
                                                            as='textarea'
                                                            name='short_description'
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={props.values.short_description}
                                                            placeholder='Enter short description'
                                                        />
                                                        {props.touched.short_description &&
                                                            props.errors.short_description ? (
                                                            <div className='bg-error'>
                                                                {props.errors.short_description}
                                                            </div>
                                                        ) : null}
                                                        <p className="text-end mb-0">
                                                            {props.values.short_description?.length} / 250 Characters
                                                        </p>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6} sm="12" className='mb-4'>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Course Description
                                                            <span className='text-danger'>*</span>
                                                        </Form.Label>
                                                        <Editor
                                                            textareaName='course_discription'
                                                            placeholder='Enter course decription'
                                                            apiKey='9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d'
                                                            init={{
                                                                plugins: [
                                                                    "advlist",
                                                                    "autolink",
                                                                    "lists",
                                                                    "link",
                                                                    "image",
                                                                    "charmap",
                                                                    "preview",
                                                                    "anchor",
                                                                    "searchreplace",
                                                                    "visualblocks",
                                                                    "code",
                                                                    "fullscreen",
                                                                    "insertdatetime",
                                                                    "media",
                                                                    "table",
                                                                    "help",
                                                                    "wordcount",
                                                                    "spellchecker",
                                                                ],

                                                                toolbar:
                                                                    " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help |spellchecker ",
                                                            }}
                                                            value={props.values.course_description}
                                                            onEditorChange={(e) =>
                                                                props.handleChange({
                                                                    target: {
                                                                        name: "course_description",
                                                                        value: e,
                                                                    },
                                                                })
                                                            }
                                                        />
                                                        {props.touched.course_description &&
                                                            props.errors.course_description ? (
                                                            <div className='bg-error'>
                                                                {props.errors.course_description}
                                                            </div>
                                                        ) : null}
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6} className='mb-5'>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            What will students learn in this course?
                                                            <span className='text-danger'>*</span>
                                                        </Form.Label>
                                                        <Editor
                                                            textareaName='student_learn_in_cutse'
                                                            apiKey='9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d'
                                                            init={{
                                                                plugins: [
                                                                    "advlist",
                                                                    "autolink",
                                                                    "lists",
                                                                    "link",
                                                                    "image",
                                                                    "charmap",
                                                                    "preview",
                                                                    "anchor",
                                                                    "searchreplace",
                                                                    "visualblocks",
                                                                    "code",
                                                                    "fullscreen",
                                                                    "insertdatetime",
                                                                    "media",
                                                                    "table",
                                                                    "help",
                                                                    "wordcount",
                                                                    "spellchecker",
                                                                ],

                                                                toolbar:
                                                                    " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help | spellchecker ",
                                                            }}
                                                            value={props.values.student_learn}
                                                            onEditorChange={(e) =>
                                                                props.handleChange({
                                                                    target: {
                                                                        name: "student_learn",
                                                                        value: e,
                                                                    },
                                                                })
                                                            }
                                                        />
                                                    </Form.Group>
                                                    {props.touched.student_learn &&
                                                        props.errors.student_learn ? (
                                                        <div className='bg-error'>
                                                            {props.errors.student_learn}
                                                        </div>
                                                    ) : null}
                                                </Col>

                                                <Col md={6} className='mb-5'>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Requirements
                                                            <span className='text-danger'>*</span>
                                                        </Form.Label>
                                                        <Editor
                                                            textareaName='requirements'
                                                            selector='textarea'
                                                            browser_spellcheck='true'
                                                            apiKey='9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d'
                                                            init={{
                                                                plugins: [
                                                                    "advlist",
                                                                    "autolink",
                                                                    "lists",
                                                                    "link",
                                                                    "image",
                                                                    "charmap",
                                                                    "preview",
                                                                    "anchor",
                                                                    "searchreplace",
                                                                    "visualblocks",
                                                                    "code",
                                                                    "fullscreen",
                                                                    "insertdatetime",
                                                                    "media",
                                                                    "table",
                                                                    "help",
                                                                    "wordcount",
                                                                ],

                                                                toolbar:
                                                                    " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help  ",
                                                            }}
                                                            onEditorChange={(e) => {
                                                                props.setFieldValue("requirement", e);
                                                            }}
                                                            value={props.values.requirement}
                                                        />
                                                    </Form.Group>
                                                    {props.touched.requirement &&
                                                        props.errors.requirement ? (
                                                        <div
                                                            className='bg-error'
                                                        >
                                                            {props.errors.requirement}
                                                        </div>
                                                    ) : null}
                                                </Col>
                                                <Col md='6' className='mb-5'>
                                                    <Row>
                                                        <Col md='6' className='mb-5'>
                                                            <Form.Group>
                                                                <Form.Label>
                                                                    Course Level
                                                                    <span className='text-danger'>*</span>
                                                                </Form.Label>
                                                                {props.values.course_level === "" ? (
                                                                    <Form.Select
                                                                        name='course_level'
                                                                        onChange={props.handleChange}
                                                                        onBlur={props.handleBlur}
                                                                        aria-label='Default select example'
                                                                    >
                                                                        <option selected='false' disabled>
                                                                            Select course level
                                                                        </option>
                                                                        {courseLevel.map((item) => (
                                                                            <option value={item.value}>
                                                                                {item.label}
                                                                            </option>
                                                                        ))}
                                                                    </Form.Select>
                                                                ) : (
                                                                    <Form.Select
                                                                        name='course_level'
                                                                        onChange={props.handleChange}
                                                                        onBlur={props.handleBlur}
                                                                        value={props.values.course_level}
                                                                        aria-label='Default select example'
                                                                    >
                                                                        <option selected='false' disabled>
                                                                            Select course level
                                                                        </option>
                                                                        {courseLevel.map((item) => (
                                                                            <option value={item.value}>
                                                                                {item.label}
                                                                            </option>
                                                                        ))}
                                                                    </Form.Select>
                                                                )}

                                                                {props.touched.course_level &&
                                                                    props.errors.course_level ? (
                                                                    <div className='bg-error'>
                                                                        {props.errors.course_level}
                                                                    </div>
                                                                ) : null}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={6} className='mb-5'>
                                                            <Form.Label>
                                                                Audio Language
                                                                <span className='text-danger'>*</span>
                                                            </Form.Label>
                                                            {param?.id ? <Form.Select
                                                                name='audio_language'
                                                                onChange={(e) => {
                                                                    props.setFieldValue(
                                                                        "audio_language",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                onBlur={props.handleBlur}
                                                                value={props.values.audio_language}
                                                                aria-label='Default select example'
                                                            >
                                                                <option selected='false' disabled>
                                                                    Select audio language
                                                                </option>

                                                                {languages &&
                                                                    languages.map((item) => (
                                                                        <option value={item.title}>
                                                                            {item.title}
                                                                        </option>
                                                                    ))}
                                                                <option value='other'>Other</option>
                                                            </Form.Select> : props?.touched?.audio_language === "" ? (
                                                                <Form.Select
                                                                    name='audio_language'
                                                                    onChange={(e) => {
                                                                        props.setFieldValue(
                                                                            "audio_language",
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    onBlur={props.handleBlur}
                                                                    value={props.values.audio_language}
                                                                    aria-label='Default select example'
                                                                >
                                                                    <option selected='false' disabled>
                                                                        Select audio language
                                                                    </option>

                                                                    {languages &&
                                                                        languages.map((item) => (
                                                                            <option value={item.title}>
                                                                                {item.title}
                                                                            </option>
                                                                        ))}
                                                                    <option value='other'>Other</option>
                                                                </Form.Select>
                                                            ) : (
                                                                <Form.Select
                                                                    name='audio_language'
                                                                    onChange={(e) => {
                                                                        props.setFieldValue(
                                                                            "audio_language",
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    onBlur={props.handleBlur}
                                                                    aria-label='Default select example'
                                                                >
                                                                    <option selected='false' disabled>
                                                                        Select audio language
                                                                    </option>
                                                                    {languages &&
                                                                        languages.map((item) => (
                                                                            <option value={item.title}>
                                                                                {item.title}
                                                                            </option>
                                                                        ))}
                                                                    <option value='other'>Other</option>
                                                                </Form.Select>
                                                            )}


                                                            {props.touched.audio_language &&
                                                                props.errors.audio_language ? (
                                                                <div className='bg-error'>
                                                                    {props.errors.audio_language}
                                                                </div>
                                                            ) : null}
                                                        </Col>
                                                        {props.values.audio_language === "other" && (
                                                            <Col md={6} className='mb-5'>
                                                                <Form.Group>
                                                                    <Form.Label>
                                                                        Other Language
                                                                        <span className='text-danger'>*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        name='other_language'
                                                                        onChange={props.handleChange}
                                                                        onBlur={props.handleBlur}
                                                                        value={props.values.other_language}
                                                                        placeholder='Enter other language'
                                                                    />
                                                                    {props.touched.other_language &&
                                                                        props.errors.other_language ? (
                                                                        <div className='bg-error'>
                                                                            {props.errors.other_language}
                                                                        </div>
                                                                    ) : null}
                                                                </Form.Group>
                                                            </Col>
                                                        )}
                                                        <Col md={6} className='mb-5'>
                                                            <Form.Group>
                                                                <Form.Label>
                                                                    Course Category
                                                                    <span className='text-danger'>*</span>
                                                                </Form.Label>
                                                                {props.values.course_category === "" ? (
                                                                    <Form.Select
                                                                        name='course_category'
                                                                        onChange={(e) => {
                                                                            props.setFieldValue(
                                                                                "course_category",
                                                                                e.target.value
                                                                            );
                                                                        }}
                                                                        onBlur={props.handleBlur}
                                                                        aria-label='Default select example'
                                                                    >
                                                                        <option selected='false' disabled>
                                                                            Select course category
                                                                        </option>

                                                                        {category.map((item) => (
                                                                            <option value={item.id}>
                                                                                {item.name}
                                                                            </option>
                                                                        ))}
                                                                        <option value='other'>Other</option>
                                                                    </Form.Select>
                                                                ) : (
                                                                    <Form.Select
                                                                        name='course_category'
                                                                        onChange={(e) => {
                                                                            props.setFieldValue(
                                                                                "course_category",
                                                                                e.target.value
                                                                            );
                                                                        }}
                                                                        onBlur={props.handleBlur}
                                                                        value={props.values.course_category}
                                                                        aria-label='Default select example'
                                                                    >
                                                                        <option selected='false' disabled>
                                                                            Select course category
                                                                        </option>

                                                                        {category.map((item) => (
                                                                            <option value={item.id}>
                                                                                {item.name}
                                                                            </option>
                                                                        ))}
                                                                        <option value='other'>Other</option>
                                                                    </Form.Select>
                                                                )}

                                                                {props.touched.course_category &&
                                                                    props.errors.course_category ? (
                                                                    <div className='bg-error'>
                                                                        {props.errors.course_category}
                                                                    </div>
                                                                ) : null}
                                                            </Form.Group>
                                                        </Col>
                                                        {props.values.course_category === "other" && (
                                                            <Col md={6} className='mb-5'>
                                                                <Form.Group>
                                                                    <Form.Label>
                                                                        Other Course Category
                                                                        <span className='text-danger'>*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        name='other_course_category'
                                                                        onChange={props.handleChange}
                                                                        onBlur={props.handleBlur}
                                                                        value={
                                                                            props.values.other_course_category
                                                                        }
                                                                        placeholder='Enter  other course category'
                                                                    />
                                                                    {props.touched.other_course_category &&
                                                                        props.errors.other_course_category ? (
                                                                        <div className='bg-error'>
                                                                            {props.errors.other_course_category}
                                                                        </div>
                                                                    ) : null}
                                                                </Form.Group>
                                                            </Col>
                                                        )}
                                                        <Col md={6} className='mb-5'>
                                                            <Form.Group>
                                                                <Form.Label>
                                                                    Is School / University
                                                                    <span className='text-danger'>*</span>
                                                                </Form.Label>
                                                                {props.values.is_professional === "" ? (
                                                                    <Form.Select
                                                                        name='is_professional'
                                                                        onChange={(e) => {
                                                                            props.setFieldValue(
                                                                                "is_professional",
                                                                                e.target.value
                                                                            );
                                                                        }}
                                                                        onBlur={props.handleBlur}
                                                                    >
                                                                        <option selected='false' disabled>
                                                                            Select option
                                                                        </option>
                                                                        <option value='0'>Yes</option>
                                                                        <option value='1'>No</option>
                                                                    </Form.Select>
                                                                ) : (
                                                                    <Form.Select
                                                                        name='is_professional'
                                                                        onChange={(e) => {
                                                                            props.setFieldValue(
                                                                                "is_professional",
                                                                                e.target.value
                                                                            );
                                                                        }}
                                                                        onBlur={props.handleBlur}
                                                                        value={props.values.is_professional}
                                                                    >
                                                                        <option selected='false' disabled>
                                                                            Select option
                                                                        </option>
                                                                        <option value='0'>Yes</option>
                                                                        <option value='1'>No</option>
                                                                    </Form.Select>
                                                                )}
                                                                {props.touched.is_professional &&
                                                                    props.errors.is_professional ? (
                                                                    <div className='bg-error'>
                                                                        {props.errors.is_professional}
                                                                    </div>
                                                                ) : null}
                                                            </Form.Group>
                                                        </Col>
                                                        {props.values.is_professional == "0" && (
                                                            <>
                                                                <Col md={6} className='mb-5'>
                                                                    <Form.Group>
                                                                        <Form.Label>
                                                                            Board
                                                                            <span className='text-danger'>*</span>
                                                                        </Form.Label>
                                                                        {props.values.board === "" ? (
                                                                            <Form.Select
                                                                                name='board'
                                                                                onChange={props.handleChange}
                                                                                onBlur={props.handleBlur}
                                                                            >
                                                                                <option selected='false' disabled>
                                                                                    Select board
                                                                                </option>
                                                                                {board &&
                                                                                    board.map((v, i) => (
                                                                                        <option value={v.title} key={i}>
                                                                                            {v.title}
                                                                                        </option>
                                                                                    ))}

                                                                                <option value='other'>Other</option>
                                                                            </Form.Select>
                                                                        ) : (
                                                                            <Form.Select
                                                                                name='board'
                                                                                onChange={props.handleChange}
                                                                                onBlur={props.handleBlur}
                                                                                value={props.values.board}
                                                                            >
                                                                                <option selected='false' disabled>
                                                                                    Select board
                                                                                </option>
                                                                                {board &&
                                                                                    board.map((v, i) => (
                                                                                        <option value={v.title} key={i}>
                                                                                            {v.title}
                                                                                        </option>
                                                                                    ))}

                                                                                <option value='other'>Other</option>
                                                                            </Form.Select>
                                                                        )}
                                                                        {props.touched.board &&
                                                                            props.errors.board ? (
                                                                            <div className='bg-error'>
                                                                                {props.errors.board}
                                                                            </div>
                                                                        ) : null}
                                                                    </Form.Group>
                                                                </Col>
                                                                {props.values.board === "other" && (
                                                                    <Col md={6} className='mb-5'>
                                                                        <Form.Group>
                                                                            <Form.Label>
                                                                                Other Board
                                                                                <span className='text-danger'>
                                                                                    *
                                                                                </span>
                                                                            </Form.Label>
                                                                            <Form.Control
                                                                                name='other_board'
                                                                                onChange={props.handleChange}
                                                                                onBlur={props.handleBlur}
                                                                                value={props.values.other_board}
                                                                                placeholder='Enter other language'
                                                                            />
                                                                            {props.touched.other_board &&
                                                                                props.errors.other_board ? (
                                                                                <div className='bg-error'>
                                                                                    {props.errors.other_board}
                                                                                </div>
                                                                            ) : null}
                                                                        </Form.Group>
                                                                    </Col>
                                                                )}
                                                                <Col md={6} className='mb-5'>
                                                                    <Form.Group>
                                                                        <Form.Label>
                                                                            Medium
                                                                            <span className='text-danger'>*</span>
                                                                        </Form.Label>
                                                                        {props.values.medium === "" ? (
                                                                            <Form.Select
                                                                                name='medium'
                                                                                onChange={props.handleChange}
                                                                                onBlur={props.handleBlur}
                                                                            >
                                                                                <option selected='false' disabled>
                                                                                    Select medium
                                                                                </option>
                                                                                {medium &&
                                                                                    medium.map((v, i) => (
                                                                                        <option value={v.title} key={i}>
                                                                                            {v.title}
                                                                                        </option>
                                                                                    ))}
                                                                                <option value='other'>Other</option>
                                                                            </Form.Select>
                                                                        ) : (
                                                                            <Form.Select
                                                                                name='medium'
                                                                                onChange={props.handleChange}
                                                                                onBlur={props.handleBlur}
                                                                                value={props.values.medium}
                                                                            >
                                                                                <option selected='false' disabled>
                                                                                    Select medium
                                                                                </option>
                                                                                {medium &&
                                                                                    medium.map((v, i) => (
                                                                                        <option value={v.id} key={i}>
                                                                                            {v.title}
                                                                                        </option>
                                                                                    ))}
                                                                                <option value='other'>Other</option>
                                                                            </Form.Select>
                                                                        )}
                                                                        {props.touched.medium &&
                                                                            props.errors.medium ? (
                                                                            <div className='bg-error'>
                                                                                {props.errors.medium}
                                                                            </div>
                                                                        ) : null}
                                                                    </Form.Group>
                                                                </Col>
                                                                {props.values.medium === "other" && (
                                                                    <Col md={6} className='mb-5'>
                                                                        <Form.Group>
                                                                            <Form.Label>
                                                                                Other Medium
                                                                                <span className='text-danger'>
                                                                                    *
                                                                                </span>
                                                                            </Form.Label>
                                                                            <Form.Control
                                                                                name='other_medium'
                                                                                onChange={props.handleChange}
                                                                                onBlur={props.handleBlur}
                                                                                value={props.values.other_medium}
                                                                                placeholder='Enter other language'
                                                                            />
                                                                            {props.touched.other_medium &&
                                                                                props.errors.other_medium ? (
                                                                                <div className='bg-error'>
                                                                                    {props.errors.other_medium}
                                                                                </div>
                                                                            ) : null}
                                                                        </Form.Group>
                                                                    </Col>
                                                                )}
                                                                <Col md={6} className='mb-5'>
                                                                    <Form.Group>
                                                                        <Form.Label>
                                                                            Standard
                                                                            <span className='text-danger'>*</span>
                                                                        </Form.Label>
                                                                        {props.values.standard === "" ? (
                                                                            <Form.Select
                                                                                name='standard'
                                                                                onChange={props.handleChange}
                                                                                onBlur={props.handleBlur}
                                                                            >
                                                                                <option selected='false' disabled>
                                                                                    Select standard
                                                                                </option>
                                                                                {standard &&
                                                                                    standard.map((v, i) => (
                                                                                        <option value={v.title} key={i}>
                                                                                            {v.title}
                                                                                        </option>
                                                                                    ))}
                                                                                <option value='other'>Other</option>
                                                                            </Form.Select>
                                                                        ) : (
                                                                            <Form.Select
                                                                                name='standard'
                                                                                onChange={props.handleChange}
                                                                                onBlur={props.handleBlur}
                                                                                value={props.values.standard}
                                                                            >
                                                                                <option selected='false' disabled>
                                                                                    Select standard
                                                                                </option>
                                                                                {standard &&
                                                                                    standard.map((v, i) => (
                                                                                        <option value={v.title} key={i}>
                                                                                            {v.title}
                                                                                        </option>
                                                                                    ))}
                                                                                <option value='other'>Other</option>
                                                                            </Form.Select>
                                                                        )}

                                                                        {props.touched.standard &&
                                                                            props.errors.standard ? (
                                                                            <div className='bg-error'>
                                                                                {props.errors.standard}
                                                                            </div>
                                                                        ) : null}
                                                                    </Form.Group>
                                                                </Col>
                                                                {props.values.standard === "other" && (
                                                                    <Col md={6} className='mb-5'>
                                                                        <Form.Group>
                                                                            <Form.Label>
                                                                                Other Standard
                                                                                <span className='text-danger'>
                                                                                    *
                                                                                </span>
                                                                            </Form.Label>
                                                                            <Form.Control
                                                                                name='other_standard'
                                                                                onChange={props.handleChange}
                                                                                onBlur={props.handleBlur}
                                                                                value={props.values.other_standard}
                                                                                placeholder='Enter other language'
                                                                            />
                                                                            {props.touched.other_standard &&
                                                                                props.errors.other_standard ? (
                                                                                <div className='bg-error'>
                                                                                    {props.errors.other_standard}
                                                                                </div>
                                                                            ) : null}
                                                                        </Form.Group>
                                                                    </Col>
                                                                )}
                                                            </>
                                                        )}
                                                        <Col md={6} className='mb-5'>
                                                            <Form.Group>
                                                                <Form.Label>
                                                                    Branch
                                                                    <span className='text-danger'>*</span>
                                                                </Form.Label>

                                                                {props.values.branch === "" ? (
                                                                    <Form.Select
                                                                        name='branch'
                                                                        onChange={(e) => {
                                                                            props.setFieldValue(
                                                                                "branch",
                                                                                e.target.value
                                                                            );
                                                                            getAllTeacherDropdown(e.target.value);
                                                                        }}
                                                                        onBlur={props.handleBlur}
                                                                        aria-label='Default select example'
                                                                    >
                                                                        <option selected='false' disabled>
                                                                            Select branch
                                                                        </option>
                                                                        {branch.map((item) => (
                                                                            <option value={item.id}>
                                                                                {item.name}
                                                                            </option>
                                                                        ))}
                                                                    </Form.Select>
                                                                ) : (
                                                                    <Form.Select
                                                                        name='branch'
                                                                        onChange={(e) => {
                                                                            props.setFieldValue(
                                                                                "branch",
                                                                                e.target.value
                                                                            );
                                                                            getAllTeacherDropdown(e.target.value);
                                                                        }}
                                                                        onBlur={props.handleBlur}
                                                                        value={props.values.branch}
                                                                        aria-label='Default select example'
                                                                    >
                                                                        <option selected='false' disabled>
                                                                            Select branch
                                                                        </option>
                                                                        {branch.map((item) => (
                                                                            <option value={item.id}>
                                                                                {item.name}
                                                                            </option>
                                                                        ))}
                                                                    </Form.Select>
                                                                )}
                                                                {props.touched.branch &&
                                                                    props.errors.branch ? (
                                                                    <div className='bg-error'>
                                                                        {props.errors.branch}
                                                                    </div>
                                                                ) : null}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md='6' className='mb-5'>
                                                            <Form.Group>
                                                                <Form.Label>
                                                                    Teacher Name
                                                                    <span className='text-danger'>*</span>
                                                                </Form.Label>
                                                                {props.values.teacher_name === "" ? (
                                                                    <Form.Select
                                                                        name='teacher_name'
                                                                        onChange={props.handleChange}
                                                                        onBlur={props.handleBlur}
                                                                        aria-label='Default select example'
                                                                    >
                                                                        <option selected='false' disabled>
                                                                            Select teacher name
                                                                        </option>
                                                                        {teacher.map((item) => (
                                                                            <option value={item.id}>
                                                                                {item.name}
                                                                            </option>
                                                                        ))}
                                                                    </Form.Select>
                                                                ) : (
                                                                    <Form.Select
                                                                        name='teacher_name'
                                                                        onChange={props.handleChange}
                                                                        onBlur={props.handleBlur}
                                                                        value={props.values.teacher_name}
                                                                        aria-label='Default select example'
                                                                    >
                                                                        <option selected='false' disabled>
                                                                            Select teacher name
                                                                        </option>
                                                                        {teacher.map((item) => (
                                                                            <option value={item.id}>
                                                                                {item.name}
                                                                            </option>
                                                                        ))}
                                                                    </Form.Select>
                                                                )}
                                                                {props.touched.teacher_name &&
                                                                    props.errors.teacher_name ? (
                                                                    <div className='bg-error'>
                                                                        {props.errors.teacher_name}
                                                                    </div>
                                                                ) : null}
                                                            </Form.Group>
                                                        </Col>
                                                        {(param?.id && (props.values.status === "active" || (props.values.is_submit === 0 && props.values.is_update !== 0))) && (
                                                            <>
                                                                <Col md={12} className='mb-5'>
                                                                    <Form.Group>
                                                                        <Form.Label>
                                                                            Admin Remarks <span className='text-danger'>*</span>
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            name='admin_remark'
                                                                            onChange={props.handleChange}
                                                                            onBlur={props.handleBlur}
                                                                            value={props.values.admin_remark}
                                                                            placeholder='Enter admin remark'
                                                                        />
                                                                        {props.touched.admin_remark &&
                                                                            props.errors.admin_remark && (
                                                                                <div className='bg-error'>
                                                                                    {props.errors.admin_remark}
                                                                                </div>
                                                                            )}
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md={12} className='mb-5'>
                                                                    <Form.Group>
                                                                        <Form.Label>
                                                                            User Remarks <span className='text-danger'>*</span>
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            name='user_remark'
                                                                            onChange={props.handleChange}
                                                                            onBlur={props.handleBlur}
                                                                            value={props.values.user_remark}
                                                                            placeholder='Enter user remark'
                                                                        />
                                                                        {props.touched.user_remark &&
                                                                            props.errors.user_remark && (
                                                                                <div className='bg-error'>
                                                                                    {props.errors.user_remark}
                                                                                </div>
                                                                            )}
                                                                    </Form.Group>
                                                                </Col>
                                                            </>
                                                        )}
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <div className='d-flex justify-content-between mt-4'>
                                                <button
                                                    className='btn btn-danger'
                                                    disabled
                                                >
                                                    <i className="fa fa-chevron-left me-1"></i>
                                                    Previous
                                                </button>
                                                <button
                                                    className='btn btn-primary btn-next '
                                                    type='submit'
                                                >Next
                                                    <i className="fa fa-chevron-right ms-1"></i>
                                                </button>
                                            </div>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </div>
                    )}
                    {secondForm && (
                        <div id='curriculum-step' role='tabpanel' aria-labelledby='curriculum-step-trigger' >
                            <div className='content-header'>
                                <h4 className='mb-5'>Course Teaser / Introduction</h4>
                            </div>
                            <Formik
                                initialValues={secondFormValues}
                                onSubmit={secondFormSubmit}
                                enableReinitialize={true}
                                validationSchema={secondValidateSchema}
                            >
                                {(props) => {

                                    return (
                                        <form
                                            noValidate='novalidate'
                                            onSubmit={props.handleSubmit}
                                        >
                                            <div className="row">
                                                <div className="form-group col-md-6">
                                                    <Form.Group>
                                                        <Form.Label>Video<span className='text-danger'>*</span> </Form.Label>
                                                        <Form.Control
                                                            type='file'
                                                            accept='video/*'
                                                            name='video'
                                                            onChange={async (e) => {
                                                                const duration = await getVideoDuration(
                                                                    e.target.files[0]
                                                                );
                                                                const durationsStr = duration.toString()
                                                                props.setFieldValue("video_duration", durationsStr);
                                                                setVideoduration(duration);
                                                                props.setFieldValue(
                                                                    "video",
                                                                    e.target.files[0]
                                                                );
                                                            }}
                                                        />
                                                        {props.values.video &&
                                                            typeof props.values.video ===
                                                            "string" ? (
                                                            <div className='form-text text-muted'>
                                                                {props.values.video
                                                                    .split("/")
                                                                    .at(-1)}
                                                            </div>
                                                        ) : null}
                                                        {props.errors.video ? (
                                                            <div className='bg-error'>
                                                                {props.errors.video}
                                                            </div>
                                                        ) : null}
                                                    </Form.Group>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Thumbnail Image
                                                            <span className='text-danger'>*</span>
                                                        </Form.Label>

                                                        <Form.Control
                                                            type='file'
                                                            accept='image/png, image/jpeg'
                                                            name='thumbnail_image'
                                                            onChange={(e) => {
                                                                handleFileInputChange(
                                                                    e,
                                                                    "thumbnail_image",
                                                                    props
                                                                );
                                                            }}
                                                        />
                                                        <p className="fs-12 mb-0"><span className="text-danger">Note:</span> Image size: width = 368px and height = 200px</p>
                                                        {props.values.thumbnail_image &&
                                                            typeof props.values.thumbnail_image ===
                                                            "string" ? (
                                                            <div className='form-text text-muted'>
                                                                {props.values.thumbnail_image
                                                                    .split("/")
                                                                    .at(-1)}
                                                            </div>
                                                        ) : null}
                                                        {props.errors.thumbnail_image ? (
                                                            <div className='bg-error'>
                                                                {props.errors.thumbnail_image}
                                                            </div>
                                                        ) : null}
                                                    </Form.Group>
                                                </div>
                                                {props.values.thumbnail_image && (
                                                    <div className='col-md-2' >
                                                        {props.values.thumbnail_image && (
                                                            <img
                                                                src={image || props.values.thumbnail_image}
                                                                alt='image'
                                                                className="img-thumbnail"
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            <div className='d-flex justify-content-between mt-4'>
                                                <button
                                                    className='btn btn-danger btn-prev '
                                                    onClick={() => {
                                                        setFirstForm(false);
                                                        setSecondForm(false);
                                                        setThirdForm(true);
                                                        setFourthForm(false);
                                                    }}
                                                >
                                                    <i className="fa fa-chevron-left me-1"></i>
                                                    Previous
                                                </button>
                                                {loading ? (
                                                    <SubmitLoader />
                                                ) : (
                                                    <button
                                                        className='btn btn-primary btn-next '
                                                        type='submit'
                                                    >
                                                        Submit & Next
                                                        <i className="fa fa-chevron-right ms-1"></i>
                                                    </button>
                                                )}
                                            </div>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </div>
                    )}
                    {thirdForm && (
                        <div id='price-step' role='tabpanel' aria-labelledby='price-step-trigger' >
                            <Formik
                                initialValues={thirdFormValues}
                                onSubmit={thirdFormSubmit}
                                enableReinitialize={true}
                                validationSchema={thirdValidateSchema}
                            >
                                {(props) => (
                                    <form
                                        noValidate='novalidate'
                                        onSubmit={props.handleSubmit}
                                    >
                                        <div className='row'>
                                            <div className='form-group col-lg-2 col-md-4 col-sm-6'>
                                                <label>
                                                    Price Type <span className='text-danger'>*</span>
                                                </label>
                                                <div className='form-group m-t-15 mb-0 custom-radio-ml'>
                                                    <div className='radio form-check-inline'>
                                                        <input
                                                            id='free'
                                                            type='radio'
                                                            name='price_type'
                                                            value='free'
                                                            checked={props.values.price_type === "free"}
                                                            onChange={props.handleChange}
                                                            className='me-3'
                                                        />
                                                        <label className='mb-0' htmlFor='free'>
                                                            Free
                                                        </label>
                                                    </div>
                                                    <div className='radio form-check-inline'>
                                                        <input
                                                            id='paid'
                                                            type='radio'
                                                            name='price_type'
                                                            checked={props.values.price_type === "paid"}
                                                            onChange={props.handleChange}
                                                            value='paid'
                                                            className='me-3'
                                                        />
                                                        <label className='mb-0' htmlFor='paid'>
                                                            Paid
                                                        </label>
                                                    </div>
                                                </div>
                                                {props.touched.price_type &&
                                                    props.errors.price_type ? (
                                                    <div className='bg-error'>
                                                        {props.errors.price_type}
                                                    </div>
                                                ) : null}
                                            </div>
                                            {props.values.price_type === "paid" && (
                                                <>
                                                    <div className='form-group col-lg-2 col-md-4 col-sm-6'>
                                                        <Form.Group>
                                                            <Form.Label>
                                                                Regular Price
                                                                <span className='text-danger'>*</span>
                                                            </Form.Label>
                                                            <Form.Control
                                                                type='number'
                                                                name='regular_price'
                                                                value={props.values.regular_price}
                                                                onChange={props.handleChange}
                                                                placeholder='Enter regular price'
                                                                min='0'
                                                            />
                                                            {props.touched.regular_price &&
                                                                props.errors.regular_price ? (
                                                                <div className='bg-error'>
                                                                    {props.errors.regular_price}
                                                                </div>
                                                            ) : null}
                                                        </Form.Group>
                                                    </div>
                                                    <div className='form-group col-lg-2 col-md-4 col-sm-6'>
                                                        <Form.Label>
                                                            Discount
                                                            <span className='text-danger'>*</span>
                                                        </Form.Label>
                                                        <Form.Check
                                                            inline
                                                            type='radio'
                                                            name='is_discount'
                                                            value='1'
                                                            checked={props.values.is_discount == "1"}
                                                            onChange={props.handleChange}
                                                            label='Yes'
                                                        />

                                                        <Form.Check
                                                            inline
                                                            type='radio'
                                                            name='is_discount'
                                                            label='No'
                                                            checked={props.values.is_discount == "0"}
                                                            value='0'
                                                            onChange={props.handleChange}
                                                        />
                                                        {props.touched.is_discount &&
                                                            props.errors.is_discount ? (
                                                            <div className='bg-error'>
                                                                {props.errors.is_discount}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </>
                                            )}

                                            {props.values.is_discount == 1 &&
                                                props.values.price_type === "paid" && (
                                                    <>
                                                        <div className='form-group col-lg-2 col-md-4 col-sm-6'>
                                                            <Form.Group>
                                                                <Form.Label>
                                                                    Discount Rate (in %)
                                                                    <span className='text-danger'>*</span>
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type='number'
                                                                    name='discount_price'
                                                                    value={props.values.discount_price}
                                                                    onChange={props.handleChange}
                                                                    placeholder='Enter discount price in %'
                                                                    min='1'
                                                                    max={90}
                                                                />
                                                                {props.touched.discount_price &&
                                                                    props.errors.discount_price ? (
                                                                    <div className='bg-error'>
                                                                        {props.errors.discount_price}
                                                                    </div>
                                                                ) : null}
                                                            </Form.Group>
                                                        </div>
                                                        <div className='form-group col-lg-2 col-md-4 col-sm-6'>
                                                            <Form.Group>
                                                                <Form.Label>
                                                                    Price After Discount
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type='number'
                                                                    name='discount_price'
                                                                    placeholder='show here price after discount'
                                                                    disabled
                                                                    value={
                                                                        Math.round(props.values.regular_price -
                                                                            (
                                                                                props.values.regular_price *
                                                                                (props.values.discount_price / 100)
                                                                            ))
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className='form-group col-lg-2 col-md-4 col-sm-6'>
                                                            <Form.Group>
                                                                <Form.Label>
                                                                    Discount Start Date
                                                                    <span className='text-danger'>*</span>
                                                                </Form.Label>
                                                                <input
                                                                    className='form-control'
                                                                    name='discount_start_date'
                                                                    value={props.values.discount_start_date}
                                                                    onChange={props.handleChange}
                                                                    type='date'
                                                                    min={moment().format("YYYY-MM-DD")}

                                                                />
                                                                {props.touched.discount_start_date &&
                                                                    props.errors.discount_start_date ? (
                                                                    <div className='bg-error'>
                                                                        {props.errors.discount_start_date}
                                                                    </div>
                                                                ) : null}
                                                            </Form.Group>
                                                        </div>
                                                        <div className='form-group col-lg-2 col-md-4 col-sm-6'>
                                                            <Form.Label>
                                                                Limited Offer
                                                                <span className='text-danger'>*</span>
                                                            </Form.Label>
                                                            <Form.Check
                                                                inline
                                                                type='radio'
                                                                name='limited_offer'
                                                                value='1'
                                                                checked={props.values.limited_offer == "1"}
                                                                onChange={props.handleChange}
                                                                label='Yes'
                                                            />
                                                            <Form.Check
                                                                inline
                                                                type='radio'
                                                                name='limited_offer'
                                                                label='No'
                                                                checked={props.values.limited_offer == "0"}
                                                                value='0'
                                                                onChange={props.handleChange}
                                                            />
                                                            {props.touched.limited_offer &&
                                                                props.errors.limited_offer ? (
                                                                <div className='bg-error'>
                                                                    {props.errors.limited_offer}
                                                                </div>
                                                            ) : null}
                                                        </div>

                                                        {props.values.limited_offer == 1 && (
                                                            <div className='form-group col-lg-2 col-md-4 col-sm-6'>
                                                                <Form.Group>
                                                                    <Form.Label>Discount End Date</Form.Label>
                                                                    <input
                                                                        className='form-control'
                                                                        name='discount_end_date'
                                                                        type='date'
                                                                        value={props.values.discount_end_date}
                                                                        onChange={props.handleChange}
                                                                        min={props.values.discount_start_date || moment().format("YYYY-MM-DD")}
                                                                    />
                                                                    {props.touched.discount_end_date &&
                                                                        props.errors.discount_end_date ? (
                                                                        <div className='bg-error'>
                                                                            {props.errors.discount_end_date}
                                                                        </div>
                                                                    ) : null}
                                                                </Form.Group>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                        </div>
                                        <div className='d-flex justify-content-between mt-4'>
                                            <button
                                                className='btn btn-danger btn-prev '
                                                onClick={() => {
                                                    setFirstForm(true);
                                                    setSecondForm(false);
                                                    setThirdForm(false);
                                                    setFourthForm(false);
                                                }}
                                            >
                                                <i className="fa fa-chevron-left me-1"></i>Previous
                                            </button>
                                            <button
                                                className='btn btn-primary btn-next '
                                                type='submit'
                                            >
                                                Next
                                                <i className="fa fa-chevron-right ms-1"></i>
                                            </button>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    )}
                    {fourthForm && (
                        <div id='publish-step' role='tabpanel' aria-labelledby='publish-step-trigger' >
                            <CreateCourseVideo />
                            <div className='d-flex justify-content-end mt-4'>
                                {/* <button
                                    className='btn btn-danger btn-prev '
                                    type='button'
                                    onClick={() => {
                                        setFirstForm(false);
                                        setSecondForm(true);
                                        setThirdForm(false);
                                        setFourthForm(false);
                                    }}
                                >
                                    <i className="fa fa-chevron-left me-1"></i>Previous
                                </button> */}
                                {loading ? (
                                    <SubmitLoader />
                                ) : (
                                    <Link
                                        to={(sessionStorage.getItem("is_main") == 0) ? '/institute-dashboard/revised-video-course' : '/institute-dashboard/video-course'}
                                        className="btn btn-primary "
                                    >
                                        Submit
                                    </Link>
                                )}
                            </div>
                        </div>
                    )}
                    {/* </div> */}
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

