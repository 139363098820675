import "./Preloader.css"

export default function Preloader() {
  return (
    <>
      <div className="mainloader">
        {/* <div className="spin-loader" aria-hidden="true"></div> */}
        {/* <img src="./assets/images/Loader.gif" alt="" /> */}
        <div className="preloader row text-center loaderdots">
          <img style={{ height: "auto", width: "400px", marginTop: "180px" }} src="./assets/images/EduM_White_New.png" alt="" />
          <iframe className="loaderdots" src="https://lottie.host/embed/1ec7ef36-c9ee-4f7e-8520-ceb46326f8af/Bv3kJHidrF.json"></iframe>
        </div>
      </div>
    </>
  );
}
