import React from 'react';
import { Link } from 'react-router-dom';

const ContactNow = () => {
    return (
        <>
            <section className="cover-image about-widget sptb bg-background-color" data-bs-image-src="assets/images/banners/banner5.webp" style={{ background: 'url("assets/images/banners/banner5.webp") center center' }}>
                <div className="content-text mb-0">
                    <div className="container text-center">
                        <h1 className="text-white">Join us Today to Achieve your Goals</h1>
                        <Link to="/contact-us" className="btn btn-danger">Contact Now !</Link>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactNow;