import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Footer from "../../Footer/Footer";
import Headerinner from "../../Header/Headerinner";
import Curve from '../../Header/Curve';
import { getGetTeacher } from "../../../../apis_web";
import { calculateExperience } from '../../../../utils/utils';
import NoDataAvailable from "../../../common/NoDataAvailable";

const Teacher = () => {
    let rating = [1, 2, 3, 4, 5];
    const history = useHistory(); 
    const [data, setTeacherData] = useState();
    const [totalPages, setTotalPages] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [offset, setOffset] = useState(0);
    const [Search, setSearch] = useState({
        query: "",
        limit: 12,
        offset: 0,
    });
    const apiCall = async (param, limit, offset) => {
        let res = await getGetTeacher(param, limit, offset);
        if (res.status === "success") {
            setTeacherData(res.data);
            setTotalPages(res?.total_teacher / Search.limit)
            setTotalCount(res.total_teacher);
        }
    };
    const getAllBestTeacherData = async () => {
        await apiCall(Search.query, Search.limit, Search.offset);
    };

    const handlePageClick = async (data) => {
        let currentPage = data.selected * Search.limit;
        setOffset(currentPage)
        await apiCall(Search.query, Search.limit, currentPage);
    };

    useEffect(() => {
        getAllBestTeacherData();
    }, []);
    return (
        <>
            <Headerinner />
            <div
                className="cover-image bg-background-1"
                data-bs-image-src="assets/images/banners/banner1.webp"
                style={{
                    background: 'url("assets/images/banners/banner1.webp") center center',
                }}
            >
                <div className="container header-text">
                    <h1>Best Teachers</h1>
                </div>
            </div>
            <Curve />

            <section className="sptb">
                <div className="container">
                    <div className="row">
                        {data?.length > 0 ? (
                            <>
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="mb-0">
                                                Showing {offset + 1} to {offset + data?.length} of {totalCount} entries
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                {data?.map((item, i) => (
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={i}>
                                        <Link to={`/teacher-detail/${item?.teacher_id}`}>
                                            <div className="card">
                                                <div className="card-body" >
                                                    <div className="text-center">
                                                        <div className="item-card7-img">
                                                            <img
                                                                src={item?.image}
                                                                className="cover-image teacher-student-image"
                                                                alt={item?.name}
                                                            />
                                                        </div>
                                                        <h4 className="mt-4 mb-2 leading-tight">
                                                            {item?.name}
                                                        </h4>
                                                        <h6 className="team-section badge">
                                                            {item?.total_experiance != 0 ? calculateExperience(item?.total_experiance) : "---"} Experience
                                                        </h6>
                                                        <div className="d-flex justify-content-center">
                                                            <div className="br-widget">
                                                                {rating.map((el, i) => (
                                                                    <i
                                                                        data-rating-value={el}
                                                                        data-rating-text={el}
                                                                        className={
                                                                            el <= item?.average_review
                                                                                ? "br-selected"
                                                                                : ""
                                                                        }
                                                                        key={i}
                                                                    />
                                                                ))}
                                                            </div>
                                                            <span>({item?.review_count} Reviews)</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <>
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="mb-0">
                                                Showing {0} to {offset + data?.length} of {totalCount} entries
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <NoDataAvailable/>
                            </>
                        )}
                    </div>
                    {data?.length > 0 && (
                        <div className="row">
                            <div className="col-md-12">
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    breakLabel={"..."}
                                    pageCount={Math.ceil(totalPages)}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={
                                        "pagination"
                                    }
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Teacher;
