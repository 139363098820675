import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import ReactPaginate from "react-paginate";

const TeacherList = (props) => {
    return (
        <div className="col-lg-12">
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Teacher's List</h4>
                </div>
                <div className="card-body">
                    <div id="basic-1_wrapper" className="dataTables_wrapper no-footer">
                        <div id="basic-1_filter" className="dataTables_filter">
                            <label>
                                Search:
                                <input
                                    type="search"
                                    placeholder="Search..."
                                    onChange={(e) => props.searchData(e)}
                                />
                            </label>
                        </div>
                        <table className="table table-bordered table-hover dataTable table-responsive-xl">
                            <thead>
                                <tr>
                                    <th className="text-center text-nowrap"> Sr. No. </th>
                                    <th >Teacher Details</th>
                                    <th className="text-center">Contact No</th>
                                    <th className="text-center">Gender</th>
                                    <th className="text-center">Board</th>
                                    <th className="text-center">Standard</th>
                                    <th className="text-center">Subject</th>
                                    <th className="text-center">
                                        Status
                                    </th>
                                    <th className="text-center">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.data?.length > 0
                                    ? props.data.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <th className="text-center">
                                                    {i + props.offset + 1}
                                                </th>

                                                <td>
                                                    <div className="media">
                                                        <img
                                                            src={item?.image}
                                                            alt={item?.name}
                                                            className="me-3 rounded-circle"
                                                        />
                                                        <div className="media-body">
                                                            <Link
                                                                to={`/institute-dashboard/teacher-detail/${item?.id}`}
                                                                className="font-weight-semibold"
                                                            >{item?.name}</Link>
                                                            <p className="text-muted mb-0">
                                                                {item?.email}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">{item?.contact}</td>
                                                <td className="text-center text-capitalize">{item?.gender ? item?.gender : "---"}</td>
                                                <td className="text-center">{item?.board ? item?.board : "---"}</td>
                                                <td className="text-center">{item?.languages ? item?.languages : "---"}</td>
                                                <td className="text-center">{item?.subject ? item?.subject : "---"}</td>
                                                <td className="text-center text-capitalize">{item?.status}</td>
                                                <td className="text-center">
                                                    <Link title="Edit Teacher"
                                                        className="btn btn-primary btn-sm"
                                                        to={"/institute-dashboard/teacher-edit/" + item?.id}
                                                    >
                                                        <i className="fa fa-pen-to-square fs-16"></i>
                                                    </Link>
                                                </td>
                                            </tr>
                                        );
                                    })
                                    : <tr>
                                        <td className="text-center" colSpan={9}>No Data Available !</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        {props.data?.length > 0 && (
                            <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                pageCount={props.count / 10}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={props.handlePageClick}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeacherList;
