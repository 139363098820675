import React from 'react';

const Teachers = () => {
    return (
        <>
            <section className="sptb">
                <div className="container">
                    <div className="section-title">
                        <h2>Benefits for Teachers</h2>
                    </div>
                    <div className="row match-height">
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="feature">
                                        <div className="fa-stack fea-icon bg-primary mb-3">
                                            <i className="fa fa-eye text-white" />
                                        </div>
                                        <h3>Increased Visibility</h3>
                                        <p>By showcasing their expertise and skills on our platform,
                                            teachers can increase their visibility and reach a wider audience of potential
                                            students. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="feature">
                                        <div className="fa-stack fea-icon bg-success mb-3">
                                            <i className="fa fa-line-chart text-white" />
                                        </div>
                                        <h3>Career Growth</h3>
                                        <p>Our platform provides teachers with the opportunity to grow their
                                            careers by reaching more students and expanding their teaching network.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="feature">
                                        <div className="fa-stack fea-icon bg-secondary mb-3">
                                            <i className="fa fa-handshake text-white" />
                                        </div>
                                        <h3>Networking Opportunities</h3>
                                        <p> Our platform provides teachers with the opportunity to
                                            network with other teachers and institutions, sharing ideas and best practices to
                                            improve education quality</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center match-height">
                        <div className="col-md-4">
                            <div className="card mb-lg-0">
                                <div className="card-body text-center">
                                    <div className="feature">
                                        <div className="fa-stack fea-icon bg-primary mb-3">
                                            <i className="fa fa-face-smile text-white" />
                                        </div>
                                        <h3>Reduced Administrative Work</h3>
                                        <p>We take care of teachers' administrative tasks allowing teachers to focus on teaching and student engagement.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card mb-lg-0">
                                <div className="card-body text-center">
                                    <div className="feature">
                                        <div className="fa-stack fea-icon bg-success mb-3">
                                            <i className="fa fa-comment text-white" />
                                        </div>
                                        <h3>Student Feedback</h3>
                                        <p>Teachers can receive feedback from students, helping them to
                                            improve their teaching methods and reach more students.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

        </>
    );
};

export default Teachers;