import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import url from "../../../../../constants/url";
import ReactPaginate from "react-paginate";
import SubjectList from "./SubjectList";

const SubjectView = () => {
    const { class_id } = useParams();
    const [filters, setFilters] = useState({
        limit: 10,
        offset: 0,
        class_id: class_id,
    })
    const [loading, setLoading] = useState(false)
    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage.auth_token;
    const [timeSchedule, setTimeSchedule] = useState([]);
    const [count, setCount] = useState();
    const getClassSchedule = async () => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
                limit: filters.limit,
                offset: filters.offset,
                class_id: filters.class_id,
            })
        };

        fetch(url.apiUrl + "institute/class-subject-schedule", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setTimeSchedule(result.data);
                    setCount(result.count);
                }
            })
            .catch((error) => console.log("error", error))
            .finally(() => {
                setLoading(false)
            })
    }
    const handlePageClick = async (lectures) => {
        let currentPage = lectures.selected * filters.limit;
        setFilters({
            ...filters,
            limit: filters.limit,
            offset: currentPage,
        })
        await getClassSchedule();
    };
    useEffect(() => {
        getClassSchedule()
    }, [filters])

    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">View Classroom Subject Time Slot Teacher List</li>
                </ol>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <SubjectList filters={filters} timeSchedule={timeSchedule} loading={loading} getClassSchedule={getClassSchedule} />
                        <div className="card-body">
                            {timeSchedule.length > 0 && (
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    breakLabel={"..."}
                                    pageCount={Math.ceil(count / filters.limit)}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SubjectView;
