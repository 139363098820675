import moment from "moment";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { achievementSchema } from "./achievement.helper";
import { Formik } from "formik";
import useToken from "../../../../../../hooks/useToken";
import { toast } from "react-toastify";
import url from "../../../../../../constants/url";
import SubmitLoader from "../../../../../common/SubmitLoader";
import { Editor } from "@tinymce/tinymce-react";

function AddAchievement({ show, setShow, getAllAchievements }) {
    const { token } = useToken();
    const [loader, setLoader] = useState(false);
    const [initialState, setInitialState] = useState({
        title: "",
        date: "",
        description: "",
    });
    const handleClose = () => setShow(false);

    function onSubmit(payload, actions) {
        try {
            setLoader(true);
            fetch(`${url.apiUrl}institute-teacher/add-achievement`, {
                method: "POST",
                headers: { "Content-Type": "application/json", AUTHTOKEN: token },
                body: JSON.stringify(payload),
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === "success") {
                        actions.resetForm({
                            title: "",
                            date: "",
                            description: "",
                        });
                        getAllAchievements();
                        handleClose();
                        setLoader(false);
                        toast.success("Achievement Added Successfully", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    } else {
                        setLoader(false);
                        toast.error("Something went wrong", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                })
                .catch((error) => console.log("error", error));
        } catch (error) {
            console.log("Error");
        }
    }

    return (
        <Formik
            initialValues={initialState}
            onSubmit={onSubmit}
            validationSchema={achievementSchema}
        >
            {(formProps) => {
                return (
                    <React.Fragment>
                        <Modal id="contained-modal-title-vcenter"
                            aria-labelledby="contained-modal-title-vcenter"
                            size="lg"
                            show={show}
                            centered
                            onHide={() => {
                                formProps.handleReset();
                                handleClose();
                            }}
                            animation={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Add Achievement's Details
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='row'>
                                    <div className='col-md-6 form-group'>
                                        <label htmlFor='title'>
                                            Title <span className='text-danger'> *</span>
                                        </label>
                                        <input
                                            type='text'
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                            name='title'
                                            id='title'
                                            className='form-control'
                                            placeholder='Enter title'
                                        />
                                        {formProps.touched.title && formProps.errors.title ? (
                                            <div className='bg-error'>
                                                {formProps.errors.title}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className='col-md-6 form-group'>
                                        <label htmlFor='date'>
                                            Date<span className='text-danger'> *</span>
                                        </label>
                                        <input
                                            type='date'
                                            max={moment().format("YYYY-MM-DD")}
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                            name='date'
                                            id='date'
                                            placeholder='MM/DD/YYYY'
                                            className='form-control'
                                        />
                                        {formProps.touched.date && formProps.errors.date ? (
                                            <div className='bg-error'>
                                                {formProps.errors.date}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className='col-md-12 form-group'>
                                        <label htmlFor='description'>
                                            Description<span className='text-danger'> *</span>
                                        </label>
                                        <Editor
                                            textareaName='description'
                                            placeholder='Enter description'
                                            apiKey='9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d'
                                            init={{
                                                plugins: [
                                                    "advlist",
                                                    "autolink",
                                                    "lists",
                                                    "link",
                                                    "image",
                                                    "charmap",
                                                    "preview",
                                                    "anchor",
                                                    "searchreplace",
                                                    "visualblocks",
                                                    "code",
                                                    "fullscreen",
                                                    "insertdatetime",
                                                    "media",
                                                    "table",
                                                    "help",
                                                    "wordcount",
                                                    "spellchecker",
                                                ],

                                                toolbar:
                                                    " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help |spellchecker ",
                                            }}
                                            value={formProps?.values?.description}
                                            onEditorChange={(e) =>
                                                formProps.handleChange({
                                                    target: {
                                                        name: `description`,
                                                        value: e,
                                                    },
                                                })
                                            }
                                        />

                                        {formProps.touched.description &&
                                            formProps.errors.description ? (
                                            <div className='bg-error'>
                                                {formProps.errors.description}
                                            </div>
                                        ) : null}
                                        <p className='text-end'>
                                            {formProps?.values?.description.length} / 500 Characters
                                        </p>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                {loader ? (
                                    <SubmitLoader />
                                ) : (
                                    <>
                                        <button
                                            className='btn btn-primary'
                                            type='button'
                                            onClick={formProps.handleSubmit}
                                        >
                                            Submit
                                        </button>
                                        <button
                                            className='btn btn-danger'
                                            type='button'
                                            onClick={() => {
                                                formProps.handleReset();
                                                handleClose();
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </>
                                )}
                            </Modal.Footer>
                        </Modal>
                    </React.Fragment>
                );
            }}
        </Formik>
    );
}

export default AddAchievement;
