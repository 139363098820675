import React, { useState } from 'react';
import Headerinner from '../../Header/Headerinner';
import Footer from '../../Footer/Footer';
import Curve from '../../Header/Curve';
import { Link } from "react-router-dom";
import Select from "react-select";
import { Editor } from "@tinymce/tinymce-react";
import "react-phone-number-input/style.css";

const Institute = () => {
    const [isLoader, setIsLoader] = useState(false);
    return (
        <>
            <Headerinner />
            <div
                className="cover-image bg-background-1"
                data-bs-image-src="assets/images/banners/banner1.webp"
                style={{
                    background: 'url("assets/images/banners/banner1.webp") center center',
                }}
            >
                <div className="container header-text">
                    <h1>Alumni Registration</h1>
                </div>
            </div>
            <Curve />
            <section className="sptb">
                <div className="container customer-page">
                    <div className="col-md-10 d-block mx-auto">
                        <div className="card">
                            <div className="card-header"> <h4 className="card-title">Alumni</h4> </div>
                            <div className="card-body">
                                <form>
                                    <div className="row">
                                        <div className="col-md-6 col-12 form-group">
                                            <label>Branch</label>
                                            <Select name="branch" />
                                        </div>
                                        <div className="col-md-6 col-12 form-group">
                                            <label>Classroom Course <span className="text-danger">*</span></label>
                                            <Select name="classroom" />
                                        </div>
                                        <div className="col-md-6 col-12 form-group">
                                            <label>Student Name <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name="student" placeholder="Enter student name" />
                                        </div>
                                        <div className="col-md-6 col-12 form-group">
                                            <label>Student Profile <span className="text-danger">*</span></label>
                                            <input type="file" className="form-control" name="student" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-12 form-group">
                                            <div className="row">
                                                <div className="col-md-12 form-group">
                                                    <label>Batch Years <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" name="student" placeholder="Enter session" />
                                                </div>
                                                <div className="col-md-12 form-group">
                                                    <label>Current Company <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" name="student" placeholder="Enter cureent company" />
                                                </div>
                                                <div className="col-md-12 form-group">
                                                    <label>Current Designation <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" name="student" placeholder="Enter cureent designation" />
                                                </div>
                                                <div className="col-md-12 form-group">
                                                    <label>Student Contact No <span className="text-danger">*</span></label>
                                                    <input type="tel" className="form-control" name="student" placeholder="Enter student conatct no" />
                                                </div>
                                                <div className="col-md-12 form-group">
                                                    <label>Student Email ID <span className="text-danger">*</span></label>
                                                    <input type="email" className="form-control" name="student" placeholder="Enter student email id" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 form-group'>
                                            <label htmlFor='reason'>About the Student <span className='text-danger'>* </span></label>
                                            <Editor
                                                textareaName='about'
                                                placeholder='Enter about'
                                                apiKey='9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d'
                                                init={{
                                                    plugins: [
                                                        "advlist",
                                                        "autolink",
                                                        "lists",
                                                        "link",
                                                        "image",
                                                        "charmap",
                                                        "preview",
                                                        "anchor",
                                                        "searchreplace",
                                                        "visualblocks",
                                                        "code",
                                                        "fullscreen",
                                                        "insertdatetime",
                                                        "media",
                                                        "table",
                                                        "help",
                                                        "wordcount",
                                                        "spellchecker",
                                                    ],

                                                    toolbar:
                                                        " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help |spellchecker ",
                                                }}
                                            />
                                        </div>
                                        <div className="mb-1 col-md-12 text-end">
                                            <button type="submit" className="btn btn-primary me-2 ">Submit</button>
                                            <Link to="/institute-dashboard/alumni" className="btn btn-danger ">Cancel</Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Institute;