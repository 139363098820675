import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import url from "../../../../constants/url";
import ReactPaginate from "react-paginate";
import { Formik } from "formik";
import { replaceWord } from "../../../../utils/utils";
import SubmitLoader from "../../../common/SubmitLoader";
import Select from "react-select";

const Attendance = () => {
    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage.auth_token;
    var myHeaders = new Headers();
    myHeaders.append("AUTHTOKEN", auth_token);
    myHeaders.append("Content-Type", "application/json");
    const [attendanceLectures, setAttendanceLectures] = useState([]);
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState();
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [LiveBranch, setLiveBranch] = useState([]);
    const [values, setValues] = useState({
        branch: "",
        class_type: ""
    });
    const [filters, setFilters] = useState({
        limit: 10,
        offset: 0,
        list_type: 'time_slot',
        is_main_course: '1',
        class_type: "",
        lecture_type: "",
        branch_id: "",
    });
    const getBranch = async () => {
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
        };

        fetch(url.apiUrl + "institute-branch/get-branches", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    const arr =
                        result.data &&
                        result.data.map((v) => {
                            return { value: v.id, label: v.text }
                        });
                    setLiveBranch(arr);
                }
            })
            .catch((error) => console.log("error", error));
    };
    const getAttendanceLectures = async (activity) => {
        setLoading(true);
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(activity),
        };

        fetch(url.apiUrl + "institute/class-lecture-list", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setAttendanceLectures(result.data);
                    setCount(result.count);
                }
            })
            .catch((error) => console.log("error", error))
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getAttendanceLectures({
            status: "active",
            is_main_course: '1',
            list_type: filters.list_type,
            limit: filters.limit,
            offset: filters.offset,
        });
        getBranch();
    }, []);

    const handlePageClick = async (attendanceLectures) => {
        let currentPage = attendanceLectures.selected * filters.limit;
        setOffset(currentPage);
        setFilters({
            ...filters,
            limit: filters.limit,
            offset: currentPage,
        })
        await getAttendanceLectures({
            status: "active",
            is_main_course: '1',
            list_type: filters.list_type,
            limit: filters.limit,
            offset: currentPage,
            branch_id: filters.branch?.value || undefined,
            class_type: filters.class_type || undefined,
            lecture_type: filters.lecture_type || undefined,
            query: filters.query || undefined
        });
    };
    const [search, setSearch] = useState({
        start: 0,
        perPage: 12,
        state: "",
        city: "",
        query: "",
    });

    const handleSearch = (e) => {
        getAttendanceLectures({
            is_main_course: '1',
            list_type: filters.list_type,
            limit: filters.limit,
            offset: filters.offset,
            query: e,
            branch_id: filters.branch?.value || undefined,
            class_type: filters.lecture_type || undefined,
            status: filters.status || undefined,
        });
    }
    const FormSubmit = async (values) => {
        setFilters({
            ...filters,
            limit: filters.limit,
            offset: 0,
        })
        getAttendanceLectures({
            status: "active",
            is_main_course: '1',
            list_type: filters.list_type,
            limit: filters.limit,
            offset: 0,
            branch_id: filters.branch?.value || undefined,
            class_type: filters.class_type || undefined,
            lecture_type: filters.lecture_type || undefined,
        });

    }
    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Attendance</li>
                </ol>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Attendance Filter</h4>
                        </div>
                        <div className="card-body">
                            <Formik
                                initialValues={values}
                                onSubmit={FormSubmit}
                            >
                                {(props) => (
                                    <form onSubmit={props.handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-6 form-group">
                                                <label>Branch</label>
                                                <Select
                                                    onChange={(value) => {
                                                        props.setFieldValue("branch", value)
                                                        setFilters({ ...filters, branch: value })
                                                    }
                                                    }
                                                    options={LiveBranch}
                                                    value={props.values?.branch}
                                                    name="branch"
                                                />
                                            </div>
                                            <div className="col-md-3 form-group">
                                                <label>Class Type </label>
                                                <select
                                                    className="form-select"
                                                    value={props.values.class_type}
                                                    onChange={(e) => {
                                                        props.setFieldValue(
                                                            "class_type",
                                                            e.target.value
                                                        );
                                                        setFilters({ ...filters, class_type: e.target.value })
                                                    }}
                                                >
                                                    <option value="">Select class type</option>
                                                    <option value="school">School (Till 12th Standard)</option>
                                                    <option value="university">University</option>
                                                    <option value="preparation">Entrance Exam / Professional Courses</option>
                                                    <option value="tuition">Tuition</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-3 col-md-6 form-group">
                                                <label>Classroom Type</label>
                                                <select className="form-select"
                                                    value={props.values.lecture_type}
                                                    onChange={(e) => {
                                                        props.setFieldValue(
                                                            "lecture_type",
                                                            e.target.value
                                                        );
                                                        setFilters({ ...filters, lecture_type: e.target.value })
                                                    }}
                                                >
                                                    <option value="">Select classroom type</option>
                                                    <option value='video_conference'>Online</option>
                                                    <option value='manual'>Offline</option>
                                                    <option value='both'>Hybrid (Online + Offline)</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-3 col-md-6 form-group open-button">
                                                {loader ? (
                                                    <SubmitLoader />
                                                ) :
                                                    (
                                                        <button type="submit" className="btn btn-primary"><i className="fe fe-search" /> Search</button>
                                                    )}
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Attendance List</h4>
                        </div>
                        <div className="card-body">
                            <div id="basic-1_wrapper" className="dataTables_wrapper no-footer" >
                                <div id="basic-1_filter" className="dataTables_filter">
                                    <label>
                                        Search:
                                        <input
                                            type="search"
                                            placeholder="Search..."
                                            onChange={(e) => handleSearch(e.target.value)}
                                        />
                                    </label>
                                </div>
                                <table className="table table-bordered table-hover dataTable table-responsive">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Sr. No.</th>
                                            <th>Class Details</th>
                                            <th>Branch Details</th>
                                            <th className="text-center text-nowrap">Class Type</th>
                                            <th className="text-center text-nowrap">Board/University</th>
                                            <th className="text-center text-nowrap">Section/Semester</th>
                                            <th className="text-center text-nowrap">Classroom Type</th>
                                            <th className="text-center">Medium</th>
                                            <th className="text-center">Overall Past</th>
                                            <th className="text-center">Today Attendance</th>
                                            <th className="text-center">OverAll Past</th>
                                        <th className="text-center">Today</th>
                                        <th className="text-center">Status</th>
                                            <th className="text-center">View Subject</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading ? (
                                            <tr>
                                                <td className='text-center' colSpan={12}>
                                                    Loading..
                                                </td>
                                            </tr>
                                        ) : attendanceLectures.length === 0 ? (
                                            <tr>
                                                <td className='text-center' colSpan={12}>No Data Available !</td>
                                            </tr>
                                        ) : (
                                            attendanceLectures.map((item, index) => (
                                                <tr key={item.id}>
                                                    <th className='text-center text-nowrap'>{index + offset + 1}</th>
                                                    <td>
                                                        <Link to={"/institute-dashboard/class-detail/1/" + item?.id} >
                                                            <div className="media">
                                                                <img src={item?.thumbnail} alt={item?.title} className="me-3 rounded-circle" />
                                                                <div className="media-body">
                                                                    <p className="font-weight-semibold mb-0">{item?.title}</p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <div className='media'>
                                                            <img
                                                                src={item?.institute_branch?.image}
                                                                alt={item?.institute_branch?.name}
                                                                className='me-3 rounded-circle'
                                                            />
                                                            <div className='media-body'>
                                                                <Link to={"/institute-dashboard/branch-detail/" + item?.branch_id}
                                                                    className='font-weight-semibold'
                                                                >
                                                                    {item?.institute_branch?.name}
                                                                </Link>
                                                                <p className='mb-0'>
                                                                    {item?.institute_branch?.email}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='text-center text-capitalize'>
                                                        {replaceWord(item?.class_type)} {item?.class_type == "tuition" ? " : " + (replaceWord(item?.tuition_type)) : ""}
                                                    </td>

                                                    <td className='text-center text-capitalize'>
                                                        {item?.board || item?.universities?.name || '---'}
                                                    </td>
                                                    <td className='text-center text-capitalize'>
                                                        {item?.section || item?.semester || '---'}
                                                    </td>
                                                    <td className="text-center text-capitalize">
                                                        {replaceWord(item?.lecture_type)}
                                                    </td>
                                                    <td className="text-center text-capitalize">{item?.medium || '---'}</td>
                                                    <td className='text-center text-capitalize'>
                                                        {item.marked_time_slot}/{item.time_slot}
                                                    </td>
                                                    <td className="text-center text-capitalize">
                                                        {item.today_marked_time_slot}/{item.today_time_slot}
                                                    </td>
    
                                                <td className='text-center text-capitalize'>
                                                    4/150
                                                </td>
                                                <td className="text-center text-capitalize">
                                                    2/6
                                                </td>
                                                <td className={`text-center text-capitalize ${item?.status === "decline" && " text-danger"}`}>
                                                        {(item?.status === "decline" || item?.status === 'pending') &&
                                                            item?.is_submit == 1
                                                            ? "Submitted"
                                                            : item?.status}  {item?.status === "decline" && `(${item?.reason?.reason})`}
                                                    </td>
                                                    <td className="text-center">
                                                        <Link
                                                            className="btn btn-primary me-2"
                                                            to={`/institute-dashboard/attendance-view-subject/${item?.id}`}
                                                        ><i className="fa fa-eye"></i> View
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                                {attendanceLectures.length > 0 && (
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        pageCount={Math.ceil(count / filters.limit)}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Attendance;
