import React from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

const RevenueFilter = (props) => {
    return (
        <>
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Revenue List</h4>
                    </div>
                    <div className="card-body ">
                        <div id="basic-1_wrapper" className="dataTables_wrapper no-footer">
                            <div id="basic-1_filter" className="dataTables_filter">
                                <label>
                                    Search:
                                    <input
                                        type="search"
                                        placeholder="Search..."
                                        onChange={(e) => {
                                            props.setSearch({
                                                ...props.search,
                                                searchTxt: e.target.value,
                                            });
                                        }}
                                        value={props.search.searchTxt}
                                    />
                                </label>
                            </div>
                            <table className="table table-bordered table-hover dataTable table-responsive-xl">
                                <thead>
                                    <tr>
                                        <th className="text-center">Sr. No.</th>
                                        <th>Branch Details</th>
                                        <th>Course Details</th>
                                        <th className="text-center">Price</th>
                                        <th className="text-center">EM Commission</th>
                                        <th className="text-center">GST (18%)</th>
                                        <th className="text-center">Selling Price <span className="text-danger">*</span></th>
                                        <th className="text-center">Net Revenue <span className="text-danger">#</span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.data.length != 0 ? (
                                        props.data.map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <th className="text-center">{props.offset + i + 1}</th>
                                                    <td>
                                                        <div className="media">
                                                            <img
                                                                src={item?.institute_branch?.image}
                                                                alt="image"
                                                                className="me-3 rounded-circle"
                                                            />
                                                            <div className="media-body">
                                                                <Link to="#" className="font-weight-semibold">
                                                                    {item?.institute_branch.name}
                                                                </Link>
                                                                <p className="mb-0 text-muted">
                                                                    {item?.institute_branch?.email}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {item?.course_type === "online_course" ? (
                                                            <div className="media">
                                                                <img
                                                                    src={item?.online_course?.teaser_thumb_image}
                                                                    alt="image"
                                                                    className="me-3 rounded-circle"
                                                                />
                                                                <div className="media-body">
                                                                    <Link to="#" className="font-weight-semibold">
                                                                        {item?.online_course?.title}
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="media">
                                                                <img
                                                                    src={item?.class_course?.thumbnail}
                                                                    alt="image"
                                                                    className="me-3 rounded-circle"
                                                                />
                                                                <div className="media-body">
                                                                    <Link to="#" className="font-weight-semibold">
                                                                        {item?.class_course?.title}
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        )}

                                                    </td>
                                                    <td className="text-center">₹ {item?.total_price} {item?.discount != 0 && (<del className="fs-14 text-muted">₹{item?.discount}</del>)} </td>
                                                    <td className="text-center">₹ {item?.admin_commission} {item?.commission_type === "percentage" && '(' + item?.commission_amount + '%)'}</td>
                                                    <td className="text-center">₹ {item?.gst || 0}</td>
                                                    <td className="text-center">₹ {item?.total_payable_price}</td>
                                                    <td className="text-center">₹ {item?.total_payable_price - item?.admin_commission}</td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr><td colSpan={8} className="text-center">No Data Available !</td></tr>
                                    )}
                                </tbody>
                                {props.data.length > 0 && (
                                <tfoot>
                                    <tr>
                                        <th colSpan={3} className="font-weight-semibold text-end">Total</th>
                                        <td className="font-weight-semibold text-center">₹ {props.total?.price}</td>
                                        <td className="font-weight-semibold text-center">₹ {props.total?.admin_commission}</td>
                                        <td className="font-weight-semibold text-center">₹ {props.total?.gst}</td>
                                        <td className="font-weight-semibold text-center">₹ {props.total?.selling_price}</td>
                                        <td className="font-weight-semibold text-center">₹ {props.total?.net_price}</td>
                                    </tr>
                                </tfoot>
                                )}
                            </table>
                            {props.data.length > 0 && (
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    breakLabel={"..."}
                                    pageCount={Math.ceil(props.count)}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={props.handlePageClick}
                                    containerClassName={"pagination"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />
                            )}
                        </div>
                        <p className="mt-5 mb-0"><span className="text-danger">Note:*</span> Selling Price = Price + GST (0%)</p>
                        <p className="mb-0"><span className="text-danger">Note:#</span> Net Revenue = Price - EM Commission</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RevenueFilter;
