import React, { useEffect, useState } from "react";
import Headerinner from "../../Header/Headerinner";
import Curve from '../../Header/Curve';
import Footer from "../../Footer/Footer";
import { Link, useParams, useHistory } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Moment from "react-moment";
import Tabs from "react-bootstrap/Tabs";
import url from "../../../../constants/url";
import utils, { calculateExperience, replaceWord } from "../../../../utils/utils";
import ReactPaginate from "react-paginate";
import NoDataAvailable from "../../../common/NoDataAvailable";
let rating = [1, 2, 3, 4, 5];

function TeacherDetail() {
    const history = useHistory();
    const param = useParams();
    let now = new Date().setHours(0, 0, 0, 0);
    const [data, setData] = useState({});
    const [courseData, setCourseData] = useState([]);
    const [classData, setClassData] = useState([]);
    const [totalPage, setTotalPages] = useState(0);
    const [offset, setOffset] = useState(0);
    const [search, setSearch] = useState({
        start: 0,
        perPage: 9,

    });
    const [totalClassPage, setTotalClassPages] = useState(0);
    const [classOffset, setClassOffset] = useState(0);
    const [classSearch, setClassSearch] = useState({
        start: 0,
        perPage: 9,

    });
    const GetTrainnerDetail = async (e) => {
        var requestOptions = {
            method: "GET",
        };

        fetch(`${url.apiUrl}get-teacher-profile/${param.id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setData(result.data);
                } else {
                    history.push('/404');
                }
            })
            .catch((error) => console.log("error", error));
    };
    useEffect(() => {
        GetTrainnerDetail();
    }, []);

    useEffect(() => {
        TeacherCourseList('online_course', search.perPage, search.start);
    }, [search]);
    useEffect(() => {
        TeacherCourseList('class_course', classSearch.perPage, classSearch.start);
    }, [classSearch]);

    const TeacherCourseList = (course_type, limit, offset) => {
        var requestOptions = {
            method: "GET",
        };
        fetch(`${url.apiUrl}teacher-course-list/${param.id}/${course_type}/${limit}/${offset}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    if (course_type == "online_course") {

                        setCourseData(result.data);
                        setTotalPages(result.count / search.perPage);
                    } else if (course_type == "class_course") {
                        setClassData(result.data);
                        setTotalClassPages(result.count / classSearch.perPage);

                    }
                }
            })
            .catch((error) => console.log("error", error));
    }
    const handlePageClick = async (data) => {
        let currentPage = data.selected * search.perPage;
        setOffset(currentPage);
        search.start = currentPage
        TeacherCourseList('online_course', search.perPage, search.start);
    };
    const handleClassClick = async (data) => {
        let currentPage = data.selected * classSearch.perPage;
        setClassOffset(currentPage);
        classSearch.start = currentPage
        TeacherCourseList('class_course', classSearch.perPage, currentPage);
    };

    return (
        <>
            <Headerinner />
            <div
                className="cover-image bg-background-1"
                data-bs-image-src="/assets/images/banners/banner1.webp"
                style={{
                    background: 'url("/assets/images/banners/banner1.webp") center center',
                }}
            >
                <div className="container header-text">
                    <h1>{data?.name}</h1>
                </div>
            </div>
            <Curve />

            <section className="sptb">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="wideget-user-desc">
                                        <div className="item-card7-img">
                                            <img
                                                className="cover-image teacher-student-image"
                                                src={data?.image}
                                                alt={data?.name}
                                            />
                                        </div>
                                        <div className="user-wrap wideget-user-info">
                                            <h4>
                                                {data?.name}
                                            </h4>
                                            <h6 className="team-section badge">
                                                {data?.total_experiance != 0 ? calculateExperience(data?.total_experiance) : "---"} Experience
                                            </h6>
                                            <div className="d-flex justify-content-center">
                                                <div className="br-widget">
                                                    {rating.map((el, i) => (
                                                        <i
                                                            data-rating-value={el}
                                                            data-rating-text={el}
                                                            className={
                                                                el <= data?.average_review
                                                                    ? "br-selected"
                                                                    : ""
                                                            }
                                                            key={i}
                                                        />
                                                    ))}
                                                </div>
                                                <span>( {data?.review_count} Reviews )</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-9 col-lg-8 col-md-12">
                            <div className="card">
                                <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example">
                                    <Tab eventKey="profile" title="Profile">
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">About Teacher</h4>
                                            </div>
                                            <div className="card-body education-mandal" dangerouslySetInnerHTML={{ __html: data?.about ? data?.about : "No Data Available !" }}>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="class" title="Classroom Course">
                                        <div className="row">
                                            {classData?.length > 0 ? (
                                                classData.map((item, i) => (
                                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12" key={i}>
                                                        <Link to={`/classroom-course-detail/${item?.id}`}>
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className="item-card7-img">
                                                                        <img
                                                                            src={item?.thumbnail}
                                                                            className="cover-image"
                                                                            alt={item?.title}
                                                                        />
                                                                        {(
                                                                            item?.price == "paid" && (
                                                                                item?.is_discount == 1 && (
                                                                                    (item?.is_limited_offer == 1 && (new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                                    (item?.is_limited_offer == 0 && new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                                            )
                                                                        ) && (
                                                                                <Link to="#" className="bg-danger top left">
                                                                                    {item?.discount_price}% off
                                                                                </Link>
                                                                            )}
                                                                        <Link to="#" className="bg-danger top right">
                                                                            Classroom Course
                                                                        </Link>
                                                                        {item?.class_type === "school" ? (
                                                                            <Link to="#" className="bg-danger right bottom">
                                                                                {item?.board}
                                                                            </Link>
                                                                        ) : (item?.class_type === "university" ? (
                                                                            <Link to="#" className="bg-danger right bottom">
                                                                                {item?.universities?.name}
                                                                            </Link>
                                                                        ) : (item?.class_type === "tuition" && (
                                                                            <Link to="#" className="bg-danger right bottom">
                                                                                {replaceWord(item?.tuition_type)}
                                                                            </Link>
                                                                        )))}
                                                                    </div>
                                                                    <div className="item-card2-desc">
                                                                        <div className="d-inline-flex mb-2">
                                                                            <div className="br-widget">
                                                                                {rating.map((el, i) => (
                                                                                    <i
                                                                                        data-rating-value={el}
                                                                                        data-rating-text={el}
                                                                                        className={
                                                                                            el <= item?.average_review
                                                                                                ? "br-selected"
                                                                                                : ""
                                                                                        }
                                                                                        key={i}
                                                                                    />
                                                                                ))}
                                                                            </div>
                                                                            <span>({item?.review_count} Reviews)</span>
                                                                        </div>
                                                                        <h4 className="mb-2 leading-tight2">
                                                                            {item?.title}
                                                                        </h4>
                                                                        <p className="leading-tight3 education-mandal" dangerouslySetInnerHTML={{ __html: item?.description }} />

                                                                        <div className="pt-2 pb-2 border-bottom d-flex justify-content-between">
                                                                            <div className="d-flex justify-content-start">
                                                                                <i className="fa fa-shop me-2" />
                                                                                <span className="text-muted text-data-over-cut">
                                                                                    {item?.institute?.name}
                                                                                </span>
                                                                            </div>
                                                                            <div className="float-end d-flex justify-content-start">
                                                                                <i className="fa fa-building me-2" />
                                                                                <span className="text-muted text-capitalize text-data-over-cut">
                                                                                    {replaceWord(item?.class_type)}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="pt-2 d-flex justify-content-between">
                                                                            <div className="d-flex justify-content-start">
                                                                                <i className="fa fa-file me-2" />
                                                                                <span className="text-muted text-data-over-cut">
                                                                                    {replaceWord(item?.lecture_type)}
                                                                                </span>
                                                                            </div>
                                                                            <div className="float-end">
                                                                                {item?.price === "paid" && (
                                                                                    <h4 className="mb-0"> ₹ {Math.round(item?.payable_price)} {(
                                                                                        item?.price == "paid" && (
                                                                                            item?.is_discount == 1 && (
                                                                                                (item?.is_limited_offer == 1 && (new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                                                (item?.is_limited_offer == 0 && new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                                                        )
                                                                                    ) && (<del className="fs-14 text-muted"> ₹ {Math.round(item?.regular_price)} </del>
                                                                                        )}
                                                                                    </h4>
                                                                                )}
                                                                                {item?.price === "free" && (
                                                                                    <h4 className="mb-0">Free</h4>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                ))
                                            ) : (
                                                <NoDataAvailable />
                                            )}

                                            {classData?.length > 0 && (
                                                <ReactPaginate
                                                    previousLabel={"Previous"}
                                                    nextLabel={"Next"}
                                                    breakLabel={"..."}
                                                    pageCount={Math.ceil(totalPage)}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={handleClassClick}
                                                    containerClassName={"pagination"}
                                                    pageClassName={"page-item"}
                                                    pageLinkClassName={"page-link"}
                                                    previousClassName={"page-item"}
                                                    previousLinkClassName={"page-link"}
                                                    nextClassName={"page-item"}
                                                    nextLinkClassName={"page-link"}
                                                    breakClassName={"page-item"}
                                                    breakLinkClassName={"page-link"}
                                                    activeClassName={"active"}
                                                />
                                            )}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="course" title="Video Course">
                                        <div className="row">
                                            {courseData?.length > 0 ? (
                                                courseData.map((item, i) => (
                                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12" key={i} >
                                                        <Link to={`/video-course-detail/${item?.id}`}>
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className="item-card7-img">
                                                                        <img
                                                                            src={item?.teaser_thumb_image}
                                                                            className="cover-image"
                                                                            alt={item?.title}
                                                                        />
                                                                        {(
                                                                            item?.price == "paid" && (
                                                                                item?.is_discount == 1 && (
                                                                                    (item?.is_limited_offer == 1 && (new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                                    (item?.is_limited_offer == 0 && new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                                            )
                                                                        ) && (
                                                                                <Link to="#" className="bg-danger top left">
                                                                                    {item?.discount_price}% off
                                                                                </Link>
                                                                            )}
                                                                        <Link to="#" className="bg-danger right top">
                                                                            Video Course
                                                                        </Link>
                                                                        {item?.is_professional === 0 && (
                                                                            <Link to="#" className="bg-danger right bottom">
                                                                                {item?.board}
                                                                            </Link>
                                                                        )}
                                                                    </div>
                                                                    <div className="item-card2-desc">
                                                                        <div className="d-inline-flex mb-2">
                                                                            <div className="br-widget">
                                                                                {rating.map((el, i) => (
                                                                                    <i
                                                                                        data-rating-value={el}
                                                                                        data-rating-text={el}
                                                                                        className={
                                                                                            el <= item?.average_review
                                                                                                ? "br-selected"
                                                                                                : ""
                                                                                        }
                                                                                        key={i}
                                                                                    />
                                                                                ))}
                                                                            </div>
                                                                            <span>({item?.review_count} Reviews)</span>
                                                                        </div>
                                                                        <h4 className="mb-2 leading-tight2">
                                                                            {item?.title}
                                                                        </h4>
                                                                        <p className="leading-tight3 education-mandal">
                                                                            {item?.short_discription}
                                                                        </p>

                                                                        <div className="pt-2 pb-2 border-bottom d-flex justify-content-between">
                                                                            <div className="d-flex justify-content-start">
                                                                                <i className="fa fa-shop me-2" />
                                                                                <span className="text-muted text-data-over-cut">
                                                                                    {item?.institute?.name}
                                                                                </span>
                                                                            </div>
                                                                            <div className="float-end">
                                                                                <i className="fa fa-signal me-2" />
                                                                                <span className="text-muted text-capitalize">
                                                                                    {item?.course_level}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="pt-2 pb-2 border-bottom">
                                                                            <i className="fa fa-file me-2" />
                                                                            <span className="text-muted">
                                                                                {item?.lectures_count} Videos
                                                                            </span>
                                                                            <div className="float-end ">
                                                                                <i className="fa fa-clock-o me-2" />
                                                                                <span className="text-muted">
                                                                                    {item?.total_duretion ? utils.toHHMMSS(item?.total_duretion) : 0} Hours
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="pt-2 d-flex justify-content-between">
                                                                            <div className="d-flex justify-content-start">
                                                                                <i className="fa fa-microphone me-2" />
                                                                                <span className="text-muted text-data-over-cut">
                                                                                    {item?.audio_language}
                                                                                </span>
                                                                            </div>
                                                                            <div className="float-end">
                                                                                {item?.price === "paid" && (
                                                                                    <h4 className="mb-0">
                                                                                        ₹ {Math.round(item?.payable_price)} {(
                                                                                            item?.price == "paid" && (
                                                                                                item?.is_discount == 1 && (
                                                                                                    (item?.is_limited_offer == 1 && (new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                                                    (item?.is_limited_offer == 0 && new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                                                            )
                                                                                        ) && (
                                                                                                <del className="fs-14 text-muted">
                                                                                                    ₹{Math.round(item?.regular_price)}
                                                                                                </del>
                                                                                            )}
                                                                                    </h4>
                                                                                )}
                                                                                {item?.price === "free" && (
                                                                                    <h4 className="mb-0">Free</h4>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                ))
                                            ) : (
                                                <NoDataAvailable />
                                            )}
                                            {courseData?.length > 0 && (
                                                <ReactPaginate
                                                    previousLabel={"Previous"}
                                                    nextLabel={"Next"}
                                                    breakLabel={"..."}
                                                    pageCount={Math.ceil(totalPage)}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={handlePageClick}
                                                    containerClassName={"pagination"}
                                                    pageClassName={"page-item"}
                                                    pageLinkClassName={"page-link"}
                                                    previousClassName={"page-item"}
                                                    previousLinkClassName={"page-link"}
                                                    nextClassName={"page-item"}
                                                    nextLinkClassName={"page-link"}
                                                    breakClassName={"page-item"}
                                                    breakLinkClassName={"page-link"}
                                                    activeClassName={"active"}
                                                />
                                            )}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="education" title="education">
                                        {data?.education?.length > 0 ? (
                                            data?.education.map((item, i) => (
                                                <div className="card" key={i}>
                                                    <div className="card-body">
                                                        <ul className="d-flex mb-0">
                                                            <li>
                                                                <div className="activity1 bg-primary">
                                                                    <i className="fe fe-shopping-bag"></i>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="font-weight-semibold">
                                                                    {item?.degree} - ( {item?.field_of_study} )
                                                                </div>
                                                                <p className="text-muted mb-0">
                                                                    {item?.school_college}
                                                                </p>
                                                            </li>
                                                            <div className="text-muted ms-auto">
                                                                <Moment format="DD-MM-YYYY">
                                                                    {item?.start_date}
                                                                </Moment> to {item?.is_present === 1 ? (
                                                                    "Present"
                                                                ) : (
                                                                    <Moment format="DD-MM-YYYY">
                                                                        {item?.end_date}
                                                                    </Moment>
                                                                )}
                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <NoDataAvailable />
                                        )}
                                    </Tab>
                                    <Tab eventKey="experience" title="experience">
                                        {data?.experience?.length > 0 ? (
                                            data?.experience.map((item, i) => (
                                                <div className="card" key={i}>
                                                    <div className="card-body">
                                                        <ul className="d-flex mb-0">
                                                            <li>
                                                                <div className="activity1 bg-primary">
                                                                    <i className="fe fe-shopping-bag"></i>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="font-weight-semibold">
                                                                    {item?.title} - ( {item?.employement_type === "full_time" ? "Full Time" : "Part Time"} )
                                                                </div>
                                                                <p className="text-muted mb-0">
                                                                    {item?.company_name}
                                                                </p>
                                                                <p className="text-muted mb-2">
                                                                    {item?.location}
                                                                </p>
                                                            </li>
                                                            <div className="text-muted ms-auto">
                                                                <Moment format="DD-MM-YYYY">
                                                                    {item?.start_date}
                                                                </Moment> to {item?.is_present === 1 ? (
                                                                    "Present"
                                                                ) : (
                                                                    <Moment format="DD-MM-YYYY">
                                                                        {item?.end_date}
                                                                    </Moment>
                                                                )}
                                                            </div>
                                                        </ul>
                                                        <p className="text-muted mb-0 margin-left education-mandal" dangerouslySetInnerHTML={{ __html: item?.description }}>
                                                        </p>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <NoDataAvailable />
                                        )}
                                    </Tab>
                                    <Tab eventKey="achievement" title="achievement">
                                        {data?.achievement?.length > 0 ? (
                                            data?.achievement.map((item, i) => (
                                                <div className="card" key={i}>
                                                    <div className="card-body">
                                                        <ul className="d-flex mb-0">
                                                            <li>
                                                                <div className="activity1 bg-primary">
                                                                    <i className="fe fe-shopping-bag"></i>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="font-weight-semibold">
                                                                    {item?.title}
                                                                </div>
                                                            </li>
                                                            <div className="text-muted ms-auto">
                                                                <Moment format="DD-MM-YYYY">
                                                                    {item?.date}
                                                                </Moment>
                                                            </div>
                                                        </ul>
                                                        <p className="text-muted mb-0 margin-left1 education-mandal" dangerouslySetInnerHTML={{ __html: item?.description }}>
                                                        </p>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <NoDataAvailable />
                                        )}
                                    </Tab>
                                    <Tab eventKey="reviews" title="reviews">
                                        {data?.course_review?.length > 0 ? (
                                            data?.course_review.map((item, i) => (
                                                <div
                                                    key={i}
                                                    className="media mt-0 mb-3 p-3 border br-5 review-media"
                                                >
                                                    <div className="d-flex me-3">
                                                        <Link to="#">
                                                            <img
                                                                className="border-radius-50 avatar-lg"
                                                                alt={item?.name}
                                                                src={item?.student?.image}
                                                            />
                                                        </Link>
                                                    </div>
                                                    <div className="media-body">
                                                        <h4 className="mt-0 mb-1 font-weight-semibold">
                                                            {item?.name}
                                                            <small className="text-muted float-end">
                                                                <Moment format="DD-MM-YYYY hh:mm A">
                                                                    {item?.created_at}
                                                                </Moment>
                                                            </small>
                                                        </h4>
                                                        <div className="d-flex justify-content-start">
                                                            <div className="br-widget">
                                                                {rating.map((el, i) => (
                                                                    <i
                                                                        data-rating-value={el}
                                                                        data-rating-text={el}
                                                                        className={
                                                                            el <= item?.average_review
                                                                                ? "br-selected"
                                                                                : ""
                                                                        }
                                                                        key={i}
                                                                    />
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <p className="mb-2 mt-2 education-mandal" dangerouslySetInnerHTML={{ __html: item?.comment }}>
                                                        </p>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <NoDataAvailable />
                                        )}
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}

export default TeacherDetail;
