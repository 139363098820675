import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Moment from "react-moment";
import PopularBlogs from "../BlogDetails/PopularBlogs";
// import { ShareSocial } from "react-share-social";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useWindowSize from "../../../../../hooks/useWindowSize";
import Curve from '../../../Header/Curve';
import BlogCategory from "./BlogCategory";
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    WhatsappIcon,
} from 'react-share';

const BlogDetailsshow = ({ getBlogDetailsData, data }) => {
    const { width: windowWidth } = useWindowSize();
    const history = useHistory();
    const param = useParams();
    const [like, setLike] = useState();
    const [active, setActive] = useState(false);
    const auth_info = localStorage.getItem("common-user-info");
    const auth_json = JSON.parse(auth_info);
    const auth_token = auth_json?.auth_token;

    const shareUrl = window.location.href; // You can also use any specific URL here
    const title = 'Check out this blog post!';
    const getlikedBlogs = async () => {
        let obj = {
            blog_id: data?.id,
        };

        const config = {
            headers: {
                content: "application/json",
            },
        };
        try {
            const response = await axios.post(
                "https://educationmandal.com/em-admin/api/" + `blog-like-unlike`,
                obj,
                config
            );
            if (response?.data?.status === "success") {
                toast.success(response.data?.message)
                getBlogDetailsData(param.id);
            } else {
                toast.error(response.data?.message);
            }
        } catch (err) {
            throw err;
        }
    };

    const style = {
        copyContainer: {
            display: "none",
        },
        root: {
            border: 0,
            padding: 0,
        },
        title: {
            color: "aquamarine",
            fontStyle: "italic",
        },
    };
    return (
        <>
            <div
                className="cover-image bg-background-1"
                data-bs-image-src="/assets/images/banners/banner1.webp"
                style={{
                    background: 'url("/assets/images/banners/banner1.webp") center center',
                }}
            >
                <div className="container header-text">
                    <h1>{data?.name}</h1>
                </div>
            </div>
            <Curve />
            <section className="sptb">
                <div className="container">
                    <div className="row">
                        {windowWidth >= 768 && (
                            <div className="col-xl-3 col-lg-4 col-md-12">
                                <BlogCategory />
                                <PopularBlogs param={param.id} />
                            </div>

                        )}
                        <div className="col-xl-9 col-lg-8 col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-12">
                                            <div className="item-card7-img">
                                                <img
                                                    src={data?.thumb_image}
                                                    alt={data?.name}
                                                    className="cover-image"
                                                />
                                                <div className="item-card-text-bottom">
                                                    {data?.category?.name}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12 mt-5 mt-xl-0">
                                            <div className="post-bottom-excerpt">
                                                <ul className="list-unstyled">
                                                    <li className="post-author">
                                                        <img
                                                            src={data?.user_profile}
                                                            className="avatar border-radius-50 avatar-lg me-3"
                                                            alt={data?.user_name}
                                                        />
                                                        <span className="font-weight-semibold">{data?.user_name}</span>
                                                    </li>
                                                    <li className="post-tags">
                                                        <span className="fe fe-calendar text-primary"></span>
                                                        <Link to="#">
                                                            <Moment format="DD-MM-YYYY hh:mm:A">{data?.updated_at}</Moment>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="card">
                                                <div className="card-header">
                                                    <h4 className="card-title">Like and Share</h4>
                                                </div>
                                                <div className="card-body pt-0 pb-0 d-flex justify-content-between align-items-center my-2">
                                                    <FacebookShareButton url={shareUrl} quote={title}>
                                                        <FacebookIcon size={32} round />
                                                    </FacebookShareButton>
                                                    <TwitterShareButton url={shareUrl} title={title}>
                                                        <TwitterIcon size={32} round />
                                                    </TwitterShareButton>

                                                    <LinkedinShareButton url={shareUrl} title={title}>
                                                        <LinkedinIcon size={32} round />
                                                    </LinkedinShareButton>

                                                    <WhatsappShareButton url={shareUrl} title={title}>
                                                        <WhatsappIcon size={32} round />
                                                    </WhatsappShareButton>

                                                    {/* <ShareSocial
                                                            url={window.location.href}
                                                            socialTypes={[
                                                                "facebook",
                                                                "whatsapp",
                                                                "linkedin",
                                                            ]}
                                                            style={style}
                                                        /> */}
                                                    <ul className="mb-0">
                                                        <li
                                                            className="post-likes text-primary cursor-pointer"
                                                            onClick={() => {
                                                                setActive(!active);
                                                                getlikedBlogs();
                                                            }}
                                                        >
                                                            <span className="fa-regular fa-thumbs-up"></span> {data?.like_count} Likes
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <p className="education-mandal"
                                        dangerouslySetInnerHTML={{ __html: data?.description }}
                                    ></p>
                                </div>
                            </div>
                        </div>
                        {windowWidth <= 768 && (
                            <div className="col-xl-4 col-lg-4 col-md-12">
                                <BlogCategory />
                                <PopularBlogs param={param.id} />
                            </div>
                        )}
                    </div>
                </div>
                <ToastContainer />
            </section>
        </>
    );
};

export default BlogDetailsshow;
