import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Link } from "react-router-dom";
import url from "../../../../constants/url";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { replaceWord } from "../../../../utils/utils";

const Course = () => {
    const [totalPages, setTotalPages] = useState(0);
    const [search, setSearch] = useState({
        start: 0,
        perPage: 10,
        searchTxt: "",
        searchField: "",
    });
    const [classsearch, setClassSearch] = useState({
        start: 0,
        perPage: 10,
        searchTxt: "",
        searchField: "",
    });
    const [courseCount, setCourseCount] = useState();
    const [classcourseCount, setClassCourseCount] = useState();
    const [offset, setOffset] = useState(0);
    const [classoffset, setClassOffset] = useState(0);
    const [data, setCourseData] = useState([]);
    const [classData, setclassData] = useState([]);
    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage.auth_token;

    const apiCall = async (activity) => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        const config = {
            headers: {
                content: "application/json",
                AUTHTOKEN: auth_token,
            },
        };
        try {
            const response = await axios.post(
                `${url.apiUrl}student/online-course`,
                activity,
                config
            );
            if (response) {
                setCourseData(response.data?.data);
                setCourseCount(response.data?.count);
                setTotalPages(response.data?.count / search.perPage);
            }
        } catch (err) {
            throw err;
        }
    };
    const getAllCourseData = async () => {
        const activity = {
            status: "all",
            limit: search.perPage,
            offset: search.start,
            query: search.searchTxt,
        };

        await apiCall(activity);
    };
    const handlePageClick = async (data) => {
        let currentPage = data.selected * search.perPage;
        setOffset(currentPage);
        const activity = {
            status: "all",
            limit: search.perPage,
            offset: currentPage,
            query: search.searchTxt,
        };
        await apiCall(activity);
    };
    const getAllClassData = async () => {
        const activity = {
            status: "all",
            limit: classsearch.perPage,
            offset: classsearch.start,
            query: classsearch.searchTxt,
        };

        await apiCallForClass(activity);
    };

    const apiCallForClass = async (activity) => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        const config = {
            headers: {
                content: "application/json",
                AUTHTOKEN: auth_token,
            },
        };
        try {
            const response = await axios.post(
                `${url.apiUrl}student/class-course`,
                activity,
                config
            );
            if (response) {
                setclassData(response.data?.data);
                setClassCourseCount(response.data?.count / classsearch.perPage);
            }
        } catch (err) {
            throw err;
        }
    };
    const handlePageClickForClass = async (data) => {
        let currentPage = data.selected * classsearch.perPage;
        setClassOffset(currentPage);
        const activity = {
            status: "all",
            limit: classsearch.perPage,
            offset: currentPage,
            query: classsearch.searchTxt,
        };
        await apiCallForClass(activity);
    };

    useEffect(() => {
        getAllClassData();
        getAllCourseData();
    }, []);
    return (
        <>
            <div className="col-12">
                <div className="card">
                    <Tabs defaultActiveKey="classroom" id="uncontrolled-tab-example">
                        <Tab eventKey="classroom" title="Classroom Course">
                            <div
                                id="basic-1_wrapper"
                                className="dataTables_wrapper no-footer"
                            >
                                <div id="basic-1_filter" className="dataTables_filter">
                                    <label>
                                        Search:
                                        <input
                                            type="search"
                                            placeholder="Search..."
                                            onChange={(e) => {
                                                setClassSearch({
                                                    ...classsearch,
                                                    searchTxt: e.target.value,
                                                });
                                            }}
                                        />
                                    </label>
                                </div>
                                <table className="table table-bordered dataTable table-responsive">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Sr. No.</th>
                                            <th>Class Details</th>
                                            <th>Institute Details</th>
                                            <th className="text-center">Class Type</th>
                                            <th className="text-center">BOARD/UNIVERSITY</th>
                                            <th className="text-center">SECTION/SEMESTER</th>
                                            <th className="text-center">Classroom Type</th>
                                            <th className="text-center">Join Class</th>
                                            <th className="text-center">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {classData?.length > 0
                                            ? classData?.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <th className="text-center">{i + classoffset + 1}</th>

                                                        <td className="text-capitalize">
                                                            <div className="media">
                                                                <img
                                                                    src={item?.thumbnail}
                                                                    className="me-3 rounded-circle"
                                                                    alt={item?.class_name}
                                                                />
                                                                <div className="media-body">
                                                                    <Link to={`/student-dashboard/class-detail/${item?.id}`} className="font-weight-semibold" >
                                                                        {item?.class_name}
                                                                    </Link>
                                                                    <p className="mb-0 text-muted">
                                                                        {item?.institute?.email}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="text-capitalize">
                                                            <div className="media">
                                                                <img
                                                                    src={item?.institute?.image}
                                                                    className="me-3 rounded-circle"
                                                                    alt={item?.institute?.name}
                                                                />
                                                                <div className="media-body">
                                                                    <Link to={`/institute-detail/${item?.institute?.institute_id}`} className="font-weight-semibold" >
                                                                        {item?.institute?.name}
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="text-center text-capitalize">{replaceWord(item?.class_type)} {item?.class_type == "tuition" ? " : " + (replaceWord(item?.tuition_type)) : ""}</td>
                                                        <td className="text-center text-capitalize">{item?.board || item?.universities?.name || '---'}</td>
                                                        <td className="text-center text-capitalize">{item?.section || item?.semester || '---'}</td>
                                                        <td className="text-center text-capitalize">{replaceWord(item?.lecture_type)}</td>
                                                        <td className="text-center">
                                                            {item?.video_conference_link ? (
                                                                <a href={item?.video_conference_link} className="btn btn-primary" target="_blank" rel="noreferrer">
                                                                    Join Now
                                                                </a>
                                                            ) : ("---")}
                                                        </td>
                                                        <td className="text-center text-capitalize">{item?.status}</td>
                                                    </tr>
                                                );
                                            })
                                            : <tr>
                                                <td className="text-center" colSpan={9}>No Data Available !</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                                {classData?.length > 0 && (
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        pageCount={classcourseCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClickForClass}
                                        containerClassName={"pagination"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                )}
                            </div>
                        </Tab>
                        <Tab eventKey="video" title="Video Course">
                            <div
                                id="basic-1_wrapper"
                                className="dataTables_wrapper no-footer"
                            >
                                <div id="basic-1_filter" className="dataTables_filter">
                                    <label>
                                        Search:
                                        <input
                                            type="search"
                                            placeholder="Search..."
                                            onChange={(e) => {
                                                setSearch({
                                                    ...search,
                                                    searchTxt: e.target.value,
                                                });
                                            }}
                                            value={search.searchTxt}
                                        />
                                    </label>
                                </div>
                                <table className="table table-bordered dataTable table-responsive-xl">
                                    <thead>
                                        <tr>
                                            <th className="text-center text-nowrap">Sr. No</th>
                                            <th>Course Details</th>
                                            <th className="text-center">Course Category</th>
                                            <th>Institute Details</th>
                                            <th>Teacher Details</th>
                                            <th className="text-center">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.length > 0 ?
                                            data.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td className="text-center">{i + offset + 1}</td>
                                                        <td>
                                                            <div className="media">
                                                                <img
                                                                    src={item?.teaser_thumb_image}
                                                                    className="me-3 rounded-circle"
                                                                    alt={item?.title}
                                                                />
                                                                <div className="media-body">
                                                                    <Link to={`/student-dashboard/video-course-detail/${item?.id}`} className="font-weight-semibold" >
                                                                        {item?.title}
                                                                    </Link>
                                                                    <p className="mb-0 text-muted">
                                                                        {item?.institute?.email}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="text-center">
                                                            {item?.category?.title}
                                                        </td>
                                                        <td>
                                                            <div className="media">
                                                                <img
                                                                    src={item?.institute?.image}
                                                                    className="me-3 rounded-circle"

                                                                    alt={item?.institute?.name}
                                                                />
                                                                <div className="media-body">
                                                                    <Link to={`/institute-detail/${item?.institute?.institute_id}`} className="font-weight-semibold" >
                                                                        {item?.institute?.name}
                                                                    </Link>
                                                                    <p className="mb-0 text-muted">
                                                                        {item?.institute_branch?.email}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div className="media">
                                                                <img
                                                                    src={item?.teacher?.image}
                                                                    className="me-3 rounded-circle"
                                                                    alt={item?.teacher?.name}
                                                                />
                                                                <div className="media-body">
                                                                    <Link to={`/teacher-detail/${item?.teacher?.teacher_id}`} className="font-weight-semibold">
                                                                        {item?.teacher?.name}
                                                                    </Link>
                                                                    <p className="mb-0 text-muted">
                                                                        {item?.teacher?.email}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="text-capitalize text-center">
                                                            {item?.status}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                            : <tr>
                                                <td className="text-center" colSpan={9}>No Data Available !</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                                {data?.length > 0 && (
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        pageCount={Math.ceil(totalPages)}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                )}
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </>
    );
};

export default Course;
