import React from 'react';
import Slider from 'react-slick';

const Testimonial = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    dots: true,
                },
            },
        ],
    };

    return (
        <>
            <section className='testimonial-section'>
                <h2 className="faq-header" style={{marginBottom:'0'}}>Testimonial</h2>
                <p className='text-center text-primary' style={{marginTop:'-7px'}}>_____________________</p>
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <Slider {...settings}>
                            <div className="col-md-12" >
                                <div id="testimonial-slider">
                                    <div className="testimonial text-center">
                                        <div className="pic">
                                            <img
                                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4YMKaEEchODbdH3toCa6Fxpi_MyrYEfn96g&s"
                                                alt="testimonial"
                                                style={{
                                                    width: '100%',
                                                    borderRadius: '50%',
                                                    objectFit: 'cover',
                                                }}
                                            />
                                        </div>
                                        <div className="testimonial-content mt-3">
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet eum excepturi incidunt
                                                laudantium nesciunt omnis, provident repudiandae rerum sed! Amet blanditiis eaque eu!
                                            </p>
                                        </div>
                                        <h3 className="testimonial-title mt-2">
                                            <a href="#">Kelli</a>
                                            <small>- Web Developer</small>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div id="testimonial-slider">
                                    <div className="testimonial text-center">
                                        <div className="pic">
                                            <img
                                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4YMKaEEchODbdH3toCa6Fxpi_MyrYEfn96g&s"
                                                alt="testimonial"
                                                style={{
                                                    width: '100%',
                                                    borderRadius: '50%',
                                                    objectFit: 'cover',
                                                }}
                                            />
                                        </div>
                                        <div className="testimonial-content mt-3">
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet eum excepturi incidunt
                                                laudantium nesciunt omnis, provident repudiandae rerum sed! Amet blanditiis eaque eu!
                                            </p>
                                        </div>
                                        <h3 className="testimonial-title mt-2">
                                            <a href="#">Kelli</a>
                                            <small>- Web Developer</small>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" >
                                <div id="testimonial-slider">
                                    <div className="testimonial text-center">
                                        <div className="pic">
                                            <img
                                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4YMKaEEchODbdH3toCa6Fxpi_MyrYEfn96g&s"
                                                alt="testimonial"
                                                style={{
                                                    width: '100%',
                                                    borderRadius: '50%',
                                                    objectFit: 'cover',
                                                }}
                                            />
                                        </div>
                                        <div className="testimonial-content mt-3">
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet eum excepturi incidunt
                                                laudantium nesciunt omnis, provident repudiandae rerum sed! Amet blanditiis eaque eu!
                                            </p>
                                        </div>
                                        <h3 className="testimonial-title mt-2">
                                            <a href="#">Kelli</a>
                                            <small>- Web Developer</small>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Testimonial;
