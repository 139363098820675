import React, { useEffect, useState } from "react";
import url from "../../../../../src/constants/url";
import { Row, Form, Col, Button } from "react-bootstrap";

const CourseFilter = ({
    setBranch,
    setStatus,
    setTeacherId, getAllCourseData
}) => {
    const [branchData, setBranchdata] = useState([]);
    const [teacherData, setTeacherdata] = useState([]);
    var localdata = localStorage.getItem("common-user-info");
    var jsondata = JSON.parse(localdata);
    var token_authorization = jsondata.auth_token;
    useEffect(() => {
        getBranchData();
    }, []);
    const getTeacherData = async (id) => {
        let result = await fetch(
            `${url.apiUrl}institute-teacher/get-teachers/${id}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    AUTHTOKEN: token_authorization,
                },
            }
        );
        if (result?.status === 200) {
            let varResult = await result.json();

            if (varResult?.status == "success") {
                setTeacherdata(varResult?.data);
            }
        }
    };
    const getBranchData = async () => {
        let result = await fetch(`${url.apiUrl}institute-branch/get-branches`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                AUTHTOKEN: token_authorization,
            },
        });
        if (result?.status === 200) {
            let varResult = await result.json();
            if (varResult?.status == "success") {
                setBranchdata(varResult?.data);
            }
        }
    };
    return (
        <>
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Video Course Approvel Filter</h4>
                    </div>
                    <div className="card-body">
                        <form>
                            <Row>
                                <Col md={3} className="mb-3">
                                    <Form.Group>
                                        <Form.Label>Branch</Form.Label>
                                        <Form.Select
                                            aria-label="Default select example"
                                            onChange={(e) => {
                                                setBranch(e.target.value);
                                                getTeacherData(e.target.value);
                                            }}
                                        >
                                            <option selected="false" disabled>
                                                Select branch
                                            </option>
                                            {branchData &&
                                                branchData.map((v, i) => (
                                                    <option value={v.id} key={i}>
                                                        {v.text}
                                                    </option>
                                                ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={3} className="mb-3">
                                    <Form.Group>
                                        <Form.Label>Teacher</Form.Label>
                                        <Form.Select
                                            aria-label="Default select example"
                                            onChange={(e) => setTeacherId(e.target.value)}
                                        >
                                            <option selected="false" disabled>
                                                Select teacher
                                            </option>
                                            {teacherData &&
                                                teacherData.map((v, i) => (
                                                    <option value={v.id} key={i}>
                                                        {v.name}
                                                    </option>
                                                ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={3} className="open-button">
                                    <Button
                                        variant="primary"
                                        className="me-3"
                                        onClick={() => {
                                            getAllCourseData();
                                        }}
                                    > <i className="fe fe-search"></i> Search
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CourseFilter;
