import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import url from "../../../../constants/url";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import ReactPaginate from "react-paginate";
import axios from "axios";
import ApproveCoursePopup from "./ApproveCoursePopup";
import { ToastContainer } from "react-toastify";

const CourseUpdateList = () => {
    const [courseId, setCourseId] = useState(0);
    const [aboutForm, setAboutForm] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [search, setSearch] = useState({
        status: 'all',
        start: 0,
        perPage: 10,
        searchTxt: "",
        searchField: "",
        is_updated: 1
    });
    const [data, setCourseData] = useState([]);
    const [offset, setOffset] = useState(0);
    const apiCall = async (activity) => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        const config = {
            headers: {
                content: "application/json",
                AUTHTOKEN: auth_token,
            },
        };
        try {
            const response = await axios.post(
                `${url.apiUrl}teacher-course/get-all-online-courses`,

                activity,
                config
            );
            if (response) {
                setCourseData(response.data?.data);
                setTotalPages(response.data?.count / 10);
            }
        } catch (err) {
            throw err;
        }
    };
    const handlePageClick = async (data) => {
        let currentPage = data.selected * search.perPage;
        setOffset(currentPage);
        const activity = {
            status: search.status,
            limit: search.perPage,
            offset: currentPage,
            query: search.searchTxt,
            is_updated: search.is_updated
        };
        await apiCall(activity);
    };
    const getAllCourseData = async () => {
        const activity = {
            status: search.status,
            limit: search.perPage,
            offset: search.start,
            query: search.searchTxt,
            is_updated: search.is_updated
        };

        await apiCall(activity);
    };

    useEffect(() => {
        getAllCourseData();
    }, [search]);

    const aboutHandler = (id) => {
        setCourseId(id);
        setAboutForm(true);
    };
    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/teacher-dashboard">Dashboard</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Updated Video Course List</li>
                </ol>
            </div>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Updated Video Course List</h4>
                </div>
                <div className="card-body">
                    <div
                        id="basic-1_wrapper"
                        className="dataTables_wrapper no-footer"
                    >
                        <div id="basic-1_filter" className="dataTables_filter">
                            <label>
                                Search:
                                <input
                                    type="search"

                                    placeholder="Search..."
                                    onChange={(e) => {
                                        setSearch({
                                            ...search,
                                            searchTxt: e.target.value,
                                        });
                                    }}
                                    value={search.searchTxt}
                                />
                            </label>
                        </div>
                        <table className="table table-bordered table-hover dataTable table-responsive-xl">
                            <thead>
                                <tr>
                                    <th className='text-center'>Sr. No.</th>
                                    <th>Course Details</th>
                                    <th className='text-center'>Course Category</th>
                                    <th>Branch Detail</th>
                                    <th className='text-center'>Students</th>
                                    <th className='text-center'>Institute Status</th>
                                    <th className='text-center'>EM Status</th>
                                    <th className='text-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.length > 0 ? (
                                    data.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className='text-center'>
                                                    {i + offset + 1}
                                                </td>
                                                <td>
                                                    <div className="media">
                                                        <img
                                                            src={item?.teaser_thumb_image}
                                                            alt={item?.title}
                                                            className="me-3 rounded-circle"
                                                        />
                                                        <div className="media-body">
                                                            <Link className="font-weight-semibold" to={"/teacher-dashboard/video-course-detail/1/" + item?.id}>
                                                                {item?.title}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='text-capitalize text-center'>
                                                    {item?.category?.title}
                                                </td>
                                                <td>
                                                    <div className="media">
                                                        <img
                                                            src={item?.institute_branch?.image}
                                                            alt={item?.institute_branch?.name}
                                                            className="me-3 rounded-circle"
                                                        />
                                                        <div className="media-body">
                                                            <Link className="font-weight-semibold"
                                                                to={`/teacher-dashboard/branch-detail/${item?.id}`}
                                                            >
                                                                {item?.institute_branch?.name}
                                                            </Link>
                                                            <p className="mb-0">
                                                                {item?.institute_branch?.email}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='text-capitalize text-center'>
                                                    <Link
                                                        className='btn btn-primary btn-sm'
                                                        to={`/teacher-dashboard/student-course-class/${item?.type}/${item?.id}`}
                                                    >
                                                        {item?.student_count}
                                                    </Link>
                                                </td>
                                                <td className='text-capitalize text-center'>
                                                    {item?.institute_status}
                                                </td>
                                                <td className='text-capitalize text-center'>
                                                    {item?.status}
                                                </td>
                                                <td className='text-center'>
                                                    {(item?.institute_status === "pending" || (item?.institute_status === "active" && item?.status === "pending")) &&
                                                        item?.is_submit == 1 ? (
                                                        <Link
                                                            to="#"
                                                            disabled
                                                            className="btn btn-outline-grey btn-sm me-2"
                                                            onClick={() => {
                                                                alert(
                                                                    "You can't edit this video course, since your video course is pending for approval from our administrator. Thank you for your patience."
                                                                );
                                                            }}
                                                        >
                                                            <i className="fa fa-pen-to-square fs-16"></i>
                                                        </Link>
                                                    ) : (
                                                        item?.is_update == 1 &&
                                                            item?.is_submit == 1 ? (
                                                            <Link
                                                                to="#"
                                                                disabled
                                                                className="btn btn-primary btn-sm me-2"
                                                                onClick={() => {
                                                                    alert(
                                                                        "You can't update the course until it gets approved !"
                                                                    );
                                                                }}
                                                            >
                                                                <i className="fa fa-pen-to-square fs-16"></i>
                                                            </Link>
                                                        ) : (

                                                            <Link
                                                                className='btn btn-primary btn-sm me-2'
                                                                to={
                                                                    "/teacher-dashboard/video-course-edit/0/" +
                                                                    item?.id
                                                                } title="Change Status"
                                                            >
                                                                <i className='fa fa-pen-to-square fs-16'></i>
                                                            </Link>
                                                        )
                                                    )}
                                                    {((item?.institute_status === "decline" || item?.status === "decline") && item?.is_update !== 1) ? (
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary btn-sm me-2"
                                                            onClick={() => {
                                                                alert(
                                                                    "First update your course"
                                                                );
                                                            }}
                                                        >
                                                            <i className='fa fa-eye fs-16'></i>
                                                        </button>

                                                    ) : (
                                                        <button  title="Change Status"
                                                            type="button"
                                                            className="btn btn-primary btn-sm"
                                                            onClick={() => aboutHandler(item?.id)}
                                                        >
                                                            <i className='fa fa-eye fs-16'></i>
                                                        </button>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td className='text-center' colSpan={8}> No Data Available ! </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        {data?.length > 0 && (
                            <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                pageCount={Math.ceil(totalPages)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        )}
                    </div>
                </div>
            </div>
            <ToastContainer />
            <ApproveCoursePopup getAllCourseData={getAllCourseData} setAboutForm={setAboutForm} aboutForm={aboutForm} courseId={courseId} setCourseId={setCourseId} isMain={0} />

        </>
    );
};

export default CourseUpdateList;
