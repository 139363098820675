import React from 'react';
import Moment from "react-moment";
import { calculateExperience } from "../../../../../utils/utils";

const StudentProfile = ({ student }) => {
    return (
        <>
            <section className="app-user-view">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Student Profile</h4>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-12">
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-12">
                                                <div className="item-card7-img">
                                                    <img className="cover-image" src={student?.image} alt={student?.name} />
                                                </div>
                                            </div>
                                            <div className="col-xl-9 col-lg-12">
                                                <h4>{student?.name}</h4>
                                                <p className="card-text mb-2">{student?.email}</p>
                                                <p className="card-text mb-2">{student?.contact}</p>
                                                <p className="card-text mb-2">
                                                    <i className="fa-solid fa-location-dot me-2"></i>
                                                    {student?.address ? student?.address : "---"}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-12 mt-5 mt-xl-0">
                                        <table className="user-details table mb-0">
                                            <tbody>
                                                <tr>
                                                    <td className="py-1 px-0 font-weight-semibold w-50">
                                                    Username / Referral Code
                                                    </td>
                                                    <td className="py-1 px-0 text-capitalize">{student?.user_name}</td>
                                                </tr>
                                                <tr>
                                                    <td className="py-1 px-0 font-weight-semibold w-50">
                                                        Gender
                                                    </td>
                                                    <td className="py-1 px-0 text-capitalize">{student?.gender ? student?.gender : "---"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="py-1 px-0 font-weight-semibold w-50">
                                                        Experience
                                                    </td>
                                                    <td className="py-1 px-0">{student?.total_experience !== 0 ? calculateExperience(student?.total_experience) : "---"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="py-1 px-0 font-weight-semibold w-50">
                                                        Last Update
                                                    </td>
                                                    <td className="py-1 px-0 text-capitalize"><Moment format="DD-MM-YYYY hh:mm A">{student?.updated_at}</Moment></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default StudentProfile;