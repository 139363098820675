import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TeacherProfile from './TeacherProfile';
import TeacherDetails from './TeacherDetails';
import { getTeacherGetProfile } from '../../../../apis'

const Profile = () => {

    const [tearchdata, setTeacherdata] = useState({})
    const getTeacherGetProfiles = async () => {
        let res = await getTeacherGetProfile()
        if (res.status === 'success') {
            setTeacherdata(res.data)
        }

    }
    useEffect(() => {
        getTeacherGetProfiles()
    }, [])

    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/teacher-dashboard">Dashboard</Link></li>
                    <li className="breadcrumb-item active">Teacher Profile</li>
                </ol>
            </div>
            <TeacherProfile data={tearchdata && tearchdata} />
            <TeacherDetails data={tearchdata && tearchdata} getTeacherGetProfiles={getTeacherGetProfiles} />
        </>
    );
};

export default Profile;