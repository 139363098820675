import React, { useEffect, useState } from "react";
import url from "../../../../constants/url";
import { toast } from "react-toastify";
import { Formik } from "formik";
import SubmitLoader from "../../../common/SubmitLoader";
import * as Yup from "yup";

const ApproveTeacherCoursePopup = ({ getAllCourseData, setApproveCourseForm, approveCourseForm, courseId, setCourseId, courseType }) => {
    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage.auth_token;
    const [loading, setLoading] = useState(0)
    const [values, setValues] = useState({
        status: "",
        course_id: "",
        reason: "",
        course_type: courseType,
    })
    const ValidateSchema = Yup.object().shape({
        status: Yup.string().required("Required"),
        reason: Yup.string().when("is_professional", {
            is: (status) => status && status == "decline",
            then: Yup.string().required(),
        }).nullable(),
    });

    const FormSubmit = async (values) => {
        try {
            setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("AUTHTOKEN", auth_token);
            myHeaders.append("Content-Type", "application/json");
            values.course_id = courseId
            values.course_type = courseType
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify(values),
            };
            fetch(url.apiUrl + "institute-course/approve-course", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    setApproveCourseForm(false)
                    setLoading(false)
                    setCourseId(0)
                    if (result.status === "success") {
                        toast.success("Your video course is pending for approval from our administrator. Thank you for your patience.", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        getAllCourseData();
                    } else {
                        toast.error(result.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                })
                .catch((error) => console.log("error", error));
        } catch (err) {
            throw err;
        }
    }
    return (
        <div
            className={approveCourseForm ? "modal fade show" : "modal fade"}
            id='about'
            tabIndex={-1}
            aria-labelledby='InstituteAbout'
            aria-hidden='true'
            style={approveCourseForm ? { display: "block" } : { display: "none" }}
        >
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h4 className='modal-title' id='InstituteAbout'>
                            Video Course Change Status
                        </h4>
                        <button
                            type='button'
                            className='btn-close'
                            onClick={() => setApproveCourseForm(false)}
                        />
                    </div>
                    {approveCourseForm && (
                        <Formik
                            initialValues={values}
                            onSubmit={FormSubmit}
                            enableReinitialize={true}
                            validationSchema={ValidateSchema}
                        >
                            {(props) => {
                                return (
                                    <form noValidate='novalidate' onSubmit={props.handleSubmit} >
                                        <div className='modal-body'>
                                            <div className='row'>
                                                <div className='col-md-12 mt-2'>
                                                    <label htmlFor='name'>
                                                        Change Status<span className='text-danger'>*</span>
                                                    </label>
                                                    <select
                                                        className='form-select'
                                                        onChange={props.handleChange}
                                                        name="status"
                                                        defaultValue={props.values.status}
                                                    >
                                                        <option>Select status</option>
                                                        <option value="active">Approve</option>
                                                        <option value="decline">Decline</option>
                                                    </select>
                                                    {props.touched.reason &&
                                                        props.errors.reason ? (
                                                        <div className='bg-error'>
                                                            {props.errors.reason}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {props.values.status === "decline" && (
                                                    <div className='col-md-12 mt-2'>
                                                        <label htmlFor='name'> reason<span className='text-danger'>*</span>
                                                        </label>
                                                        <textarea type="text"
                                                            className='form-control'
                                                            name='reason'
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={props.values.reason}
                                                            placeholder='Enter course title'
                                                        />
                                                        {props.touched.reason &&
                                                            props.errors.reason ? (
                                                            <div className='bg-error'>
                                                                {props.errors.reason}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='modal-footer'>
                                            {loading ? (
                                                <SubmitLoader />
                                            ) : (
                                                <>
                                                    <button className='btn btn-primary btn-next '  type='submit' >
                                                        Submit
                                                    </button>
                                                    <button
                                                        type='reset'
                                                        id='reset'
                                                        className='btn btn-danger '
                                                        data-bs-dismiss='modal'
                                                        onClick={() => {
                                                            setApproveCourseForm(false);
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </form>
                                );
                            }}
                        </Formik>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ApproveTeacherCoursePopup;

