import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import url from "../../../../constants/url";
import ReactPaginate from "react-paginate";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import ApproveTeacherCoursePopup from "./ApproveTeacherCoursePopup";
export default function ApprovePendingCourse() {

    const [approveCourseForm, setApproveCourseForm] = useState(false);
    const [courseId, setCourseId] = useState(0);
    const [offset, setOffset] = useState(0);
    const [data, setCourseData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [search, setSearch] = useState({
        start: 0,
        perPage: 10,
        searchTxt: "",
        searchField: "",
        is_updated: 0
    });
    const apiCall = async (activity) => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        const config = {
            headers: {
                content: "application/json",
                AUTHTOKEN: auth_token,
            },
        };
        try {
            const response = await axios.post(
                `${url.apiUrl}institute-course/get-all-online-courses`,

                activity,
                config
            );
            if (response) {
                setCourseData(response.data?.data);
                setTotalPages(response.data?.count / 10);
            }
        } catch (err) {
            throw err;
        }
    };
    const getAllCourseData = async () => {
        const activity = {
            status: "all",
            limit: search.perPage,
            offset: search.start,
            query: search.searchTxt,
            is_updated: search.is_updated,
            added_by: "teacher"
        };

        await apiCall(activity);
    };
    const handlePageClick = async (data) => {
        let currentPage = data.selected * search.perPage;
        setOffset(currentPage);
        const activity = {
            status: "all",
            limit: search.perPage,
            offset: currentPage,
            query: search.searchTxt,
            is_updated: search.is_updated,
            added_by: "teacher"
        };
        await apiCall(activity);
    };

    useEffect(() => {
        getAllCourseData();
    }, [search]);

    const CourseHandler = (id) => {
        setCourseId(id);
        setApproveCourseForm(true);
    };

    return (
        <>
            <div id="basic-1_wrapper" className="dataTables_wrapper no-footer">
                <div id="basic-1_filter" className="dataTables_filter">
                    <label>
                        Search:
                        <input
                            type="search"
                            placeholder="Search..."
                            onChange={(e) => {
                                setSearch({
                                    ...search,
                                    searchTxt: e.target.value,
                                });
                            }}
                            value={search.searchTxt}
                        />
                    </label>
                </div>
                <table className="table table-bordered table-hover dataTable table-responsive-xl">
                    <thead>
                        <tr>
                            <th className="text-center">Sr. No.</th>
                            <th>Course Details</th>
                            <th>Course Category</th>
                            <th>Branch Details</th>
                            <th>Teacher Details</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.length > 0 ?
                            data.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <th className="text-center text-nowrap">{i + offset + 1}</th>
                                        <td className="text-capitalize">
                                            <div className="media">
                                                <img
                                                    src={item?.teaser_thumb_image}
                                                    alt={item?.title}
                                                    className="me-3 rounded-circle"
                                                />
                                                <div className="media-body">
                                                    <Link className="font-weight-semibold" to={"/institute-dashboard/video-course-detail/1/" + item?.id}>
                                                        {item?.title}
                                                    </Link>
                                                    <p className=" mb-0">
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-capitalize">
                                            {item?.category?.title}
                                        </td>
                                        <td>
                                            <div className="media">
                                                <img
                                                    src={item?.institute_branch?.image}
                                                    alt={item?.institute_branch?.name}
                                                    className="me-3 rounded-circle"
                                                />
                                                <div className="media-body">
                                                    <Link className="font-weight-semibold"
                                                        to={`/institute-dashboard/branch-detail/${item?.institute_branch_id}`}
                                                    >
                                                        {item?.institute_branch?.name}
                                                    </Link>
                                                    <p className=" mb-0">
                                                        {item?.institute_branch?.email} </p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="media">
                                                <img
                                                    src={item?.teacher?.image}
                                                    alt={item?.teacher?.name}
                                                    className="me-3 rounded-circle"
                                                />
                                                <div className="media-body">
                                                    <Link className="font-weight-semibold"
                                                        to={`/institute-dashboard/teacher-detail/${item?.teacher_id}`}
                                                    >{item?.teacher?.name}

                                                    </Link>
                                                    <p className=" mb-0">{item?.teacher?.email}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-capitalize text-center">
                                            {item?.status}
                                        </td>
                                        <td className="text-center">
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-sm"
                                                onClick={() => CourseHandler(item?.id)}
                                            >
                                                <i className='fa fa-eye fs-16'></i>
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })
                            : <tr>
                                <td className="text-center" colSpan={9}>No Data Available !</td>
                            </tr>
                        }
                    </tbody>
                </table>
                {data?.length > 0 && (
                    <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={Math.ceil(totalPages)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={
                            "pagination"
                        }
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                    />
                )}
            </div>
            <ApproveTeacherCoursePopup getAllCourseData={getAllCourseData} setApproveCourseForm={setApproveCourseForm} approveCourseForm={approveCourseForm} courseId={courseId} setCourseId={setCourseId} courseType="online_course" />
        </>
    )
}
