import React from "react";
import ReactDOM from 'react-dom/client'; 
// import { createRoot } from "react-dom/client";
// import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { ContextProvider } from "./Context";
import App from "./App";
import '../src/CustBootstrap.css'
import '../node_modules/aos/dist/aos.css'
import '../node_modules/aos/dist/aos.js'
import 'animate.css';


window.tinyAPIKEY = "9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d";
window.user = JSON.parse(localStorage.getItem("common-user-info"))
  ? JSON.parse(localStorage.getItem("user"))
  : null;
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
  <BrowserRouter>
    <ContextProvider>
      <App />
    </ContextProvider>
  </BrowserRouter>
);
