import React from "react";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Moment from "react-moment";
import Footer from "../../Footer/Footer";
import Headerinner from "../../Header/Headerinner";
import Curve from "../../Header/Curve";

const Alumni = () => {
    return (
        <>
            <Headerinner />
            <div className="cover-image bg-background-1" data-bs-image-src="assets/images/banners/banner1.webp" style={{ background: 'url("assets/images/banners/banner1.webp") center center' }}>
                <div className="container header-text">
                    <div className="row">
                        <div className="col-xl-9 col-lg-12 col-md-12 d-block mx-auto">
                            <h1>Student Name</h1>
                        </div>
                    </div>
                </div>
            </div>
            <Curve />
            <section className="sptb">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="wideget-user">
                                        <div className="wideget-user-desc text-center">
                                            <div className="item-card7-img">
                                                <img
                                                    className="cover-image teacher-student-image"
                                                    src="http://mainuat.educationmandal.com/em-admin/public/assets/images/default.png"
                                                />
                                            </div>
                                            <div className="user-wrap wideget-user-info">
                                                <h4>
                                                    Studend
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-header">
                                    <h4 className="card-title">Alumni Details :</h4>
                                </div>
                                <table className="table table-hover dataTable">
                                    <tbody>
                                        <tr>
                                            <td className="text-nowrap font-weight-semibold">
                                                Institute Name
                                            </td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end">
                                                <Link className="text-primary" to="#">
                                                    Course Name
                                                </Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-nowrap font-weight-semibold">
                                                Branch Name
                                            </td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end">
                                                <Link className="text-primary" to="#">
                                                    Course Name
                                                </Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-nowrap font-weight-semibold">
                                                Session
                                            </td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end">
                                                2014 - 2018
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-nowrap font-weight-semibold">
                                                Course Name
                                            </td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end">
                                                <Link className="text-primary" to="#">
                                                    Course Name
                                                </Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-nowrap font-weight-semibold">
                                                Current Company
                                            </td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end">
                                                gusfdg
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-nowrap font-weight-semibold">
                                                Current Description
                                            </td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end">
                                                dfgu
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-semibold">
                                                Last Update
                                            </td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end">
                                                <Moment format="DD-MM-YYYY hh:mm A">
                                                    5858
                                                </Moment>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div >
                        </div>
                        <div className="col-xl-9 col-lg-8 col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Current Company Details</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <table className="table table-hover dataTable">
                                                <tbody>
                                                    <tr>
                                                        <td className="text-nowrap font-weight-semibold">
                                                            Institute Name
                                                        </td>
                                                        <td className="w-0 text-center"> : </td>
                                                        <td className="text-end">
                                                            <Link className="text-primary" to="#">
                                                                Course Name
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-nowrap font-weight-semibold">
                                                            Branch Name
                                                        </td>
                                                        <td className="w-0 text-center"> : </td>
                                                        <td className="text-end">
                                                            <Link className="text-primary" to="#">
                                                                Course Name
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-nowrap font-weight-semibold">
                                                            Session
                                                        </td>
                                                        <td className="w-0 text-center"> : </td>
                                                        <td className="text-end">
                                                            2014 - 2018
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-nowrap font-weight-semibold">
                                                            Course Name
                                                        </td>
                                                        <td className="w-0 text-center"> : </td>
                                                        <td className="text-end">
                                                            <Link className="text-primary" to="#">
                                                                Course Name
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <table className="table table-hover dataTable">
                                                <tbody>
                                                    <tr>
                                                        <td className="text-nowrap font-weight-semibold">
                                                            Current Company
                                                        </td>
                                                        <td className="w-0 text-center"> : </td>
                                                        <td className="text-end">
                                                            gusfdg
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-nowrap font-weight-semibold">
                                                            Current Description
                                                        </td>
                                                        <td className="w-0 text-center"> : </td>
                                                        <td className="text-end">
                                                            dfgu
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="font-weight-semibold">
                                                            Last Update
                                                        </td>
                                                        <td className="w-0 text-center"> : </td>
                                                        <td className="text-end">
                                                            <Moment format="DD-MM-YYYY hh:mm A">
                                                                5858
                                                            </Moment>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">About the Student</h4>
                                </div>
                                <div className='card-body education-mandal'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </>
    );
};
export default Alumni;