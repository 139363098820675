import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import Select from "react-select";
import Swal from "sweetalert2";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import MapboxAutocomplete from "react-mapbox-autocomplete";
import { Link, useHistory, useParams } from "react-router-dom";
import url from "../../../../constants/url";
import { Formik } from "formik";
import { Editor } from "@tinymce/tinymce-react";

export default function InstituteRegisterFormFields({ isLoader, setIsLoader }) {
    const history = useHistory();
    const params = useParams();
    const [city, setCity] = useState([]);
    const [instituteId, setInstituteId] = useState("");
    const [state, setState] = useState([]);
    const [UIValues, setUIValues] = useState({
        name: "",
        contact_person_name: "",
        contact: "",
        email: "",
        address: "",
        location: "",
        state: "",
        city: "",
        pincode: "",
        about: "",
        terms: "",
        latitude: "",
        longitude: "",
    });
    const [experienceValidation, setExperienceValidation] = useState({
        name: "",
        contact_person_name: "",
        contact: "",
        email: "",
        address: "",
        location: "",
        state: "",
        city: "",
        pincode: "",
        about: "",
        terms: "",
        latitude: "",
        longitude: "",
    });
    useEffect(() => {
        setExperienceValidation({
            name: "",
            contact_person_name: "",
            contact: "",
            email: "",
            address: "",
            location: "",
            state: "",
            city: "",
            pincode: "",
            about: "",
            terms: "",
            latitude: "",
            longitude: "",
        });
    }, [UIValues]);
    const onSubmit = async (values) => {
        setIsLoader(true);
        var formdata = new FormData();
        formdata.append("type", "institute");
        formdata.append("name", values.name);
        formdata.append("contact_person_name", values.contact_person_name);
        formdata.append("contact", values.contact);
        formdata.append("email", values.email);
        formdata.append("address", values.address);
        formdata.append("about", values.about);
        formdata.append("state", values.state.value);
        formdata.append("city", values.city.value);
        formdata.append("pincode", values.pincode);
        formdata.append("location", values.location);
        formdata.append("latitude", values.latitude);
        formdata.append("longitude", values.longitude);
        formdata.append("resubmit_url", 'https://' + window.location.host + "/institute-resubmission");
        if (params.token) {
            formdata.append("token", params.token);
            formdata.append("institute_id", instituteId);
        } else {
        }

        var requestOptions = {
            method: "POST",
            body: formdata,
        };

        fetch(`${url.apiUrl}institute/register`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setExperienceValidation(result.data);
                    setIsLoader(false);
                    Swal.fire({
                        title: "Pending for approval",
                        text: result.message,
                        icon: "success",
                        confirmButtonText: "OK",
                    }).then(function () {
                        history.push("/");
                    });
                } else {
                    setIsLoader(false);
                    setExperienceValidation(result.data);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const RegistrationSchema = Yup.object().shape({
        name: Yup.string().required("Required"),
        contact_person_name: Yup.string().required("Required"),
        city: Yup.mixed().required("Required"),
        state: Yup.mixed().required("Required"),
        email: Yup.string().email("Please enter valid email ID").required("Required"),
        address: Yup.string().required("Required"),
        location: Yup.string().required("Required"),
        pincode: Yup.string()
            .required("Required")
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(6, "Please enter valid pincode")
            .max(6, "Please enter valid pincode"),
        about: Yup.string()
            .required("Required")
            .max(500, "Please write less than 500 characters !"),
        terms: Yup.string().required("Required"),
        contact: Yup.string()
            .required("Required")
            .min(13, "Please enter valid contact number")
            .max(13, "Please enter valid contact number"),
    });

    const mapAccess = {
        mapboxApiAccessToken:
            "pk.eyJ1Ijoic2FuY2hpdHNoYXJtYSIsImEiOiJjbGZocmRoMm0yMTJmM3FwZ21nZ2NqdG91In0.Ngsso-JXR08GApV2N1rmUw",
    };

    function _suggestionSelect(result, lat, long, text, props) {
        props.setFieldValue("location", result);
        props.setFieldValue("latitude", lat);
        props.setFieldValue("longitude", long);
    }

    useEffect(() => {
        StateListApi();
    }, []);

    const getInstituteData = async () => {
        var formdata = new FormData();
        formdata.append("email", params.email);
        formdata.append("token", params.token);
        var requestOptions = {
            method: "POST",
            body: formdata,
        };

        fetch(`${url.apiUrl}institute-pending-detail`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setInstituteId(result.data?.id);
                setUIValues({
                    name: result.data.name,
                    contact_person_name: result.data.contact_person_name,
                    contact: result.data.contact,
                    email: result.data.email,
                    address: result.data.address,
                    location: result.data.location,
                    state: result.data.states,
                    city: result.data.cities,
                    pincode: result.data.pincode,
                    about: result.data.about,
                    terms: result.data.terms,
                    latitude: result.data.latitude,
                    longitude: result.data.longitude,
                });
            })
            .catch((error) => console.log("error", error));
    };
    const StateListApi = async (e) => {
        var requestOptions = {
            method: "GET",
        };

        fetch(`${url.apiUrl}state/101`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    const arr = result.data.map((v) => {
                        return { value: v.id, label: v.name };
                    });
                    setState(arr);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const CityListApi = async (id) => {
        var requestOptions = {
            method: "GET",
        };

        fetch(`${url.apiUrl}city/${id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    const arr = result.data.map((v) => {
                        return { value: v.id, label: v.name };
                    });
                    setCity(arr);
                }
            })
            .catch((error) => console.log("error", error));
    };

    useEffect(() => {
        getInstituteData();
    }, [params.token]);
    return (
        <Formik
            initialValues={UIValues}
            validationSchema={RegistrationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
        >
            {(props) => (
                <form id="register" tabIndex="500" onSubmit={props.handleSubmit}>
                    <div className="row">
                        <div className="col-lg-6 col-12 form-group">
                            <label>
                                Institute Name <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name="name"
                                value={props.values.name}
                                onChange={props.handleChange}
                                placeholder="Please enter institute name."
                            />
                            {props.touched.name && props.errors.name ? (
                                <div className=" bg-error">{props.errors.name}</div>
                            ) : (
                                <p className="bg-error">{experienceValidation?.name}</p>
                            )}
                        </div>
                        <div className="col-lg-6 col-12 form-group">
                            <label>
                                Contact Person Name <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name="contact_person_name"
                                value={props.values.contact_person_name}
                                onChange={props.handleChange}
                                placeholder="Please enter contact person name."
                            />
                            {props.touched.contact_person_name &&
                                props.errors.contact_person_name ? (
                                <div className=" bg-error">
                                    {props.errors.contact_person_name}
                                </div>
                            ) : null}
                            <p className="bg-error">
                                {experienceValidation?.contact_person_name}
                            </p>
                        </div>
                        <div className="col-lg-6 col-12 form-group">
                            <label>
                                Contact No. <span className="text-danger">*</span>
                            </label>
                            <PhoneInputWithCountrySelect
                                name="contact"
                                value={props.values.contact}
                                onChange={(e) => {
                                    props.setFieldValue("contact", e);
                                }}
                                defaultCountry="IN"
                                className="form-control"
                                placeholder="Please enter contact no"
                            />
                            {props.touched.contact && props.errors.contact ? (
                                <div className=" bg-error">{props.errors.contact}</div>
                            ) : (
                                <p className="bg-error">{experienceValidation?.contact}</p>
                            )}
                        </div>
                        <div className="col-lg-6 col-12 form-group">
                            <label>
                                Email ID <span className="text-danger">*</span>
                            </label>
                            <input
                                type="email"
                                className="form-control"
                                name="email"
                                value={props.values.email}
                                onChange={props.handleChange}
                                placeholder="Please enter email id"
                            />
                            {props.touched.email && props.errors.email ? (
                                <div className=" bg-error">{props.errors.email}</div>
                            ) : (
                                <p className="bg-error">{experienceValidation?.email}</p>
                            )}
                        </div>
                        <div className="col-lg-6 col-12 form-group">
                            <label>
                                House No. / Street / Ward No
                                <span className="text-danger">*</span>
                            </label>
                            <input
                                name="address"
                                className="form-control"
                                value={props.values.address}
                                onChange={props.handleChange}
                                placeholder="Please enter house no. / street / ward no."
                            />
                            {props.touched.address && props.errors.address ? (
                                <div className=" bg-error">{props.errors.address}</div>
                            ) : null}
                            <p className="bg-error">{experienceValidation?.address}</p>
                        </div>
                        {params.token ? (
                            <>
                                {props.values.location && (
                                    <div className="col-lg-6 col-12 form-group">
                                        <label>
                                            Location <span className="text-danger">*</span>
                                        </label>
                                        <MapboxAutocomplete
                                            publicKey={mapAccess.mapboxApiAccessToken}
                                            inputclassName="form-control "
                                            onSuggestionSelect={(result, lat, long, text) => {
                                                _suggestionSelect(result, lat, long, text, props);
                                            }}
                                            country="IN"
                                            query={props.values.location}
                                            resetSearch={false}
                                            placeholder="Search Location..."
                                        />
                                        {props.touched.location && props.errors.location ? (
                                            <div className=" bg-error">{props.errors.location}</div>
                                        ) : null}
                                        <p className="bg-error">{experienceValidation?.location}</p>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="col-lg-6 col-12 form-group">
                                <label>
                                    Location <span className="text-danger">*</span>
                                </label>
                                <MapboxAutocomplete
                                    publicKey={mapAccess.mapboxApiAccessToken}
                                    inputclassName="form-control "
                                    onSuggestionSelect={(result, lat, long, text) => {
                                        _suggestionSelect(result, lat, long, text, props);
                                    }}
                                    country="IN"
                                    query={props.values.location}
                                    resetSearch={false}
                                    placeholder="Search Location..."
                                />
                                {props.touched.location && props.errors.location ? (
                                    <div className=" bg-error">{props.errors.location}</div>
                                ) : null}
                                <p className="bg-error">{experienceValidation?.location}</p>
                            </div>
                        )}

                        <div className="col-lg-4 col-12 form-group">
                            <label>
                                State <span className="text-danger">*</span>
                            </label>
                            <Select
                                value={props.values.state}
                                name="state"
                                onChange={(e) => {
                                    CityListApi(e.value);
                                    props.setFieldValue("state", e);
                                }}
                                options={state}
                            />
                            {props.touched.state && props.errors.state ? (
                                <div className=" bg-error">{props.errors.state}</div>
                            ) : null}
                            <p className="bg-error">{experienceValidation?.state}</p>
                        </div>
                        <div className="col-lg-4 col-12 form-group">
                            <label>
                                City <span className="text-danger">*</span>
                            </label>
                            <Select
                                value={props.values.city}
                                onChange={(e) => {
                                    props.setFieldValue("city", e);
                                }}
                                options={city}
                            />
                            {props.touched.city && props.errors.city ? (
                                <div className=" bg-error">{props.errors.city}</div>
                            ) : null}
                            <p className="bg-error">{experienceValidation?.city}</p>
                        </div>
                        <div className="col-lg-4 col-12 form-group">
                            <label>
                                Pincode <span className="text-danger">*</span>
                            </label>
                            <input
                                text="text"
                                name="pincode"
                                className="form-control"
                                value={props.values.pincode}
                                onChange={props.handleChange}
                                placeholder="Please enter pincode"
                            />
                            {props.touched.pincode && props.errors.pincode ? (
                                <div className=" bg-error">{props.errors.pincode}</div>
                            ) : null}
                            <p className="bg-error">{experienceValidation?.pincode}</p>
                        </div>
                        <div className="col-lg-12 form-group">
                            <label>
                                About the Institute <span className="text-danger">*</span>
                            </label>
                            <Editor
                                textareaName='about'
                                placeholder='Please enter brief about the Institute.'
                                apiKey='9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d'
                                init={{
                                    plugins: [
                                        "advlist",
                                        "autolink",
                                        "lists",
                                        "link",
                                        "image",
                                        "charmap",
                                        "preview",
                                        "anchor",
                                        "searchreplace",
                                        "visualblocks",
                                        "code",
                                        "fullscreen",
                                        "insertdatetime",
                                        "media",
                                        "table",
                                        "help",
                                        "wordcount",
                                        "spellchecker",
                                    ],

                                    toolbar:
                                        " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help |spellchecker ",
                                }}
                                value={props?.values?.about}
                                onEditorChange={(e) =>
                                    props.handleChange({
                                        target: {
                                            name: `about`,
                                            value: e,
                                        },
                                    })
                                }
                            />

                            <p style={{ position: "absolute", right: "15px" }}>
                                {props.values.about?.length} / 500 Characters
                            </p>
                            {props.touched.about && props.errors.about ? (
                                <div className=" bg-error">{props.errors.about}</div>
                            ) : null}
                            <p className="bg-error">{experienceValidation?.about}</p>
                        </div>
                        <div className="col-lg-12 form-group">
                            <div className="checkbox checkbox-info">
                                <label className="custom-control mt-4 checkbox-inline mb-0" htmlFor="checked">
                                    <input
                                        type="checkbox"
                                        name="terms"
                                        id="checked"
                                        value={props.values.terms}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                props.setFieldValue("terms", "true");
                                            } else {
                                                props.setFieldValue("terms", "");
                                            }
                                        }}
                                        className="custom-control-input"
                                    />
                                    <span className="custom-control-label  ps-2">
                                        I agree with the
                                        <Link to="/all-policy" className="text-primary ms-1" target="_blank" rel="noreferrer">
                                            Terms & Conditions.
                                        </Link>
                                    </span>
                                </label>
                                {props.touched.terms && props.errors.terms ? (
                                    <div className=" bg-error">{props.errors.terms}</div>
                                ) : null}
                                <p className="bg-error">{experienceValidation?.terms}</p>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3 mb-4 text-start">
                            <p className="mb-0 ">
                                <strong>Note:</strong> Teacher's registration will be initiated by
                                Institutes.
                            </p>
                        </div>
                        <div className="col-md-6 mb-4 text-end">
                            <button type="submit" className="btn btn-primary"><i className="fa-solid fa-arrow-right-to-bracket me-2"></i> Sign up
                            </button>
                        </div>
                    </div>

                    <p className="mb-0 text-end">
                        Already have an account?
                        <Link to={"/login"} className="text-primary ms-1"> Sign In</Link>
                    </p>
                </form>
            )}
        </Formik>
    );
}
