import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Attendance from "../../services/Attendance";
import { FieldArray, Formik } from "formik";
import SubmitLoader from "../../../common/SubmitLoader";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";

const AttendanceEdit = () => {
    const params = useParams();
    const serve = new Attendance();
    const history = useHistory()
    const [student, setStudent] = useState({ present_student: 0, absent_student: 0, leave_student: 0, total_student: 0 });
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [classData, setClassData] = useState({ name: "", id: "", subject: "" })
    const [addEdit, setAddEdit] = useState("");
    const [data, setData] = useState({
        slot_id: "",
        attendance_array: [{ student_id: "", attendance: "", student: "" }],
    });
    const [values, setValues] = useState({
        slot_id: "",
        attendance_array: [{ student_id: "", attendance: "", student: "" }],
    });
    const getStudentList = async (activity) => {
        try {
            setLoading(true)
            let response = await serve.getStudentAttendanceList(activity);
            if (response) {
                setStudent({ present_student: response.present_student, absent_student: response.absent_student, leave_student: response.leave_student, total_student: response.total_student })
                setClassData({ name: response.class_name, id: response.class_id, subject: response.subject })
                var attendance_array = response.data.map((student, i) => {
                    return { student_id: student.user_id, attendance: student.attendance, student: student.student }
                })
                setValues({
                    slot_id: params.slot_id,
                    attendance_array: attendance_array,
                })
                setData(attendance_array)
                setLoading(false)
            }
        } catch (err) {
            setLoading(false)
            throw err;
        }
    }

    const ValidateSchema = Yup.object().shape({
        attendance_array: Yup.array().of(Yup.object().shape({
            attendance: Yup.string().required('Required').oneOf(['present', 'absent', 'leave'], 'Please Mark')
        }))
    })

    const FormSubmit = async (values) => {
        try {
            setSubmitLoading(true)
            let response = await serve.markStudentAttendance(values);
            if (response.status == "success") {
                setSubmitLoading(false)
                toast.success(response.message)
                setTimeout(() => {
                    history.push(`/teacher-dashboard/attendance-view-time-slot/${response.class_id}/${response.subject}`)
                }, 5000);
            } else {
                setSubmitLoading(false)
                toast.error(response.message)
            }
        } catch (err) {
            setSubmitLoading(false)
            throw err;
        }
    };

    const manageSearch = (value) => {
        var attendance_array = data.filter((item, i) => {
            return (item.student.name.toLowerCase().includes(value.toLowerCase()) || item.student.email.toLowerCase().includes(value.toLowerCase()) || item.student.contact.toLowerCase().includes(value.toLowerCase()))
        })
        setValues({ ...values, attendance_array: attendance_array })
    }

    const handleSelectAll = (e) => {
        var attendance_array = data.map((item, i) => {
            item.attendance = e.target.value
            return item;
        })
        setValues({ ...values, attendance_array: attendance_array })
    };

    useEffect(() => {
        getStudentList({
            slot_id: params.slot_id
        })
        if (window.location.pathname === `/teacher-dashboard/attendance-edit/${params.slot_id}`) {
            setAddEdit('Edit');
        } else {
            setAddEdit('Add');
        }
    }, [])
    return (
        <>
            <ToastContainer />
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/teacher-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="/teacher-dashboard/attendance">Attendance List</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to={`/teacher-dashboard/attendance-view-subject/${classData.id}`}>View Attendance Subject</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to={`/teacher-dashboard/attendance-view-time-slot/${classData.id}/${classData.subject}`}>View Attendance Time Slots</Link>
                    </li>
                    <li className="breadcrumb-item active">{addEdit} Attendance</li>
                </ol>
            </div >
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                            <div className="card bg-primary text-white">
                                <div className="card-body">
                                    <div className="counter-status dash3-counter d-flex">
                                        <div className="counter-icon bg-primary-dark my-auto br-100">
                                            <i className="fa-solid fa-calendar-week text-white leading-loose"></i>
                                        </div>
                                        <div className="ms-auto text-end">
                                            <h5 className="mb-0 text-white">Total Students</h5>
                                            <h2 className="mb-0 text-white mt-2">{student.total_student}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                            <div className="card bg-success">
                                <div className="card-body">
                                    <div className="counter-status dash3-counter d-flex">
                                        <div className="counter-icon bg-primary-dark my-auto br-100">
                                            <i className="fa-solid fa-calendar-week text-white leading-loose"></i>
                                        </div>
                                        <div className="ms-auto text-end">
                                            <h5 className="mb-0 text-white">Present Students</h5>
                                            <h2 className="mb-0 text-white mt-2">{student.present_student}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                            <div className="card bg-secondary">
                                <div className="card-body">
                                    <div className="counter-status dash3-counter d-flex">
                                        <div className="counter-icon bg-primary-dark my-auto br-100">
                                            <i className="fa-solid fa-calendar-week text-white leading-loose"></i>
                                        </div>
                                        <div className="ms-auto text-end">
                                            <h5 className="mb-0 text-white">Absent Students</h5>
                                            <h2 className="mb-0 text-white mt-2">{student.absent_student}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                            <div className="card bg-orange">
                                <div className="card-body">
                                    <div className="counter-status dash3-counter d-flex">
                                        <div className="counter-icon bg-primary-dark my-auto br-100">
                                            <i className="fa-solid fa-calendar-week text-white leading-loose"></i>
                                        </div>
                                        <div className="ms-auto text-end">
                                            <h5 className="mb-0 text-white">Students on Leave</h5>
                                            <h2 className="mb-0 text-white mt-2">{student.leave_student}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">{addEdit} Attendance Student List for {classData.name}</h4>
                        </div>
                        <div className="card-body">
                            <div id="basic-1_wrapper" className="dataTables_wrapper no-footer">
                                {addEdit === "Edit" && (
                                    <div id="basic-1_filter" className="dataTables_filter">
                                        <label>
                                            Search:
                                            <input type="search" placeholder="Search..." onChange={(e) => { manageSearch(e.target.value) }}
                                            />
                                        </label>
                                    </div>
                                )}
                                <Formik
                                    initialValues={values}
                                    enableReinitialize={true}
                                    onSubmit={FormSubmit}
                                    validationSchema={ValidateSchema}
                                >
                                    {(props) => (

                                        <form onSubmit={props.handleSubmit}>
                                            <table className="table table-bordered table-hover dataTable table-responsive-xl">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center" rowSpan={2}>Sr. No.</th>
                                                        <th rowSpan={2}>Student Details</th>
                                                        <th className="text-center text-nowrap" colSpan={3}>Mark Attendance</th>
                                                        <th className="text-center text-nowrap" rowSpan={2}>Attendance Status</th>
                                                    </tr>
                                                    <tr >
                                                        <th className="text-center">
                                                            <div className="form-check form-check-inline">
                                                                <label className="form-check-label text-success" htmlFor="present">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        id="present"
                                                                        name="attendance"
                                                                        value={'present'}
                                                                        onChange={handleSelectAll}
                                                                    />
                                                                    All Present
                                                                </label>
                                                            </div>
                                                        </th>
                                                        <th className="text-center">
                                                            <div className="form-check form-check-inline">
                                                                <label className="form-check-label text-danger" htmlFor="absent">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        id="absent"
                                                                        name="attendance"
                                                                        value={'absent'}
                                                                        onChange={handleSelectAll}
                                                                    />
                                                                    All Absent
                                                                </label>
                                                            </div>
                                                        </th>
                                                        <th className="text-center">
                                                            <div className="form-check form-check-inline">
                                                                <label className="form-check-label text-danger" htmlFor="leave">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        id="leave"
                                                                        name="attendance"
                                                                        value={'leave'}
                                                                        onChange={handleSelectAll}
                                                                    />
                                                                    All Leave
                                                                </label>
                                                            </div>
                                                        </th>
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    {loading ? (
                                                        <tr>
                                                            <td className='text-center' colSpan={12}>
                                                                Loading..
                                                            </td>
                                                        </tr>
                                                    ) : props.values.attendance_array == 0 ? (
                                                        <tr>
                                                            <td className='text-center' colSpan={12}>No Data Available !</td>
                                                        </tr>
                                                    ) : (
                                                        <FieldArray
                                                            name="attendance_array"
                                                            render={(arrayHelpers) => (
                                                                <>
                                                                    {arrayHelpers?.form?.values?.attendance_array?.map((data, index) => (
                                                                        <tr key={index}>
                                                                            <th className="text-center">{index + 1}</th>
                                                                            <td>
                                                                                <div className="media">
                                                                                    <img src={data.student.image} alt="text" className="me-3 rounded-circle" />
                                                                                    <div className="media-body">
                                                                                        <Link to={`/teacher-dashboard/student-profile/${data.student.id}`} className="font-weight-semibold">
                                                                                            {data.student.name}
                                                                                        </Link>
                                                                                        <p className="text-muted mb-0">{data.student.email}</p>
                                                                                        <p className="text-muted mb-0">{data.student.contact}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <div className="form-check form-check-inline">
                                                                                    <input className="form-check-input"
                                                                                        type="radio"
                                                                                        id={`present.${index}`}
                                                                                        value="present"
                                                                                        checked={data.attendance == "present"}
                                                                                        name={`attendance_array.${index}.attendance`}
                                                                                        onChange={props.handleChange}
                                                                                    />
                                                                                    <label className="form-check-label text-success" htmlFor={`present.${index}`}>
                                                                                        Present
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <div className="form-check form-check-inline">
                                                                                    <input className="form-check-input"
                                                                                        type="radio"
                                                                                        id={`absent.${index}`}
                                                                                        value="absent"
                                                                                        checked={data.attendance == "absent"}
                                                                                        name={`attendance_array.${index}.attendance`}
                                                                                        onChange={props.handleChange}
                                                                                    />
                                                                                    <label className="form-check-label text-danger" htmlFor={`absent.${index}`}>
                                                                                        Absent
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <div className="form-check form-check-inline">
                                                                                    <input className="form-check-input"
                                                                                        type="radio"
                                                                                        id={`leave.${index}`}
                                                                                        value="leave"
                                                                                        checked={data.attendance == "leave"}
                                                                                        name={`attendance_array.${index}.attendance`}
                                                                                        onChange={props.handleChange}
                                                                                    />
                                                                                    <label className="form-check-label text-danger" htmlFor={`leave.${index}`}>
                                                                                        Leave
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                            {data.attendance === 'pending' ? (
                                                                                (props.errors.attendance_array?.[index]?.attendance &&
                                                                                    props.touched.attendance_array?.[index]?.attendance) ? (
                                                                                    <td className="text-center text-danger text-capitalize">
                                                                                        {props.errors.attendance_array?.[index]?.attendance}
                                                                                    </td>
                                                                                ) : (
                                                                                    <td className="text-center text-capitalize">---</td>
                                                                                )
                                                                            ) : (
                                                                                <td className="text-center text-capitalize">
                                                                                    {data?.attendance}
                                                                                </td>
                                                                            )}
                                                                        </tr>
                                                                    ))}
                                                                </>
                                                            )}
                                                        />
                                                    )}
                                                </tbody>
                                            </table>

                                            {props.values.attendance_array.length > 0 && (
                                                <div className="text-end col-md-12 mt-5">
                                                    {submitLoading ? (
                                                        <SubmitLoader />
                                                    ) :
                                                        (
                                                            <button type="submit" className="btn btn-primary me-2">Submit </button>
                                                        )}
                                                    <button type="button" className="btn btn-danger" onClick={() => window.location.reload()}>
                                                        Cancel
                                                    </button>
                                                </div>
                                            )}
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AttendanceEdit;
