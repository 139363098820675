import React, { useState, useEffect } from "react";
import url from "../../../../../constants/url";
import Select from "react-select";
import { FieldArray } from "formik";
import { Editor } from "@tinymce/tinymce-react";
import moment from "moment";

export default function AddMoreUniversityData({ props, handleFileInputChange, medium }) {
    const [university, setUniversity] = useState();
    useEffect(() => {
        getUniversity();
    }, []);
    const getUniversity = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
        };

        fetch(`${url.apiUrl}get-university`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setUniversity(result.data);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const getDiscountedPrice = (index) => {
        let discount = parseInt(props.values?.universityData?.[index]?.discount_price || 0) || 0
        let price = parseInt(props.values?.universityData?.[index]?.regular_price || 0) || 0

        return Math.round(price - ((discount * price) / 100))
    }
    return (
        <FieldArray
            name="universityData"
            render={(arrayHelpers) => (
                <div>
                    {arrayHelpers?.form?.values?.universityData.map((v, index) => (
                        <React.Fragment key={index}>

                            <div className="row">
                                <div className="col-md-3 form-group">
                                    <label>University <span className="text-danger"> *</span></label>
                                    <select
                                        className="form-select"
                                        onChange={props.handleChange}
                                        name={`universityData.${index}.university`}
                                        value={props.values?.universityData?.[index]?.university}
                                    >
                                        <option value="">Select university</option>
                                        {university &&
                                            university.map((v, i) => (
                                                <option value={v.id} key={i}>
                                                    {v.name}
                                                </option>
                                            ))}
                                        <option value="other">Other</option>
                                    </select>
                                    {props.errors.universityData?.[index]?.university && props.touched.universityData?.[index]?.university && (<p className="bg-error">{props.errors.universityData?.[index]?.university}</p>)}
                                </div>
                                {props.values?.universityData?.[index]?.university ===
                                    "other" && (
                                        <div className="col-md-3 form-group">
                                            <label>
                                                Other University <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter other university"
                                                onChange={props.handleChange}
                                                name={`universityData.${index}.otheruniversity`}
                                                value={
                                                    props.values?.universityData?.[index]?.otheruniversity
                                                }
                                            />
                                            {props.errors.universityData?.[index]?.otheruniversity && props.touched.universityData?.[index]?.otheruniversity && (<p className="bg-error">{props.errors.universityData?.[index]?.otheruniversity}</p>)}
                                        </div>
                                    )}
                                <div className="col-md-3 form-group">
                                    <label>
                                        Class Name <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter class name"
                                        onChange={props.handleChange}
                                        name={`universityData.${index}.class`}
                                        value={props.values?.universityData?.[index]?.class}
                                    />
                                    {props.errors.universityData?.[index]?.class && props.touched.universityData?.[index]?.class && (<p className="bg-error">{props.errors.universityData?.[index]?.class}</p>)}
                                </div>

                                <div className="col-md-3 form-group">
                                    <label>Semester <span className="text-danger">*</span></label>
                                    <select
                                        className="form-select"
                                        onChange={props.handleChange}
                                        name={`universityData.${index}.semester`}
                                        value={props.values?.universityData?.[index]?.semester}
                                    >
                                        <option value="">Select Semester</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>
                                    {props.errors.universityData?.[index]?.semester && props.touched.universityData?.[index]?.semester && (<p className="bg-error">{props.errors.universityData?.[index]?.semester}</p>)}
                                </div>
                                <div className="col-md-3 form-group">
                                    <label>
                                        Medium <span className="text-danger">*</span>
                                    </label>
                                    <select
                                        className="form-select"
                                        onChange={props.handleChange}
                                        name={`universityData.${index}.medium`}
                                        value={props.values?.universityData?.[index]?.medium}
                                    >
                                        <option value="">Select medium</option>
                                        {medium &&
                                            medium.map((v, i) => (
                                                <option value={v.title} key={i}>
                                                    {v.title}
                                                </option>
                                            ))}
                                        <option value="other">Other</option>
                                    </select>
                                    {props.errors.universityData?.[index]?.medium &&
                                        props.touched.universityData?.[index]?.medium && (
                                            <p className="bg-error">
                                                {props.errors.universityData?.[index]?.medium}
                                            </p>
                                        )}
                                </div>
                                {props.values?.universityData?.[index]?.medium === "other" && (
                                    <div className="col-md-3 form-group">
                                        <label>
                                            Other Medium <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter other medium"
                                            onChange={props.handleChange}
                                            name={`universityData.${index}.otherMedium`}
                                            value={props.values?.universityData?.[index]?.otherMedium}
                                        />
                                        {props.errors.universityData?.[index]?.otherMedium &&
                                            props.touched.universityData?.[index]?.otherMedium && (
                                                <p className="bg-error">
                                                    {props.errors.universityData?.[index]?.otherMedium}
                                                </p>
                                            )}
                                    </div>
                                )}
                                <div className="col-md-3 form-group">
                                    <label>
                                        Thumbnail Image <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        accept="image/*"
                                        onChange={(e) => {
                                            handleFileInputChange(
                                                e,
                                                `universityData.${index}.thumbnail`,
                                                props,
                                                `universityData.${index}.image`
                                            );

                                        }}
                                        name={`universityData.${index}.thumbnail`}
                                    />
                                    <p className="fs-12 mb-0"><span className="text-danger">Note:</span> Image size: width = 368px and height = 200px</p>
                                    {props.errors.universityData?.[index]?.thumbnail && props.touched.universityData?.[index]?.thumbnail && (<p className="bg-error">{props.errors.universityData?.[index]?.thumbnail}</p>)}
                                </div>
                                {props.values?.universityData?.[index]?.thumbnail && (
                                    <div
                                        className="col-md-3 form-group"
                                    >
                                        <img
                                            src={props.values?.universityData?.[index]?.image || props.values?.universityData?.[index]?.thumbnail}
                                            alt="image"
                                            className="img-thumbnail"
                                        />
                                    </div>
                                )}

                                {props.values?.universityData?.[index]?.semester ===
                                    "other" && (
                                        <div className="col-md-3 form-group">
                                            <label>
                                                Other Semester <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter other semester"
                                                onChange={props.handleChange}
                                                name={`universityData.${index}.othersemester`}
                                                value={
                                                    props.values?.universityData?.[index]?.othersemester
                                                }
                                            />
                                            {props.errors.universityData?.[index]?.othersemester && props.touched.universityData?.[index]?.othersemester && (<p className="bg-error">{props.errors.universityData?.[index]?.othersemester}</p>)}
                                        </div>
                                    )}
                                <div className="col-md-3 form-group">
                                    <label>Classroom Type <span className="text-danger"> *</span></label>
                                    <div className="form-check form-check-inline">
                                        <input
                                            type="radio"
                                            name={`universityData.${index}.lecture_type`}
                                            checked={props.values?.universityData?.[index]?.lecture_type === 'video_conference'}
                                            onChange={e => props.setFieldValue(`universityData.${index}.lecture_type`, e.target.value)}
                                            className="form-check-input"
                                            value="video_conference"
                                        />
                                        <label className="form-check-label">Online</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            type="radio"
                                            name={`universityData.${index}.lecture_type`}
                                            checked={props.values?.universityData?.[index]?.lecture_type === 'manual'}
                                            onChange={e => props.setFieldValue(`universityData.${index}.lecture_type`, e.target.value)}
                                            className="form-check-input"
                                            value="manual"
                                        />
                                        <label className="form-check-label">Offline</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            type="radio"
                                            name={`universityData.${index}.lecture_type`}
                                            checked={props.values?.universityData?.[index]?.lecture_type === 'both'}
                                            onChange={e => props.setFieldValue(`universityData.${index}.lecture_type`, e.target.value)}
                                            className="form-check-input"
                                            value="both"
                                        />
                                        <label className="form-check-label">Hybrid (Online + Offline)</label>
                                    </div>
                                    {props.errors.universityData?.[index]?.lecture_type && props.touched.universityData?.[index]?.lecture_type && (<p className="bg-error">{props.errors.universityData?.[index]?.lecture_type}</p>)}
                                </div>
                                {(props.values?.universityData?.[index]?.lecture_type && props.values?.universityData?.[index]?.lecture_type != "manual") && (
                                    <div className="col-md-4 form-group">
                                        <label>Video Conference Link <span className="text-danger"> *</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter video conference link"
                                            name={`universityData.${index}.video_conference_link`}
                                            onChange={props.handleChange}
                                            value={props.values?.universityData?.[index]?.video_conference_link}
                                        />
                                        {props.errors.universityData?.[index]?.video_conference_link && props.touched.universityData?.[index]?.video_conference_link && (<p className="bg-error">{props.errors.universityData?.[index]?.video_conference_link}</p>)}

                                    </div>
                                )}
                            </div>
                            <div className="row">
                                <div className="col-md-3 form-group">
                                    <label>Price Type <span className="text-danger"> *</span></label>
                                    <div className="form-check form-check-inline">
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            value="free"
                                            onChange={props.handleChange}
                                            name={`universityData.${index}.price_type`}
                                            checked={props.values?.universityData?.[index]?.price_type === 'free'}
                                        />
                                        <label className="form-check-label">Free</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            value="paid"
                                            onChange={props.handleChange}
                                            name={`universityData.${index}.price_type`}
                                            checked={props.values?.universityData?.[index]?.price_type === 'paid'}
                                        />
                                        <label className="form-check-label">Paid</label>
                                    </div>
                                    {props.errors.universityData?.[index]?.price_type && props.touched.universityData?.[index]?.price_type && (<p className="bg-error">{props.errors.universityData?.[index]?.price_type}</p>)}
                                </div>
                                {props.values?.universityData?.[index]?.price_type === "paid" && (
                                    <>
                                        <div className="col-md-3 form-group">
                                            <label> Regular Price <span className="text-danger">*</span> </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Enter regular price"
                                                name={`universityData.${index}.regular_price`}
                                                onChange={props.handleChange}
                                                value={props.values?.universityData?.[index]?.regular_price}
                                            />
                                            {props.errors.universityData?.[index]?.regular_price && props.touched.universityData?.[index]?.regular_price && (<p className="bg-error">{props.errors.universityData?.[index]?.regular_price}</p>)}
                                        </div>
                                        <div className="col-md-3 form-group">
                                            <label>Discount Type <span className="text-danger"> *</span></label>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    value="yes"
                                                    name={`universityData.${index}.is_discount`}
                                                    onChange={props.handleChange}
                                                    checked={props.values?.universityData?.[index]?.is_discount === "yes"}
                                                />
                                                <label className="form-check-label">Yes</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    value="no"
                                                    name={`universityData.${index}.is_discount`}
                                                    onChange={props.handleChange}
                                                    checked={props.values?.universityData?.[index]?.is_discount === "no"} />
                                                <label className="form-check-label">No</label>
                                            </div>
                                            {props.errors.universityData?.[index]?.is_discount && props.touched.universityData?.[index]?.is_discount && (<p className="bg-error">{props.errors.universityData?.[index]?.is_discount}</p>)}
                                        </div>
                                    </>
                                )}
                                {props.values?.universityData?.[index]?.is_discount === "yes" &&
                                    props.values?.universityData?.[index]?.price_type === "paid" && (
                                        <>
                                            <div className="col-md-3 form-group">
                                                <label>
                                                    Discount Price (in %) <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Enter discount price"
                                                    name={`universityData.${index}.discount_price`}
                                                    onChange={props.handleChange}
                                                    value={props.values?.universityData?.[index]?.discount_price}
                                                />
                                                {props.errors.universityData?.[index]?.discount_price && props.touched.universityData?.[index]?.discount_price && (<p className="bg-error">{props.errors.universityData?.[index]?.discount_price}</p>)}
                                            </div>
                                            <div className="col-md-3 form-group">
                                                <label>
                                                    Price After Discount <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Price after discount "
                                                    disabled
                                                    name={`universityData.${index}.discount_price`}
                                                    value={getDiscountedPrice(index)}
                                                />
                                            </div>
                                            <div className="col-md-3 form-group">
                                                <label>
                                                    Discount Start Date <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    name={`universityData.${index}.discount_start_date`}
                                                    value={props.values?.universityData?.[index]?.discount_start_date}
                                                    onChange={props.handleChange}
                                                    min={moment().format("YYYY-MM-DD")}
                                                />
                                                {props.errors.universityData?.[index]?.discount_start_date && props.touched.universityData?.[index]?.discount_start_date && (<p className="bg-error">{props.errors.universityData?.[index]?.discount_start_date}</p>)}
                                            </div>
                                            <div className="col-md-3 form-group">
                                                <label>Limited Offer <span className="text-danger"> *</span></label>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        type="radio"
                                                        className="form-check-input"
                                                        value="yes"
                                                        checked={props.values?.universityData?.[index]?.limited_offer === "yes"}
                                                        onChange={props.handleChange}
                                                        name={`universityData.${index}.limited_offer`}
                                                    />
                                                    <label className="form-check-label" htmlFor="validationRadio7">Yes</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        type="radio"
                                                        className="form-check-input"
                                                        value="no"
                                                        checked={props.values?.universityData?.[index]?.limited_offer === "no"}
                                                        onChange={props.handleChange}
                                                        name={`universityData.${index}.limited_offer`}
                                                    />
                                                    <label className="form-check-label" htmlFor="validationRadio8">No</label>
                                                </div>
                                                {props.errors.universityData?.[index]?.limited_offer && props.touched.universityData?.[index]?.limited_offer && (<p className="bg-error">{props.errors.universityData?.[index]?.limited_offer}</p>)}
                                            </div>
                                            {props.values?.universityData?.[index]?.limited_offer === "yes" && (
                                                <div className="col-md-3 form-group">
                                                    <label>
                                                        Discount End Date <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        name={`universityData.${index}.discount_end_date`}
                                                        value={props.values?.universityData?.[index]?.discount_end_date}
                                                        onChange={props.handleChange}
                                                        min={props.values?.universityData?.[index]?.discount_start_date || moment().format("YYYY-MM-DD")}
                                                    />
                                                    {props.errors.universityData?.[index]?.discount_end_date && props.touched.universityData?.[index]?.discount_end_date && (<p className="bg-error">{props.errors.universityData?.[index]?.discount_end_date}</p>)}
                                                </div>
                                            )}
                                        </>
                                    )}
                            </div>
                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <label>
                                        Class Description <span className="text-danger">*</span>
                                    </label>
                                    <Editor
                                        textareaName="course_discription"
                                        placeholder="Enter course decription"
                                        apiKey="9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d"
                                        init={{
                                            plugins: [
                                                "advlist",
                                                "autolink",
                                                "lists",
                                                "link",
                                                "image",
                                                "charmap",
                                                "preview",
                                                "anchor",
                                                "searchreplace",
                                                "visualblocks",
                                                "code",
                                                "fullscreen",
                                                "insertdatetime",
                                                "media",
                                                "table",
                                                "help",
                                                "wordcount",
                                            ],

                                            toolbar:
                                                " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help ",
                                        }}
                                        value={props.values?.universityData?.[index]?.description}
                                        onEditorChange={(e) =>
                                            props.handleChange({
                                                target: {
                                                    name: `universityData.${index}.description`,
                                                    value: e,
                                                },
                                            })
                                        }
                                    />
                                    {props.errors.universityData?.[index]?.description && props.touched.universityData?.[index]?.description && (<p className="bg-error">{props.errors.universityData?.[index]?.description}</p>)}
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>
                                        What will students learn in this classroom? <span className="text-danger">*</span>
                                    </label>
                                    <Editor
                                        textareaName="course_discription"
                                        placeholder="Enter course decription"
                                        apiKey="9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d"
                                        init={{
                                            plugins: [
                                                "advlist",
                                                "autolink",
                                                "lists",
                                                "link",
                                                "image",
                                                "charmap",
                                                "preview",
                                                "anchor",
                                                "searchreplace",
                                                "visualblocks",
                                                "code",
                                                "fullscreen",
                                                "insertdatetime",
                                                "media",
                                                "table",
                                                "help",
                                                "wordcount",
                                            ],

                                            toolbar:
                                                " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help ",
                                        }}
                                        value={props.values?.universityData?.[index]?.curriculum}
                                        onEditorChange={(e) =>
                                            props.handleChange({
                                                target: {
                                                    name: `universityData.${index}.curriculum`,
                                                    value: e,
                                                },
                                            })
                                        }
                                    />
                                    {props.errors.universityData?.[index]?.curriculum && props.touched.universityData?.[index]?.curriculum && (<p className="bg-error">{props.errors.universityData?.[index]?.curriculum}</p>)}
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>
                                        Requirements <span className="text-danger">*</span>
                                    </label>
                                    <Editor
                                        textareaName="course_discription"
                                        placeholder="Enter course decription"
                                        apiKey="9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d"
                                        init={{
                                            plugins: [
                                                "advlist",
                                                "autolink",
                                                "lists",
                                                "link",
                                                "image",
                                                "charmap",
                                                "preview",
                                                "anchor",
                                                "searchreplace",
                                                "visualblocks",
                                                "code",
                                                "fullscreen",
                                                "insertdatetime",
                                                "media",
                                                "table",
                                                "help",
                                                "wordcount",
                                            ],

                                            toolbar:
                                                " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help ",
                                        }}
                                        value={props.values?.universityData?.[index]?.requirements}
                                        onEditorChange={(e) =>
                                            props.handleChange({
                                                target: {
                                                    name: `universityData.${index}.requirements`,
                                                    value: e,
                                                },
                                            })
                                        }
                                    />
                                    {props.errors.universityData?.[index]?.requirements && props.touched.universityData?.[index]?.requirements && (<p className="bg-error">{props.errors.universityData?.[index]?.requirements}</p>)}
                                </div>
                                {props.values.universityData?.length > 1 && (
                                    <div className="col-md-2 form-group open-button">
                                        <button
                                            className="btn btn-danger"
                                            type="button"
                                            onClick={() => {
                                                if (window.confirm(`Are you sure you want to delete the Classroom course ${index + 1} `)) {
                                                    arrayHelpers.remove(index)
                                                }
                                            }}
                                        >
                                            Remove Classroom {index + 1}
                                        </button>
                                    </div>
                                )}
                            </div>
                        </React.Fragment>
                    ))}

                    <button
                        className="btn btn-primary mb-4"
                        type="button"
                        onClick={() =>
                            arrayHelpers.push({
                                university: "",
                                class: "",
                                semester: "",

                                lecture_type: '',
                                otheruniversity: '',
                                othersemester: '',
                                regular_price: '',
                                price_type: '',

                                is_discount: '',
                                discount_price: '',
                                discount_start_date: '',
                                limited_offer: '',
                                discount_end_date: '',
                                description: '',
                                title: '',
                                thumbnail: '',
                                requirements: '',
                                curriculum: '',
                            })
                        }
                    >
                        Add More Classroom
                    </button>
                </div >
            )}
        />
    );
}
