import utils from "../../../utils/utils";

export default class AttendanceApi {
    getClassList(payload) {
        return utils.sendApiRequest(`student/class-course`, "POST", true, payload)
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    }

    getClassSubjectList(payload) {
        return utils.sendApiRequest(`student/class-subject-list`, "POST", true, payload)
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    }

    getClassSubjects(id) {
        return utils.sendApiRequest(`student/class-subjects/${id}`, "GET", true, [])
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    }


    getClassSubjectAttendanceList(payload) {
        return utils.sendApiRequest(`student/class-subject-attendance`, "POST", true, payload)
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    }
}