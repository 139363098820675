import React from 'react';

const RegisterYourself = () => {
    return (
        <>
            <div className="register mb-5">
                <div className="row">
                    <div className="col-md-6 col-sm-12 register-left pr-5 d-sm-none d-md-block">
                        <img src="/assets/images/form.webp" alt="Registration" />
                    </div>
                    <div className="col-md-6 col-sm-12 register-right" data-aos="zoom-in">
                        <div className="tab-content mt-5" id="myTabContent">
                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <h2 className="register-heading">Contact Us</h2>
                                <div className="row register-form">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className='ms-3' htmlFor="name">Name *</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                id="name" 
                                                placeholder="Enter Your Name *" 
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className='ms-3' htmlFor="email">Email *</label>
                                            <input 
                                                type="email" 
                                                className="form-control" 
                                                id="email" 
                                                placeholder="Your Email *" 
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className='ms-3' htmlFor="phone">Phone *</label>
                                            <input 
                                                type="text" 
                                                minLength="10" 
                                                maxLength="10" 
                                                name="txtEmpPhone" 
                                                className="form-control" 
                                                id="phone" 
                                                placeholder="Your Phone *" 
                                            />
                                        </div>
                                        <input 
                                            type="submit" 
                                            className="btn btn-primary btnRegister mt-3" 
                                            value="Register Now" 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RegisterYourself;
