import React from 'react'

const HomePrice = () => {
    return (
        <>
            <section class="pricing-section mt-9 mb-9">
                <div class="container">
                    <div class="row justify-content-md-center">
                        <div class="col-xl-5 col-lg-6 col-md-8">
                            <div class="Psection-title text-center title-ex1">
                                <h2>Our Facilitys</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 " data-aos="fade-right">
                            <div class="price-card featured">
                                <h2 className='mb-8'>Educational Aggregator</h2>
                                {/* <p class="price"><span>49</span>/ Month</p> */}
                                <ul class="pricing-offers">
                                    <li> <i class="fas fa-check-circle me-2"></i>Diverse Resources</li>
                                    <li> <i class="fas fa-check-circle me-2"></i>Course Discovery</li>
                                    <li> <i class="fas fa-check-circle me-2"></i>Institute Finder</li>
                                    <li> <i class="fas fa-check-circle me-2"></i>Teacher Matching</li>
                                    <li> <i class="fas fa-check-circle me-2"></i>Personalized Recommendations</li>
                                    <li> <i class="fas fa-check-circle me-2"></i>One-Stop Solution</li>
                                </ul>
                                <a href="#" class="btn btn-primary btn-mid">More Details</a>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-5" data-aos="flip-left">
                            <div class="price-card featured">
                                <h2 className='mb-lg-4  mb-4 mb-sm-8 '>Learning Management System</h2>
                                {/* <p class="price"><span>69</span>/ Month</p> */}
                                <ul class="pricing-offers pt-5 pt-md-0">
                                    <li> <i class="fas fa-check-circle me-2"></i>Attendance Management</li>
                                    <li> <i class="fas fa-check-circle me-2"></i>Video Hosting</li>
                                    <li> <i class="fas fa-check-circle me-2"></i>Virtual Classrooms</li>
                                    <li> <i class="fas fa-check-circle me-2"></i>Reporting and Analytics</li>
                                    <li> <i class="fas fa-check-circle me-2"></i>Alerts and Reminders</li>
                                    <li> <i class="fas fa-check-circle me-2"></i>Collaboration and Communication</li>
                                </ul>
                                <a href="#" class="btn btn-primary btn-mid">More Details</a>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-5" data-aos="flip-right">
                            <div class="price-card featured">
                                <h2 className='mb-9 mb-sm-8 mb-xl-9 defaultmrg'>Career Guidance</h2>
                                {/* <p class="price"><span>89</span>/ Month</p> */}
                                <ul class="pricing-offers">
                                    <li> <i class="fas fa-check-circle me-2"></i>Assessment</li>
                                    <li> <i class="fas fa-check-circle me-2"></i>Decision-making Support</li>
                                    <li> <i class="fas fa-check-circle me-2"></i>Skill Development</li>
                                    <li> <i class="fas fa-check-circle me-2"></i>Networking Opportunities</li>
                                    <li> <i class="fas fa-check-circle me-2"></i>Insight into job market trends</li>
                                    <li></li>
                                    <li></li>
                                </ul>
                                <a href="#" class="btn btn-primary btn-mid">More Details</a>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-5" data-aos="fade-left">
                            <div class="price-card featured">
                                <h2 className='mb-9 mb-sm-8 mb-xl-9 defaultmrg'>Education Loan</h2>
                                {/* <p class="price"><span>89</span>/ Month</p> */}
                                <ul class="pricing-offers">
                                    <li> <i class="fas fa-check-circle me-2"></i>Loan Amount</li>
                                    <li> <i class="fas fa-check-circle me-2"></i>Repayment Terms</li>
                                    <li> <i class="fas fa-check-circle me-2"></i>Low Interest Rates</li>
                                    <li> <i class="fas fa-check-circle me-2"></i>Easy Application Process</li>
                                    <li> <i class="fas fa-check-circle me-2"></i>Personalized Customer Support</li>
                                    <li></li>
                                    <li></li>
                                </ul>
                                <a href="#" class="btn btn-primary btn-mid">More Details</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomePrice
