import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import url from "../../../../constants/url";
import ReactPaginate from "react-paginate";
import moment from "moment";
import NoDataAvailable from "../../../common/NoDataAvailable";
let rating = [1, 2, 3, 4, 5];
const Reviews = () => {
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [search, setSearch] = useState({
        start: 0,
        perPage: 5,
    });

    const apiCall = async (limit, offset) => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        const config = {
            headers: {
                content: "application/json",
                AUTHTOKEN: auth_token,
            },
        };

        try {
            const response = await axios.get(
                `${url.apiUrl}get-own-added-review/${limit}/${offset}`,
                config
            );
            if (response) {
                setData(response.data?.data);
                setTotalPages(response?.data.total_review / search.perPage)
            }
        } catch (err) {
            throw err;
        }
    };

    const handlePageClick = async (data) => {
        let currentPage = data.selected * search.perPage;
        await apiCall(search.perPage, currentPage);
    };
    useEffect(() => {
        apiCall(search.perPage, search.start);
    }, [search]);

    return (
        <>
            <div className="row ">
                {data?.length > 0 ? (
                    data.map((item, i) => {
                        return (
                            <div className="media mt-0 mb-4 p-5 border br-5 review-media" key={i}>
                                <div className="d-flex me-3">
                                    {item?.online_course ? (
                                        <Link to={"/institute-dashboard/video-course-detail/" + item?.online_course.id}>
                                            <img
                                                className="border-radius-50 avatar-lg"
                                                alt={item?.online_course?.name}
                                                src={item?.online_course?.teaser_thumb_image}
                                            />
                                        </Link>
                                    ) : (
                                        <Link to={"/student-dashboard/class-detail/" + item?.class_course.id}>
                                            <img
                                                className="border-radius-50 avatar-lg"
                                                alt={item?.class_course?.name}
                                                src={item?.class_course?.thumbnail}
                                            />
                                        </Link>
                                    )}
                                </div>
                                <div className="media-body">
                                    <h4 className="mt-0 mb-1 font-weight-semibold">
                                        {item?.online_course ? (
                                            <Link to={"/institute-dashboard/video-course-detail/" + item?.online_course.id}>
                                                {item?.online_course?.title}
                                            </Link>
                                        ) : (
                                            <Link to={"/student-dashboard/class-detail/" + item?.class_course.id}>
                                                {item?.class_course?.title}
                                            </Link>
                                        )}
                                        <small className="text-muted float-end">
                                            {moment(item?.created_at).format("DD-MM-YYYY hh:mm A")}
                                        </small>
                                    </h4>
                                    <span className="d-inline-flex mb-2">
                                        <div className="br-widget">
                                            {rating.map((el, i) => (
                                                <i
                                                    data-rating-value={el}
                                                    data-rating-text={el}
                                                    className={
                                                        el <= item?.average_review
                                                            ? "br-selected"
                                                            : ""
                                                    }
                                                    key={i}
                                                />
                                            ))}
                                        </div>
                                    </span>
                                    <p className="mb-2 mt-2 education-mandal" dangerouslySetInnerHTML={{ __html: item?.comment }}>
                                    </p>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <NoDataAvailable/>
                )}
                {data?.length > 0 && (
                    <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={Math.ceil(totalPages)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                    />
                )}
            </div>
        </>
    );
};

export default Reviews;
