import React, { useState, useEffect } from "react";
import AddEducation from "./AddEducation";
import EditEducation from "./EditEducation";
import url from "../../../../../../constants/url";
import useToken from "../../../../../../hooks/useToken";
import Moment from "react-moment";
import NoDataAvailable from "../../../../../common/NoDataAvailable";
const Education = () => {
    const { token } = useToken();
    const [allEducations, setAllEducations] = useState([]);
    const [singleEducation, setSingleEducation] = useState({});
    const [show, setShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const handleShow = () => setShow(true);
    const handleIsEditShow = () => setIsEdit(true);
    function getAllEducations() {
        try {
            fetch(`${url.apiUrl}institute-teacher/get-all-educations`, {
                method: "POST",
                headers: { "Content-Type": "application/json", AUTHTOKEN: token },
                body: JSON.stringify({
                    limit: 10,
                    offset: 0,
                }),
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === "success") {
                        setAllEducations(result.data);
                    }
                })
                .catch((error) => console.log(error));
        } catch (error) {
            console.log(
                error?.data?.message || error.message || "Something want wrong"
            );
        }
    }
    useEffect(() => {
        getAllEducations();
    }, []);
    return (
        <React.Fragment>
            <div className='card'>
                <div className='card-header justify-content-between'>
                    <h4 className='card-title'>Teacher's Education Details</h4>
                    <div className='d-flex align-items-center'>
                        <button
                            type='button'
                            className='btn btn-primary'
                            onClick={handleShow}
                        >
                            <i className='fa fa-plus'></i>
                        </button>
                        <AddEducation
                            show={show}
                            setShow={setShow}
                            getAllEducations={getAllEducations}
                        />
                        <EditEducation
                            show={isEdit}
                            setShow={setIsEdit}
                            data={singleEducation}
                            getAllEducations={getAllEducations}
                        />
                    </div>
                </div>
                {allEducations?.length > 0 ? (
                    allEducations?.map((education) => (
                        <div className='card-body' key={education?.id}>
                            <ul className='d-flex mb-0'>
                                <li>
                                    <div className='activity1 bg-primary'>
                                        <i className='fe fe-shopping-bag'></i>
                                    </div>
                                </li>
                                <li>
                                    <div className='font-weight-semibold'>
                                        {education?.degree} - ({education?.field_of_study})
                                    </div>
                                    <p className="text-muted mb-0">{education?.school_college}</p>
                                </li>
                                <div className='text-muted ms-auto'>
                                    <Moment format='DD-MM-YYYY'>{education?.start_date}</Moment> to {education?.is_present === 1 ? (
                                        "Present "
                                    ) : (
                                        <React.Fragment>
                                            <Moment format='DD-MM-YYYY'>{education?.end_date}</Moment>{" "}
                                        </React.Fragment>
                                    )}
                                    <button
                                        type='button'
                                        className='btn btn-primary ms-2'
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setSingleEducation(education);
                                            handleIsEditShow();
                                        }}
                                    >
                                        <i className='fa fa-edit'></i>
                                    </button>
                                </div>
                            </ul>
                        </div>
                    ))
                ) : (
                    <div className="card-body">
                        <NoDataAvailable />
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default React.memo(Education);
