import React, { useEffect, useState } from "react";
import Footer from "../../Footer/Footer";
import Headerinner from "../../Header/Headerinner";
import Curve from '../../Header/Curve';
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Moment from "react-moment";
import ReactPaginate from "react-paginate";
import useWindowSize from "../../../../hooks/useWindowSize";
import BlogCategory from "./BlogDetails/BlogCategory";
import PopularBlogs from "./BlogDetails/PopularBlogs";
import NoDataAvailable from "../../../common/NoDataAvailable";

export default function Blog() {
    const { width: windowWidth } = useWindowSize();
    const [category, setCategory] = useState([]);
    const [blogList, setBlogList] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [offset, setOffset] = useState(0);
    const param = useParams();
    const [search, setSearch] = useState({
        start: 0,
        perPage: 12,
        searchTxt: "",
        searchField: "",
        categoryId: 0
    });
    useEffect(() => {
        BlogListApi();
    }, []);
    useEffect(() => {
        BlogListApi();
    }, [param]);


    const apiCall = async (categoryId, limit, offset) => {
        const config = {
            headers: {
                content: "multipart/form-data",
            },
        };
        try {
            const response = await axios.get(
                "https://educationmandal.com/em-admin/api/" +
                `get-blog-list/${categoryId}/${limit}/${offset}`,
                config
            );
            if (response) {
                setBlogList(response.data?.data);
                setTotalPages(response.data?.count / search.perPage);
                setTotalCount(response.data?.count);
            }
        } catch (err) {
            throw err;
        }
    };

    const BlogListApi = async () => {
        if (param.id) {
            await apiCall(param.id, search.perPage, 0);
        } else {
            await apiCall(0, search.perPage, search.start);
        }
    };

    const handlePageClick = async (data) => {
        let currentPage = data.selected * search?.perPage;
        setOffset(currentPage)
        if (param.id) {
            setSearch({ ...search, categoryId: param.id })
            await apiCall(param.id, search.perPage, currentPage);
        } else {
            setSearch({ ...search, categoryId: 0 })
            await apiCall(0, search.perPage, currentPage);
        }
    };
    return (
        <>
            <Headerinner />
            <div
                className="cover-image bg-background-1"
                data-bs-image-src="assets/images/banners/banner1.webp"
                style={{
                    background: 'url("/assets/images/banners/banner1.webp") center center',
                }}
            >
                <div className="container header-text">
                    <h1>Latest Blogs</h1>
                </div>
            </div>
            <Curve />
            <section className="sptb">
                <div className="container">
                    <div className="row">
                        {windowWidth >= 768 && (
                            <div className="col-xl-3 col-lg-4 col-md-12">
                                {param.id && (
                                    <div className="card">
                                        <div className="card-body">
                                            <Link to="/blog">
                                                All Blogs
                                            </Link>
                                        </div>
                                    </div>
                                )}

                                <BlogCategory />
                                <PopularBlogs />
                            </div>
                        )}

                        <div className="col-xl-9 col-lg-8 col-md-12">
                            <div className="row">
                                {blogList?.length > 0 ? (
                                    <>
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="mb-0">
                                                        Showing {offset + 1} to {offset + blogList?.length} of {totalCount} entries
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        {blogList.map((item, i) => (
                                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12" key={i}>
                                                <Link
                                                    to={`/blog-detail/${item?.id}`}
                                                >
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="item-card7-img">
                                                                <img
                                                                    src={item?.thumb_image}
                                                                    alt={item?.category?.name}
                                                                    className="cover-image"
                                                                />
                                                            </div>
                                                            <div className="item7-card-desc d-flex justify-content-between mt-3">
                                                                <span className="item-card-badge">
                                                                    {item?.category?.name}
                                                                </span>
                                                                <span className="ms-auto">
                                                                    <i className="fe fe-calendar me-2" />
                                                                    <Moment format="DD-MM-YYYY">{item?.updated_at}</Moment>
                                                                </span>
                                                            </div>
                                                            <div className="item-card2-desc">
                                                                <h4 className="mb-2 leading-tight">
                                                                    {item?.name}
                                                                </h4>
                                                                <div
                                                                    className="mb-4 leading-tight3 education-mandal"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: item?.description.substring(0, 150),
                                                                    }}
                                                                ></div>
                                                            </div>
                                                            <div className="d-flex align-items-center pt-2 mt-auto">
                                                                <img
                                                                    src={item?.user_profile}
                                                                    className="avatar border-radius-50 avatar-md me-3"
                                                                    alt={item?.user_name}
                                                                />
                                                                <div>
                                                                    {item?.user_name}
                                                                    <small className="d-block text-muted">
                                                                        <Moment fromNow ago>{(item?.updated_at)}</Moment> ago
                                                                    </small>
                                                                </div>
                                                                <div className="ms-auto text-primary">
                                                                    <span className="fa-regular fa-thumbs-up"></span> {item?.like_count} Likes
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="mb-0">
                                                        Showing {0} to {offset + blogList?.length} of {totalCount} entries
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <NoDataAvailable/>
                                    </>
                                )}
                            </div>
                            {blogList?.length > 0 && (
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    breakLabel={"..."}
                                    pageCount={Math.ceil(totalPages)}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />
                            )}
                        </div>
                        {windowWidth <= 768 && (
                            <div className="col-xl-3 col-lg-4 col-md-12">
                                {param.id && (
                                    <div className="card">
                                        <div className="card-body">
                                            <Link to="/blog">
                                                All Blogs
                                            </Link>
                                        </div>
                                    </div>
                                )}
                                <BlogCategory />
                            </div>
                        )}
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}; 