import React from "react";
import { Link } from "react-router-dom";
import NoDataAvailable from "../../../common/NoDataAvailable";

export default function StudentList({
    studentList,
    searchs,
    setSearch,
    studentCount,
    type
}) {
    const loadMorehandle = () => {
        setSearch({ ...searchs, perpage: searchs.perpage + 9 });
    };

    return (
        <>
            {(type == 'student' && studentList?.length == 0) && (
                <NoDataAvailable/>
            )}
            {studentList?.length > 0 && (
                <div className="col-md-12 ">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Student's List</h4>
                        </div>
                        <div className="card-body pb-0">
                            {studentList?.length > 0 ? (
                                <div className="row">
                                    {studentList.map((item, i) => (
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12" key={i}>
                                            <Link to={`/student-detail/${item?.student_id}`}>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="text-center">
                                                            <div className="item-card7-img">
                                                                <img
                                                                    src={item?.image}
                                                                    className="cover-image teacher-student-image"
                                                                    alt={item?.name}
                                                                />
                                                            </div>
                                                            <h4 className="mt-4 leading-tight">
                                                                {item?.name}
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <NoDataAvailable/>
                            )}
                        </div>
                        {studentList?.length < studentCount && (
                            <div className="card-footer" onClick={loadMorehandle}>
                                <Link to="#" className="w-100">
                                    Load More Student's
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
