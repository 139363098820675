import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";


const Payment = (props) => {
    return (
        <>
            <div className="card">
                <div className="card-header ">
                    <h4 className="card-title">Payment History</h4>
                </div>
                <div className="card-body ">
                    <div id="basic-1_wrapper" className="dataTables_wrapper no-footer">
                        <div id="basic-1_filter" className="dataTables_filter">
                            <label>
                                Search:
                                <input
                                    type="search"
                                    placeholder="Search..."
                                    onChange={(e) => {
                                        props.setSearch({
                                            ...props.search,
                                            searchTxt: e.target.value,
                                        });
                                    }}
                                />
                            </label>
                        </div>
                        <table className="table table-bordered tex-nowrap table-hover dataTable table-responsive-xl">
                            <thead>
                                <tr>
                                    <th className="text-center">Sr. No.</th>
                                    <th>Student Details</th>
                                    <th>Teacher Details</th>
                                    <th>Course Details</th>
                                    <th className="text-center">Order Id</th>
                                    <th className="text-center text-nowrap">Price</th>
                                    <th className="text-center">Purchase Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.data.length > 0 ? (
                                    props.data.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <th className="text-center"> {i + props.offset + 1}</th>
                                                <td>
                                                    <div className="media">
                                                        <img
                                                            src={item?.student?.image}
                                                            alt={item?.student?.name}
                                                            className="me-3 rounded-circle"
                                                        />
                                                        <div className="media-body">
                                                            <Link to={`/institute-dashboard/student-profile/${item?.student?.id}`} className="font-weight-semibold">
                                                                {item?.student?.name}
                                                            </Link>
                                                            <p className="mb-0 text-muted">
                                                                {item?.student?.email}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    {item?.teacher ? (
                                                        <div className="media">
                                                            <img
                                                                src={item?.teacher?.image}
                                                                alt={item?.teacher?.name}
                                                                className="me-3 rounded-circle"
                                                            />
                                                            <div className="media-body">
                                                                <Link to={`/institute-dashboard/teacher-detail/${item.id}`} className="font-weight-semibold">
                                                                    {item?.teacher?.name}
                                                                </Link>
                                                                <p className="mb-0 text-muted">
                                                                    {item?.teacher?.email}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ) : (<p className="text-center mb-0">---</p>)}
                                                </td>
                                                <td>
                                                    {item?.online_course ? (
                                                        <div className="media">
                                                            <img
                                                                src={item?.online_course?.teaser_thumb_image}
                                                                alt={item?.online_course?.title}
                                                                className="me-3 rounded-circle"
                                                            />
                                                            <div className="media-body">
                                                                <Link to={`/institute-dashboard/video-course-detail/1/${item?.online_course?.id}`} className="font-weight-semibold">
                                                                    {item?.online_course?.title}
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="media">
                                                            <img
                                                                src={item?.class_course?.thumbnail}
                                                                alt={item?.class_course?.title}
                                                                className="me-3 rounded-circle"
                                                            />
                                                            <div className="media-body">
                                                                <Link to={`/institute-dashboard/class-detail/1/${item?.class_course?.id}`} className="font-weight-semibold">
                                                                    {item?.class_course?.title}
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    )}
                                                </td>
                                                <td className="text-center">
                                                    {item?.payment ? item?.payment.orderId : '---'}
                                                </td>
                                                <td className="text-center text-nowrap">
                                                    {item?.is_enroll == 1 ? (<>
                                                        Free
                                                    </>) : (
                                                        <>
                                                            <p className="mb-0"><b>MRP: </b> ₹{item?.mrp} </p>
                                                            <p className="mb-0"><b>Discount: </b> ₹{item?.discount} </p>
                                                            <p className="mb-0"><b>GST: </b> ₹{Math.round(item?.gst)}</p>
                                                            <p className="mb-0"><b>Total Amount: </b> ₹{Math.round(item?.total_payable_price)}</p>
                                                        </>
                                                    )}

                                                </td>
                                                <td className="text-center text-nowrap">{moment(item?.payment_date).format("DD-MM-YYYY hh:mm A")}</td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td className="text-center" colSpan={7}>No Data Available !</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        {props.data.length > 0 && (
                            <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                pageCount={Math.ceil(props.count)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={props.handlePageClick}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Payment;
