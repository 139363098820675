import React, { useState } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    const [date] = useState(new Date().getFullYear());
    return (
        <>
            <footer className="footer">
                <div className="container"> 
                    <div className="row align-items-center"> 
                        <div className="col-lg-12 col-sm-12 text-center"> Copyright © {date} 
                            <Link to="#" className="text-primary"> Education Mandal </Link>! Developed By 
                            <Link to="#" className="text-primary"> Msts Global </Link> All rights reserved. 
                        </div> 
                    </div> 
                </div> 
            </footer>
        </>
    );
};

export default Footer;