import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import url from '../../../../constants/url';
import utils from "../../../../utils/utils";
import ReactPaginate from 'react-paginate';
import NoDataAvailable from "../../../common/NoDataAvailable";
const CourseList = () => {
    let now = new Date().setHours(0, 0, 0, 0);
    let rating = [1, 2, 3, 4, 5];
    const [Data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [search, setSearch] = useState({
        start: 0,
        perPage: 12,
    });
    const getAllCourse = async (activity) => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);

        var formdata = new FormData();
        formdata.append("limit", activity.limit);
        formdata.append("offset", activity.offset);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
        };

        fetch(`${url.apiUrl}student/online-course`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setData(result.data);
                    setTotalCount(result.count / search.perPage);
                }
            })
            .catch((error) => console.log("error", error));
    };

    useEffect(() => {
        var activity = {
            limit: search.perPage,
            offset: search.start
        }
        getAllCourse(activity);
    }, []);


    const handlePageClick = async (data) => {
        let currentPage = data.selected * search.perPage;
        const activity = {
            limit: search.perPage,
            offset: currentPage,
        };
        await getAllCourse(activity);
    };
    return (
        <>
            <div className="row">
                {Data?.length > 0 ? (
                    Data?.map((item, key) => (
                        <div className="col-lg-4 col-md-6 col-sm-12" key={key}>
                            <div className="card">
                                <Link to={`/student-dashboard/video-course-detail/${item?.id}`} >
                                    <div className="card-body ">
                                        <div className="item-card7-img">
                                            <img className="cover-image" src={item?.teaser_thumb_image} alt={item?.title} />
                                            {(
                                                item?.price == "paid" && (
                                                    item?.is_discount == 1 && (
                                                        (item?.is_limited_offer == 1 && (new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                        (item?.is_limited_offer == 0 && new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                )
                                            ) && (
                                                    <Link to="#" className="bg-danger top left">
                                                        {item?.discount_price}% off
                                                    </Link>
                                                )}
                                            <Link to="#" className="bg-danger right top">
                                                Video Course
                                            </Link>
                                            {item?.is_professional === 0 && (
                                                <Link to="#" className="bg-danger right bottom">
                                                    {item?.board}
                                                </Link>
                                            )}
                                        </div>
                                        <div className="item-card2-desc">
                                            <div className="d-inline-flex mb-2">
                                                <div className="br-widget">
                                                    {rating.map((el, i) => (
                                                        <i
                                                            data-rating-value={el}
                                                            data-rating-text={el}
                                                            className={
                                                                el <= item?.average_review
                                                                    ? "br-selected"
                                                                    : ""
                                                            }
                                                            key={i}
                                                        />
                                                    ))}
                                                </div>
                                                <span>({item?.review_count} Reviews)</span>
                                            </div>
                                            <h4 className="mb-2 leading-tight2">
                                                {item?.title}
                                            </h4>
                                            <p className="leading-tight3 education-mandal">
                                                {item?.short_discription}
                                            </p>

                                            <div className="pt-2 pb-2 border-bottom d-flex justify-content-between">
                                                <div className="d-flex justify-content-start">
                                                    <i className="fa fa-shop me-2" />
                                                    <span className="text-muted text-data-over-cut">
                                                        {item?.institute.name}
                                                    </span>
                                                </div>
                                                <div className="float-end">
                                                    <i className="fa fa-signal me-2" />
                                                    <span className="text-muted">
                                                        {item?.course_level}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="pt-2 pb-2 border-bottom">
                                                <i className="fa fa-file me-2" />
                                                <span className="text-muted">
                                                    {item?.lectures_count} Videos
                                                </span>
                                                <div className="float-end">
                                                    <i className="fa fa-clock-o me-1" />
                                                    <span className="text-muted">
                                                        {item?.total_duretion ? utils.toHHMMSS(item?.total_duretion) : 0} Hours
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="pt-2">
                                                <i className="fa fa-microphone me-2" />
                                                <span className="text-muted">
                                                    {item?.audio_language}
                                                </span>
                                                <div className="float-end">
                                                    {item?.price === "paid" && (
                                                        <h4 className="mb-0"> ₹ {Math.round(item?.payable_price)} {(
                                                            item?.price == "paid" && (
                                                                item?.is_discount == 1 && (
                                                                    (item?.is_limited_offer == 1 && (new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                    (item?.is_limited_offer == 0 && new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                            )
                                                        ) && (<del className="fs-14 text-muted"> ₹ {Math.round(item?.regular_price)} </del>
                                                            )}
                                                        </h4>
                                                    )}
                                                    {item?.price === "free" && (
                                                        <h4 className="mb-0">Free</h4>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    ))
                ) : (
                    <NoDataAvailable />
                )}
            </div>
            {Data?.length > 0 && (
                <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={totalCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={
                        "pagination"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                />
            )}
        </>
    );
};

export default CourseList;
