import React, { useEffect, useState } from "react";
import url from "../../../../constants/url";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

function BranchDetails(props) {
    const [data, setData] = useState([]);
    const [search, setSearch] = useState({
        start: 0,
        perPage: 10,
        searchTxt: "",
        searchField: "",
    });
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState();
    var localdata = localStorage.getItem("common-user-info");
    var jsondata = JSON.parse(localdata);
    var token_authorization = jsondata.auth_token;

    const apiCall = async (activity) => {
        let result = await fetch(`${url.apiUrl}teacher/get-all-branches`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                AUTHTOKEN: token_authorization,
            },
            body: JSON.stringify({
                status: activity.status,
                limit: activity.limit,
                offset: activity.offset,
                query: activity.query,
                address: activity.address,
            }),
        });
        if (result?.status === 200) {
            let varResult = await result.json();
            if (varResult?.status == "success") {
                setData(varResult?.data);
                setCount(varResult?.count);
            }
        }
    };

    const getbranches = async () => {
        let activity = {
            limit: search.perPage,
            offset: search.start,
            status: "",
            address: props.statusFilter,
            query: search.searchTxt,
        };
        await apiCall(activity);
    };

    useEffect(() => {
        // ComponentDidMount();
        getbranches();
    }, [search]);

    const handlePageClick = async (data) => {
        let currentPage = data.selected * search.perPage;
        setOffset(currentPage);
        let activity = {
            limit: search.perPage,
            offset: currentPage,
            status: "",
            address: props.statusFilter,
            query: search.searchTxt,
        };
        await apiCall(activity);
    };

    function searchCategoryList(e) {
        search.searchTxt = e.target.value;
        search.start = 0;
        getbranches();
    }

    return (
        <>
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Branch List</h4>
                    </div>
                    <div className="card-body">
                        <div className=" p-0 pb-2">
                            <div
                                id="basic-1_wrapper"
                                className="dataTables_wrapper no-footer"
                            >
                                <div id="basic-1_filter" className="dataTables_filter">
                                    <label>
                                        Search:
                                        <input
                                            type="search"
                                            placeholder="Search..."
                                            onChange={(e) => searchCategoryList(e)}
                                        />
                                    </label>
                                </div>
                                <table className="table table-bordered table-hover dataTable table-responsive-xl">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Sr. No.</th>
                                            <th>Branch Details</th>
                                            <th className=" text-nowrap">Contact Person Details</th>
                                            <th>Branch Location</th>
                                            <th className="text-center">Year of Incorporation</th>
                                            <th className="text-center">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.length > 0
                                            ? data.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <th className="text-center">
                                                            {i + offset + 1}
                                                        </th>
                                                        <td>
                                                            <Link
                                                                to={"/teacher-dashboard/branch-detail/" + item?.id}

                                                            >
                                                                <div className="media">
                                                                    <img
                                                                        src={item?.image}
                                                                        alt={item?.name}
                                                                        className="me-3 rounded-circle"
                                                                    />
                                                                    <div className="media-body">
                                                                        <p className="font-weight-semibold mb-0">
                                                                            {item?.name}
                                                                        </p>
                                                                        {item?.email}
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <div className="media">
                                                                <div className="media-body">
                                                                    <p className="mb-0 h6">
                                                                        {item?.contact_person_name}
                                                                    </p>
                                                                    <p className="mb-0 text-muted">
                                                                        {item?.contact}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {item?.address}, {item?.cities?.name}, {item?.states?.name}, {item?.pincode}
                                                        </td>
                                                        <td className="text-center">
                                                            {item?.year_of_startup ? item?.year_of_startup : "-"}
                                                        </td>
                                                        <td className="text-center text-capitalize">
                                                            {item?.status}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                            : <tr>
                                                <td className="text-center" colSpan={6}>No Data Available !</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                                {data?.length > 0 && (
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        pageCount={Math.ceil(count / 10)}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default BranchDetails;
