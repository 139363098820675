
import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { toast, ToastContainer } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";
import url from "../../../../constants/url";
import { getInstituteBranchGetAllBranches, getGetAllBoard, getGetAllUniversity, getGetAllMediums } from "../../../../apis";
import axios from "axios";
import { createSlug } from "../../../../utils/utils";
import SubmitLoader from "../../../common/SubmitLoader"
import moment from "moment";

const Careers = () => {
    const params = useParams();
    const history = useHistory();
    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data)
    const auth_token = local_storage.auth_token;
    const [loading, setLoading] = useState(0)
    const [branch, setBranch] = useState([]);
    const [board, setBoard] = useState([]);
    const [university, setUniversity] = useState([]);
    const [medium, setMedium] = useState([]);
    const [image, setImage] = useState("");
    const [instituteData, setInstituteData] = useState({});
    const [values, setValues] = useState({
        branch_id: "",
        title: "",
        slug: "",
        job_type: "",
        employee_type: "",
        class_type: "",
        tuition_type: "",
        board: "",
        university: "",
        medium: "",
        start_date: "",
        is_end_job: "",
        end_date: "",
        experience: "",
        is_disclosed_salary: "",
        salary: "",
        description: "",
        job_responsibility: "",
        requirment: "",
        about_company: "",
        thumb_image: ""
    })

    var myHeaders = new Headers();
    myHeaders.append("AUTHTOKEN", auth_token);
    myHeaders.append("Content-Type", "application/json");

    const getCareerDetail = () => {
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
        };

        fetch(`${url.apiUrl}institute/career/${params.is_main_career}/${params.id}`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setValues(result?.data)
                }
            })
            .catch(error => console.log('error', error));
    }

    const ValidateSchema = Yup.object().shape({
        branch_id: Yup.string().required("Required"),
        title: Yup.string().required("Required"),
        slug: Yup.string().required("Required"),
        job_type: Yup.string().required("Required"),
        employee_type: Yup.string().required("Required"),
        class_type: Yup.string().required("Required"),
        tuition_type: Yup.string().when("class_type", {
            is: (class_type) => class_type && class_type == "tuition",
            then: Yup.string().required("Required"),
        }).nullable(),
        board: Yup.string().when(["class_type", "tuition_type"], {
            is: (class_type, tuition_type) => class_type && (class_type == "school" || (class_type == "tuition" && tuition_type == "school")),
            then: Yup.string().required("Required"),
        }).nullable(),
        university: Yup.string().when(["class_type", "tuition_type"], {
            is: (class_type, tuition_type) => class_type && (class_type == "university" || (class_type == "tuition" && tuition_type == "university")),
            then: Yup.string().required("Required"),
        }).nullable(),
        medium: Yup.string().required("Required"),
        start_date: Yup.string().required("Required"),
        is_end_job: Yup.string().required("Required"),
        end_date: Yup.string().when("is_end_job", {
            is: (is_end_job) => is_end_job && is_end_job == "1",
            then: Yup.string().required("Required"),
        }).nullable(),
        experience: Yup.string().required("Required"),
        is_disclosed_salary: Yup.string().required("Required"),
        salary: Yup.string().when("is_disclosed_salary", {
            is: (is_disclosed_salary) => is_disclosed_salary && is_disclosed_salary == "1",
            then: Yup.string().required("Required"),
        }).nullable(),
        description: Yup.string().required("Required"),
        job_responsibility: Yup.string().required("Required"),
        requirment: Yup.string().required("Required"),
        about_company: Yup.string().required("Required"),
        thumb_image: Yup.string().required("Required"),
    });

    const FormSubmit = async (values) => {
        try {
            setLoading(true)

            const formData = new FormData();
            formData.set("branch_id", values.branch_id);
            formData.set("title", values.title);
            formData.set("slug", createSlug(values.slug));
            formData.set("job_type", values.job_type);
            formData.set("employee_type", values.employee_type);
            formData.set("class_type", values.class_type);
            if (values.class_type === "tuition") {
                formData.set("tuition_type", values.tuition_type);
            }
            if (values.class_type === "school" || (values.class_type === "tuition" && values.tuition_type === "school")) {
                formData.set("board", values.board);
            }
            if (values.class_type === "university" || (values.class_type === "tuition" && values.tuition_type === "university")) {
                formData.set("university", values.university);
            }
            formData.set("medium", values.medium);
            formData.set("start_date", values.start_date);
            formData.set("is_end_job", values.is_end_job);
            if (values.is_end_job == "1") {
                formData.set("end_date", values.end_date);
            }
            formData.set("experience", values.experience);
            formData.set("is_disclosed_salary", values.is_disclosed_salary);
            if (values.is_disclosed_salary == "1") {
                formData.set("salary", values.salary);
            }
            formData.set("description", values.description);
            formData.set("job_responsibility", values.job_responsibility);
            formData.set("requirment", values.requirment);
            formData.set("about_company", values.about_company);
            formData.set("thumb_image", values.thumb_image);

            const config = {
                headers: {
                    content: "multipart/form-data",
                    AUTHTOKEN: auth_token,
                },
            };

            if (params.id) {
                var response = await axios.post(
                    "https://educationmandal.com/em-admin/api/" +
                    `institute/update-career/${params.is_main_career}/${params.id}`,
                    formData,
                    config
                );
            } else {

                var response = await axios.post(
                    "https://educationmandal.com/em-admin/api/" +
                    `institute/create-career`,
                    formData,
                    config
                );
            }
            if (response) {
                setLoading(false)
                if (response.data.status === "success") {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setTimeout(() => {
                        if (params.id) {
                            history.push("/institute-dashboard/careers");
                        } else {
                            history.push("/institute-dashboard/careers");
                        }
                    }, 5000)
                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            }


        } catch (err) {
            throw err;
        }
    }

    const getAllBraches = async () => {
        let res = await getInstituteBranchGetAllBranches();
        if (res.status === "success") {
            let data = res.data;

            setBranch(data);
        }
    };

    const getAllBoard = async () => {
        let res = await getGetAllBoard();
        if (res.status === "success") {
            let data = res.data;

            setBoard(data);
        }
    };
    const getAllUniversity = async () => {
        let res = await getGetAllUniversity();
        if (res.status === "success") {
            let data = res.data;

            setUniversity(data);
        }
    };
    const getAllMediums = async () => {
        let res = await getGetAllMediums();
        if (res.status === "success") {
            let data = res.data;

            setMedium(data);
        }
    };

    const GetInstituteProfileData = async () => {
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
        };
        fetch(`${url.apiUrl}institute/get-profile`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setValues({ ...values, about_company: result.data.about })
                    setInstituteData(result.data);
                }
            })
            .catch((error) => console.log("error", error));
    };

    useEffect(() => {
        getAllBraches();
        getAllBoard();
        getAllUniversity();
        getAllMediums();
        if (!params.id) {
            GetInstituteProfileData();
        } else {
            getCareerDetail()
        }
    }, []);

    const handleFileInputChange = async (e, props) => {
        let { file } = e.target.files[0];

        file = e.target.files[0];

        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function (e) {
            var image = new Image();
            image.src = e.target.result;
            image.onload = function () {
                var height = this.height;
                var width = this.width;
                if (height > 200 || width > 368) {
                    alert(
                        "Image size: width = 368px and height = 200px"
                    );
                    props.setFieldValue("thumb_image", "");
                    props.setFieldError("thumb_image", "Image size: width = 368px and height = 200px")
                } else if (height < 200 || width < 368) {
                    alert(
                        "Image size: width = 368px and height = 200px"
                    );
                    props.setFieldValue("thumb_image", "");
                    props.setFieldError("thumb_image", "Image size: width = 368px and height = 200px")

                } else {
                    getBase64(file)
                        .then((result) => {
                            setImage(result);
                            // setImageUpdate(true);
                            props.setFieldValue("thumb_image", file);
                        })
                        .catch((err) => { });
                }
            };
        };
    };

    const getBase64 = (file) => {
        return new Promise((resolve) => {
            let fileInfo;
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };
    return (
        <>
            <ToastContainer />
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard/careers">Careers List</Link>
                    </li>
                    <li className="breadcrumb-item active">Add Career Job</li>
                </ol>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Add Career Job</h4>
                        </div>
                        <div className="card-body">
                            <Formik
                                initialValues={values}
                                onSubmit={FormSubmit}
                                enableReinitialize={true}
                                validationSchema={ValidateSchema}
                            >
                                {(props) => {
                                    return (
                                        <form noValidate='novalidate' onSubmit={props.handleSubmit} >
                                            <div className="row">
                                                <div className="col-lg-4 col-md-6 form-group">
                                                    <label>Branch Name <span className="text-danger">*</span></label>
                                                    <select
                                                        className='form-select'
                                                        onChange={props.handleChange}
                                                        name={`branch_id`}
                                                        value={props.values.branch_id}
                                                    >
                                                        <option value=''>Select branch</option>
                                                        {branch &&
                                                            branch.map((item) => (
                                                                <option key={item.id} value={item.id}>
                                                                    {item.text}
                                                                </option>
                                                            ))}
                                                    </select>
                                                    {props.touched.branch_id &&
                                                        props.errors.branch_id ? (
                                                        <div className='bg-error'>
                                                            {props.errors.branch_id}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="col-lg-4 col-md-6 form-group">
                                                    <label>Job Title <span className="text-danger">*</span></label>
                                                    <input type="text"
                                                        value={props.values.title}
                                                        className="form-control"
                                                        aria-describedby="title"
                                                        name="title"
                                                        placeholder="Enter title"
                                                        onBlur={props.handleBlur}
                                                        onChange={(event) => {
                                                            props.setFieldValue("title", event.target.value);
                                                            if (props.values.id === undefined) {
                                                                props.setFieldValue("slug", createSlug(event.target.value));
                                                            }
                                                        }}
                                                    />
                                                    {props.touched.title &&
                                                        props.errors.title ? (
                                                        <div className='bg-error'>
                                                            {props.errors.title}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="col-lg-4 col-md-6 form-group">
                                                    <label>Slug <span className="text-danger">*</span></label>
                                                    <input type="text" value={(props.values.id === undefined) ? createSlug(props.values.title) : props.values.slug}
                                                        className="form-control"
                                                        aria-describedby="slug"
                                                        name="slug"
                                                        placeholder="Enter slug"
                                                        onChange={props.handleChange}
                                                        readOnly disabled />
                                                    { }
                                                    {props.touched.slug &&
                                                        props.errors.slug ? (
                                                        <div className='bg-error'>
                                                            {props.errors.slug}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="col-lg-4 col-md-6 form-group">
                                                    <label>Job Type <span className="text-danger">*</span></label>
                                                    <select className="form-select" value={props.values.job_type} name="job_type" onChange={props.handleChange}>
                                                        <option>Select job type</option>
                                                        <option value='WFH (Work From Home)'>Work From Home (WFH)</option>
                                                        <option value='WFO (Work From Office)'>Work From Office (WFO)</option>
                                                        <option value='WFH + WFO (Work From Home + Work From Office)'>Hybrid (WFH + WFO)</option>
                                                    </select>
                                                    {props.touched.job_type &&
                                                        props.errors.job_type ? (
                                                        <div className='bg-error'>
                                                            {props.errors.job_type}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="col-lg-4 col-md-6 form-group">
                                                    <label>Employee Type <span className="text-danger">*</span></label>
                                                    <select className="form-select" value={props.values.employee_type} name="employee_type" onChange={props.handleChange}>
                                                        <option>Select employee type</option>
                                                        <option value='Full Time'>Full Time</option>
                                                        <option value='Part Time'>Part Time</option>
                                                        <option value='Internship'>Internship</option>
                                                        <option value='Contract'>Contract</option>
                                                        <option value='Freelancer'>Freelancer</option>
                                                        <option value='Seasonal'>Seasonal</option>
                                                        <option value='Temporary'>Temporary</option>
                                                    </select>
                                                    {props.touched.employee_type &&
                                                        props.errors.employee_type ? (
                                                        <div className='bg-error'>
                                                            {props.errors.employee_type}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="col-lg-4 col-md-6 form-group">
                                                    <label>Class Type <span className="text-danger">*</span></label>
                                                    <select className="form-select" value={props.values.class_type} name="class_type" onChange={props.handleChange}>
                                                        <option value="">Select class type</option>
                                                        <option value="school">School (Till 12th Standard)</option>
                                                        <option value="university">University</option>
                                                        <option value="preparation">Entrance Exam / Professional Courses</option>
                                                        <option value="tuition">Tuition</option>
                                                    </select>
                                                    {props.touched.class_type &&
                                                        props.errors.class_type ? (
                                                        <div className='bg-error'>
                                                            {props.errors.class_type}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {props.values.class_type === "tuition" && (
                                                    <div className="col-lg-4 col-md-6 form-group">
                                                        <label>Tuition Type <span className="text-danger">*</span></label>
                                                        <select className="form-select" value={props.values.tuition_type} name="tuition_type" onChange={props.handleChange}>
                                                            <option value="">Select tuition type</option>
                                                            <option value="school">School (Till 12th Standard)</option>
                                                            <option value="university">University</option>
                                                            <option value="preparation">Entrance Exam / Professional Courses</option>
                                                        </select>
                                                        {props.touched.tuition_type &&
                                                            props.errors.tuition_type ? (
                                                            <div className='bg-error'>
                                                                {props.errors.tuition_type}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                )}
                                                {(props.values.class_type === "school" || (props.values.class_type === "tuition" && props.values.tuition_type === "school")) && (
                                                    <div className="col-lg-4 col-md-6 form-group">
                                                        <label>Board <span className="text-danger">*</span></label>
                                                        <select
                                                            className='form-select'
                                                            onChange={props.handleChange}
                                                            name={`board`}
                                                            value={props.values.board}
                                                        >
                                                            <option value=''>Select board</option>
                                                            {board &&
                                                                board.map((item) => (
                                                                    <option key={item.title} value={item.title}>
                                                                        {item.title}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                        {props.touched.board &&
                                                            props.errors.board ? (
                                                            <div className='bg-error'>
                                                                {props.errors.board}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                )}
                                                {(props.values.class_type === "university" || (props.values.class_type === "tuition" && props.values.tuition_type === "university")) && (
                                                    <div className="col-lg-4 col-md-6 form-group">
                                                        <label>University <span className="text-danger">*</span></label>

                                                        <select
                                                            className='form-select'
                                                            onChange={props.handleChange}
                                                            name={`university`}
                                                            value={props.values.university}
                                                        >
                                                            <option value=''>Select university</option>
                                                            {university &&
                                                                university.map((item) => (
                                                                    <option key={item.name} value={item.name}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                        {props.touched.university &&
                                                            props.errors.university ? (
                                                            <div className='bg-error'>
                                                                {props.errors.university}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                )}
                                                <div className="col-lg-4 col-md-6 form-group">
                                                    <label>Medium <span className="text-danger">*</span></label>
                                                    <select
                                                        className='form-select'
                                                        onChange={props.handleChange}
                                                        name={`medium`}
                                                        value={props.values.medium}

                                                    >
                                                        <option value=''>Select medium</option>
                                                        {medium &&
                                                            medium.map((item) => (
                                                                <option key={item.title} value={item.title}>
                                                                    {item.title}
                                                                </option>
                                                            ))}
                                                    </select>
                                                    {props.touched.medium &&
                                                        props.errors.medium ? (
                                                        <div className='bg-error'>
                                                            {props.errors.medium}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="col-lg-4 col-md-6 form-group">
                                                    <label>Experience <span className="text-danger">*</span></label>
                                                    <select className="form-select" value={props.values.experience} name="experience" onChange={props.handleChange}>
                                                        <option>Select experience</option>
                                                        <option value="0 - 1 Year">0 - 1 Year</option>
                                                        <option value="1 - 2 Years">1 - 2 Years</option>
                                                        <option value="2 - 3 Years">2 - 3 Years</option>
                                                        <option value="3 - 5 Years">3 - 5 Years</option>
                                                        <option value="5 - 10 Years">5 - 10 Years</option>
                                                        <option value="More than 10 Years">More than 10 Years</option>
                                                    </select>
                                                    {props.touched.experience &&
                                                        props.errors.experience ? (
                                                        <div className='bg-error'>
                                                            {props.errors.experience}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="col-lg-4 col-md-6 form-group">
                                                    <label>Salary <span className="text-danger">*</span></label>
                                                    <select className="form-select" name="is_disclosed_salary" value={props.values.is_disclosed_salary} onChange={props.handleChange}>
                                                        <option>Select salary</option>
                                                        <option value={1}>Disclosed</option>
                                                        <option value={0}>Do not disclosed</option>
                                                    </select>
                                                    {props.touched.is_disclosed_salary &&
                                                        props.errors.is_disclosed_salary ? (
                                                        <div className='bg-error'>
                                                            {props.errors.is_disclosed_salary}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {props.values.is_disclosed_salary == 1 && (
                                                    <div className="col-lg-4 col-md-6 form-group">
                                                        <label>Salary Range (Amount in Rs.) <span className="text-danger">*</span></label>
                                                        <input type="text" value={props.values.salary} className="form-control" aria-describedby="name" name="salary" placeholder="Enter salary amount" onChange={props.handleChange} />
                                                        <p className="text-danger mb-0">Eg.: 1,50,000 to 2,80,000 P.A.</p>
                                                        {props.touched.salary &&
                                                            props.errors.salary ? (
                                                            <div className='bg-error'>
                                                                {props.errors.salary}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                )}
                                                <div className="col-lg-4 col-md-6 form-group">
                                                    <label>Thumbnail Image <span className="text-danger">*</span></label>
                                                    <input type="file"
                                                        className="form-control"
                                                        aria-describedby="name"
                                                        name="thumb_image"
                                                        onChange={(e) => handleFileInputChange(e, props)} />
                                                    <p className="text-danger mb-0">Note: Image size: width = 368px and height = 200px</p>
                                                    {props.touched.thumb_image &&
                                                        props.errors.thumb_image ? (
                                                        <div className='bg-error'>
                                                            {props.errors.thumb_image}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {(image || props.values.thumb_image) && (
                                                    <div className="col-md-2 form-group">
                                                        <img
                                                            className='img-thumbnail'
                                                            src={image || props.values.thumb_image}
                                                            height='200px'
                                                            width='368px'
                                                            alt='Branch Image'
                                                        />
                                                    </div>
                                                )}
                                                <div className="col-lg-4 col-md-6 form-group">
                                                    <label>Start Date <span className="text-danger">*</span></label>
                                                    <input type="date"
                                                        min={moment().format("YYYY-MM-DD")}
                                                        value={props.values.start_date}
                                                        className="form-control"
                                                        aria-describedby="name"
                                                        name="start_date"
                                                        onChange={props.handleChange} />
                                                    {props.touched.start_date &&
                                                        props.errors.start_date ? (
                                                        <div className='bg-error'>
                                                            {props.errors.start_date}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="col-md-4 col-md-6 form-group">
                                                    <label>Is End Date ? <span className="text-danger"> *</span></label>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            type="radio"
                                                            className="form-check-input"
                                                            value="1"
                                                            checked={props.values.is_end_job == "1"}
                                                            onChange={props.handleChange}
                                                            name="is_end_job"
                                                        />
                                                        <label className="form-check-label">yes</label>

                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            type="radio"
                                                            className="form-check-input"
                                                            value="0"
                                                            checked={props.values.is_end_job == "0"}
                                                            onChange={props.handleChange}
                                                            name="is_end_job"
                                                        />
                                                        <label className="form-check-label">no</label>
                                                    </div>
                                                    {props.touched.is_end_job &&
                                                        props.errors.is_end_job ? (
                                                        <div className='bg-error'>
                                                            {props.errors.is_end_job}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {props.values.is_end_job == 1 && (
                                                    <div className="col-lg-4 col-md-6 form-group">
                                                        <label>End Date <span className="text-danger">*</span></label>
                                                        <input type="date"
                                                            min={props.values.start_date || moment().format("YYYY-MM-DD")}
                                                            value={props.values.end_date}
                                                            className="form-control"
                                                            aria-describedby="name"
                                                            name="end_date"
                                                            onChange={props.handleChange} />
                                                        {props.touched.end_date &&
                                                            props.errors.end_date ? (
                                                            <div className='bg-error'>
                                                                {props.errors.end_date}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 form-group">
                                                    <label>Description <span className="text-danger">*</span></label>
                                                    <Editor
                                                        value={props.values.description}
                                                        textareaName='course_discription'
                                                        placeholder='Enter course decription'
                                                        apiKey='9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d'
                                                        init={{
                                                            plugins: [
                                                                "advlist",
                                                                "autolink",
                                                                "lists",
                                                                "link",
                                                                "image",
                                                                "charmap",
                                                                "preview",
                                                                "anchor",
                                                                "searchreplace",
                                                                "visualblocks",
                                                                "code",
                                                                "fullscreen",
                                                                "insertdatetime",
                                                                "media",
                                                                "table",
                                                                "help",
                                                                "wordcount",
                                                                "spellchecker",
                                                            ],

                                                            toolbar:
                                                                " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help |spellchecker ",
                                                        }}
                                                        onEditorChange={(e) =>
                                                            props.handleChange({
                                                                target: {
                                                                    name: `description`,
                                                                    value: e,
                                                                },
                                                            })
                                                        }
                                                    />
                                                    {props.touched.description &&
                                                        props.errors.description ? (
                                                        <div className='bg-error'>
                                                            {props.errors.description}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="col-lg-6 col-md-6 form-group">
                                                    <label>Job Responsibilities <span className="text-danger">*</span></label>
                                                    <Editor
                                                        value={props.values.job_responsibility}
                                                        textareaName='course_discription'
                                                        placeholder='Enter course decription'
                                                        apiKey='9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d'
                                                        init={{
                                                            plugins: [
                                                                "advlist",
                                                                "autolink",
                                                                "lists",
                                                                "link",
                                                                "image",
                                                                "charmap",
                                                                "preview",
                                                                "anchor",
                                                                "searchreplace",
                                                                "visualblocks",
                                                                "code",
                                                                "fullscreen",
                                                                "insertdatetime",
                                                                "media",
                                                                "table",
                                                                "help",
                                                                "wordcount",
                                                                "spellchecker",
                                                            ],

                                                            toolbar:
                                                                " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help |spellchecker ",
                                                        }}
                                                        onEditorChange={(e) =>
                                                            props.handleChange({
                                                                target: {
                                                                    name: `job_responsibility`,
                                                                    value: e,
                                                                },
                                                            })
                                                        }
                                                    />
                                                    {props.touched.job_responsibility &&
                                                        props.errors.job_responsibility ? (
                                                        <div className='bg-error'>
                                                            {props.errors.job_responsibility}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="col-lg-6 col-md-6 form-group">
                                                    <label>Requirements <span className="text-danger">*</span></label>
                                                    <Editor
                                                        value={props.values.requirment}
                                                        textareaName='course_discription'
                                                        placeholder='Enter course decription'
                                                        apiKey='9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d'
                                                        init={{
                                                            plugins: [
                                                                "advlist",
                                                                "autolink",
                                                                "lists",
                                                                "link",
                                                                "image",
                                                                "charmap",
                                                                "preview",
                                                                "anchor",
                                                                "searchreplace",
                                                                "visualblocks",
                                                                "code",
                                                                "fullscreen",
                                                                "insertdatetime",
                                                                "media",
                                                                "table",
                                                                "help",
                                                                "wordcount",
                                                                "spellchecker",
                                                            ],

                                                            toolbar:
                                                                " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help |spellchecker ",
                                                        }}
                                                        onEditorChange={(e) =>
                                                            props.handleChange({
                                                                target: {
                                                                    name: `requirment`,
                                                                    value: e,
                                                                },
                                                            })
                                                        }
                                                    />
                                                    {props.touched.requirment &&
                                                        props.errors.requirment ? (
                                                        <div className='bg-error'>
                                                            {props.errors.requirment}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="col-lg-6 col-md-6 form-group">
                                                    <label>About Company <span className="text-danger">*</span></label>
                                                    <Editor
                                                        value={props.values.about_company}
                                                        textareaName='about_company'
                                                        placeholder='Enter course decription'
                                                        apiKey='9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d'
                                                        init={{
                                                            plugins: [
                                                                "advlist",
                                                                "autolink",
                                                                "lists",
                                                                "link",
                                                                "image",
                                                                "charmap",
                                                                "preview",
                                                                "anchor",
                                                                "searchreplace",
                                                                "visualblocks",
                                                                "code",
                                                                "fullscreen",
                                                                "insertdatetime",
                                                                "media",
                                                                "table",
                                                                "help",
                                                                "wordcount",
                                                                "spellchecker",
                                                            ],

                                                            toolbar:
                                                                " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help |spellchecker ",
                                                        }}
                                                        onEditorChange={(e) =>
                                                            props.handleChange({
                                                                target: {
                                                                    name: `about_company`,
                                                                    value: e,
                                                                },
                                                            })
                                                        }
                                                    />
                                                    {props.touched.about_company &&
                                                        props.errors.about_company ? (
                                                        <div className='bg-error'>
                                                            {props.errors.about_company}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="col-md-12 text-end mt-5">
                                                    {loading ? (
                                                        <SubmitLoader />
                                                    ) : (
                                                        <>
                                                            <button type="submit" className="btn btn-primary me-2">
                                                                Submit
                                                            </button>
                                                            <Link
                                                                to="/institute-dashboard/careers"
                                                                className="btn btn-danger "
                                                            >
                                                                Cancel
                                                            </Link>
                                                        </>
                                                    )}
                                                </div>
                                            </div>

                                        </form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Careers;